
import { Component, Prop, Vue } from 'vue-property-decorator';
import DNSResolverList from '@/components/dns/DNSResolverList.vue';

@Component({
  components: {
    'dns-resolver-list': DNSResolverList,
  },
})
export default class DNSResolvers extends Vue {
  @Prop({ default: null }) private campusSlug!: string | null;
  @Prop({ default: false }) private fixedNetworkCampus!: boolean;
  @Prop({ default: false }) private canChangeCampus!: boolean;
}
