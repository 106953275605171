
import { Component, Prop } from 'vue-property-decorator';

import { Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import {
  Network,
  CampusSimple,
  Zone,
  Device,
  VlanWithZones,
  GatewayInterface,
} from '@/api/interfaces';
import { deviceIsRoutingCapable } from '@/helpers';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { getColorForVlanType } from '@/components/shared/helpers';
import { getFontColorBasedOnBackground } from '@/helpers';

const userModule = namespace(USER_NAMESPACE_PATH);

interface NetworkDetail extends Network {
  id: number;
  vlan: VlanWithZones;
  gateway_interfaces: GatewayInterface[];
}

@Component
export default class EditGatewaysModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private networks!: NetworkDetail[];
  @Prop() private campusSlug!: string;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private getColorForVlanType = getColorForVlanType;
  private getFontColorBasedOnBackground = getFontColorBasedOnBackground;

  private campus: CampusSimple | null = null;
  private campusZones: Zone[] | null = null;
  private filteredRoutingDevices: Device[] = [];
  private routingDevices: Device[] = [];
  private zones: Zone[] = [];
  private errors: string[] = [];
  private networksRoutingDevices: {
    [key: number]: { [key: number]: { gateway: boolean; l3_enabled: boolean } };
  } = {};
  private loading = {
    initial: false,
    submit: false,
  };
  private refreshKey = 0;

  private submit(): void {
    this.loading.submit = true;

    const data = this.networks.map((n) => {
      let devices = [];
      for (const [key, value] of Object.entries(
        this.networksRoutingDevices[n.id],
      )) {
        if (value.gateway) {
          devices.push({
            device_id: parseInt(key),
            l3_enabled: value.l3_enabled,
          });
        }
      }
      return {
        id: n.id,
        routing_devices: devices,
      };
    });

    repositories.lan.network
      .editGateways(data)
      .then(() => {
        // fetch tasks state so that it immediatelly updates the badges
        this.fetchTasksStateAction();
        // we don't have L3 interfaces so we can't really pass anything useful
        // back to the parent component - theoretically API could return them but we
        // will implement this only if someone will complain about this solution
        this.ok(null);
      })
      .catch((error: any) => {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
        this.loading.submit = false;
      });
  }

  private intersection(...arrays: any[]) {
    return arrays.reduce((a, b) => a.filter((c: any) => b.includes(c)));
  }

  private async created() {
    this.loading.initial = true;
    try {
      await Promise.all([this.fetchCampus(), this.fetchZones()]);
      this.errors = [];
      for (const network of this.networks) {
        if (
          this.campusZones!.filter(
            (zone) =>
              zone.lanonly &&
              (network.vlan as VlanWithZones).zones
                .map((z) => z.id)
                .includes(zone.id),
          ).length > 0
        ) {
          this.errors.push(`Omrežje ${network.name} je del lokalne cone.`);
        }
      }
      const zones = this.campusZones!.filter((zone) =>
        this.networks.every((network) =>
          (network.vlan as VlanWithZones).zones
            .map((z) => z.id)
            .includes(zone.id),
        ),
      );
      this.zones = this.intersection(zones);
      this.filterRoutingDevices();
      this.filteredRoutingDevices.forEach((device) => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;
        this.networks.forEach((network) => {
          const networkId = network.id as number;
          const l3_routing_interfaces = network.gateway_interfaces.filter(
            (intf) => {
              return (
                device.id === intf.device.id &&
                ['l3_switch', 'router'].includes(intf.device.function) &&
                intf.device.roles.some((role) => ['tsp', 'cpe'].includes(role))
              );
            },
          );
          if (l3_routing_interfaces.length > 0) {
            this.addRoutingDevice(device);
          }
          l3_routing_interfaces.forEach((intf) => {
            vm.networksRoutingDevices[networkId][device.id] = {
              gateway: true,
              l3_enabled: intf.enabled,
            };
          });
        });
      });
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'omrežju' },
        ).toString(),
      );
      return;
    } finally {
      this.loading.initial = false;
    }
  }

  private addRoutingDevice(device: Device) {
    if (!this.routingDevices.some((d) => d.id === device.id)) {
      // Add device key to networksRoutingDevices
      this.routingDevices.push(device);
      this.selectOptionForAllNetworks(device, {
        gateway: false,
        l3_enabled: false,
      });
    }
  }

  private selectOptionForAllNetworks(
    device: Device,
    option: { gateway: boolean; l3_enabled: boolean },
  ) {
    for (const network of this.networks) {
      const networkId = network.id as number;
      if (!this.networksRoutingDevices[networkId]) {
        this.networksRoutingDevices[networkId] = {};
      }

      this.networksRoutingDevices[network.id][device.id] = option;
    }
    this.refreshKey++;
  }

  private removeRoutingDevice(device: Device) {
    this.routingDevices = this.routingDevices.filter((d) => d.id !== device.id);
    // Remove device id key from networksRoutingDevices
    for (const network of this.networks) {
      delete this.networksRoutingDevices[network.id][device.id];
    }
  }

  get notSelectedRoutingDevices() {
    return this.filteredRoutingDevices.filter(
      (device: Device) => !this.routingDevices.some((d) => d.id === device.id),
    );
  }

  private async fetchCampus() {
    try {
      const { data } = await repositories.tenants.campus.getCampus(
        this.campusSlug,
      );
      this.campus = data;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
      throw error;
    }
  }

  private filterRoutingDevices() {
    if (this.campusZones === null) {
      return [];
    }
    const routingDevices: Device[] = [];
    const zones = this.zones;
    for (const zone of zones) {
      for (const device of zone.devices!) {
        if (!routingDevices.some((d) => d.id === device.id)) {
          routingDevices.push(device);
        }
      }
    }
    // convert a set to a list so that you can use filter
    const routingDevicesInAllZones = [...routingDevices].filter((device) =>
      zones.every((zone) => zone.devices!.some((d) => d.id === device.id)),
    );
    // filter by routing capability
    this.filteredRoutingDevices = routingDevicesInAllZones.filter(
      deviceIsRoutingCapable,
    );
  }

  private async fetchZones() {
    try {
      const { data } = await repositories.zoning.zone.getZones(this.campusSlug);
      this.campusZones = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'zonah' },
        ).toString(),
      );
      throw error;
    }
  }
}
