
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { UserSettings, Theme } from '@/store/types';
import { USER_SETTINGS } from '@/store/getters.type';
import { CHANGE_THEME } from '@/store/actions.type';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class GeneralSettings extends Vue {
  @userModule.Getter(USER_SETTINGS) public userSettings!: UserSettings;
  @userModule.Action(CHANGE_THEME) public changeThemeAction!: (
    theme: Theme,
  ) => Promise<any>;

  public darkTheme = false;

  public created() {
    this.darkTheme = this.userSettings.theme.dark;
  }

  public submit(): void {
    const newTheme: Theme = {
      dark: this.darkTheme,
    };
    this.changeThemeAction(newTheme);
    this.$toasted.show('Nastavitve so bile shranjene', {
      icon: 'mdi-settings-outline',
      type: 'success',
    });
  }
}
