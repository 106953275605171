
import { Component, Prop, Vue } from 'vue-property-decorator';
import DHCPServerList from '@/components/dhcp/DHCPServerList.vue';

@Component({
  components: {
    'dhcp-server-list': DHCPServerList,
  },
})
export default class CampusDHCPServers extends Vue {
  @Prop({ default: null }) private campusSlug!: string | null;
  @Prop({ default: false }) private fixedNetworkCampus!: boolean;
  @Prop({ default: false }) private canChangeCampus!: boolean;
}
