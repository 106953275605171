
import axios from 'axios';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, IS_ROID } from '@/store/getters.type';
import { Editor, EditorContent } from '@tiptap/vue-2';

import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Highlight from '@tiptap/extension-highlight';
import Placeholder from '@tiptap/extension-placeholder';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Gapcursor from '@tiptap/extension-gapcursor';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';

import { createImageExtension } from './ImageExtension';
import { RichTextDoc, Upload, SelectEl } from '@/api/interfaces';
import MenuBar from './MenuBar.vue';
import Toolbar from './Toolbar.vue';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { getRichTextDocPolicyRepr } from '@/components/shared/helpers';
import { deepClone } from '@/helpers';
import ListAttachments from '@/components/shared/ListAttachments.vue';

const userModule = namespace(USER_NAMESPACE_PATH);

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: (element) => {
          return {
            backgroundColor: element.getAttribute('data-background-color'),
          };
        },
        renderHTML: (attributes) => {
          return {
            'data-background-color': attributes.backgroundColor,
            'style': `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
});

@Component({
  components: {
    EditorContent,
    MenuBar,
    Toolbar,
    ListAttachments,
  },
})
export default class DocEditor extends Vue {
  public $refs!: {
    editorContent: any;
  };
  @Prop({ required: true }) public document!: RichTextDoc;
  @Prop() private adminOnly!: boolean;
  @Prop({ default: false }) private showDeleteIcon!: boolean;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_ROID) private isRoid!: boolean;

  private editor: any = null;
  private editable = false;
  private savedContent: any = null;
  private loading = {
    initial: false,
    save: false,
    rename: false,
  };
  private policy: SelectEl | null = null;
  private savedPolicy = '';
  private roidCanEdit = false;
  private savedRoidCanEdit = false;
  private availablePolicies: SelectEl[] = [];
  private attachments: Upload[] = [];
  private toolbar: { leftItems: any[]; rightItems: any[] } = {
    leftItems: [],
    rightItems: [],
  };

  private get showToolbar() {
    if (!this.editor || !this.editable) {
      return false;
    }
    const allToolbarItems: any[] = this.toolbar.leftItems.concat(
      this.toolbar.rightItems,
    );
    return allToolbarItems
      .filter((item) => item.type !== 'divider')
      .some((item) => item.show());
  }

  private async uploadImage(file: File) {
    try {
      const { data } = await repositories.documentation.upload.createUpload(
        this.document.id,
        'richtextdoc',
        'image',
        file,
      );
      // we must return whatever the 'src' of an image is because DropImagePlugin
      // expects that
      return data.stored_name!;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }

  private created() {
    this.savedContent = deepClone(this.document.content);
    this.editor = new Editor({
      editable: this.editable,
      content: this.savedContent,
      extensions: [
        StarterKit,
        createImageExtension(
          this.uploadImage as (file: File) => Promise<string>,
        ),
        Image.configure({ inline: true }),
        Highlight,
        Placeholder.configure({
          placeholder: 'Vsebina dokumenta je trenutno prazna',
          showOnlyWhenEditable: false,
        }),
        TaskList,
        TaskItem,
        BulletList,
        OrderedList,
        ListItem,
        Gapcursor,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        // Custom TableCell with backgroundColor attribute
        CustomTableCell,
        Link.configure({
          openOnClick: false,
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph', 'image'],
          alignments: ['left', 'center', 'right'],
        }),
      ],
    });
    // there are cases where editor.getJSON()['content'] will immediatelly
    // diverge from document.content's value without user making these changes
    // (eg. if document.content has 'href' element which has no 'class' attribute
    // then editor's getJSON will return `"class": null` in its json. So the
    // initial savedContent is set to whatever editor outputs as json.
    setTimeout(() => {
      this.savedContent = this.editor.getJSON();
    });
    if (this.isAdmin) {
      this.availablePolicies = [
        { text: getRichTextDocPolicyRepr('admin'), value: 'admin' },
        {
          text: getRichTextDocPolicyRepr('admin|support'),
          value: 'admin|support',
        },
      ];
      if (!this.adminOnly) {
        this.availablePolicies.push({
          text: getRichTextDocPolicyRepr('admin|support|roid'),
          value: 'admin|support|roid',
        });
      }
    } else {
      this.availablePolicies = [];

      if (!this.adminOnly) {
        this.availablePolicies = [
          {
            text: getRichTextDocPolicyRepr('admin|support|roid'),
            value: 'admin|support|roid',
          },
        ];
      }
    }
    this.policy = this.availablePolicies.find(
      (el) => el.value === this.document.policy,
    ) as SelectEl;
    if (this.policy) {
      this.savedPolicy = this.policy!.value;
    }
    this.roidCanEdit = !this.document.readonly;
    this.savedRoidCanEdit = this.roidCanEdit;
    this.toolbar = {
      leftItems: [
        {
          icon: 'insert-row-top',
          title: 'Dodaj vrstico zgoraj',
          action: () => this.editor.chain().focus().addRowBefore().run(),
          show: () => this.editor.can().addRowBefore(),
        },
        {
          icon: 'insert-row-bottom',
          title: 'Dodaj vrstico spodaj',
          action: () => this.editor.chain().focus().addRowAfter().run(),
          show: () => this.editor.can().addRowAfter(),
        },
        {
          icon: 'delete-row',
          title: 'Izbriši vrstico',
          action: () => this.editor.chain().focus().deleteRow().run(),
          show: () => this.editor.can().deleteRow(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'insert-column-left',
          title: 'Dodaj stolpec levo',
          action: () => this.editor.chain().focus().addColumnBefore().run(),
          show: () => this.editor.can().addColumnBefore(),
        },
        {
          icon: 'insert-column-right',
          title: 'Dodaj stolpec desno',
          action: () => this.editor.chain().focus().addColumnAfter().run(),
          show: () => this.editor.can().addColumnAfter(),
        },
        {
          icon: 'delete-column',
          title: 'Izbriši stolpec',
          action: () => this.editor.chain().focus().deleteColumn().run(),
          show: () => this.editor.can().deleteColumn(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'merge-cells-horizontal',
          title: 'Združi celice',
          action: () => this.editor.chain().focus().mergeCells().run(),
          show: () => this.editor.can().mergeCells(),
        },
        {
          icon: 'split-cells-horizontal',
          title: 'Razdruži celice',
          action: () => this.editor.chain().focus().splitCell().run(),
          show: () => this.editor.can().splitCell(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'layout-row-fill',
          title: 'Preklopi naslovno vrstico',
          action: () => this.editor.chain().focus().toggleHeaderRow().run(),
          show: () => this.editor.can().toggleHeaderRow(),
        },
        {
          icon: 'layout-column-fill',
          title: 'Preklopi naslovni stolpec',
          action: () => this.editor.chain().focus().toggleHeaderColumn().run(),
          show: () => this.editor.can().toggleHeaderColumn(),
        },
        {
          icon: 'checkbox-blank-line',
          title: 'Preklopi naslovno celico',
          action: () => this.editor.chain().focus().toggleHeaderCell().run(),
          show: () => this.editor.can().toggleHeaderCell(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'delete-bin-line',
          title: 'Izbriši tabelo',
          action: () => this.editor.chain().focus().deleteTable().run(),
          show: () => this.editor.can().deleteTable(),
        },
      ],
      rightItems: [],
    };
    this.fetchAttachments();
  }

  private get policyRepr() {
    return getRichTextDocPolicyRepr(this.document.policy);
  }

  @Watch('editable')
  private onEditableChange(val: boolean) {
    this.editor.setEditable(val);
  }

  @Watch('policy')
  private onPolicyChange(val: SelectEl | null) {
    if (val == null || !val.value.includes('roid')) {
      this.roidCanEdit = false;
    }
  }

  private async fetchAttachments() {
    this.loading.initial = true;
    try {
      const { data } = await repositories.documentation.upload.getUploads(
        'richtextdoc',
        this.document.id + '',
        'attachment',
      );
      this.attachments = data.results;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.initial = false;
  }

  public closeDocument() {
    if (this.editable) {
      if (this.docHasChanges()) {
        const answer = window.confirm(
          'Dokument ima neshranjene spremembe. Ste prepričani, da ga želite zapreti?',
        );
        if (!answer) {
          return false;
        }
      }
      this.cancelEditing();
    }
    return true;
  }

  private cancelEditing() {
    // reset the content
    this.editor.commands.setContent(this.savedContent);
    this.editable = false;
  }

  private async renameDocument(name: string) {
    this.loading.rename = true;
    try {
      const { data } =
        await repositories.documentation.richtextdoc.updateRichTextDoc(
          this.document.id,
          name,
        );
      this.$toasted.success('Dokument preimenovan');
      this.$emit('rename', data);
    } catch (error: unknown) {
      if (error && axios.isAxiosError(error)) {
        if (error.response && error.response.status === 400) {
          this.$toasted.error(
            'Datoteka s takim ali podobnim imenom že obstaja.',
          );
        } else {
          this.$toasted.error(
            new ErrorHandler({ error, status: true }).toString(),
          );
        }
      }
    }
    this.loading.rename = false;
  }

  private async saveDocument() {
    this.loading.save = true;
    try {
      await repositories.documentation.richtextdoc.updateRichTextDoc(
        this.document.id,
        this.document.name,
        JSON.stringify(this.editor.getJSON()),
        this.policy!.value,
        !this.roidCanEdit,
      );
      this.savedContent = this.editor.getJSON();
      this.document.readonly = !this.roidCanEdit;
      this.document.policy = this.policy!.value;
      this.savedPolicy = this.policy!.value;
      this.savedRoidCanEdit = this.roidCanEdit;
      this.$toasted.success('Dokument shranjen');
      this.cancelEditing();
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.save = false;
  }

  private async printDocument() {
    window.print();
  }

  private beforeDestroy() {
    this.editor.destroy();
  }

  private canEditDocument() {
    return (
      this.isAdmin ||
      (this.isRoid &&
        this.document.policy.includes('roid') &&
        !this.document.readonly)
    );
  }

  public docHasChanges() {
    return (
      JSON.stringify(this.savedContent) !==
        JSON.stringify(this.editor.getJSON()) ||
      (this.policy != null && this.savedPolicy !== this.policy!.value) ||
      this.savedRoidCanEdit !== this.roidCanEdit
    );
  }
}
