// auth
export const STATUS = 'status';
export const CSRF_TOKEN = 'csrfToken';
export const AUTH_TYPE = 'authType';

// user - don't add SETTINGS here, use getter for it!
export const USER = 'user';
export const IS_ADMIN = 'isAdmin';
export const IS_ROID = 'isRoid';
export const IS_SUPPORT = 'isSupport';

// global
export const LOADING = 'loading';
export const SHOW_MODAL = 'showModal';

// circuits
export const CIRCUITS = 'circuits';
