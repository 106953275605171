
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RichTextDoc, Upload } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN_OR_ROID, IS_ADMIN } from '@/store/getters.type';
import ErrorHandler from '@/components/shared/errorHandler';
import DocEditor from '@/components/shared/editor/DocEditor.vue';
import { deepClone } from '@/helpers';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    DocEditor,
  },
})
export default class ListDocuments extends Vue {
  @Prop() private objectId!: string;
  @Prop() private modelName!: string;
  @Prop() private attachmentTitle!: string;
  @Prop() private canCreateDoc!: () => boolean;
  @Prop() private canDeleteDoc!: (doc: RichTextDoc) => boolean;
  @Prop({ default: false }) private adminOnly!: boolean;
  @Prop({ required: true }) private documents!: RichTextDoc[];
  @Prop({ default: null }) private docSlug!: string | null;
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  private loading = { attachments: true };
  private tab: string | null = null;
  private attachments: Upload[] = [];
  // we can't mutate the prop, so we clone the documents and use the cloned variable
  private mutableDocuments: RichTextDoc[] = [];

  private created() {
    let tab = 'tab-0';
    this.mutableDocuments = deepClone(this.documents);
    if (this.docSlug) {
      const matchingDocIdx = this.sortedMutableDocs.findIndex(
        (doc) => doc.slug === this.docSlug,
      );
      if (matchingDocIdx === -1) {
        this.$toasted.error('Podan dokument ni na voljo');
      } else {
        tab = 'tab-' + matchingDocIdx;
      }
    }
    if (this.documents.length > 0) {
      this.tab = tab;
    }
    this.fetchAttachments();
  }

  private get sortedMutableDocs() {
    return [...this.mutableDocuments].sort((a, b) => {
      if (a.modified! > b.modified!) {
        return -1;
      }
      if (a.modified! < b.modified!) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Change url when tab changes so that you can copy it
   */
  @Watch('tab')
  private onTabChange(val: any) {
    const params: { [key: string]: string } = { docSlug: '' };
    if (val) {
      const docIdx = val.split('-')[1];
      params['docSlug'] = this.sortedMutableDocs[docIdx].slug;
    }
    this.$router
      .push({
        name: this.$route.name!,
        params: {
          ...this.$route.params,
          ...params,
        },
      })
      .catch((err) => {
        return null;
      });
  }

  private async fetchAttachments() {
    this.loading.attachments = true;
    try {
      const { data } = await repositories.documentation.upload.getUploads(
        this.modelName,
        this.objectId,
        'attachment',
      );
      this.attachments = data.results;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.attachments = false;
  }

  private showRichTextDocCreateModal() {
    const promise: Promise<RichTextDoc> = this.$modals.open(
      'app-add-richtextdoc-modal',
      {
        component: {
          props: {
            objectId: this.objectId,
            modelName: this.modelName,
            adminOnly: this.adminOnly,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<RichTextDoc>;
    promise.then((doc: RichTextDoc) => {
      this.mutableDocuments.push(doc);
      // find the index of this document in sorted docs
      const tabIdx = this.sortedMutableDocs.findIndex((el) => el.id == doc.id);
      this.tab = `tab-${tabIdx}`;
      // if the new documents falls to the same index as the previously opened one
      // in the sortedMutableDocs then the watch isn't triggered so we trigger it
      // manually to update the url
      this.onTabChange(this.tab);
    });
  }

  private openDeleteDocModal(doc: RichTextDoc) {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `dokument ${doc.name}`,
            item: doc,
          },
          deleteFn: this.deleteDocument,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteDocument(modal: any, doc: RichTextDoc) {
    repositories.documentation.richtextdoc
      .deleteRichTextDoc(doc.id)
      .then(() => {
        this.$toasted.success('Dokument je bil uspešno izbrisan.');
        this.mutableDocuments = this.mutableDocuments.filter(
          (el) => el.id !== doc.id,
        );
        this.$modals.close();
        // currently viewed document got deleted, go to the first document
        this.tab = this.mutableDocuments.length > 0 ? 'tab-0' : null;
        // if the newly focused document falls to the same index (0) as the previously
        // opened one in the sortedMutableDocs then the watch isn't triggered so we
        // trigger it manually to update the url
        this.onTabChange(this.tab);
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { message: 'Med brisanjem dokumenta je prišlo do napake.' },
          ).toString(),
        );
        modal.deleting = false;
      });
  }

  private handleDocChange(event: Event) {
    const activeDocEditor = (this.$refs['docEditor'] as DocEditor[]).find(
      (el) =>
        el.document.id ===
        this.sortedMutableDocs[Number(this.tab!.split('-')[1])].id,
    ) as DocEditor;
    if (!activeDocEditor.closeDocument()) {
      event.stopPropagation();
    }
  }

  private updateDoc(document: RichTextDoc) {
    const docIdx = this.mutableDocuments.findIndex(
      (el) => el.id === document.id,
    );
    this.mutableDocuments[docIdx] = document;
    // manually trigger changes in mutableDocuments
    this.mutableDocuments = this.mutableDocuments.filter((el) => true);
    // trigger tab update so that url updates
    const tabIdx = this.sortedMutableDocs.findIndex(
      (el) => el.id === document.id,
    );
    this.tab = `tab-${tabIdx}`;
    this.onTabChange(this.tab);
  }

  private showDocumentationHelpModal() {
    const promise: Promise<any> = this.$modals.open(
      'app-documentation-help-modal',
      {
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<any>;
  }
}
