
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Interface } from '@/api/interfaces';
import { getPortType } from '@/components/shared/helpers';

@Component
export default class InterfaceInfo extends Vue {
  @Prop() private iface!: Interface;
  private getPortType = getPortType;
}
