
import { Component, Prop, Vue } from 'vue-property-decorator';
import { StatsL2Interface } from '@/api/interfaces';

@Component
export default class InterfaceSTP extends Vue {
  @Prop() private ifaceFacts!: StatsL2Interface;
  @Prop() private stpSummary!: {
    text: string;
    color: string;
    enabled: boolean;
  } | null;

  private get stpFacts() {
    let list: {
      key: string;
      value: string | number | null;
      text: string;
      color?: string;
    }[] = [];
    if (this.stpSummary != null) {
      list.push({
        key: 'summary',
        value: this.stpSummary.text,
        text: 'Povzetek',
        color: this.stpSummary.color,
      });
    }
    if (!(this.ifaceFacts == null || this.ifaceFacts.stp == null)) {
      list.push(
        ...[
          {
            key: 'state',
            value: this.ifaceFacts.stp.state,
            text: 'Stanje',
          },
          {
            key: 'role',
            value: this.ifaceFacts.stp.role,
            text: 'Vloga',
          },
          {
            key: 'cost',
            value: this.ifaceFacts.stp.cost,
            text: 'Strošek (cost)',
          },
        ],
      );

      if (this.ifaceFacts.stp.type != null) {
        list.push({
          key: 'type',
          value: this.ifaceFacts.stp.type,
          text: 'Tip',
        });
      }
    }

    return list;
  }
}
