import axios, { AxiosPromise } from 'axios';
import { Street, House, ExtendedHouse } from '@/api/interfaces';
import { Query } from '../query';

const resources = { default: 'tenants/ehis' };

interface EhisApiInterface {
  getHouses(mid: number): AxiosPromise<House[]>;
  getExtendedHouse(mid: number): AxiosPromise<ExtendedHouse>;
  searchStreets(search: string): AxiosPromise<Street[]>;
}

const EhisApi: EhisApiInterface = {
  getHouses(mid) {
    return axios.get(`${resources.default}/get-houses/${mid}/`);
  },

  getExtendedHouse(mid) {
    return axios.get(`${resources.default}/get-house/${mid}/`);
  },

  searchStreets(search) {
    const query = new Query({ search });
    return axios.get(`${resources.default}/get-streets/${query}`);
  },
};

export default EhisApi;
export { EhisApiInterface };
