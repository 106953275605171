export function transformData(
  data: Record<string, unknown>,
  ignoreProps: string[],
  fks: string[],
) {
  const transformedData = Object.assign({}, data);
  fks.forEach((prop) => {
    if (typeof (transformedData as any)[prop] === 'object') {
      (transformedData as any)[prop] = (transformedData as any)[prop].id;
    }
  });
  ignoreProps.forEach((prop) => {
    delete (transformedData as any)[prop];
  });
  return transformedData;
}
