// This import needs to be executed before the Vuetify import
import 'vuetify/dist/vuetify.min.css';

import Vue from 'vue';
import Vuetify from 'vuetify';
import en from 'vuetify/src/locale/en';
import sl from 'vuetify/src/locale/sl';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#E6832A',
        secondary: '#69737E',
        subsecondary: '#C7C8C9',
        accent: '#FFa300',
        error: '#f4242f',
        warning: '#f2da02',
        info: '#0096f4',
        success: '#53d635',
      },
      dark: {
        primary: '#E6832A',
        secondary: '#69737E',
        subsecondary: '#878F97',
        accent: '#cf7525',
        error: '#D11A34',
        warning: '#D0B901',
        info: '#0074D1',
        success: '#52B91C',
      },
    },
  },
  lang: {
    locales: { sl, en },
    current: 'sl',
  },
  // Arnes theme from material design colors
  // theme: {
  //   primary: 'ff9800',
  //   secondary: '#607d8b',
  //   accent: '#009688',
  //   error: '#ff5722',
  //   warning: '#ffc107',
  //   info: '#03a9f4',
  //   success: '#8bc34a',
  // },
});
