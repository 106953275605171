import { render, staticRenderFns } from "./SiteDocs.vue?vue&type=template&id=3f5a91ba&scoped=true&"
import script from "./SiteDocs.vue?vue&type=script&lang=ts&"
export * from "./SiteDocs.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f5a91ba",
  null
  
)

export default component.exports