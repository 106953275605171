
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import PersistentModal from '@/components/shared/modals/PersistentModal';

@Component
export default class AddAttachmentModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private objectId!: number | string;
  @Prop() private modelName!: string;
  private file: any = null;
  private loading = { submit: false };
  private uploadType = 'attachment';

  private async addAttachment() {
    this.loading.submit = true;
    try {
      const { data } = await repositories.documentation.upload.createUpload(
        this.objectId,
        this.modelName,
        this.uploadType,
        this.file,
      );
      this.ok(data);
      this.$toasted.success('Datoteka je bila uspešno naložena.');
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.submit = false;
  }
}
