
import { Component, Vue } from 'vue-property-decorator';
import {
  getColorForVlanType,
  getIconForVlanType,
  getSloveneNetworkType,
} from '@/components/shared/helpers';
import { getFontColorBasedOnBackground } from '@/helpers';

@Component
export default class InterfaceLegend extends Vue {
  // private legend = require('@/assets/ports/legenda.png');
  private networkTypes = [
    'ztp',
    'wan',
    'infrastructure',
    'user-default',
    'user-pedag',
    'user-pedag2',
    'user-admin',
    'user-admin2',
    'user-it',
    'user-eduroam',
    'user-voip',
    'user-other1',
    'user-other2',
    'user-other3',
    'tagged',
  ];

  private nets = this.networkTypes.map((nt) => {
    return {
      color: getColorForVlanType(nt),
      textColor: getFontColorBasedOnBackground(getColorForVlanType(nt)),
      translation: getSloveneNetworkType(nt),
      icon: getIconForVlanType(nt),
    };
  });
}
