
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  DeviceSvg,
  PortGroupSvg,
  PortGroupDrawConf,
  PortDrawConf,
  PortGroupDto,
  PortDto,
  LabelDrawConf,
  SkeletonPortDrawConf,
  ShapeDrawConf,
} from 'draw-network-devices';
import { PortGroup, Product, PGInterface } from '@/api/interfaces';
import { getPortType, getColorForVlanType } from './helpers';
import _get from 'lodash.get';
import isEqual from 'lodash.isequal';
import debounce from 'lodash.debounce';

type PortClickedEvent = Event & {
  detail: { portId: string; selected: boolean };
};

type SelectedPortsChanged = Event & {
  detail: { selectedPorts: string[] };
};

@Component
export default class DrawProduct extends Vue {
  @Prop(Object) private product!: Product;
  @Prop(Boolean) private multiple!: boolean;

  private drawing: DeviceSvg | null = null;
  private portGroups: { [key: string]: number } = {};
  private lastId = 1;
  private slider = 1.7;

  public redraw(): void {
    this.clearImage();
    this.initDrawing();
  }

  @Watch('product', { deep: true })
  private redrawOnDeviceInfoChange(oldVal: any, newVal: any): void {
    if (!isEqual(oldVal, newVal)) {
      this.redraw();
    }
  }

  private debouncedRedraw = debounce(() => {
    this.redraw();
  }, 500);

  @Watch('slider')
  private redrawOnSliderChange(): void {
    this.debouncedRedraw();
  }

  private initDrawing(): void {
    if (this.product) {
      this.drawing = new DeviceSvg({
        name: _get(this.product, 'info.name', ''),
        selector: '#drawing',
        size: _get(this.product, 'info.size', {
          x: 800,
          y: 110,
        }),
        scale: this.slider,
        radius: _get(this.product, 'info.radius'),
        backgroundColor: _get(this.product, 'info.background_color', '#aaa'),
        portGroups: [],
        onlyOneSelected: !this.multiple,
      });
      this.draw(this.drawing);
    }
  }

  private addPortGroup(id: number, pg: PortGroup): void {
    if (!this.drawing) {
      throw Error('No drawing created');
    }

    const portGroupDto: PortGroupDto = {
      numberOfPorts: pg.interfaces.length,
      data: {},
    };

    const portGroupDrawConf: PortGroupDrawConf = {
      rotated: pg.rotated,
      twoLines: pg.two_lines,
      mixedCount: pg.mixed_count,
      margin: pg.margin,
      startingPoint: { x: pg.starting_point.x, y: pg.starting_point.y },
      interfaces: [],
      paper: this.drawing.getPaperGroup(),
    };

    const portGroup = this.drawing.createAddPortGroup(
      id,
      portGroupDto,
      portGroupDrawConf,
    );

    // Add ports to port group
    pg.interfaces.forEach((portIface) => {
      const portData = this.getPortDtoInfo(portIface);

      if (portData) {
        const portDrawConf = this.getPortDrawInfo(
          portData.id,
          portGroup,
          portIface,
        );

        if (
          (portData.port === null || (portData.port && portData.portActive)) &&
          !portDrawConf.hidden // TODO: remove when draw-network-devices will support.
        ) {
          portGroup.addPort(portData, portDrawConf, this.multiple);
        }
      }
    });
  }

  private getPortDtoInfo(port: PGInterface): PortDto | null {
    if (port.port) {
      if (!this.portGroups[port.port]) {
        this.portGroups[port.port] = this.lastId;
        this.lastId = this.lastId + 1;
      }
    }

    const portData: PortDto = {
      id: Math.ceil(Math.random() * 100000),
      vid: 1, // TODO: use vid
      formFactor: getPortType(port.form_factor) as
        | 'RJ45'
        | 'SFP'
        | 'QSFP'
        | 'MISC',
      usage: '',
      enabled: true,
      state: 'up',
      poe: false,
      switchPortMode: 'access',
      managed: true,
      port: port.port ? this.portGroups[port.port] : null,
      portActive: _get(port, 'port_active', false),
      trustPolicy: '',
      backgroundColor: getColorForVlanType('ztp'),
    };

    return portData;
  }

  private getPortDrawInfo(
    portId: number,
    portGroup: PortGroupSvg,
    port: PGInterface,
    startingPaper = true,
  ) {
    const portDrawConf: PortDrawConf = {
      paper: startingPaper ? portGroup.getPaperGroup() : null,
      shortName: port.short_name,
      name: port.name,
      label: port.label,
      rotated: false, // is set in library
      hidden: port.hidden,
      selected: false,
      selectedColor: '#50FF00',
      position: { x: 0, y: 0 },
      disabled: false,
    } as PortDrawConf;

    return portDrawConf;
  }

  private clearImage() {
    const draw = document.querySelector('#drawing');
    const svgimg = document.querySelector('#drawing > svg');
    if (draw && svgimg) {
      draw.removeChild(svgimg);
    }
  }

  private addLabels(): void {
    const labels: LabelDrawConf[] = _get(this.product, 'info.labels', []);
    if (labels) {
      labels.forEach((label: LabelDrawConf) => {
        if (this.drawing) {
          this.drawing.createLabel({
            paper: this.drawing.getPaperGroup(),
            name: label.name,
            hidden: false,
            color: label.color,
            fontSize: label.fontSize,
            fontWeight: label.fontWeight,
            fontFamily: label.fontFamily,
            rotation: label.rotation, // rotation in degrees
            position: label.position,
          });
        }
      });
    }
  }

  private addSkeletonPorts(): void {
    const sPorts: SkeletonPortDrawConf[] = _get(
      this.product,
      'info.skeletonPorts',
      [],
    );
    if (sPorts) {
      sPorts.forEach((port: SkeletonPortDrawConf) => {
        if (this.drawing) {
          this.drawing.createSkeletonPort({
            paper: this.drawing.getPaperGroup(),
            hidden: port.hidden,
            color: port.color,
            formFactor: port.formFactor,
            rotation: port.rotation, // rotation in degrees
            position: port.position,
          });
        }
      });
    }
  }

  private addShapes(): void {
    const shapes: ShapeDrawConf[] = _get(this.product, 'info.shapes', []);
    if (shapes) {
      shapes.forEach((shape: ShapeDrawConf) => {
        if (this.drawing) {
          this.drawing.createShape(
            // vue has RangeError _traverse problem so object needs to be recreated here
            {
              paper: this.drawing.getPaperGroup(),
              hidden: shape.hidden,
              borderWidth: shape.borderWidth,
              borderColor: shape.borderColor,
              filled: shape.filled,
              fillColor: shape.fillColor,
              rotation: shape.rotation,
              position: shape.position,
              properties: shape.properties,
            },
          );
        }
      });
    }
  }

  private draw(parent: DeviceSvg): void {
    if (!this.drawing) {
      throw Error('Trying to draw before initial');
    }
    this.addShapes();
    this.addSkeletonPorts();
    this.addLabels();
    const portGroups: PortGroup[] = _get(this.product, 'info.port_groups', []);
    portGroups.forEach((pg: PortGroup, index) => {
      this.addPortGroup(index, pg);
    });
    parent.draw();
  }

  private mounted() {
    this.initDrawing();
  }
}
