
import { Component, Vue } from 'vue-property-decorator';
import Login from '@/views/Login.vue';
import { IS_LOGGED_IN, ROLES, USER } from '../store/getters.type';
import { LOGOUT } from '@/store/actions.type';
import { LogoutOptions } from '@/store/types';
import Dashboard from '@/components/shared/Dashboard.vue';
import { User } from '@/api/interfaces';
import { namespace } from 'vuex-class';
import {
  AUTH_NAMESPACE_PATH,
  USER_NAMESPACE_PATH,
} from '../store/namespaces.type';

const authModule = namespace(AUTH_NAMESPACE_PATH);
const userModule = namespace(USER_NAMESPACE_PATH);

@Component({ components: { Login, Dashboard } })
export default class Home extends Vue {
  @authModule.Getter(IS_LOGGED_IN) public isLoggedIn!: boolean;
  @authModule.Action(LOGOUT) public logoutAction!: (
    options?: LogoutOptions,
  ) => Promise<any>;
  @userModule.Getter(ROLES) public roles!: string[];
  @userModule.Getter(USER) public user!: User | null;
  private images = [
    { src: require('../assets/automator_logo.svg') },
    { src: require('../assets/automator_text.svg') },
  ];
  private banner = require('../assets/logo-eu-arnes.png');
  private logoText = require('../assets/automator_text.svg');
  private logoTextWhite = require('../assets/automator_text_white.svg');

  private logout() {
    if (!this.isLoggedIn) {
      this.$router.push({ name: 'home' });
      return;
    }
    this.logoutAction();
  }
}
