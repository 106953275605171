
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { Network, Zone } from '@/api/interfaces';
import Autocomplete from '@/components/shared/Autocomplete.vue';

@Component({
  components: {
    Autocomplete,
  },
})
export default class PickZonesModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop({ default: null }) private network!: Network | null;
  @Prop() private zones!: Zone[];
  private selectedZones: Zone[] = [];

  private submit() {
    this.ok(this.selectedZones);
  }
}
