
import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { repositories } from '@/api/ApiFactory';
import { LogoutOptions } from '@/store/types';
import { copyToClipboard } from '../../utils/other';
import { AUTH_NAMESPACE_PATH } from '@/store/namespaces.type';
import { LOGOUT } from '@/store/actions.type';

const authModule = namespace(AUTH_NAMESPACE_PATH);

@Component
export default class ScriptsSettings extends Vue {
  @authModule.Action(LOGOUT) public logoutAction!: (
    options?: LogoutOptions,
  ) => Promise<any>;
  public loading = {
    gettingToken: false,
    changingUserSigningKey: false,
  };
  public generatedJWT: string | null = null;
  public copied = false;

  public generateScriptingJWT() {
    this.loading.gettingToken = true;
    const options = {
      withCredentials: true,
    };
    axios
      .post(this.$config.generateScriptingJWTUrl, {}, options)
      .then((result) => {
        this.loading.gettingToken = false;
        this.generatedJWT = result.data.jwt;
      })
      .catch((error: unknown) => {
        this.loading.gettingToken = false;
        this.generatedJWT = null;
        this.$toasted.show('Generiranje JWT žetona ni uspelo', {
          icon: 'mdi-alert-circle-outline',
          type: 'error',
        });
      });
  }

  public async changeUserSigningKey() {
    this.loading.changingUserSigningKey = true;
    try {
      await repositories.people.user.changeSigningKey();
      this.loading.changingUserSigningKey = false;
      this.$toasted.success(
        'Obstoječi žetoni so bili uspešno preklicani, potrebna je ponovna prijava',
      );
      this.logoutAction({ backend: false, redirectTo: { name: 'login' } });
    } catch (error) {
      this.$toasted.show('Preklic obstoječih žetonov ni bil uspešen', {
        icon: 'mdi-alert-circle-outline',
        type: 'error',
      });
    }
    this.loading.changingUserSigningKey = false;
  }

  public copyJWTToClipboard() {
    const inputEl = (this.$refs.jwtField as Vue).$el.getElementsByTagName(
      'input',
    )[0];
    copyToClipboard(inputEl);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 2000);
  }
}
