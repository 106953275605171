
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  CIRCUITS_NAMESPACE_PATH,
  USER_NAMESPACE_PATH,
} from '@/store/namespaces.type';
import {
  CREATE_CIRCUIT,
  UPDATE_CIRCUIT,
  FETCH_TASKS_STATE,
} from '@/store/actions.type';
import { Site, Device, Interface, CircuitInfo } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { AxiosResponse } from 'axios';
import { PaginatedResponse } from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';

const circuitsModule = namespace(CIRCUITS_NAMESPACE_PATH);
const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class CircuitForm extends Vue {
  @Prop({ default: null }) private campusSlug!: string | null;
  @Prop({ type: Boolean, default: false }) private editMode!: boolean | null;
  @Prop({ default: null }) private circuit!: {
    id: number;
    status: string;
    type: string;
    campusDeviceID: number | null;
    campusInterfaceID: number | null;
    campusInterfaceDescription: string | null;
    siteSlug: string | null;
    nodeDeviceID: number | null;
    nodeInterfaceID: number | null;
    nodeInterfaceDescription: string | null;
  } | null;
  @circuitsModule.Action(CREATE_CIRCUIT)
  private createCircuitAction!: (circuit: {
    campusSlug: string;
    status: string;
    type: string;
    campusInterfaceID: number | null;
    campusInterfaceDescription: string | null;
    nodeInterfaceID: number | null;
    nodeInterfaceDescription: string | null;
  }) => Promise<CircuitInfo>;
  @circuitsModule.Action(UPDATE_CIRCUIT)
  private updateCircuitAction!: (circuit: {
    id: number;
    status?: string;
    type?: string;
    campusInterfaceID?: number | null;
    campusInterfaceDescription?: string | null;
    nodeInterfaceID?: number | null;
    nodeInterfaceDescription?: string | null;
  }) => Promise<CircuitInfo>;

  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;

  private sites: Site[] = [];
  private site: Site | null = null;
  private status = '';
  private nodeDevices: Device[] = [];
  private nodeDevice: Device | null = null;
  private nodeInterfaces: Interface[] = [];
  private nodeInterface: Interface | null = null;
  private nodeInterfaceDescription: string | null = null;
  private campusDevices: Device[] = [];
  private campusDevice: Device | null = null;
  private campusInterfaces: Interface[] = [];
  private campusInterface: Interface | null | undefined = null;
  private campusInterfaceDescription: string | null = null;
  private connectionType = 'l2';

  private statuses = [
    { text: 'Planirana', value: 'planned' },
    { text: 'Aktivna', value: 'active' },
  ];
  private types = [
    {
      text: 'L2',
      value: 'l2',
      help:
        'Gre za povezave, kjer je na vozliščni strani TSP naprava, na kampus strani pa stikalo. Vmesnika se bosta nastavila na tip \'infrastruktura\'. Vsi VLANi kampusa (ki niso lanonly) so dovoljeni na vmesnikih te povezave.\n\n' +
        'Pri povezavi s statusom \'planirana\', bodo izbrani vmesniki označeni kot \'v uporabi\', vendar se še ne bodo konfigurirali. Če je povezava planirana, je lahko vmesnik na kampus strani prazen.\n\n' +
        'Preden je možno povezavo postavit v \'aktivno\' stanje, mora biti TSP naprava umeščena v globalne omrežne cone kampusa in določen vmesnik na kampus strani.',
    },
    {
      text: 'Tunel',
      value: 'tunnel',
      help: 
        'Gre za povezave, kjer je CPE naprava usmerjevalnik, kateri je običajno povezan v IP omrežje zunanjega operaterja. CPE dodatno vzpostavi tunel do Arnes koncentratorja. Promet se usmerja skozi tunel.\n\n' +
        'Na kampus strani se izbere fizični vmesnik, ki je povezan v omrežje operaterja, ki ponuja povezljivost.',
    },
    {
      text: 'L3',
      value: 'l3',
      help: 
        'Gre za povezave, kjer je CPE naprava usmerjevalnik in je povezana na hrbtenični usmerjevalnik ali TSP.\n\n' +
        'Vmesnike, ki so del te povezave, NOC ročno konfigurira direktno na napravah. Ti vmesniki imajo v automatorju nastavljen tip \'ZTP\' in izključeno avtomatsko konfiguriranje.',
    },
  ];

  private loading = {
    sites: true,
    nodeDevices: false,
    nodeInterfaces: false,
    campusDevices: true,
    campusInterfaces: false,
    creating: false,
    edit: false,
  };
  private get typeHelpText() {
    return (
      this.types.find((t) => t.value === this.connectionType)?.help ||
      'Izberite tip povezave.'
    );
  }

  private get campusInterfacesWithDisabled() {
    return this.campusInterfaces.map((intf: Interface) => {
      const not_active = intf.port !== null && !intf.port_active;
      const already_used =
        intf.circuit !== null &&
        (!this.editMode ||
          (this.editMode && this.circuit?.id !== intf.circuit?.id));

      const disabled = not_active || already_used;
      return { ...intf, ...{ disabled, already_used } };
    });
  }

  private get nodeInterfacesWithDisabled() {
    return this.nodeInterfaces.map((intf: Interface) => {
      const not_active = intf.port !== null && !intf.port_active;
      const already_used =
        intf.circuit !== null &&
        (!this.editMode ||
          (this.editMode && this.circuit?.id !== intf.circuit?.id));

      const disabled = not_active || already_used;
      return { ...intf, ...{ disabled, already_used } };
    });
  }

  @Watch('nodeInterface')
  private setNodeInterfaceDescription() {
    if (this.nodeInterface) {
      this.nodeInterfaceDescription = this.nodeInterface.description;
    } else {
      this.nodeInterfaceDescription = null;
    }
  }

  @Watch('campusInterface')
  private setCampusInterfaceDescription() {
    if (this.campusInterface) {
      this.campusInterfaceDescription = this.campusInterface.description;
    } else {
      this.campusInterfaceDescription = null;
    }
  }

  private async created() {
    if (this.editMode) {
      this.loading.edit = true;
    }

    try {
      const { data } = await repositories.connectivity.site.getSites();
      this.sites = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { message: 'Napaka pri pridobivanju vozlišč.' },
        ).toString(),
      );
    } finally {
      this.loading.sites = false;
    }

    if (this.editMode && this.circuit) {
      this.status = this.circuit.status;
      this.connectionType = this.circuit.type;

      this.site =
        this.sites.find((site: Site) => site.slug === this.circuit?.siteSlug) ||
        null;
      await Promise.all([
        this.populateNodeDevices(),
        this.populateCampusDevices(),
      ]);

      const interfacePopulateFunctions = [];

      this.nodeDevice = this.circuit.nodeDeviceID
        ? this.nodeDevices.find(
            (device: Device) => device.id === this.circuit?.nodeDeviceID,
          ) || null
        : null;
      if (this.nodeDevice) {
        interfacePopulateFunctions.push(
          this.populateInterfaces(this.nodeDevice, 'node'),
        );
      }

      this.campusDevice = this.circuit.campusDeviceID
        ? this.campusDevices.find(
            (device: Device) => device.id === this.circuit?.campusDeviceID,
          ) || null
        : null;
      if (this.campusDevice) {
        interfacePopulateFunctions.push(
          this.populateInterfaces(this.campusDevice, 'campus'),
        );
      }

      await Promise.all(interfacePopulateFunctions);

      if (this.nodeDevice) {
        this.nodeInterface = this.circuit.nodeInterfaceID
          ? this.nodeInterfaces.find(
              (intf: Interface) => intf.id === this.circuit?.nodeInterfaceID,
            ) || null
          : null;
      }
      if (this.campusDevice) {
        this.campusInterface = this.circuit.campusInterfaceID
          ? this.campusInterfaces.find(
              (intf: Interface) => intf.id === this.circuit?.campusInterfaceID,
            ) || null
          : null;
      }

      this.loading.edit = false;
    } else {
      await this.populateCampusDevices();
    }
  }

  private async populateCampusDevices() {
    // tsp supports L2, L3 and routers, backbone doesn't support L2
    const nodeDeviceType = 'tsp';
    const functions = ['router', 'l3_switch'];
    if (
      this.nodeDevice == null ||
      !this.nodeDevice!.roles!.some((role) => role.name === 'Backbone')
    ) {
      // node device isn't a backbone device, so L2 switch is also ok
      functions.push('l2_switch');
    }
    this.loading.campusDevices = true;
    try {
      const { data } = await repositories.infrastructure.device.getDevices({
        campusSlug: this.campusSlug as string,
        roles: ['cpe'],
        functions,
      });
      this.campusDevices = data.results;
      // reset selected campus device if it's not in the new list
      if (
        this.campusDevice != null &&
        !this.campusDevices.some(
          (device) => device.id === this.campusDevice!.id,
        )
      ) {
        this.campusDevice = null;
        this.campusInterface = null;
        this.campusInterfaces = [];
      }
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { message: 'Napaka pri pridobivanju naprav kampusa.' },
        ).toString(),
      );
    } finally {
      this.loading.campusDevices = false;
    }
  }

  private createConnection() {
    if (this.connectionType === null) {
      this.$toasted.error('Izberite tip povezave.');
      return;
    }
    this.loading.creating = true;
    this.createCircuitAction({
      campusSlug: this.campusSlug as string,
      status: this.status,
      type: this.connectionType,
      campusInterfaceID: this.campusInterface
        ? (this.campusInterface as Interface).id
        : null,
      campusInterfaceDescription: this.campusInterfaceDescription,
      nodeInterfaceID: this.nodeInterface
        ? (this.nodeInterface as Interface).id
        : null,
      nodeInterfaceDescription: this.nodeInterfaceDescription,
    })
      .then((circuit: CircuitInfo) => {
        // fetch tasks state so that it immediatelly updates the badges
        this.fetchTasksStateAction();
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
        this.loading.creating = false;
      });
  }

  private updateCircuit() {
    this.loading.creating = true;
    if (this.editMode && this.circuit) {
      let body: {
        id: number;
        status?: string;
        type?: string;
        campusInterfaceID?: number | null;
        campusInterfaceDescription?: string | null;
        nodeInterfaceID?: number | null;
        nodeInterfaceDescription?: string | null;
      } = {
        id: this.circuit.id,
      };
      if (this.status !== this.circuit.status) {
        body['status'] = this.status;
      }
      if (
        this.connectionType !== this.circuit.type &&
        this.connectionType !== null
      ) {
        body['type'] = this.connectionType;
      }
      if (this.circuit.campusInterfaceID !== this.campusInterface?.id) {
        body['campusInterfaceID'] = this.campusInterface?.id || null;
      }
      if (
        this.campusInterface?.id &&
        (this.circuit.campusInterfaceDescription !==
          this.campusInterfaceDescription ||
          this.circuit.campusInterfaceID !== this.campusInterface?.id)
      ) {
        body['campusInterfaceDescription'] = this.campusInterfaceDescription;
      }

      if (this.circuit.nodeInterfaceID !== this.nodeInterface?.id) {
        body['nodeInterfaceID'] = this.nodeInterface?.id || null;
      }

      if (
        this.nodeInterface?.id != null &&
        this.circuit.nodeInterfaceDescription !== this.nodeInterfaceDescription
      ) {
        body['nodeInterfaceDescription'] = this.nodeInterfaceDescription;
      }

      this.updateCircuitAction(body)
        .then((circuit: CircuitInfo) => {
          // fetch tasks state so that it immediatelly updates the badges
          this.fetchTasksStateAction();
          this.$modals.close();
        })
        .catch((error) => {
          this.$toasted.error(
            new ErrorHandler({ error, status: true }).toString(),
          );
          this.loading.creating = false;
        });
    }
  }

  private async populateNodeDevices() {
    // if connection type is tunnel then node device must be empty
    if (this.site == null || this.connectionType === 'tunnel') {
      if (this.connectionType === 'tunnel') {
        this.site = null;
      }
      this.nodeDevices = [];
      this.nodeDevice = null;
      this.nodeInterfaces = [];
      this.nodeInterface = null;
      return;
    }
    let roles = [];
    if (this.connectionType === 'l2' || this.connectionType === 'l3') {
      roles.push('tsp');
    }
    if (this.connectionType === 'l3') {
      roles.push('backbone');
    }
    this.loading.nodeDevices = true;
    try {
      const { data } =
        await repositories.infrastructure.device.searchSiteDevices({
          siteSlug: this.site!.slug,
          roles,
        });
      this.nodeDevices = data.results;
      if (
        this.nodeDevice != null &&
        !data.results.some(
          (device: Device) => device.id === this.nodeDevice!.id,
        )
      ) {
        this.nodeDevice = null;
      }
      if (this.nodeDevice === null) {
        this.nodeInterfaces = [];
        this.nodeInterface = null;
      }
      this.loading.nodeDevices = false;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { message: 'Napaka pri pridobivanju naprav v vozlišču.' },
        ).toString(),
      );
      this.loading.nodeDevices = false;
    }
  }

  private async populateInterfaces(device: Device | null, type: string) {
    if (device === null) {
      if (type === 'node') {
        this.nodeInterfaces = [];
        this.nodeInterface = null;
      } else {
        this.campusInterfaces = [];
        this.campusInterface = null;
      }
      return;
    }
    if (type === 'campus') {
      this.loading.campusInterfaces = true;
    } else {
      this.loading.nodeInterfaces = true;
    }
    await repositories.infrastructure.interface
      .getInterfacesByDeviceId(device.id, { physicalOnly: true })
      .then((resp: AxiosResponse<PaginatedResponse<Interface>>) => {
        const data = resp.data.results;
        if (type === 'node') {
          this.nodeInterfaces = data;
          this.nodeInterface = null;
          this.loading.nodeInterfaces = false;
        } else {
          this.campusInterfaces = data;
          this.campusInterface = null;
          this.loading.campusInterfaces = false;
        }
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { message: 'Napaka pri pridobivanju vmesnikov.' },
          ).toString(),
        );
      });
  }
}
