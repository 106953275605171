
import { Component, Prop, Vue } from 'vue-property-decorator';
import CampusUserNetworks from '@/components/campus/CampusUserNetworks.vue';

import Lists, { Button } from '@/components/shared/Lists.vue';
import ErrorHandler from '@/components/shared/errorHandler';

import { CampusWlc, CampusManagement, Zone, ZoneGroup } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { IS_ADMIN, IS_SUPPORT } from '@/store/getters.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
const userModule = namespace(USER_NAMESPACE_PATH);

interface CampusManagementListItem {
  title: string;
  content: string | null;
  extraContent: { text: string; icon?: string | null }[];
}

@Component({
  components: {
    'app-list': Lists,
    'app-user-networks': CampusUserNetworks,
  },
})
export default class Networks extends Vue {
  @Prop() private campusSlug!: string;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_SUPPORT) private isSupport!: boolean;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private networksKey = 1;

  private zoneGroupsButtons: Button[] = [
    {
      name: '?',
      action: () => this.showZoneHelpModal(),
      icon: 'help',
      fab: true,
      rounded: true,
      onlyIcon: true,
      asyncAction: false,
      xsmall: true,
    },
    {
      name: 'Uredi',
      action: () => this.showAssignZoneGroupsModal(),
      icon: 'pencil',
      asyncAction: true,
      rounded: true,
      small: true,
    },
  ];

  private campusManagementNetworkButtons: Button[] = [
    {
      name: '?',
      action: () => this.showCampusManagementNetworkHelpModal(),
      icon: 'help',
      fab: true,
      rounded: true,
      onlyIcon: true,
      asyncAction: false,
      xsmall: true,
    },
    {
      name: 'Uredi',
      action: () => this.showAssignCampusManagementNetworkModal(),
      icon: 'pencil',
      asyncAction: true,
      rounded: true,
      small: true,
    },
  ];

  private zones: { loading: boolean; items: Zone[] } = {
    loading: true,
    items: [],
  };

  private management: { loading: boolean; items: CampusManagement[] } = {
    loading: true,
    items: [],
  };

  private wlcs: { loading: boolean; items: CampusWlc[] | null } = {
    loading: true,
    items: null,
  };

  private get parsedZones(): any {
    const pZones = [];
    for (const zone of this.zones.items) {
      let zoneGroupsRepr = '/';
      if (zone.zone_groups!.length > 0) {
        zoneGroupsRepr = zone
          .zone_groups!.map((zoneGroup) => zoneGroup.slug as string)
          .join(', ');
      }
      pZones.push({
        name: `${zone.name} (${zone.slug})`,
        content: `Del TSP/skupine: ${zoneGroupsRepr}`,
        actions: [
          {
            icon: 'mdi-pencil',
            callback: this.openZoneEditModal.bind(null, zone),
          },
        ],
      });
    }

    return pZones;
  }

  private openZoneEditModal(zone: Zone) {
    const promise: Promise<Zone> = this.$modals.open('app-zone-edit-modal', {
      component: {
        props: {
          campusSlug: this.campusSlug,
          zoneId: zone.id,
          zoneName: zone.name,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    }) as Promise<Zone>;

    promise.then((modifiedZone: Zone) => {
      // we could manually fix zone but it's safer and easier to just fetch
      // again - zone changes also happen very rarely
      this.fetchZones();
    });
  }

  private get parsedWlcs(): any {
    if (this.wlcs.items === null) {
      return [];
    }
    const pWlc = [];
    for (const wlc in this.wlcs.items) {
      if (Object.prototype.hasOwnProperty.call(this.wlcs.items, wlc)) {
        const element = this.wlcs.items[wlc];
        if (element.wlc) {
          pWlc.push({
            name: element.wlc.name,
            primary_address: element.wlc.primary_address,
            href: `https://${element.wlc.name}`,
          });
        }
      }
    }
    return pWlc;
  }

  private get parsedManagement(): CampusManagementListItem[] {
    const listItems: CampusManagementListItem[] = [];

    this.management.items.forEach((campusManagement: CampusManagement) => {
      let managementNetwork = 'Omrežje: /';
      let parsedCampusManagement: CampusManagementListItem = {
        title: `Podomrežje: ${campusManagement.subnet.address}/${campusManagement.subnet.prefixlen}`,
        content: managementNetwork,
        extraContent: [],
      };

      // Network can be null if subnet is not assigned to any network.
      const network = campusManagement.subnet.network;
      if (network) {
        // There can be only one management network per subnet.
        managementNetwork = `Omrežje: ${network.name} (VLAN ID: ${
          network.vlan?.vid || '/'
        }) `;
        let gateway = 'Prehod: /';
        let interfaces: { text: string; icon: string | null }[] = [];

        if (network.gateway) {
          // There could be zero or one gateway assigned to network.
          gateway = `Prehod: ${network.gateway.address}/${network.gateway.prefixlen} na napravah:`;
          // Gatway can have zero or more interfaces.
          interfaces = network.gateway.interfaces.map((l3Iface) => {
            return {
              text: `- ${l3Iface.device.name}: ${l3Iface.name || '/'}`,
              icon: l3Iface.enabled ? null : 'mdi-network-off-outline',
            };
          });
          Object.assign(parsedCampusManagement, {
            content: managementNetwork,
            extraContent: [{ text: gateway }, ...interfaces],
          });
        }
      }
      listItems.push(parsedCampusManagement);
    });
    return listItems;
  }

  private async fetchZones(): Promise<void> {
    this.zones.loading = true;
    try {
      const { data } = await repositories.zoning.zone.getZones(this.campusSlug);
      this.zones.items = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'zonah v omrežju' },
        ).toString(),
      );
    }
    this.zones.loading = false;
  }

  private async fetchCampusManagement(): Promise<void> {
    this.management.loading = true;
    try {
      const { data } =
        await repositories.provision.campusManagement.getCampusManagementForCampus(
          this.campusSlug,
        );
      this.management.items = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'management omrežju' },
        ).toString(),
      );
    }
    this.management.loading = false;
  }

  private async fetchCampusWlcs(): Promise<void> {
    this.wlcs.loading = true;
    try {
      const { data } = await repositories.provision.campusWlc.getCampusWlcs(
        this.campusSlug,
      );
      this.wlcs.items = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'wlcju' },
        ).toString(),
      );
    }
    this.wlcs.loading = false;
  }
  private created() {
    if (this.isAdmin || this.isSupport) {
      this.fetchZones();
      this.fetchCampusManagement();
      this.fetchCampusWlcs();
    }
  }

  private get wlcButtons() {
    if (this.wlcs.items === null) {
      return [];
    }
    const buttons: Button[] = [
      {
        name: '?',
        action: () => this.showAssignWLCHelpModal(),
        icon: 'help',
        fab: true,
        rounded: true,
        onlyIcon: true,
        asyncAction: false,
        xsmall: true,
      },
    ];
    if (this.parsedWlcs.length === 0) {
      buttons.push({
        name: 'Dodeli',
        action: this.assignCampusWLC.bind(this),
        icon: 'plus-thick',
        asyncAction: true,
        rounded: true,
        small: true,
      });
    }
    return buttons;
  }

  private async assignCampusWLC() {
    await repositories.tenants.campus.assignWLC(this.campusSlug);
    this.fetchTasksStateAction();
  }

  private showAssignWLCHelpModal() {
    this.$modals.open('app-assignwlc-help-modal', {
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private showZoneHelpModal() {
    this.$modals.open('app-zone-help-modal', {
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private showCampusManagementNetworkHelpModal() {
    this.$modals.open('app-campus-management-network-help-modal', {
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private showAssignZoneGroupsModal() {
    const promise: Promise<ZoneGroup[]> = this.$modals.open(
      'app-assign-zonegroups-modal',
      {
        component: {
          props: {
            campusSlug: this.campusSlug,
            currentZoneGroupSlugs: this.zones.items.flatMap((zone) =>
              zone.zone_groups!.map((zonegroup) => zonegroup.slug as string),
            ),
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<ZoneGroup[]>;

    promise.then((newZoneGroups: ZoneGroup[]) => {
      // we could manually fix zonegroups of this.zones.items but it's safer and
      // easier to just fetch again - zonegroup changes also happen very rarely
      this.fetchZones();
      this.networksKey += 1;
    });
  }

  private showAssignCampusManagementNetworkModal() {
    if (this.management.items !== null && this.management.items.length > 1) {
      this.$toasted.error(
        'Dovoljeno je največ eno kampus management omrežje. Kontaktirajte podporo.',
      );
      return;
    }
    let campusManagement = this.management.items[0] || null;

    const promise: Promise<CampusManagement[]> = this.$modals.open(
      'app-assign-campus-management-network-modal',
      {
        component: {
          props: {
            campusSlug: this.campusSlug,
            campusManagement: campusManagement,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<CampusManagement[]>;

    promise.then((campusManagementNetwork: CampusManagement[]) => {
      this.fetchCampusManagement();
    });
  }
}
