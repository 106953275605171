
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { repositories } from '@/api/ApiFactory';
import NetboxStatusChip from './NetboxStatusChip.vue';
import { Representations } from '@/components/shared/representation';
import ErrorHandler from '@/components/shared/errorHandler';
import debounce from 'lodash.debounce';
import Breadcrumbs from '@/views/Breadcrumbs.vue';
import {
  CampusSimple,
  NetboxAsset,
  DataOptions,
  Product,
} from '@/api/interfaces';
import {
  isAssetEnabledByStatus,
  isAssetSupportedDeviceModel,
} from '@/helpers';

const userModule = namespace(USER_NAMESPACE_PATH);
import _get from 'lodash.get';
@Component({
  components: {
    breadcrumbs: Breadcrumbs,
    NetboxStatusChip,
  },
})
export default class AssetsSearch extends Vue {
  @userModule.Getter(IS_ADMIN) public isAdmin!: boolean;
  @Prop() private value!: Record<string, unknown>[];
  /* TODO: currently it is not possible to select multiple assets because change of search string will remove all selected assets
   * Need to implement:
   * - cache selected items
   * - display cached items in the table
   */
  @Prop({ default: true }) private singleSelect!: boolean;
  @Prop({ default: true }) private showSearch!: boolean;
  @Prop() private errors!: string[];
  @Prop({ default: null }) private deviceTypeModelName!: string | null;
  @Prop({ default: null }) private kind!: string | null;
  @Prop({ default: false }) private allowAssetsInUse!: boolean;
  @Prop({ default: null }) private campusSlug!: string | null;
  @Prop({ default: false }) private showFilterOrgCheckbox!: boolean;

  private repr = new Representations();
  private indeterminate = true;
  private rowsPerPageItems = [50, 200, 500, 1000];
  private search = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );
  private excludeExternalOrgs = true;
  private netboxAssets: NetboxAsset[] = [];
  private totalNetboxAssets = 0;
  private paginationOptions: DataOptions = {
    groupBy: [],
    groupDesc: [],
    sortBy: [],
    sortDesc: [false],
    page: 1,
    itemsPerPage: 50,
    multiSort: false,
    mustSort: false,
  };
  private loading = false;
  private expanded: NetboxAsset[] = [];
  private campus: CampusSimple | null = null;
  private supportedProducts: string[] = [];
  private getLodash = _get;
  private headers = [
    {
      text: 'Proizvajalec',
      sortable: false,
      value: 'device_type.manufacturer.name',
    },
    {
      text: 'Model',
      sortable: false,
      value: 'device_type.model',
    },
    {
      text: 'Serijska številka',
      sortable: false,
      value: 'serial',
    },
    {
      text: 'MAC naslov',
      sortable: false,
      value: 'custom_fields.asset_mac',
    },
    {
      text: 'Status',
      sortable: false,
      value: 'status',
    },
    {
      text: 'Dostava',
      sortable: false,
      value: 'custom_fields.delivery_status',
    },
    {
      text: 'Dodeljeno',
      sortable: false,
      value: 'tenant.name',
      inExpansion: true,
    },
    {
      text: 'Lastnik',
      sortable: false,
      value: 'owner.name',
      inExpansion: true,
    },
    {
      text: 'Inventarna številka',
      sortable: false,
      value: 'asset_tag',
      inExpansion: true,
    },
    {
      text: 'Datum nakupa',
      sortable: false,
      value: 'purchase.date',
      inExpansion: true,
    },
    {
      text: 'Potek garancije',
      sortable: false,
      value: 'warranty_end',
      inExpansion: true,
    },
    {
      text: 'Sprememba dostave',
      sortable: false,
      value: 'custom_fields.delivery_status_date',
      inExpansion: true,
    },
    {
      text: 'Kraj hrambe',
      sortable: false,
      value: 'storage_location.display',
      inExpansion: true,
    },
    {
      text: '',
      value: 'data-table-expand',
    },
  ];
  private get headersForTable() {
    return {
      headers: this.headers.filter((header) => {
        if (this.$vuetify.breakpoint.mdAndDown) {
          return !header.inExpansion;
        }
        return true;
      }),
      expansion: this.headers.filter((header) => {
        if (this.$vuetify.breakpoint.mdAndDown) {
          return header.inExpansion;
        } else {
          return false;
        }
      }),
    };
  }

  private get disabledStatuses() {
    if (this.allowAssetsInUse) {
      return ['retired'];
    } else {
      return ['used', 'retired'];
    }
  }

  async created() {
    await this.fetchProducts();
    if (this.campusSlug) {
      await this.fetchCampus();
      // NOTE: this.paginationOptions changes when data-table element gets created,
      // which triggers fetchNetboxAssets. When we pass campusSlug fetchCampus request
      // won't return result in time, so we refetch netbox assets after we get campus data.
      this.fetchNetboxAssets();
    }
  }

  private async fetchCampus() {
    try {
      const { data } = await repositories.tenants.campus.getCampus(
        this.campusSlug!,
      );
      this.campus = data;
    } catch (error) {
      this.$toasted.error('Podatkov o kampusu ni bilo mogoče pridobiti.');
    }
  }

  private async fetchProducts() {
    try {
      const { data } = await repositories.infrastructure.product.getProducts();
      this.supportedProducts = data.results.map(
        (product: Product) => product.name,
      );
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }

  private async fetchNetboxAssets() {
    this.loading = true;
    try {
      const searchData: {
        kind: string | null;
        page: number;
        itemsPerPage: number;
        model: string | null;
        tenants?: string;
        search?: string;
      } = {
        kind: this.kind,
        page: this.paginationOptions!.page,
        itemsPerPage: this.paginationOptions!.itemsPerPage,
        model: null,
      };
      if (this.search === '' || this.search === null) {
        if (!this.campusSlug) {
          if (this.isAdmin) {
            searchData['tenants'] = 'arnes';
            searchData['model'] = 'C9500-48Y4C';
          } else {
            this.netboxAssets = [];
            this.totalNetboxAssets = 0;
            return;
          }
        } else {
          if (!this.campus) {
            this.netboxAssets = [];
            this.totalNetboxAssets = 0;
            return;
          } else {
            searchData['tenants'] = this.campus!.organizations!.map(
              (org) => org.portal_id,
            ).join(',');
            searchData['model'] = this.deviceTypeModelName;
          }
        }
      } else {
        searchData['search'] = this.search;
        searchData['model'] = this.deviceTypeModelName;
      }
      // remove tenants filter if checkbox for excluding external orgs is not checked
      if (
        'tenants' in searchData &&
        this.showFilterOrgCheckbox &&
        !this.excludeExternalOrgs
      ) {
        delete searchData.tenants;
      }
      const { data } =
        await repositories.infrastructure.asset.searchNetboxAssets(searchData);
      this.netboxAssets = data.results.map((asset: NetboxAsset) => {
        return {
          ...asset,
          isSelectable:
            isAssetEnabledByStatus(asset, this.disabledStatuses) &&
            isAssetSupportedDeviceModel(asset, this.supportedProducts),
        };
      });
      this.totalNetboxAssets = data.count;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    } finally {
      if (this.value.length > 0) {
        // Check if current selected asset is in the list of fetched assets. If not, remove it from the selected list
        // NOTE: we wouldn't need that IF but then the field would become "dirty" and error would be displayed
        // immediatelly when the page would be rendered, which we don't want.
        this.valueChanged(
          'input',
          this.value.filter((selectedAsset) => {
            return this.netboxAssets.some((asset) => {
              return asset.id === selectedAsset.id;
            });
          }),
        );
      }
    }

    this.loading = false;
  }

  private expandRow(el: NetboxAsset) {
    let remove = null;
    this.expanded.forEach((value, index) => {
      if (el.id === value.id) {
        remove = index;
      }
    });
    if (remove !== null) {
      this.expanded.splice(remove, 1);
    } else {
      this.expanded.push(el);
    }
  }
  private valueChanged(eventName: string, value: unknown): void {
    this.$emit(eventName, value);
  }
  private itemRowBackground(item: NetboxAsset) {
    return item?.isSelectable ? '' : 'disabled-tr';
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    // after a new search you want to be looking at the first page
    // manually change pagination object so that @update.pagination on data table gets triggered,
    // otherwise it doesn't update number of pages/items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.paginationOptions!.page = 1;
    this.fetchNetboxAssets();
  }
  @Watch('paginationOptions', { deep: true })
  private handleOptions() {
    this.fetchNetboxAssets();
  }
  @Watch('excludeExternalOrgs')
  private onIncludeAllOrgsCheckboxChange() {
    this.fetchNetboxAssets();
  }
}
