
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { Network, Zone } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import _get from 'lodash.get';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class CampusUserNetworkZones extends Vue {
  @Prop() private network!: Network;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  private loading = { initial: true };

  private zones: Zone[] = [];

  private headers = [
    {
      text: 'Ime',
      align: 'start',
      sortable: true,
      value: 'name',
    },
    {
      text: 'Oznaka',
      sortable: true,
      value: 'slug',
    },
    {
      text: 'Opis',
      sortable: true,
      value: 'description',
    },
    {
      text: 'Skupina',
      sortable: true,
      value: 'zone_groups',
    },
  ];

  private async fetchZone(id: number) {
    const { data } = await repositories.zoning.zone.getZone(id);
    return data;
  }

  private setZones(): Zone[] {
    this.loading.initial = true;
    const zones: Zone[] = [];
    for (const zone of _get(this.network, 'vlan.zones', []) as Zone[]) {
      this.fetchZone(zone.id).then((zoneInfo) => {
        zones.push(zoneInfo);
      });
    }
    this.loading.initial = false;
    return zones;
  }

  created() {
    this.zones = this.setZones();
  }
}
