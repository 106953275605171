import { render, staticRenderFns } from "./DNSResolverDetail.vue?vue&type=template&id=51c4ac2b&scoped=true&"
import script from "./DNSResolverDetail.vue?vue&type=script&lang=ts&"
export * from "./DNSResolverDetail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51c4ac2b",
  null
  
)

export default component.exports