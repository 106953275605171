
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DenseInterfaceToolbarElementInterfaceFactsLights extends Vue {
  @Prop() private portSTPState!: {
    text: string;
    color: string;
    enabled: boolean;
  };
  @Prop() private portLinkState!: { color: string; text: string };
  @Prop() private neighbor!: { name: string; managed: boolean };
}
