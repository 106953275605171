import axios, { AxiosPromise } from 'axios';
import { Upload, PaginatedResponse } from '@/api/interfaces';
import { Query } from '../query';

const resources = { default: 'documentation/uploads' };

interface UploadApiInterface {
  createUpload(
    objectId: number | string,
    model: string,
    _type: string,
    file: any,
  ): AxiosPromise<Upload>;
  getUpload(id: number): AxiosPromise<any>;
  getUploads(
    model: string,
    objectId: string,
    _type: string,
  ): AxiosPromise<PaginatedResponse<Upload>>;
  deleteUpload(id: number): AxiosPromise<any>;
}

const UploadApi: UploadApiInterface = {
  createUpload(objectId, model, _type, file) {
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('object_id', objectId + '');
    formData.append('model', model);
    formData.append('type', _type);
    return axios.post(`${resources.default}/`, formData);
  },
  getUpload(id) {
    return axios.get(`${resources.default}/${id}/`, { responseType: 'blob' });
  },
  getUploads(model, objectId, _type) {
    const query = new Query({ model, object: objectId, type: _type });
    return axios.get(`${resources.default}/${query}`);
  },
  deleteUpload(id) {
    return axios.delete(`${resources.default}/${id}/`);
  },
};

export default UploadApi;
export { UploadApiInterface, resources };
