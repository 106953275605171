var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-3",attrs:{"fluid":""}},[_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[(!_vm.loadingInitial)?_c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-toolbar',{attrs:{"color":"secondary"}},[_c('v-toolbar-title',{staticClass:"font-weight-bold white--text"},[_vm._v(" Dodajanje modela ")])],1),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function({ invalid, validated, passes }){return [_c('form',{staticClass:"mt-3"},[_c('v-card-title',{staticClass:"text-h6",attrs:{"primary-title":""}},[_vm._v(" Informacije o modelu ")]),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('colgroup',[_c('col',{staticStyle:{"width":"20%"},attrs:{"span":"1"}}),_c('col',{staticStyle:{"width":"80%"},attrs:{"span":"1"}})]),_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Ime")]),_c('th',{staticClass:"text-left"},[_vm._v("Vrednost")])])]),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.info.attr},[_c('td',[_c('v-row',{attrs:{"no-gutters":""}},[_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]),(item.description)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2"},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])]):_vm._e()],1)],1),(item.info.type === 'text')?_c('td',[_c('ValidationProvider',{attrs:{"name":item.title.toLowerCase(),"rules":item.info.rules,"immediate":!(
                            item.info.rules &&
                            item.info.rules.includes('required')
                          )},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"counter":item.info.counter,"error-messages":errors},model:{value:(_vm.form[item.info.formName]),callback:function ($$v) {_vm.$set(_vm.form, item.info.formName, $$v)},expression:"form[item.info.formName]"}})]}}],null,true)})],1):(item.info.type === 'select')?_c('td',[_c('ValidationProvider',{attrs:{"name":item.title.toLowerCase(),"rules":item.info.rules,"immediate":!(
                            item.info.rules &&
                            item.info.rules.includes('required')
                          )},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":item.info.items,"error-messages":errors,"item-text":item.info['item-text'],"item-value":item.info['item-value']},model:{value:(_vm.form[item.info.formName]),callback:function ($$v) {_vm.$set(_vm.form, item.info.formName, $$v)},expression:"form[item.info.formName]"}})]}}],null,true)})],1):(item.info.type === 'checkbox')?_c('td',[_c('ValidationProvider',{attrs:{"name":item.title.toLowerCase(),"rules":item.info.rules,"immediate":!(
                            item.info.rules &&
                            item.info.rules.includes('required')
                          )},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-checkbox',{attrs:{"error-messages":errors},model:{value:(_vm.form[item.info.formName]),callback:function ($$v) {_vm.$set(_vm.form, item.info.formName, $$v)},expression:"form[item.info.formName]"}})]}}],null,true)})],1):_vm._e()])}),0)]},proxy:true}],null,true)})],1),_c('v-card-text',[_c('product-info-manual')],1),_c('v-card-text',[_c('draw-product',{attrs:{"product":_vm.getFormData,"multiple":false}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"outlined":"","type":_vm.isValid ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.isValid ? 'Info je v pravilnem JSON formatu' : 'Info ni v pravilnem JSON formatu')+" ")])],1)],1),_c('v-textarea',{staticClass:"mono smallerText",attrs:{"error":!_vm.isValid,"outlined":"","rows":"20","label":"JSON"},model:{value:(_vm.form.info),callback:function ($$v) {_vm.$set(_vm.form, "info", $$v)},expression:"form.info"}}),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"ma-3",attrs:{"color":"success","loading":_vm.loading.submit,"disabled":invalid || !validated || !_vm.isValid},on:{"click":function($event){return passes(_vm.submit)}}},[_vm._v(" Dodaj ")])],1)],1)],1)],1)]}}],null,false,1190594011)})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }