
import { Component, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';

@Component
export default class AddOrganizationModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  private portalID: string | null = null;
  private portalOrg: {
    name: string;
    short_name: string | null;
    portal_id: number;
  } | null = null;
  private loading = {
    pulling: false,
    creating: false,
  };

  private pullOrganizationData() {
    if (
      this.portalOrg &&
      this.portalID === this.portalOrg.portal_id.toString()
    ) {
      // already have info for this org
      this.$toasted.info('Podatki so že naloženi');
    }
    this.loading.pulling = true;
    repositories.tenants.organization
      .getOrganizationInfoFromPortal(parseInt(this.portalID as string, 10))
      .then((res) => {
        this.portalOrg = res.data;
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            {
              message: `Napaka pri prodobivanju podatkov iz portala: ${error.response.data.detail}`,
            },
          ).toString(),
        );
        this.portalOrg = null;
      })
      .finally(() => {
        this.loading.pulling = false;
      });
  }

  private createOrganization() {
    this.loading.creating = true;
    repositories.tenants.organization
      .createOrganization(this.portalOrg!.portal_id)
      .then((res) => {
        this.ok(res.data);
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            {
              message: `Napaka pri dodajanju organizacije: ${error.response.data.detail}`,
            },
          ).toString(),
        );
      })
      .finally(() => {
        this.loading.creating = false;
      });
  }
}
