import translateWord from '@/components/shared/translations';

export function taskNameShort(fullName: string): string {
  return fullName.split('.').pop() || fullName;
}

export function prettyPrintFullDate(dateStr: string): string {
  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  const date = new Date(dateStr);
  return `${padTo2Digits(date.getDate())}.${padTo2Digits(
    date.getMonth() + 1,
  )}.${padTo2Digits(date.getFullYear())} ${padTo2Digits(
    date.getHours(),
  )}:${padTo2Digits(date.getMinutes())}:${padTo2Digits(date.getSeconds())}`;
}

export function prettyPrintJson(json: Record<string, unknown>): string {
  return JSON.stringify(json, null, 2);
}

export function translateWordFilter(word: string): string {
  return translateWord(word, false, false, true);
}
