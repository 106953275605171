
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GET_APPBAR_ITEMS } from '@/store/getters.type';
import { RouteItem } from '@/api/interfaces';

import { APP_NAMESPACE_PATH } from '@/store/namespaces.type';

const appModule = namespace(APP_NAMESPACE_PATH);

@Component
export default class Tools extends Vue {
  @appModule.Getter(GET_APPBAR_ITEMS) public appBarItems!: RouteItem[];

  get tools(): RouteItem[] {
    const item = this.appBarItems.find((item) => item.routeName === 'tools');
    return item?.children ? item.children : [];
  }

  public performRouting(item: RouteItem): void {
    if (item.routeName) {
      // catch error so that if you click on the route which is he same as the
      // current one you don't get unhandled promise exception error
      this.$router
        .push({ name: item.routeName, params: item.params })
        .catch(() => undefined);
    } else {
      const win = window.open(item.externalUrl, '_blank');
      win?.focus();
    }
  }
}
