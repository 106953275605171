
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getNetboxColorAndTranslationForStatus } from '@/components/shared/helpers';
import { getFontColorBasedOnBackground } from '@/helpers';
@Component
export default class NetboxStatusChip extends Vue {
  @Prop() private status!: string | null;
  private getFontColor = getFontColorBasedOnBackground;
  private get colorAndTranslation(): { color: string; text: string } {
    if (this.status == null) {
      return { color: 'grey', text: 'Neznan' };
    }
    return getNetboxColorAndTranslationForStatus(this.status);
  }
}
