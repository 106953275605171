
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';

import { Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import { Representations } from '../shared/representation';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { Network, DHCPServer, DNSResolver } from '@/api/interfaces';
import { deepClone } from '@/helpers';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class NetworkEditRoidModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @userModule.Getter(IS_ADMIN) private isAdmin!: string;
  @Prop() private network!: Network;
  @Prop() private campusSlug!: string;

  private repr = new Representations();
  private dhcpServers: DHCPServer[] = [];
  private availableDHCPServers: DHCPServer[] | null = null;
  private dnsResolvers: DNSResolver[] = [];
  private availableDNSResolvers: DNSResolver[] | null = null;

  private loading = {
    initial: false,
    submit: false,
  };

  private submit(): void {
    this.loading.submit = true;
    let dhcpServers: number[] | null = this.dhcpServers.map(
      (dhcpServer: DHCPServer) => dhcpServer.id!,
    );
    if (this.availableDHCPServers!.length === 0) {
      dhcpServers = null;
    }
    repositories.lan.network
      .updateNetworkRoid(
        this.campusSlug,
        this.network.id!,
        dhcpServers,
        this.dnsResolvers.map((dnsResolver: DNSResolver) => dnsResolver.id!),
      )
      .then((res: any) => {
        this.ok(res.data);
      })
      .catch((error: any) => {
        let message;
        try {
          const detail = error.response.data.detail;
          if (detail) {
            message = detail;
          }
        } catch {
          message = 'Neznana napaka pri kreiranju omrežja';
        }
        this.$toasted.error(message);
        this.loading.submit = false;
      });
  }

  private async created() {
    this.loading.initial = true;
    try {
      await this.fetchAvailableDHCPServers();
      await this.fetchAvailableDNSResolvers();
      this.dhcpServers = deepClone(this.network.dhcp_servers as DHCPServer[]);
      this.dnsResolvers = deepClone(
        this.network.dns_resolvers as DNSResolver[],
      );
    } catch (error) {
      // comment for compiler to let us pass
    }
    this.loading.initial = false;
  }

  private containsGlobalDHCP(dhcpServers: DHCPServer[]): boolean {
    return dhcpServers.filter((dhcp) => dhcp.campus === null).length > 0;
  }

  private async fetchAvailableDHCPServers() {
    try {
      const { data } = await repositories.lan.dhcpServer.getDHCPServers(
        this.campusSlug,
        true,
      );
      if (this.containsGlobalDHCP(data.results) && !this.isAdmin) {
        this.availableDHCPServers = [];
      } else {
        this.availableDHCPServers = data.results;
      }
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'DHCP strežnikih' },
        ).toString(),
      );
      throw error;
    }
  }

  private async fetchAvailableDNSResolvers() {
    try {
      const { data } = await repositories.lan.dnsResolver.getDNSResolvers(
        this.campusSlug,
        true,
      );
      this.availableDNSResolvers = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'DNS strežnikih' },
        ).toString(),
      );
      throw error;
    }
  }

  private getDHCPRepr(dhcpServer: DHCPServer): string {
    return this.repr.dhcpServerRepr(dhcpServer).text;
  }

  private getDNSRepr(dnsResolver: DNSResolver): string {
    return this.repr.dnsResolverRepr(dnsResolver).text;
  }
}
