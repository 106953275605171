
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Breadcrumbs extends Vue {
  private get crumbs() {
    const pathArray = this.$route.path.split('/');
    pathArray.shift();
    const breadcrumbs = pathArray.reduce(
      (
        breadcrumbArray: {
          path: string;
          to: string;
          text: string;
          disabled: boolean;
        }[],
        path,
        idx,
      ) => {
        if (path !== '') {
          if (idx < this.$route.matched.length) {
            breadcrumbArray.push({
              path: path,
              to: breadcrumbArray[idx - 1]
                ? breadcrumbArray[idx - 1].to + '/' + path
                : '/' + path,
              text: this.$route.matched[idx].meta.breadCrumb || path,
              disabled: !!this.$route.matched[idx].meta.breadCrumbDisabled,
            });
          }
        }
        return breadcrumbArray;
      },
      [],
    );
    return breadcrumbs;
  }
}
