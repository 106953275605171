
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { repositories } from '@/api/ApiFactory';
import { IS_ADMIN } from '@/store/getters.type';
import ErrorHandler from '@/components/shared/errorHandler';
import { deepClone } from '@/helpers';
import { Product, ProductType, Manufacturer } from '@/api/interfaces';
import DrawProduct from '@/components/shared/DrawProduct.vue';
import ProductInfoManual from './ProductInfoManual.vue';
import _get from 'lodash.get';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    DrawProduct,
    ProductInfoManual,
  },
})
export default class ProductDetail extends Vue {
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @Prop() private product!: string;

  private loading = {
    product: true,
    manufacturers: false,
    productTypes: false,
    submit: false,
  };
  private editable = false;
  private productData: Product | null = null;
  private _get = _get;

  private form: {
    editProductInfoInput: string;
    name: string;
    manufacturer: number | null;
    productType: number | null;
    platform: string | null;
    part_number: string | null;
    description: string | null;
    comment: string | null;
    managed: boolean;
    requires_mac: boolean;
  } = {
    editProductInfoInput: '',
    name: '',
    manufacturer: null,
    productType: null,
    platform: null,
    part_number: null,
    description: null,
    comment: null,
    managed: false,
    requires_mac: true,
  };

  private manufacturers: Manufacturer[] = [];
  private productTypes: ProductType[] = [];

  private get items() {
    return [
      {
        title: 'Ime',
        info: {
          type: 'text',
          attr: 'name',
          formName: 'name',
          rules: 'required|max:255',
          counter: 255,
        },
      },
      {
        title: 'Proizvajalec',
        align: 'left',
        info: {
          'type': 'select',
          'attr': 'manufacturer.name',
          'formName': 'manufacturer',
          'rules': 'required',
          'items': this.manufacturers,
          'item-text': 'name',
          'item-value': 'id',
        },
      },
      {
        title: 'Tip',
        align: 'left',
        info: {
          'type': 'select',
          'attr': 'type.name',
          'formName': 'productType',
          'rules': 'required',
          'items': this.productTypes,
          'item-text': 'name',
          'item-value': 'id',
        },
      },
      {
        title: 'Platforma',
        info: {
          type: 'text',
          attr: 'platform',
          formName: 'platform',
          rules: 'max:255',
          counter: 255,
        },
      },
      {
        title: 'Oznaka proizvajalca',
        info: {
          type: 'text',
          attr: 'part_number',
          formName: 'part_number',
          rules: 'max:255',
          counter: 255,
        },
        description: 'Part number',
      },
      {
        title: 'Opis',
        info: {
          type: 'text',
          attr: 'description',
          formName: 'description',
          rules: 'max:255',
          counter: 255,
        },
      },
      {
        title: 'Komentar',
        info: {
          type: 'text',
          attr: 'comment',
          formName: 'comment',
        },
      },
      {
        title: 'Upravljan',
        info: {
          type: 'checkbox',
          attr: 'managed',
          formName: 'managed',
        },
      },
      {
        title: 'Zahteva MAC',
        info: {
          type: 'checkbox',
          attr: 'requires_mac',
          formName: 'requires_mac',
        },
      },
    ];
  }

  @Watch('productData')
  private onProductDataChange() {
    this.resetForm();
  }

  @Watch('editable')
  private onEditableChange(val: boolean) {
    if (!val) {
      // reset the form to default values so that when you start editing it again
      // you don't have the old changed values
      this.resetForm();
    }
  }

  private resetForm() {
    this.form = {
      editProductInfoInput: JSON.stringify(this.productData!.info, null, 2),
      name: this.productData!.name,
      manufacturer: this.productData!.manufacturer.id,
      productType: this.productData!.type.id,
      platform: this.productData!.platform as string | null,
      part_number: this.productData!.part_number as string | null,
      description: this.productData!.description as string | null,
      comment: this.productData!.comment as string | null,
      managed: this.productData!.managed,
      requires_mac: this.productData!.requires_mac,
    };
  }

  private get isValid(): boolean {
    return Boolean(this.tryParseJSON(this.form.editProductInfoInput));
  }

  private get loadingInitial(): boolean {
    return this.isAdmin
      ? this.loading.product ||
          this.loading.manufacturers ||
          this.loading.productTypes
      : this.loading.product;
  }

  private get editedProductInfo(): Product | null {
    if (this.productData) {
      const productDataClone = deepClone(this.productData);
      const newJson = this.tryParseJSON(this.form.editProductInfoInput);
      if (newJson) {
        productDataClone['info'] = newJson;
      }
      return productDataClone;
    }
    return null;
  }

  private async fetchProduct(productId: string) {
    this.loading.product = true;
    try {
      const { data } = await repositories.infrastructure.product.getProduct(
        productId,
      );
      this.productData = data;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.product = false;
  }

  private repr(product: Product) {
    return `${product.name} - [ ${product.manufacturer.name} ]`;
  }

  private async fetchManufacturers() {
    this.loading.manufacturers = true;
    try {
      const { data } =
        await repositories.infrastructure.manufacturer.getManufacturers();
      this.manufacturers = data.results;
      if (this.productData) {
        this.form.manufacturer = this.productData.manufacturer.id;
      }
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.manufacturers = false;
  }

  private async fetchProductTypes() {
    this.loading.productTypes = true;
    try {
      const { data } =
        await repositories.infrastructure.productType.getProductTypes();
      this.productTypes = data.results;
      if (this.productData) {
        this.form.productType = this.productData.type.id;
      }
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.productTypes = false;
  }

  private created() {
    if (this.product) {
      this.fetchProduct(this.product);
    }
    if (this.isAdmin) {
      this.fetchManufacturers();
      this.fetchProductTypes();
    }
  }

  private tryParseJSON(jsonString: string) {
    try {
      const o = JSON.parse(jsonString);
      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
      //
    }
    return false;
  }

  private submit() {
    if (this.productData && this.isValid && this.form.editProductInfoInput) {
      this.loading.submit = true;
      repositories.infrastructure.product
        .updateProduct(
          this.productData.id,
          this.form.name,
          this.tryParseJSON(this.form.editProductInfoInput),
          this.form.manufacturer,
          this.form.productType,
          this.form.platform,
          this.form.part_number,
          this.form.description,
          this.form.comment,
          this.form.managed,
          this.form.requires_mac,
        )
        .then((res) => {
          this.productData = res.data;
          this.editable = false;
        })
        .catch((error) => {
          this.$toasted.error(
            new ErrorHandler({ error, status: true }).toString(),
          );
        })
        .finally(() => {
          this.loading.submit = false;
        });
    }
  }
}
