
import { Component, Prop, Vue } from 'vue-property-decorator';
import { StatsL2Interface, Neighbor } from '@/api/interfaces';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class InterfaceLLDPFacts extends Vue {
  @Prop() private ifaceFacts!: StatsL2Interface;

  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @Prop() private deviceId!: number;

  private loading = {
    neighbor: false,
  };

  private isArnesManaged(neighbor: Neighbor): boolean {
    let isManaged = false;
    if (Array.isArray(neighbor.devices) && neighbor.devices.length) {
      isManaged = neighbor.devices[0].managed;
    }
    return isManaged;
  }

  private getDeviceName(neighbor: Neighbor): string {
    let res = neighbor.remote_system_name;
    if (Array.isArray(neighbor.devices) && neighbor.devices.length) {
      const device = neighbor.devices[0];
      if (device.managed) {
        res = device.name;
      }
    }
    return res ?? '/';
  }

  private showLinkToNeighbor(neighbor: Neighbor): boolean {
    if (Array.isArray(neighbor.devices) && neighbor.devices.length) {
      const device = neighbor.devices[0];
      if (
        this.deviceId !== device.pk &&
        device.function_slug &&
        // We don't want to open access point devices
        ['l2_switch', 'l3_switch', 'router'].includes(device.function_slug) &&
        // Only admin users can open
        (!device.roles.includes('tsp') || this.isAdmin) &&
        !device.roles.includes('backbone')
      ) {
        return true;
      }
    }
    return false;
  }
}
