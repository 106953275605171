
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { repositories } from '@/api/ApiFactory';
import { IS_ADMIN } from '@/store/getters.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import ErrorHandler from '@/components/shared/errorHandler';
import { Device } from '@/api/interfaces';
import EditInterface from '@/components/interfaces/EditInterface.vue';
import {
  ModifyInterfacesComponentMode,
  supportedModifyInterfacesDeviceFunctions,
} from '@/components/shared/helpers';
const userModule = namespace(USER_NAMESPACE_PATH);

interface DeviceRepr extends Device {
  repr: string;
}

@Component({
  components: {
    EditInterface,
  },
})
export default class ModifyInterfaces extends Vue {
  @Prop(String) private campusSlug!: string;
  @Prop(String) private siteSlug!: string;
  @Prop(String) private deviceName!: string;
  @Prop({ type: String }) private mode!: ModifyInterfacesComponentMode;

  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private loading = { initial: true, taskActions: false };
  private devices: DeviceRepr[] = [];
  private selected: string | null = null;
  private selectedTaskActions: string[] = [];
  private ModifyInterfacesComponentMode = ModifyInterfacesComponentMode;

  private get configUrl(): string {
    const confUrl = process.env.VUE_APP_DEVICE_CONFIG_ARCHIVE_BASE;
    if (confUrl && this.selected) {
      return `${confUrl}/-/blob/master/${this.campusSlug}/${this.selected}`;
    }
    return '';
  }
  private get canConfigure(): boolean {
    return this.selectedTaskActions.includes('configure');
  }

  private async fetchDevicesForCampus(campusSlug: string) {
    this.loading.initial = true;
    try {
      const { data } = await repositories.infrastructure.device.getDevices({
        campusSlug,
        functions: supportedModifyInterfacesDeviceFunctions,
      });

      let deviceFilterFn = (device: Device) =>
        !['retired'].includes(device.status);
      if (!this.isAdmin) {
        deviceFilterFn = (device: Device) =>
          !['retired'].includes(device.status);
      }
      this.devices = data.results
        .filter(deviceFilterFn)
        .map((device) => Object.assign({ repr: this.repr(device) }, device));
      // check that device is among the allowed ones - could be retired
      if (!this.devices.map((el) => el.name).includes(this.deviceName)) {
        this.$toasted.error(
          'Naprava ne obstaja, ni upravljana ali pa je upokojena.',
        );
        this.$router.push({
          name: 'devices',
          params: {
            campusSlug: this.campusSlug,
          },
        });
      }
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    await this.setSelected();
    this.loading.initial = false;
  }

  private async fetchSiteAndSetDevices(siteSlug: string) {
    this.loading.initial = true;
    try {
      const { data } = await repositories.connectivity.site.getSite(siteSlug);
      this.devices =
        data.devices
          ?.filter(
            (device) =>
              !['retired'].includes(device.status) &&
              !device.roles?.some((role) => role.slug === 'backbone') &&
              supportedModifyInterfacesDeviceFunctions.includes(
                device.function.slug,
              ),
          )
          .map((device) =>
            Object.assign({ repr: this.repr(device) }, device),
          ) || [];

      // check that device is among the allowed ones - could be retired
      if (!this.devices.map((el) => el.name).includes(this.deviceName)) {
        this.$toasted.error(
          'Naprava ne obstaja, ni upravljana ali pa je upokojena.',
        );
        this.$router.push({
          name: 'site',
          params: {
            siteSlug: siteSlug,
          },
        });
      }
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'vozlišču' },
        ).toString(),
      );
    }
    await this.setSelected();
    this.loading.initial = false;
  }

  private repr(device: Device): string {
    return `${device.name} - 
     [ ${device.roles ? device.roles.map((role) => role.name) : '/'} ] - ${
      device.asset && device.asset.product ? device.asset.product.name : '/'
    }`;
  }

  private async fetchPossibleTaskActionsForSelectedDevice() {
    if (!this.isAdmin) {
      // we don't allow roid to manually configure
      return;
    }
    this.loading.taskActions = true;
    const selectedDevice = this.devices.find(
      (dev) => this.selected === dev.name,
    );
    if (selectedDevice) {
      try {
        const { data } =
          await repositories.infrastructure.device.getPossibleTaskActionsForDevice(
            selectedDevice.name,
          );
        this.selectedTaskActions = data.actions;
      } catch (error) {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
      }
    }
    this.loading.taskActions = false;
  }

  @Watch('selected')
  private async handleSelectedChange() {
    // NOTE: each function can only have one watcher in vue 2
    // When page is initially loaded, we do not want to route to the device because we are already on correct route
    if (!this.loading.initial) {
      this.routeTo();
    } else {
      this.fetchPossibleTaskActionsForSelectedDevice();
    }
  }

  private async routeTo() {
    if (this.isAdmin) {
      if (this.selected === null) {
        // we don't show the btn, we represent that as no actions
        this.selectedTaskActions = [];
      } else {
        // fetch possible actions for this device to figure out whether we show
        // configure btn or not
        this.fetchPossibleTaskActionsForSelectedDevice();
      }
    }
    if (this.loading.initial) {
      return;
    }

    let routerArgs = {};
    if (this.mode === ModifyInterfacesComponentMode.CAMPUS) {
      routerArgs = {
        name: 'interfacesOnDevice',
        params: {
          campusSlug: this.campusSlug,
          deviceName: this.selected || '',
        },
      };
    } else if (this.mode === ModifyInterfacesComponentMode.SITE) {
      routerArgs = {
        name: 'interfacesOnSiteDevice',
        params: {
          siteSlug: this.siteSlug,
          deviceName: this.selected || '',
        },
      };
    } else {
      return;
    }

    this.$router.push(routerArgs).catch((error) => {
      if (error.name != 'NavigationDuplicated') {
        throw error;
      }
    });
  }

  @Watch('deviceName')
  private setSelected() {
    if (this.deviceName && this.devices) {
      const tmp = this.devices.find((dev) => this.deviceName === dev.name);
      this.selected = tmp ? tmp.name : null;
    }
  }

  private created() {
    if (this.mode === ModifyInterfacesComponentMode.CAMPUS) {
      this.fetchDevicesForCampus(this.campusSlug);
    } else {
      this.fetchSiteAndSetDevices(this.siteSlug);
    }
  }

  private async configureSelectedDevice() {
    const selectedDevice = this.devices.find(
      (dev) => this.selected === dev.name,
    );
    if (selectedDevice) {
      try {
        await repositories.orchestrator.campus.runTaskAction(
          this.campusSlug,
          'configure_devices',
          [selectedDevice.name],
        );
        // fetch tasks state so that it immediatelly updates the badges
        this.fetchTasksStateAction();
      } catch (error) {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            {
              message: `Napaka pri konfiguraciji naprave ${selectedDevice.name}`,
            },
          ).toString(),
        );
      }
    }
  }
}
