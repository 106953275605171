import Vue, { RenderContext, VNode, CreateElement } from 'vue';
import { BadgeStates } from '@/store/types';

export const BadgeStatesComponent = Vue.extend({
  functional: true,
  props: {
    badgeStates: {
      type: Object as () => BadgeStates,
    },
    icon: String,
    dot: { type: Boolean, default: false },
  },
  render(createElement: CreateElement, ctx: RenderContext<any>): VNode {
    const { badgeStates, icon } = ctx.props;
    let currentEl = createElement(
      'v-icon',
      {},
      icon ? icon : 'mdi-robot-industrial',
    );
    // right of the icon, from left to right
    const orderedStates = [
      'pending',
      'ongoing',
      'failed',
      'succeeded',
      'revoked',
    ];
    for (const state of orderedStates) {
      if (badgeStates[state].value > 0) {
        currentEl = createElement(
          'v-badge',
          {
            props: {
              content: badgeStates[state].value,
              value: badgeStates[state].value,
              color: badgeStates[state].color,
              inline: true,
              dot: ctx.props.dot,
            },
            class: 'mx-0',
          },
          [currentEl],
        );
      }
    }
    return currentEl;
  },
});
