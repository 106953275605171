
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';

import { Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import Autocomplete from '../shared/Autocomplete.vue';
import { Representations } from '../shared/representation';
import { Network, CampusSimple, WifiNetwork } from '@/api/interfaces';
import { deepClone } from '@/helpers';
import { repositories } from '@/api/ApiFactory';
import { Query } from '@/api/query';
import ErrorHandler from '@/components/shared/errorHandler';
/* tslint:disable */
import WORDS from '@/components/shared/passwordSample.json';
/*tslint:enable */

import {
  PasswordCheckStrength,
  PasswordCheckService,
} from '@/components/shared/passwordStrengthChecker';

const userModule = namespace(USER_NAMESPACE_PATH);

function getInitialWifi(): WifiNetwork {
  return {
    id: null,
    network: null,
    ssid: '',
    hidden: false,
    enabled: true,
    type: '',
    psk_password: '',
    created: '',
    modified: '',
    custom_schedule: '',
  };
}

@Component({
  components: {
    Autocomplete,
  },
})
export default class WifiNetworkModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @userModule.Getter(IS_ADMIN) public isAdmin!: string;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  @Prop({ default: null }) private campusSlug!: string | null;
  @Prop({ default: false }) private selectableCampus!: boolean;
  @Prop({ default: null }) private network!: Network | null;
  @Prop({ default: null }) private wifi!: WifiNetwork | null;
  @Prop({ default: false }) private editNetwork!: boolean;

  private repr = new Representations();
  private wifiClone: WifiNetwork = getInitialWifi();

  private loading = {
    initial: false,
    submit: false,
  };
  private showPassword = false;

  private typeChoices = [{ text: 'Psk', value: 'psk' }];

  private campus: CampusSimple | null = null;

  private networks: Network[] = [];

  private campusSearchApi = repositories.tenants.campus.searchCampuses;

  private passwordChecker = new PasswordCheckService();

  @Watch('campus')
  private fetchNetworksOnCampusChange(): void {
    if (this.selectableCampus && this.campus !== null) {
      this.fetchCampusNetworks(this.campus.slug).then(() => {
        if (
          this.wifiClone.network !== null &&
          !this.networks.some(
            (el) => el.id === (this.wifiClone.network as Network).id,
          )
        ) {
          this.wifiClone.network = null;
        }
        this.loading.initial = false;
      });
    } else {
      this.networks = [];
      this.wifiClone.network = null;
    }
  }

  @Watch('wifiClone.type')
  private setSSIDforEduroam(): void {
    if (this.wifiClone.type === 'eduroam' && this.wifiClone.ssid === '') {
      this.wifiClone.ssid = 'eduroam';
    } else if (
      this.wifiClone.ssid === 'eduroam' &&
      !['wpa2-enterprise', 'eduroam'].includes(this.wifiClone.type)
    ) {
      this.wifiClone.ssid = '';
    }
  }

  private get selectedCampusSlug(): string | null {
    if (this.selectableCampus && this.campus !== null) {
      return this.campus.slug;
    }
    return this.campusSlug;
  }

  private isValidPassword(errors: { [key: string]: string[] }): boolean {
    return (
      errors !== undefined &&
      (errors['password'] === undefined || errors['password'].length == 0)
    );
  }

  private get passwordMeter(): {
    strength: number;
    text: string;
    color: string;
  } {
    const x = this.passwordChecker.checkPasswordStrength(
      this.wifiClone.psk_password,
    );
    const colors = [
      'error',
      'error lighten-1',
      'warning',
      'success',
      'success darken-1',
    ];
    return {
      strength: (x / (Object.keys(PasswordCheckStrength).length / 2 - 1)) * 100,
      text: PasswordCheckStrength[x],
      color: colors[x],
    };
  }

  private getRandomInt(min: number, max: number) {
    const randomBuffer = new Uint32Array(1);

    window.crypto.getRandomValues(randomBuffer);

    const randomNumber = randomBuffer[0] / (0xffffffff + 1);
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(randomNumber * (max - min + 1)) + min;
  }

  private generatePassword(length = 14) {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const symbols = '!#$%&+-';
    // const symbols = '!"#$%&\'()*+,-./:;<=>?@^[\\]^_`{|}~';
    const all = uppercase + lowercase + numbers + symbols;
    let password = '';
    for (let index = 0; index < length; index++) {
      const character = this.getRandomInt(0, all.length);
      password += all.substring(character, character + 1);
    }
    return password;
  }

  private generatePasswordSentence(length = 20) {
    let password = '';
    for (let i = 0; password.length <= length || i < 4; i++) {
      const random = this.getRandomInt(0, WORDS.length);
      password += WORDS[random];
    }
    return password;
  }

  private setPassword(sentence: boolean) {
    let gp = '';
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (sentence) {
        gp = this.generatePasswordSentence();
      } else {
        gp = this.generatePassword();
      }
      const pc = this.passwordChecker.checkPasswordStrength(gp);
      if (pc > 2) {
        this.wifiClone.psk_password = gp;
        this.showPassword = true;
        return;
      }
    }
  }

  private async fetchCampusNetworks(campusSlug: string) {
    try {
      const { data } = await repositories.lan.network.getNetworks(
        new Query({ campus_slug: campusSlug }),
      );
      this.networks = data.results;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }

  private submit(): void {
    const data = Object.assign({}, this.wifiClone, {
      network: (this.wifiClone.network as Network).id,
    });
    this.loading.submit = true;
    const create = this.wifiClone.id ? false : true;
    const api = create
      ? repositories.lan.wifiNetwork.createWifiNetwork(this.wifiClone)
      : repositories.lan.wifiNetwork.updateWifiNetwork(
          this.wifiClone.id as number,
          this.wifiClone,
        );

    api
      .then((res: any) => {
        this.fetchTasksStateAction();
        this.ok(res.data);
      })
      .catch((error: any) => {
        const jobtext = create ? 'kreiranju' : 'posodabljanju';
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
        this.loading.submit = false;
      });
  }

  private async created() {
    this.loading.initial = true;
    if (this.isAdmin) {
      this.typeChoices = [
        { text: 'eduroam', value: 'eduroam' },
        { text: 'PSK', value: 'psk' },
        { text: 'Odprto', value: 'open' },
        { text: 'WPA2 enterprise', value: 'wpa2-enterprise' },
      ];
    } else {
      // automatically select psk from the dropdown since it's the only one
      this.wifiClone.type = 'psk';
    }
    try {
      if (this.wifi != null) {
        this.wifiClone = deepClone(this.wifi);
      }
      if (this.network) {
        this.wifiClone.network = this.network;
      }
      if (this.selectableCampus && this.campusSlug) {
        await this.fetchCampus(this.campusSlug);
      } else {
        if (this.campusSlug) {
          await this.fetchCampusNetworks(this.campusSlug);
        }
        this.loading.initial = false;
      }
    } catch (error) {
      this.$toasted.error('Neznana napaka');
      this.loading.initial = false;
    }
  }

  private async fetchCampus(campusSlug: string) {
    try {
      const { data } = await repositories.tenants.campus.getCampus(campusSlug);
      if (data) {
        this.campus = data;
      }
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
    }
  }
}
