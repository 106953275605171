import { Component, Vue } from 'vue-property-decorator';

// NOTE: it needs to be a component - vuex-modals requirement
@Component
export default class PersistentModal extends Vue {
  private getDefaultConfig() {
    return {
      dialog: {
        props: {
          persistent: true,
        },
      },
    };
  }
}

// NOTE: it needs to be a component - vuex-modals requirement
@Component
export class PersistentScrollableModal extends Vue {
  private getDefaultConfig() {
    return {
      dialog: {
        props: {
          persistent: true,
          scrollable: true,
        },
      },
    };
  }
}