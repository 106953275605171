
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN_OR_SUPPORT } from '@/store/getters.type';
import { Representations } from '../shared/representation';
import Autocomplete from '../shared/Autocomplete.vue';
import { NetboxAsset, DataOptions, User, CampusSimple } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import Breadcrumbs from '@/views/Breadcrumbs.vue';
import NetboxStatusChip from '../device/NetboxStatusChip.vue';
import _get from 'lodash.get';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    breadcrumbs: Breadcrumbs,
    NetboxStatusChip,
    Autocomplete,
  },
})
export default class InventoryDeliveryChange extends Vue {
  @userModule.Getter(IS_ADMIN_OR_SUPPORT) public isAdminOrSupport!: boolean;

  private repr = new Representations();
  private searchUsersApi = repositories.people.user.searchUsers;
  private getLodash = _get;
  private changeKey = 0;
  private changeKeyFetch: { [key: number]: boolean } = {};
  private netboxAssets: NetboxAsset[] = [];
  private totalNetboxAssets = 0;
  private rowsPerPageItems = [200, 500, 1000];
  private paginationOptions: DataOptions = {
    groupBy: [],
    groupDesc: [],
    sortBy: [],
    sortDesc: [false],
    page: 1,
    itemsPerPage: 200,
    multiSort: false,
    mustSort: false,
  };
  private confirmed = false;
  private selectedNetboxAssets: NetboxAsset[] = [];
  private loading = { table: true, campuses: true, submit: false };
  private filterUser: User | null = null;
  private availableCampuses: CampusSimple[] = [];
  // selectedCampuses has key=<nextbox.id>, value=<selected_campus.slug>
  private selectedCampuses: { [key: number]: string | null } = {};
  private commonCampus: CampusSimple | null = null;
  private expanded: NetboxAsset[] = [];
  private e1 = 1;
  private headers = [
    {
      text: 'Kampus',
      sortable: false,
      value: 'campus',
    },
    {
      text: 'Proizvajalec',
      sortable: false,
      value: 'manufacturer',
    },
    {
      text: 'Model',
      sortable: false,
      value: 'model',
    },
    {
      text: 'Serijska številka',
      sortable: false,
      value: 'serial',
    },
    {
      text: 'MAC naslov',
      sortable: false,
      value: 'custom_fields.asset_mac',
    },
    {
      text: 'Status',
      sortable: false,
      value: 'status',
    },
    {
      text: 'Dostava',
      sortable: false,
      value: 'custom_fields.delivery_status',
    },
    {
      text: 'Dodeljeno',
      sortable: false,
      value: 'tenant.name',
      inExpansion: true,
    },
    {
      text: 'Lastnik',
      sortable: false,
      value: 'owner.name',
      inExpansion: true,
    },
    {
      text: 'Inventarna številka',
      sortable: false,
      value: 'asset_tag',
      inExpansion: true,
    },
    {
      text: 'Datum nakupa',
      sortable: false,
      value: 'purchase.date',
      inExpansion: true,
    },
    {
      text: 'Potek garancije',
      sortable: false,
      value: 'warranty_end',
      inExpansion: true,
    },
    {
      text: 'Sprememba dostave',
      sortable: false,
      value: 'custom_fields.delivery_status_date',
      inExpansion: true,
    },
    {
      text: 'Kraj hrambe',
      sortable: false,
      value: 'storage_location.display',
      inExpansion: true,
    },
    {
      text: '',
      value: 'data-table-expand',
    },
  ];

  private async created() {
    await this.fetchUserCampuses();
    this.fetchNetboxAssets();
  }

  private get headersForTable() {
    return {
      headers: this.headers.filter((header) => {
        if (this.$vuetify.breakpoint.mdAndDown) {
          return !header.inExpansion;
        }
        return true;
      }),
      expansion: this.headers.filter((header) => {
        if (this.$vuetify.breakpoint.mdAndDown) {
          return header.inExpansion;
        } else {
          return false;
        }
      }),
    };
  }

  private async fetchUserCampuses() {
    this.loading.campuses = true;
    try {
      const { data } = await repositories.people.user.getUserCampuses(
        this.filterUser ? this.filterUser.username : null,
      );
      this.availableCampuses = data;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.campuses = false;
  }

  private async fetchNetboxAssets() {
    this.loading.table = true;
    // reset selected
    this.selectedNetboxAssets = [];
    this.commonCampus = null;
    try {
      const params: {
        page: number;
        itemsPerPage: number;
        deliveryStatus: string;
        username?: string;
      } = {
        page: this.paginationOptions!.page,
        itemsPerPage: this.paginationOptions!.itemsPerPage,
        deliveryStatus: 'Poslano',
      };
      if (this.filterUser) {
        params['username'] = this.filterUser.username;
      }
      const { data } =
        await repositories.infrastructure.asset.searchNetboxAssets(params);
      this.netboxAssets = data.results;
      const newSelectedCampuses: { [key: number]: string | null } = {};
      for (const asset of this.netboxAssets) {
        try {
          const aid = Number(
            asset.tenant.slug.toLowerCase().replace('aid-', ''),
          );
          // find matching campus
          const matchingCampuses = this.availableCampuses.filter((campus) =>
            campus.organizations!.map((org) => org.portal_id).includes(aid),
          );
          if (matchingCampuses.length === 1) {
            newSelectedCampuses[asset.id] = matchingCampuses[0].slug;
          }
        } catch (error: any) {
          // either asset has no tenant or user has no perm to select matching campus
        }
      }
      this.selectedCampuses = newSelectedCampuses;
      this.totalNetboxAssets = data.count;
    } catch (error) {
      this.selectedCampuses = {};
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.table = false;
  }

  private expandRow(el: NetboxAsset) {
    let remove = null;
    this.expanded.forEach((value, index) => {
      if (el.id === value.id) {
        remove = index;
      }
    });
    if (remove !== null) {
      this.expanded.splice(remove, 1);
    } else {
      this.expanded.push(el);
    }
  }

  private valueChanged(eventName: string, value: unknown): void {
    this.$emit(eventName, value);
  }

  @Watch('paginationOptions', { deep: true })
  private handleOptions(a: any, b: any) {
    // NOTE: when data-table element gets created this watcher triggers, but with
    // no difference between 'a' and 'b'. We ignore zero-difference triggers because
    // if you're on page 3 and then a select difference user it triggers this
    // watcher twice with zero-difference (don't know why). To avoid calling the
    // api twice we manually trigger 'fetchNetboxAssets' on autocomplete change
    // and ignore zero-diff. Because we ignore zero-diff we must call 'fetchNetboxAssets'
    // in created method.
    const getDifference = (x: any, y: any) =>
      Object.fromEntries(
        Object.entries(y).filter(([key, val]) => key in x && x[key] !== val),
      );
    if (JSON.stringify(getDifference(a, b)) !== '{}') {
      this.fetchNetboxAssets();
    }
  }

  private onAutocompleteChange() {
    this.paginationOptions.page = 1;
    this.fetchNetboxAssets();
  }

  private showDeliveryConfirmedModal(): void {
    this.$modals.open('app-delivery-confirmed-modal', {
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private async submit() {
    this.loading.submit = true;
    try {
      const data: { [key: number]: string } = {};
      for (const netboxAsset of this.selectedNetboxAssets) {
        data[netboxAsset.id] = this.selectedCampuses[netboxAsset.id] as string;
      }
      await repositories.infrastructure.asset.setAssetCampus(data);
      this.fetchNetboxAssets();
      this.showDeliveryConfirmedModal();
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler({ error, status: true }).toString(),
        {
          icon: 'mdi-error',
        },
      );
    }
    this.confirmed = false;
    this.loading.submit = false;
    this.e1 = 1;
  }

  private onCommonCampusChange() {
    if (!this.commonCampus) {
      return;
    }
    // update selectedcampuses
    for (const asset of this.selectedNetboxAssets) {
      Vue.set(this.selectedCampuses, asset.id, this.commonCampus!.slug);
    }
  }
}
