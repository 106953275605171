import axios, { AxiosPromise } from 'axios';

const resources = {
  default: 'orchestrator/campus',
};

interface OrchestratorCampusApiInterface {
  runTaskAction(
    campusSlug: string,
    action: string,
    devices?: string[] | null,
  ): AxiosPromise<any>;
  getCampusDetail(campusSlug: string): AxiosPromise<any>;
  getCampusDevicesForTasks(campusSlug: string): AxiosPromise<any>;
}

const OrchestratorCampusApi: OrchestratorCampusApiInterface = {
  runTaskAction(campusSlug, action, devices = null) {
    const data: any = {
      campus_slug: campusSlug,
      action,
    };
    if (devices !== null) {
      data.devices = devices;
    }
    return axios.post(
      `${resources.default}/${campusSlug}/run-task-action/`,
      data,
    );
  },

  getCampusDetail(campusSlug) {
    return axios.get(`${resources.default}/${campusSlug}/`);
  },

  getCampusDevicesForTasks(campusSlug) {
    return axios.get(`${resources.default}/${campusSlug}/task-devices/`);
  },
};

export default OrchestratorCampusApi;
export { OrchestratorCampusApiInterface };
