
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Vlan } from '@/api/interfaces';
import { getFontColorBasedOnBackground } from '@/helpers';

@Component
export default class VlanTaggedSelectMultipleItem extends Vue {
  @Prop() private item!: Vlan;
  @Prop() private on!: Record<string, unknown>;
  @Prop() private attrs!: Record<string, unknown>;

  private getFontColor = getFontColorBasedOnBackground;
}
