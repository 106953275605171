import axios, { AxiosPromise } from 'axios';
import { ZoneGroup, PaginatedResponse } from '@/api/interfaces';
import { Query } from '../query';

const resources = { default: 'zoning/zone-groups' };

interface ZoneGroupApiInterface {
  getZoneGroups(): AxiosPromise<PaginatedResponse<ZoneGroup>>;
  getZoneGroup(id: number): AxiosPromise<ZoneGroup>;
}

const ZoneGroupApi: ZoneGroupApiInterface = {
  getZoneGroups() {
    return axios.get(`${resources.default}/`);
  },
  getZoneGroup(id) {
    return axios.get(`${resources.default}/${id}`);
  },
};

export default ZoneGroupApi;
export { ZoneGroupApiInterface };
