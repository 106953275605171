
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, BADGE_STATES } from '@/store/getters.type';
import { BadgeStates } from '@/store/types';
import {
  UPDATE_LAST_CHANGED_TASKS,
  FETCH_TASKS_STATE,
} from '@/store/actions.type';
import { Task, TaskStatus, Resource } from '../../api/orchestrator/tasks';
import ErrorHandler from '@/components/shared/errorHandler';
import { taskNameShort, prettyPrintFullDate } from '@/utils/filters';
import { sloveneTaskNames } from '@/utils/constants';
import { Device, Campus } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { Representations } from '@/components/shared/representation';
import { Query } from '@/api/query';
import Autocomplete from '@/components/shared/Autocomplete.vue';
import sortby from 'lodash.sortby';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  filters: {
    taskNameShort,
    prettyPrintFullDate,
  },
  components: {
    Autocomplete,
  },
})
export default class Tasks extends Vue {
  @Prop() private campusSlug: string | undefined;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(BADGE_STATES) private badgeStates!: BadgeStates;
  @userModule.Action(UPDATE_LAST_CHANGED_TASKS)
  public updateLastChangedTasksAction!: (
    lastCheckedTasks: number,
  ) => Promise<any>;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private campusSearchApi = repositories.tenants.campus.searchCampuses;
  private deviceSearchApi = repositories.infrastructure.device.getDevices.bind(
    this,
    {},
  );

  private tasks: Task[] = [];
  private pendingTasks: Task[] = [];
  private taskNameShortFn = taskNameShort;

  private page = 1;
  private pageSize = 10;
  private total = 0;

  private sortByAttr = 'celery_task.created';
  private sortDirection = 'desc';
  private taskStatus = '';
  private taskName = '';
  private showAll = false;
  private username = '';
  // currentTime is a date which updates every second (need to update task's duration)
  private currentTime: Date = new Date();
  private currentTimeIntervalReference: number | null = null;
  private fetchTimeoutReference: number | null = null;
  private fetchTasksInterval = 5000;
  private pickedDevice: Device | null | { name: string } = null;
  private pickedDeviceName: string | null = null;
  private pickedCampus: Campus | null | { slug: string } = null;
  private pickedCampusSlug: string | null = null;
  private repr = new Representations();
  private getDurationRepr = new Representations().celeryTaskDurationRepr;

  private sortAttrItems = [
    { text: 'Posodobljeno', value: 'celery_task.last_update' },
    { text: 'Ustvarjeno', value: 'celery_task.created' },
  ];
  private sortDirectionItems = [
    { text: 'naraščajoče', value: 'asc' },
    { text: 'padajoče', value: 'desc' },
  ];
  private allTaskTypes = [
    'device_get_facts',
    'campus_prepare',
    'campus_rename',
    'device_prepare',
    'device_configure',
    'device_change',
    'device_change_ip',
    'device_remove',
  ];

  private sloveneTaskNames = sloveneTaskNames;

  private taskNameItems = [
    {
      text: 'Vse spremembe',
      value: this.allTaskTypes
        .filter((el) => el != 'device_get_facts')
        .join(','),
    },
    { text: 'Vse (tudi pridobitev stanja)', value: '' },
    { text: this.sloveneTaskNames['campus_prepare'], value: 'campus_prepare' },
    { text: this.sloveneTaskNames['campus_rename'], value: 'campus_rename' },
    { text: this.sloveneTaskNames['device_prepare'], value: 'device_prepare' },
    {
      text: this.sloveneTaskNames['device_configure'],
      value: 'device_configure',
    },
    { text: this.sloveneTaskNames['device_change'], value: 'device_change' },
    {
      text: this.sloveneTaskNames['device_change_ip'],
      value: 'device_change_ip',
    },
    { text: this.sloveneTaskNames['device_remove'], value: 'device_remove' },
    {
      text: this.sloveneTaskNames['device_get_facts'],
      value: 'device_get_facts',
    },
  ];
  private runningStatuses = [
    TaskStatus.retry,
    TaskStatus.running,
    TaskStatus.externalJobStart,
    TaskStatus.externalJobGettingOutput,
    TaskStatus.externalJobDone,
  ];
  private statusItems = [
    { text: 'Vsi', value: '' },
    { text: 'Uspešen', value: TaskStatus.success.toLowerCase() },
    { text: 'Neuspešen', value: TaskStatus.failure.toLowerCase() },
    { text: 'Preklican', value: TaskStatus.revoked.toLowerCase() },
    { text: 'V izvajanju', value: 'ongoing' },
  ];
  private loading = {
    tasks: false,
    campus: false,
    device: false,
  };
  private taskStatusEnum = TaskStatus;

  @Watch('pickedCampus')
  private async onCampusChange() {
    const newCampusSlug = this.pickedCampus && this.pickedCampus.slug;
    if (this.pickedCampusSlug === newCampusSlug) {
      return;
    }
    this.deviceSearchApi = repositories.infrastructure.device.getDevices.bind(
      this,
      { campusSlug: newCampusSlug ? newCampusSlug : undefined },
    );
    this.pickedCampusSlug = newCampusSlug;
    this.pickedDeviceName = null;
    this.pickedDevice = null;
    this.fetchTasks(true);
  }

  @Watch('pickedDevice')
  private async onDeviceChange() {
    const newDeviceName = this.pickedDevice && this.pickedDevice.name;
    if (this.pickedDeviceName !== newDeviceName) {
      this.pickedDeviceName = newDeviceName;
      this.fetchTasks(true);
    }
  }

  get totalPages(): number {
    return Math.ceil(this.total / this.pageSize);
  }

  private async created() {
    // if url is set, set the right variable values
    this.taskName = this.taskNameItems[0].value;
    this.setVarsBasedOnQueryParams();
    this.currentTimeIntervalReference = window.setInterval(
      function (this: Tasks) {
        this.currentTime = new Date();
      }.bind(this),
      1000,
    );
    let extraData: [string, string | null] = ['campus', null];
    const campusSlug = this.$route.query.campus || this.campusSlug;
    const deviceName = this.$route.query.device;
    if (campusSlug) {
      extraData = [
        'campus',
        (this.$route.query.campus
          ? (this.$route.query.campus as string)
          : null) ||
          this.campusSlug ||
          null,
      ];
      this.fetchCampus(campusSlug as string);
    }
    if (deviceName) {
      extraData = ['device', deviceName as string];
      this.fetchDevice(deviceName as string);
    }
    this.fetchTasks(false, extraData);
  }

  private setVarsBasedOnQueryParams(): void {
    const query = this.$route.query;
    if (query.showAll) {
      this.showAll = true;
    }
    if (query.taskStatus) {
      if (
        ['success', 'failure', 'revoked'].includes(query.taskStatus as string)
      ) {
        this.taskStatus = query.taskStatus as string;
      } else {
        this.taskStatus = this.statusItems.filter(
          (el) => el.text === 'V izvajanju',
        )[0].value;
      }
    }
    if (query.sort) {
      this.sortByAttr = query.sort as string;
    }
    if (query.sortDirection) {
      this.sortDirection = query.sortDirection as string;
    }
    if (query.taskName) {
      this.taskName = query.taskName as string;
    }
    if (query.campus) {
      this.pickedCampusSlug = query.campus as string;
    }
    if (query.device) {
      this.pickedDeviceName = query.device as string;
    }
  }

  private async fetchCampus(slug: string) {
    this.loading.campus = true;
    try {
      const { data } = await repositories.tenants.campus.getCampus(slug);
      this.pickedCampus = data;
    } catch (error) {
      this.$toasted.error('Kampusa ni bilo mogoče pridobiti.');
    }
    this.loading.campus = false;
  }

  private async fetchDevice(name: string) {
    this.loading.device = true;
    try {
      const { data } = await repositories.infrastructure.device.getDevice(name);
      this.pickedDevice = data;
    } catch (error) {
      this.$toasted.error('Naprave ni bilo mogoče pridobiti.');
    }
    this.loading.device = false;
  }

  private updateUrlQueryParams(): void {
    let modifiedTaskStatus = this.taskStatus;
    if (!['success', 'failure', 'revoked', ''].includes(modifiedTaskStatus)) {
      modifiedTaskStatus = 'ongoing';
    }
    const query = new Query({
      campus: this.pickedCampus ? this.pickedCampus.slug : null,
      device: this.pickedDevice ? this.pickedDevice.name : null,
      showAll: this.showAll || null,
      taskStatus: modifiedTaskStatus || null,
      sort: this.sortByAttr,
      sortDirection: this.sortDirection,
      taskName: this.taskName,
    });
    history.replaceState({}, 'task-list filter', `${this.$route.path}${query}`);
  }

  private async fetchOngoingOrFinishedTasks(extraData: [string, string | null] | undefined = undefined) {
    const { data } = await repositories.orchestrator.task.searchTasks(
      this.page,
      this.pageSize,
      this.showAll,
      `${this.sortByAttr}:${this.sortDirection}`,
      this.taskStatus || 'non-pending',
      this.taskName,
      this.username,
      extraData,
    );
    // sort resources so that campus is at the bottom (others are more important)
    for (const hit of data.hits.hits) {
      const celeryTask = hit._source.celery_task;
      celeryTask.resources = sortby(celeryTask.resources, [
        (o: Resource) => (o.type === 'campus' ? 1 : 0),
      ]);
    }
    this.tasks = data.hits.hits;
    this.total = data.hits.total.value;
  }

  private getMainResourceType(taskName: string) {
    const deviceTaskNames = [
      'device_get_facts',
      'device_prepare',
      'device_configure',
      'device_change',
      'device_change_ip',
      'device_remove',
    ];
    const campusTaskNames = ['campus_prepare', 'campus_rename'];
    if (deviceTaskNames.includes(taskName)) {
      return 'device';
    }
    if (campusTaskNames.includes(taskName)) {
      return 'campus';
    }
    throw Error(
      `getMainResourceType cannot determine the right main resource for task ${taskName}`,
    );
  }

  private async fetchPendingTasks() {
    const { data } = await repositories.orchestrator.task.getPendingTasks();
    // remove resources that are not 'main'
    for (const hit of data.hits.hits) {
      const celeryTask = hit._source.celery_task;
      celeryTask.resources = celeryTask.resources.filter(
        (resource: Resource) =>
          resource.type ===
          this.getMainResourceType(celeryTask.name.split('.').slice(-1)[0]),
      );
    }
    this.pendingTasks = data.hits.hits;
  }

  private async fetchTasks(
    resetPaging = false,
    extraData: [string, string | null] | null = null,
  ): Promise<void> {
    if (this.fetchTimeoutReference) {
      window.clearTimeout(this.fetchTimeoutReference);
    }
    this.updateLastChangedTasksAction(new Date().getTime());
    // change url, so that it includes filter info
    this.updateUrlQueryParams();
    this.loading.tasks = true;
    if (resetPaging) {
      this.page = 1;
    }
    try {
      if (!extraData) {
        extraData = ['campus', this.pickedCampusSlug];
        if (this.pickedDeviceName) {
          extraData = ['device', this.pickedDeviceName];
        }
      }
      this.fetchTimeoutReference = window.setTimeout(
        () => this.fetchTasks(false, extraData),
        this.fetchTasksInterval,
      );
      await Promise.all([
        this.fetchOngoingOrFinishedTasks(extraData),
        this.fetchPendingTasks(),
      ]);
      // at least 0.1 second spin circle so that it's not too fast, we could
      // set tasks in this timeout however then it becomes weird when you use
      // pagination
      setTimeout(() => {
        this.loading.tasks = false;
      }, 100);
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
      this.loading.tasks = false;
    }
  }

  private getDetailsBtnColor(task: Task): string {
    const status = task._source.celery_task.status;
    switch (status) {
      case TaskStatus.success:
        return 'success';
      case TaskStatus.failure:
        return 'error';
      case TaskStatus.revoked || TaskStatus.retry:
        return 'warning';
      default:
        return 'info';
    }
  }

  private getIconForResource(resource: Resource): string {
    switch (resource.type) {
      case 'device':
        return 'mdi-router-wireless';
      case 'campus':
        return 'mdi-office-building';
      default:
        throw `Missing getIconForResource handling for resource type ${resource.type}`;
    }
  }

  private transformTaskStatus(status: string): string {
    switch (status) {
      case TaskStatus.success:
        return 'Uspešen';
      case TaskStatus.failure:
        return 'Neuspešen';
      case TaskStatus.revoked:
        return 'Preklican';
      case TaskStatus.retry:
        return 'Ponovitev';
      default:
        return 'V izvajanju';
    }
  }

  private destroyed(): void {
    if (this.currentTimeIntervalReference) {
      window.clearInterval(this.currentTimeIntervalReference);
    }
    if (this.fetchTimeoutReference) {
      window.clearTimeout(this.fetchTimeoutReference);
    }
  }
}
