
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { Network } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { getFontColorBasedOnBackground } from '@/helpers';
import {
  getColorForVlanType,
  getIconForVlanType,
  getSloveneNetworkType,
} from '@/components/shared/helpers';
const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class CampusUserNetworkExpansionHeader extends Vue {
  @Prop() private network!: Network;
  @Prop() private campusSlug!: string;
  @Prop() private networks!: Network[];
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private getIconForVlanType = getIconForVlanType;
  private getSloveneNetworkType = getSloveneNetworkType;
  private getColorForVlanType = getColorForVlanType;

  private get fontColor(): string {
    return getColorForVlanType(this.network.type, false, 1, true);
  }

  private showNetworkEditModal() {
    let modalName = 'app-network-edit-roid-modal';
    if (this.isAdmin) {
      modalName = 'app-network-modal';
    }
    const promise: Promise<Network> = this.$modals.open(modalName, {
      component: {
        props: {
          campusSlug: this.campusSlug,
          network: this.network,
          networks: this.networks,
        },
      },
      dialog: {
        props: {
          'max-width': '1500px',
        },
      },
    }) as Promise<Network>;
    promise.then((network: Network) => {
      this.$emit('changed');
    });
  }

  private showNetworkDeleteModal(): void {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `omrežje ${this.network.name}`,
            item: this.network,
          },
          deleteFn: this.deleteNetwork,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteNetwork(modal: any, network: Network): void {
    repositories.lan.network
      .deleteNetwork(network.id as number)
      .then((resp) => {
        // fetch tasks state so that it immediatelly updates the badges
        this.fetchTasksStateAction();
        this.$emit('deleted');
        this.$modals.close();
      })
      .catch(() => {
        this.$toasted.error(
          `Pri izbrisu omrežja ${network.name} je prišlo do napake.`,
          { icon: 'mdi-error' },
        );
      });
  }
}
