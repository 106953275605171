
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { Device, Network } from '@/api/interfaces';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class ManagementNetworkModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop({ default: null }) private managementNetwork!: Network | null;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;

  private routingDevices: Device[] = [];
  private availableRoutingDevices: Device[] = [];
  private enabledRoutingDevices: string[] = [];
  private originalEnabledRoutingDevices: string[] = [];

  private loading = {
    initial: false,
    submit: false,
  };

  private async created() {
    this.fetchAvailableRoutingDevices();
  }

  private async fetchAvailableRoutingDevices() {
    this.loading.initial = true;
    try {
      const { data } =
        await repositories.lan.network.getAvailableRoutingDevices(
          this.managementNetwork ? (this.managementNetwork.id as number) : null,
        );
      this.availableRoutingDevices = data.map((el) => el.device);
      this.routingDevices = data
        .filter((el) => el.l3_enabled != null)
        .map((el) => el.device);
      this.enabledRoutingDevices = data
        .filter((el) => el.l3_enabled)
        .map((el) => el.device.name);
      this.originalEnabledRoutingDevices = [...this.enabledRoutingDevices];
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    } finally {
      this.loading.initial = false;
    }
  }

  private async submit() {
    this.loading.submit = true;
    try {
      const routingDevicesData = this.routingDevices.map((d) => ({
        device: d,
        l3_enabled: this.enabledRoutingDevices.includes(d.name),
      }));
      let data;
      if (this.managementNetwork) {
        data = { data } =
          await repositories.lan.network.updateManagementNetwork(
            this.managementNetwork.id as number,
            routingDevicesData,
          );
      } else {
        data = { data } =
          await repositories.lan.network.createManagementNetwork(
            routingDevicesData,
          );
      }
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
      this.ok(data);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.submit = false;
  }

  @Watch('routingDevices')
  private updateEnabledRoutingDevices(newVal: Device[], oldVal: Device[]) {
    const routingDeviceNames = this.routingDevices.map((d) => d.name);
    this.enabledRoutingDevices = this.enabledRoutingDevices.filter(
      (deviceName) => routingDeviceNames.includes(deviceName),
    );
    // if you have device X currently set as a routing device and enabled in DB
    // and you deselect it and then reselect it, it should be enabled in the gui
    const oldValDeviceNames = oldVal.map((d) => d.name);
    const newlySelectedDevices = newVal
      .map((d) => d.name)
      .filter((name) => !oldValDeviceNames.includes(name));
    for (const deviceName of newlySelectedDevices) {
      if (this.originalEnabledRoutingDevices.includes(deviceName)) {
        this.enabledRoutingDevices.push(deviceName);
      }
    }
  }
}
