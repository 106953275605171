import {
  DataOptions,
  PaginatedResponse,
  Site,
  SiteSimple,
} from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';

const resources = {
  default: 'connectivity/sites',
  ss: 'connectivity/sites-simple',
};

interface SiteApiInterface {
  getSite(slug: string): AxiosPromise<Site>;
  getSites(
    pagination?: DataOptions | null,
    search?: string,
    query?: Query,
  ): AxiosPromise<PaginatedResponse<Site>>;
  searchSitesSimple(
    searchText: string,
  ): AxiosPromise<PaginatedResponse<SiteSimple>>;
}

const siteApi: SiteApiInterface = {
  getSite(slug) {
    return axios.get(`${resources.default}/${slug}`);
  },
  getSites(pagination = null, search = '', query = new Query({})) {
    if (pagination !== null) {
      query.addParam('size', pagination.itemsPerPage);
      query.addParam('page', pagination.page);
      let sortBy = pagination.sortBy[0];
      if (sortBy) {
        if (pagination.sortDesc[0]) {
          sortBy = `-${sortBy}`;
        }
        query.addParam('sort_by', sortBy);
      }
    }
    if (search !== null) {
      query.addParam('search', search);
    }

    return axios.get(`${resources.default}/${query}`);
  },
  searchSitesSimple(searchText) {
    const query = new Query({ search: searchText });
    return axios.get(`${resources.ss}/${query}`);
  },
};

export default siteApi;

export { SiteApiInterface };
