
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, IS_ROID, IS_ADMIN_OR_ROID } from '@/store/getters.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { WifiNetwork, Network } from '@/api/interfaces';
import WifiNetworkModal from './WifiNetworkModal.vue';
import { deepClone } from '@/helpers';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { DataTableHeader } from 'vuetify';
import { translateWordFilter } from '@/utils/filters';

const userModule = namespace(USER_NAMESPACE_PATH);

interface WifiNetworkExtended extends WifiNetwork {
  showPassword?: boolean;
}

@Component({
  components: {
    'app-wifi-network-modal': WifiNetworkModal,
  },
  filters: {
    translateWordFilter,
  },
})
export default class WifiNetworkList extends Vue {
  @Prop() private campusSlug!: string;
  @Prop() private network!: Network | null;
  @Prop() private networks!: Network[];
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_ROID) private isRoid!: boolean;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;

  private wifiNetworks: WifiNetworkExtended[] = [];
  private loading = false;

  private headers: DataTableHeader[] = [
    { text: 'SSID', align: 'start', value: 'ssid' },
    { text: 'Tip', value: 'type' },
    { text: 'Omrežje', value: 'network.name' },
    { text: 'Skrito', value: 'hidden' },
    { text: 'Aktivno', value: 'enabled' },
    { text: 'Urnik delovanja', value: 'custom_schedule' },
    // { text: 'Čas delovanja', value: 'workTime' },
    {
      text: 'Geslo',
      value: 'psk_password',
      align: 'start',
      sortable: false,
    },
  ];

  public showWifiCreateOrEditModal(
    extendedWifi: WifiNetworkExtended | null,
    edit = false,
  ): void {
    let wifi = null;
    if (extendedWifi != null) {
      wifi = Object.assign({}, extendedWifi);
      delete wifi.showPassword;
    }
    const promise: Promise<WifiNetwork> = this.$modals.open(
      'app-wifi-network-modal',
      {
        component: {
          props: {
            campusSlug: this.campusSlug,
            network: wifi ? wifi.network : null,
            wifi,
            editNetwork: edit,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<WifiNetwork>;
    promise.then((modifiedWifi: WifiNetwork) => {
      const modifiedWifiExtended: WifiNetworkExtended = {
        ...deepClone(modifiedWifi),
        showPassword: false,
      };
      const network = this.networks.find(
        (network: Network) => network.id === modifiedWifi.network,
      );
      if (network) {
        modifiedWifiExtended.network = network;
      }
      const modifiedWifiIdx = this.wifiNetworks.findIndex(
        (el) => el.id === modifiedWifi.id,
      );
      if (modifiedWifiIdx !== -1) {
        this.wifiNetworks.splice(modifiedWifiIdx, 1);
      }
      this.wifiNetworks.push(modifiedWifiExtended);
    });
  }

  public showDeleteWifiModal(wifi: WifiNetworkExtended): void {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `wifi ${wifi.ssid}`,
            item: wifi,
          },
          deleteFn: this.deleteWifiNetwork,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  public deleteWifiNetwork(modal: any, wifi: WifiNetworkExtended): void {
    repositories.lan.wifiNetwork
      .deleteWifiNetwork(wifi.id as number)
      .then((resp) => {
        this.wifiNetworks = this.wifiNetworks.filter((el) => el.id !== wifi.id);
        this.fetchTasksStateAction();
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
        modal.deleting = false;
      });
  }

  private async fetchWifiNetworks(campusSlug: string) {
    this.loading = true;
    try {
      const { data } =
        await repositories.lan.wifiNetwork.getWifiNetworksForCampus(campusSlug);
      const wifiNetworks: WifiNetworkExtended[] = [];
      data.results.forEach((wifi: WifiNetwork) => {
        const extendedWifi: WifiNetworkExtended = Object.assign({}, wifi, {
          showPassword: false,
        });
        wifiNetworks.push(extendedWifi);
      });
      this.wifiNetworks = wifiNetworks;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading = false;
  }

  @Watch('networks', { deep: true })
  private refreshWifiNetworks() {
    this.fetchWifiNetworks(this.campusSlug);
  }

  private created() {
    if (this.isAdminOrRoid) {
      this.headers.push({
        text: 'Akcije',
        value: 'actions',
        align: 'center',
        width: '100px',
      });
    }
    this.fetchWifiNetworks(this.campusSlug);
  }
}
