
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { DataOptions, Network } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import debounce from 'lodash.debounce';
import { Query } from '@/api/query';
import { prettyPrintFullDate } from '@/utils/filters';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({ filters: { prettyPrintFullDate } })
export default class ManagementNetworksList extends Vue {
  @userModule.Getter(IS_ADMIN) public isAdmin!: boolean;
  private indeterminate = true;
  private rowsPerPageItems = [25, 50, 200, 500];
  private headers = [
    { text: 'Oznaka', value: 'slug' },
    { text: 'Ime', value: 'name' },
    { text: 'Prehodi', value: 'gateway_interfaces', sortable: false },
    { text: 'Ustvarjeno', value: 'created' },
  ];
  private search = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    1000,
  );
  private managementNetworks: Network[] = [];
  private totalManagementNetworks = 0;

  private loading = { initial: true };

  private options: DataOptions | null = {
    page: 1,
    itemsPerPage: 25,
    sortBy: ['created'],
    sortDesc: [true],
    groupBy: [],
    groupDesc: [],
    mustSort: false,
    multiSort: false,
  };

  private created() {
    if (this.isAdmin) {
      this.headers.push({ text: 'Akcije', value: 'actions', sortable: false });
    }
  }

  private routeToManagementNetworkDetails(item: Network) {
    if (item && item.id) {
      this.$router.push({
        name: 'managementNetworkDetail',
        params: { managementNetworkId: item.id + '' },
      });
    }
  }

  @Watch('options', { deep: true })
  private handleOptions() {
    this.fetchManagementNetworks(this.options);
  }

  private async fetchManagementNetworks(pagination: DataOptions | null) {
    this.loading.initial = true;
    try {
      const { data } = await repositories.lan.network.getNetworks(
        new Query({ type: 'infrastructure' }),
        pagination,
        this.search,
      );
      this.managementNetworks = data.results;
      this.totalManagementNetworks = data.count;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.initial = false;
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    // after a new search you want to be looking at the first page
    // manually change pagination object so that @update.pagination on data table gets triggered,
    // otherwise it doesn't update number of pages/items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.options!.page = 1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.fetchManagementNetworks(this.options!);
  }

  private showManagementNetworkModal(network: Network | null) {
    const promise: Promise<Network> = this.$modals.open(
      'app-management-network-modal',
      {
        component: {
          props: {
            managementNetwork: network,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Network>;
    // fetch networks again to update network info
    promise.then((modifiedOrCreatedNetwork: Network) => {
      this.fetchManagementNetworks(this.options!);
    });
  }

  private openDeleteManagementNetworkModal(network: Network) {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `management omrežje ${network.name}`,
            item: network,
          },
          deleteFn: this.deleteManagementNetwork,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteManagementNetwork(modal: any, network: Network) {
    repositories.lan.network
      .deleteNetwork(network.id as number)
      .then(() => {
        this.$toasted.success('Omrežje je bilo uspešno izbrisano.');
        this.managementNetworks = this.managementNetworks.filter(
          (el) => el.id !== network.id,
        );
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
        modal.deleting = false;
      });
  }
}
