import { Circuit, CircuitInfo, PaginatedResponse } from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';

const resources = {
  default: 'connectivity/circuit',
  ci: 'connectivity/circuit-info',
};

interface CircuitApiInterface {
  getCircuits: (query?: Query) => AxiosPromise<PaginatedResponse<Circuit>>;
  getCircuit: (id: string | number) => AxiosPromise<Circuit>;
  getCircuitsInfo: (
    campusSlug: string,
  ) => AxiosPromise<PaginatedResponse<CircuitInfo>>;
  createCircuit: (
    campusSlug: string,
    status: string,
    type: string,
    campusInterfaceID: number,
    campusInterfaceDescription: string | null,
    nodeInterfaceID: number | null,
    nodeInterfaceDescription: string | null,
  ) => AxiosPromise<CircuitInfo>;
  updateCircuit: (
    id: number,
    data: {
      status?: string;
      type?: string;
      campusInterfaceID?: number | null;
      campusInterfaceDescription?: string | null;
      nodeInterfaceID?: number | null;
      nodeInterfaceDescription?: string | null;
    },
  ) => AxiosPromise<CircuitInfo>;
  // TODO: fix any
  deleteCircuit: (id: string | number) => AxiosPromise<any>;
}

const circuitApi: CircuitApiInterface = {
  getCircuits(query = new Query()) {
    return axios.get(`${resources.default}/${query}`);
  },

  getCircuit(id) {
    return axios.get(`${resources.default}/${id}`);
  },

  getCircuitsInfo(campusSlug = '') {
    const query = new Query({ campus__slug: campusSlug });
    return axios.get(`${resources.ci}/${query}`);
  },

  /**
   * Creates a new circuit based on the given data.
   *
   * @param {string} campusSlug
   * @param {string} status
   * @param {string} type
   * @param {number} campusInterfaceID
   * @param {string} campusInterfaceDescription
   * @param {number} nodeInterfaceID
   * @param {string} nodeInterfaceDescription
   * @returns {AxiosPromise<CircuitInfo>}
   */
  createCircuit(
    campusSlug,
    status,
    type,
    campusInterfaceID,
    campusInterfaceDescription,
    nodeInterfaceID,
    nodeInterfaceDescription,
  ) {
    return axios.post(`${resources.default}/`, {
      campus_slug: campusSlug,
      status: status,
      type: type,
      campus_interface_id: campusInterfaceID,
      campus_interface_description: campusInterfaceDescription,
      node_interface_id: nodeInterfaceID,
      node_interface_description: nodeInterfaceDescription,
    });
  },

  /**
   * Updates existing circuit. Data can be partial.
   *
   */
  updateCircuit(id, data) {
    const body: {
      status?: string;
      type?: string;
      campus_interface_id?: number | null;
      campus_interface_description?: string | null;
      node_interface_id?: number | null;
      node_interface_description?: string | null;
    } = {};
    if (data.status != null) {
      body['status'] = data.status;
    }
    if (data.type != null) {
      body['type'] = data.type;
    }
    if (data.campusInterfaceID !== undefined) {
      body['campus_interface_id'] = data.campusInterfaceID;
    }
    if (data.campusInterfaceDescription !== undefined) {
      body['campus_interface_description'] = data.campusInterfaceDescription;
    }
    if (data.nodeInterfaceID !== undefined) {
      body['node_interface_id'] = data.nodeInterfaceID;
    }
    if (data.nodeInterfaceDescription !== undefined) {
      body['node_interface_description'] = data.nodeInterfaceDescription;
    }
    return axios.patch(`${resources.default}/${id}/`, body);
  },

  /**
   * Deletes a circuit with the given id.
   *
   * @param {number} id
   * @returns {AxiosPromise<any>}
   */
  deleteCircuit(id) {
    return axios.delete(`${resources.default}/${id}`);
  },
};

export default circuitApi;

export { CircuitApiInterface };
