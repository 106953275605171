
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Switchport } from '@/api/interfaces';
import { getColorForVlanType } from '@/components/shared/helpers';
import { getFontColorBasedOnBackground } from '@/helpers';

@Component
export default class Switchports extends Vue {
  @Prop({ default: () => [] }) private value!: Switchport[];

  private getFontColor = getFontColorBasedOnBackground;

  private get sortedSwitchports(): Switchport[] {
    return this.value.sort((a, b) => (a.tagged > b.tagged ? 1 : -1));
  }

  private getColorForUntaggedVlan(sw: Switchport): string {
    if (sw && !sw.tagged && sw.vlan && sw.vlan.network) {
      return getColorForVlanType(sw.vlan.network.type);
    }
    return 'default';
  }
}
