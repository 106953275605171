
import { Component, Prop, Vue } from 'vue-property-decorator';
import { repositories } from '@/api/ApiFactory';
import { CampusWithUniqueLocations } from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import { Query } from '@/api/query';
import { Representations } from '@/components/shared/representation';
import { getCampusesWithUniqueLocations } from '@/helpers';

@Component
export default class GeneralCampusPortalIdRedirector extends Vue {
  @Prop() private portalId!: string;

  private campuses: CampusWithUniqueLocations[] = [];
  private headers = [
    {
      text: 'Oznaka',
      align: 'left',
      sortable: true,
      value: 'slug',
      width: '15%',
    },
    { text: 'Ime', value: 'name', width: '25%', sortable: true },
    {
      text: 'Organizacije',
      value: 'organizations',
      width: '30%',
      sortable: false,
    },
    { text: 'Lokacije', value: 'locations', width: '30%', sortable: false },
  ];
  private loading = {
    initial: true,
  };
  private attrs = {
    class: 'mb-6',
    boilerplate: false,
    elevation: 2,
  };
  private repr = new Representations();

  private async loadCampuses(portalId: string) {
    this.loading.initial = true;
    let query = new Query({});
    query.addParam('organizations__portal_id', portalId);
    try {
      const { data } = await repositories.tenants.campus.getCampuses(
        null,
        null,
        null,
        query,
      );

      let routerArgs: Record<string, unknown> | null = null;

      if (data.count === 1) {
        // If there is only one campus, redirect to it
        const campusSlug = data.results[0].slug;
        routerArgs = {
          name: 'campusGeneral',
          params: {
            campusSlug: campusSlug,
          },
        };
      } else if (data.count > 1) {
        // If there are multiple campuses, show them in a table
        const { campuses, errors } = getCampusesWithUniqueLocations(
          data.results,
        );
        for (const error of errors) {
          this.$toasted.error(error);
        }
        this.campuses = campuses;
      } else {
        // If there are no campuses, show a 404 page
        routerArgs = {
          name: 'pageNotFound',
        };
        this.$toasted.error('Kampusa za izbran Arnes ID ni mogoče odpreti.');
      }

      if (routerArgs) {
        // If there is a router argument, redirect to it
        // Replace the current route, so that the user can't go back to the redirector
        this.$router.replace(routerArgs);
      }
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'napravi' },
        ).toString(),
      );
    }
    this.loading.initial = false;
  }
  private routeToCampus(item: CampusWithUniqueLocations) {
    this.$router.push({
      name: 'organization',
      params: { campusSlug: item.slug },
    });
  }

  private created() {
    if (this.portalId) {
      this.loadCampuses(this.portalId);
    } else {
      this.$router.push({ name: 'pageNotFound' });
    }
  }
}
