
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { namespace } from 'vuex-class';
import { IS_ADMIN_OR_ROID } from '@/store/getters.type';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { Device, Zone, Vlan } from '@/api/interfaces';
import { Representations } from '../shared/representation';
import Autocomplete from '../shared/Autocomplete.vue';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    Autocomplete,
  },
})
export default class ZoneEditModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @userModule.Getter(IS_ADMIN_OR_ROID) public isAdminOrRoid!: boolean;
  @Prop() private campusSlug!: string;
  @Prop() private zoneId!: number;
  @Prop() private zoneName!: string;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private zone: Zone | null = null;
  private vlans: Vlan[] = [];
  private repr = new Representations();
  private availableDevices: Device[] = [];
  private error: string | null = null;
  private loading = {
    zone: false,
    vlans: false,
    devices: false,
    submit: false,
  };
  private form: { devices: Device[] } = {
    devices: [],
  };

  get initialLoading() {
    return this.loading.zone || this.loading.devices || this.loading.vlans;
  }

  private async created() {
    await this.fetchZone();
    await this.fetchVlans();
    this.fetchAvailableDevices();
  }

  private async fetchZone(): Promise<void> {
    this.loading.zone = true;
    try {
      const { data } = await repositories.zoning.zone.getZone(this.zoneId);
      this.zone = data;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'coni' },
        ).toString(),
      );
    }
    this.loading.zone = false;
  }

  private async fetchVlans(): Promise<void> {
    this.loading.vlans = true;
    try {
      const { data } = await repositories.ipam.vlan.getVlans({
        zones__id: this.zoneId + '',
      });
      this.vlans = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'vlanih' },
        ).toString(),
      );
    }
    this.loading.vlans = false;
  }

  private async fetchAvailableDevices() {
    this.loading.devices = true;
    try {
      // get campus devices
      const { data } = await repositories.infrastructure.device.getDevices({
        campusSlug: this.campusSlug,
      });
      // get all tsp's
      const data2 = await repositories.infrastructure.device.getDevices({
        roles: ['tsp'],
      });
      // get all devices that are not in any zone
      const data3 = await repositories.infrastructure.device.getDevices({
        hasZone: false,
      });
      let ids = data.results.map((d) => d.id);
      // add missing tsp's, campus's tsp's might already be among campus devices
      for (const device of data2.data.results) {
        if (!ids.includes(device.id)) {
          data.results.push(device);
        }
      }
      ids = data.results.map((d) => d.id);
      // add missing no-zone devices
      for (const device of data3.data.results) {
        if (
          !ids.includes(device.id) &&
          !device.roles!.some((role) => ['tsp', 'backbone'].includes(role.slug))
        ) {
          data.results.push(device);
        }
      }
      // if zone is lanonly, filter out TSP's
      if (this.zone!.lanonly) {
        data.results = data.results.filter(
          (d) => !d.roles!.map((role) => role.slug).includes('tsp'),
        );
      }
      this.availableDevices = data.results;
      // if zone has a device which is not in availableDevices then there's a problem
      const availableDevicesIds = this.availableDevices.map((d) => d.id);
      const invalidDevices = this.zone!.devices!.filter(
        (d) => !availableDevicesIds.includes(d.id),
      ).map((d) => d.name);
      if (invalidDevices.length > 0) {
        this.error =
          'Naprave [ ' +
          invalidDevices.join(', ') +
          ' ] so skrivaj v zoni, ko boste kliknili shrani se bodo odstranile!';
      }
      const zoneDeviceIds = this.zone!.devices!.map((d) => d.id);
      // set current zone's devices
      this.form.devices = this.availableDevices.filter((d) =>
        zoneDeviceIds.includes(d.id),
      );
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'napravah' },
        ).toString(),
      );
    }
    this.loading.devices = false;
  }

  private async submit() {
    this.loading.submit = true;
    try {
      const { data } = await repositories.zoning.zone.updateZone(this.zoneId, {
        devices: this.form.devices.map((d) => d.name),
      });
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
      this.ok(data);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.submit = false;
  }
}
