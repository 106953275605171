import axios, { AxiosPromise } from 'axios';
import { Vlan, VlanAdvanced, PaginatedResponse } from '@/api/interfaces';
import { Query } from '../query';

const resources = {
  default: 'ipam/vlans',
  advanced: 'ipam/vlans-advanced',
};

interface VlanApiInterface {
  getVlansAdvanced(
    networkType: string,
    deviceId: string,
    search: string,
  ): AxiosPromise<PaginatedResponse<VlanAdvanced>>;
  getVlans(queryProps: {
    [key: string]: string;
  }): AxiosPromise<PaginatedResponse<Vlan>>;
}

const VlanApi: VlanApiInterface = {
  getVlansAdvanced(networkType = '', deviceId = '', search) {
    const query = new Query({
      networks__type: networkType,
      search,
      management_on: deviceId,
    });
    return axios.get(`${resources.advanced}/${query}`);
  },
  getVlans(queryProps) {
    const query = new Query(queryProps);
    return axios.get(`${resources.default}/${query}`);
  },
};

export default VlanApi;
export { VlanApiInterface };
