
import { Component, Prop, Vue } from 'vue-property-decorator';
import { repositories } from '@/api/ApiFactory';
import NetboxStatusChip from '@/components/device/NetboxStatusChip.vue';
import { NetboxAsset, Asset } from '@/api/interfaces';

import ErrorHandler from '@/components/shared/errorHandler';

@Component({ components: { NetboxStatusChip } })
export default class extends Vue {
  @Prop() private asset!: Asset;
  private loading = false;
  private failedText = '';
  netboxAsset: NetboxAsset | null = null;

  private created() {
    if (this.asset.inventory_sys_id)
      this.fetchNetboxAssets(this.asset.inventory_sys_id);
  }

  private get currentData() {
    let data = {
      manufacturer: {
        value: this.asset.product?.manufacturer.name,
        netbox: this.netboxAsset?.device_type?.manufacturer.name,
        warning: false,
      },
      model: {
        value: this.asset.product?.name,
        netbox: this.netboxAsset?.device_type?.model,
        warning: false,
      },
      asset_mac: {
        value: this.asset.base_mac,
        netbox: this.netboxAsset?.custom_fields.asset_mac,
        warning: false,
      },
      serial: {
        value: this.asset.serial_number,
        netbox: this.netboxAsset?.serial,
        warning: false,
      },
    };

    if (this.netboxAsset) {
      if (data.asset_mac.netbox) {
        data.asset_mac.netbox = data.asset_mac.netbox.toUpperCase();
      }
      if (data.serial.netbox) {
        data.serial.netbox = data.serial.netbox.toUpperCase();
      }
      Object.values(data).forEach((value) => {
        if (value.value !== value.netbox) {
          value.warning = true;
        }
      });
    }

    return data;
  }

  private get showAlertForUnsyncedData() {
    return Object.values(this.currentData).some((item) => item.warning);
  }

  private async fetchNetboxAssets(id: string | number) {
    this.loading = true;
    try {
      const { data } = await repositories.infrastructure.asset.getNetboxAsset(
        id,
      );
      this.netboxAsset = data;
    } catch (error) {
      this.failedText =
        'Podatkov iz inventarnega sistema ni bilo mogoče pridobiti.';
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    } finally {
      this.loading = false;
    }
  }
}
