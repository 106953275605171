import { GraphTaskPost, Task as T, TaskPost } from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';
import { TasksState } from '@/store/types';

const resources = {
  default: 'orchestrator/es/tasks',
  deviceGetFacts: 'orchestrator/tasks/device-get-facts',
  tasks: 'orchestrator/tasks',
};
interface TasksStateInfo {
  state: TasksState;
  latest_user_triggered_task_time: string | null;
}

export interface TasksInterface {
  getTask(taskId: string): AxiosPromise<Task>;
  getPendingTasks(): AxiosPromise<TasksListResponse>;
  searchTasks(
    page: number,
    pageSize: number,
    showAll?: boolean,
    sortBy?: string,
    taskStatus?: string,
    taskName?: string,
    username?: string,
    extra?: [string, string | null],
  ): AxiosPromise<TasksListResponse>;
  createTaskDeviceGetFacts(devices: string[]): AxiosPromise<TaskPost>;
  createTaskCampusGraphGetFacts(
    campusSlug: string,
  ): AxiosPromise<GraphTaskPost>;
  getTaskResultsDeviceGetFacts(deviceName: string): AxiosPromise<{
    task: { id: string; last_update?: string; data: T } | null;
  }>;
  getTasksState(timestamp: number): AxiosPromise<TasksStateInfo>;
  getTaskResultsCampusGraphGetFacts(
    deviceName: string,
    graphId?: string,
  ): AxiosPromise<{
    graph: { id: string; last_update?: string; data: T } | null;
  }>;
}

export const tasksApi: TasksInterface = {
  getTask(taskId: string) {
    return axios.get(`${resources.default}/${taskId}/`);
  },
  getPendingTasks() {
    return axios.get(`${resources.default}/pending/`);
  },
  searchTasks(
    page: number,
    pageSize: number,
    showAll?: boolean,
    sortBy?: string,
    taskStatus?: string,
    taskName?: string,
    username?: string,
    [extraParamName, extraParamValue]: [string, string | null] = [
      'campus',
      null,
    ],
  ) {
    const params = new Query({
      page,
      size: pageSize,
      show_all: showAll && !username ? showAll : null,
      user: username && !showAll ? username : null,
      sort: sortBy,
      task_name: taskName,
      task_status: taskStatus,
    });
    params.addParam(extraParamName, extraParamValue);
    return axios.get(`${resources.default}/${params.toString()}`);
  },
  createTaskDeviceGetFacts(devices) {
    return axios.post(`${resources.deviceGetFacts}/`, {
      device_names: devices,
    });
  },

  getTaskResultsDeviceGetFacts(deviceName) {
    return axios.get(`${resources.deviceGetFacts}/${deviceName}/`);
  },
  createTaskCampusGraphGetFacts(campusSlug) {
    return axios.post(`${resources.tasks}/campus-get-lldp-graph`, {
      campus_slug: campusSlug,
    });
  },
  getTaskResultsCampusGraphGetFacts(campusSlug, graphId) {
    const query = new Query({ graph_id: graphId });
    return axios.get(
      `${resources.tasks}/campus-get-lldp-graph/${campusSlug}/${query}`,
    );
  },
  getTasksState(timestamp) {
    const query = new Query({ timestamp });
    return axios.get(`${resources.default}/state/${query}`);
  },
};

export interface TasksListResponse {
  hits: {
    total: {
      value: number;
      relation?: string;
    };
    hits: Task[];
  };
}

export interface Task {
  _source: {
    celery_task: CeleryTask;
    http_request_id: string;
    user_id: number | null;
    username: string;
  };
  locked: boolean;
}

export enum TaskStatus {
  success = 'SUCCESS',
  pending = 'PENDING',
  failure = 'FAILURE',
  retry = 'RETRY',
  revoked = 'REVOKED',
  running = 'RUNNING',
  externalJobDone = 'EXTERNAL-JOB-DONE',
  externalJobStart = 'EXTERNAL-JOB-START',
  externalJobGettingOutput = 'EXTERNAL-JOB-GETTING-OUTPUT',
}

export interface Resource {
  id: number;
  type: string;
  label: string;
}

export interface CeleryTask {
  id: string;
  name: string;
  args: string;
  kwargs: string;
  result?: string | null;
  created: string;
  started_at: string | null;
  completed_at: string | null;
  last_update: string | null;
  status: TaskStatus;
  external_status: string;
  internal_status: string;
  bypass_resource_status: boolean;
  result_published: null | string;
  resources: Resource[];
  exception?: string | null;
  traceback?: string | null;
}

export interface CeleryTaskResult {
  stdout?: {
    custom_stats?: any;
    plays?: CeleryTaskResultPlays[];
    stats?: any;
  };
  stderr?: string;
  return_code?: any;
}

export interface CeleryTaskResultPlays {
  play?: { name: string };
  tasks?: CeleryTaskResultTasks[];
}

export interface CeleryTaskResultTasks {
  hosts: { [hostnameKey: string]: any }; // keys are hostnames, values are hostData
  task?: CeleryTaskResultTask;
}
export interface CeleryTaskResultTask {
  duration: {
    start: string;
    end: string;
  };
  id: string;
  name: string;
}
