import { render, staticRenderFns } from "./ManagedCampuses.vue?vue&type=template&id=38c264ab&scoped=true&"
import script from "./ManagedCampuses.vue?vue&type=script&lang=ts&"
export * from "./ManagedCampuses.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38c264ab",
  null
  
)

export default component.exports