
import { AxiosResponse } from 'axios';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import { PersistentScrollableModal } from '@/components/shared/modals/PersistentModal';
import { Representations, ReprValue } from '@/components/shared/representation';
import { repositories } from '@/api/ApiFactory';
import {
  Address,
  Location,
  Street,
  House,
  PaginatedResponse,
} from '@/api/interfaces';
import Autocomplete from '@/components/shared/Autocomplete.vue';
import CampusLocationMap from './CampusLocationMap.vue';
import L, { latLng } from 'leaflet';
import { ValidationProvider } from 'vee-validate';
import ErrorHandler from '@/components/shared/errorHandler';

@Component({
  components: {
    Autocomplete,
    CampusLocationMap,
  },
})
export default class AddOrEditBuildingModal extends Mixins<
  PromiserType,
  PersistentScrollableModal
>(PromiserMixin, PersistentScrollableModal) {
  public $refs!: {
    coordinatesVP: InstanceType<typeof ValidationProvider>;
  };
  @Prop({ default: null }) private campusSlug!: string | null;
  @Prop() private locations!: Location[];
  @Prop({ default: null }) private location!: Location | null;

  private repr = new Representations();
  private searchStreetsApi = repositories.tenants.ehis.searchStreets;
  private name: string | null = null;
  private houses: ReprValue<House>[] = [];
  private selectedStreet: Street | null = null;
  private selectedHouse: House | null = null;
  private coordinates: string | null = null;
  private locationPickType = 'address';
  private loading = {
    submit: false,
    houses: false,
    house: false,
  };
  private drawMap = false;
  private mapKey = 1;

  private searchLocationsApi = (search: string) =>
    repositories.tenants.location.searchLocations(search, 'building');

  private mounted() {
    this.drawMap = true;
  }

  private async created() {
    if (this.location) {
      if (this.location.address) {
        this.fetchExtendedHouse((this.location.address as Address).ehis_mid)
      } else {
        this.coordinates = `${this.location.coordinates!.coordinates[0]}, ${this.location.coordinates!.coordinates[1]}`;
        this.locationPickType = 'coordinates';
      }
      this.name = this.location.name
    }
  }

  private async fetchExtendedHouse(mid: number) {
    this.loading.house = true;
    try {
      const response = await repositories.tenants.ehis.getExtendedHouse(mid);
      // select street
      const houseInfo = response.data.ehisHouse;
      this.selectedStreet = {
        mid: houseInfo.ehisStreet.mid,
        name: houseInfo.ehisStreet.nameOfficial,
        post: houseInfo.ehisStreet.ehisPost.name,
        postCode: houseInfo.ehisStreet.ehisPost.postalCode,
        settlement: houseInfo.ehisCity.nameOfficial,
      };
      // fetch houses on this street so that you can populate select for houses
      (this.getHouses() as Promise<any>).then(() => {
        // select house - construct house data from other data
        const house: House = {
          ehisHouse: {
            mid: houseInfo.mid,
            latitude: houseInfo.latitude,
            longitude: houseInfo.longitude,
            houseNumber: houseInfo.houseNumber,
            houseNumberAddon: houseInfo.houseNumberAddon,
          }
        };
        this.selectedHouse = house;
      })
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'stavbi' },
        ).toString(),
      );
    } finally {
      this.loading.house = false;
    }

  }

  private get latlngCoords() {
    if (!this.coordinates) {
      return null
    }
    const s = this.coordinates.split(', ')
    return latLng(Number(s[0]), Number(s[1]))
  }

  private get draggableData() {
    if (
      this.locationPickType === 'address' || (
      this.locationPickType === 'coordinates' && this.location && this.location.address)
    ) {
      if (!this.selectedStreet || !this.selectedHouse) {
        return null;
      }
      const house = this.selectedHouse!.ehisHouse;
      const street = this.selectedStreet!;
      return {
        address : `${street.name} ${house.houseNumber}${house.houseNumberAddon} - ${street.postCode} ${street.post}`,
        coordinates: latLng(this.selectedHouse.ehisHouse.latitude, this.selectedHouse.ehisHouse.longitude),
        buildingNames: [this.name || '[prazno]'],
      }
    }
    return { address: null, buildingNames: [this.name || '[prazno]'], coordinates: this.latlngCoords }
  }

  private createOrEditBuilding() {
    if (this.location) {
      this.editBuilding()
    } else {
      this.createBuilding()
    }
  }

  private editBuilding() {
    this.loading.submit = true;
    let coords: L.LatLng | null = null
    if (this.coordinates) {
      const s = this.coordinates.split(', ')
      coords = latLng(Number(s[0]), Number(s[1]))
    }
    repositories.tenants.location
      .editBuilding(
        this.location!.id,
        this.name as string,
        this.selectedHouse && this.locationPickType === 'address' ? this.selectedHouse!.ehisHouse.mid : null,
        coords,
      )
      .then((res) => {
        this.ok(res.data);
      })
      .catch((err) => {
        this.$toasted.error(
          `Napaka pri urejanju zgradbe: ${err.response.data.detail}`,
        );
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  private createBuilding() {
    this.loading.submit = true;
    let coords: L.LatLng | null = null
    if (this.coordinates) {
      const s = this.coordinates.split(', ')
      coords = latLng(Number(s[0]), Number(s[1]))
    }
    repositories.tenants.location
      .createLocation(
        'building',
        this.name as string,
        null,
        this.selectedHouse && this.locationPickType === 'address' ? this.selectedHouse!.ehisHouse.mid : null,
        this.campusSlug,
        coords,
      )
      .then((res) => {
        this.ok(res.data);
      })
      .catch((err) => {
        this.$toasted.error(
          `Napaka pri dodajanju zgradbe: ${err.response.data.detail}`,
        );
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  private getHouses() {
    if (this.selectedStreet == null) {
      this.houses = []
      this.selectedHouse = null
      return
    }
    this.loading.houses = true;
    return repositories.tenants.ehis
      .getHouses(this.selectedStreet!.mid)
      .then((res: AxiosResponse<House[]>) => {
        this.houses = res.data.map((house: House) =>
          this.repr.houseRepr(house),
        );
      })
      .finally(() => {
        this.loading.houses = false;
      });
  }

  private onHouseSelected() {
    if (this.selectedHouse) {
      this.coordinates = `${this.selectedHouse.ehisHouse.latitude}, ${this.selectedHouse.ehisHouse.longitude}`
      this.mapKey += 1
    }
  }

  private updateCoordinates(newLatLng: L.LatLng) {
    if (this.locationPickType === 'address') {
      // event 'markerChangedLatLng' got triggered after:
      // 1.  edit-building was opened
      // 2.  went to 'custom pick coordinates' and changed coordinates
      // 3.  went back to 'address pick', which sets coordinates to null, which
      //     which triggers recalculation of map coordinates, which triggers
      //     'markerChangedLatLng' for new coordinates of marker - back to original (stored address) position
      // in this case we don't do anything since we don't care
      return
    }
    this.coordinates = `${newLatLng.lat}, ${newLatLng.lng}`
    this.$refs.coordinatesVP.validate();
  }

  private onLocationPickTypeChange() {
    if (this.location) {
      if (this.location.address) {
        if (this.locationPickType === 'address') {
          // reset marker's position if it was moved
          this.coordinates = null;
        } else {
          // set coordinates to the currently selected address
          let addressCoords = (this.location.address as Address).coordinates!.coordinates
          if (this.selectedHouse) {
            addressCoords = [this.selectedHouse.ehisHouse.latitude, this.selectedHouse.ehisHouse.longitude];
          }
          this.coordinates = `${addressCoords[0]}, ${addressCoords[1]}`;
        }
      }
    } else {
      this.resetForm()
    }
  }

  private resetForm() {
    this.coordinates=null;
    this.selectedHouse=null;
    this.selectedStreet=null;
    this.mapKey+=1;
  }
}
