
import { repositories } from '@/api/ApiFactory';
import { Campus, DHCPServer } from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import { Representations } from '@/components/shared/representation';
import { deepClone } from '@/helpers';
import { IS_ADMIN, IS_ADMIN_OR_ROID, IS_ROID } from '@/store/getters.type';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import debounce from 'lodash.debounce';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { namespace } from 'vuex-class';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class DHCPServerList extends Vue {
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_ROID) private isRoid!: boolean;
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;
  @Prop({ default: null }) private campusSlug!: string | null;
  @Prop({ default: null }) private fixedDHCPServers!: DHCPServer[] | null;
  @Prop({ default: false }) private fixedNetworkCampus!: boolean;
  @Prop({ default: false }) private canChangeCampus!: boolean;
  @Prop({ default: true }) private showSearch!: boolean;
  @Prop({ default: true }) private showAdd!: boolean;
  @Prop({ default: true }) private showPagination!: boolean;
  @Prop({ default: true }) private itemExpand!: boolean;
  @Prop({ default: () => ['info', 'edit', 'delete'] })
  private showControls!: string[];
  @Prop({ default: null }) private title!: string | null;

  private repr = new Representations();
  private indeterminate = true;
  private headers: DataTableHeader[] = [
    {
      text: 'Ime',
      sortable: true,
      value: 'name',
      width: '50%',
    },
    {
      text: 'IP naslov',
      sortable: true,
      value: 'address',
      width: '40%',
    },
  ];
  private expanded: DHCPServer[] = [];

  private expandRow(el: DHCPServer) {
    let remove = null;
    this.expanded.forEach((value, index) => {
      if (el.id === value.id) {
        remove = index;
      }
    });
    if (remove !== null) {
      this.expanded.splice(remove, 1);
    } else {
      this.expanded.push(el);
    }
  }

  private search = '';
  private debouncedSearch = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );
  private dhcpServers: DHCPServer[] = [];
  private loading = true;

  private get showAddBtn() {
    // TODO: enable add button also for ROID when we implement DHCP hosting
    return this.showAdd && this.isAdmin;
  }

  private get possibleActions() {
    return this.isAdminOrRoid ? this.showControls : ['info'];
  }

  private async fetchDHCPServers() {
    if (this.fixedDHCPServers !== null) {
      this.dhcpServers = deepClone(this.fixedDHCPServers);
      this.loading = false;
      return;
    }
    this.loading = true;
    try {
      const { data } = await repositories.lan.dhcpServer.getDHCPServers(
        this.campusSlug,
        true,
      );
      this.dhcpServers = data.results;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading = false;
  }

  private created() {
    if (!this.fixedNetworkCampus) {
      this.headers[0].width = '40%';
      this.headers[1].width = '20%';
      this.headers.push({
        text: 'Kampus',
        sortable: true,
        value: 'campus.slug',
        width: '20%',
      });
    }
    if (this.possibleActions.length > 0) {
      if (this.fixedNetworkCampus) {
        this.headers[0].width = '45%';
        this.headers[1].width = '35%';
      }
      this.headers.push({
        text: 'Akcije',
        value: 'actions',
        width: '10%',
        sortable: false,
      });
    }
    this.fetchDHCPServers();
    if (this.itemExpand) {
      this.expanded = deepClone(this.dhcpServers);
    }
  }

  private openDHCPServerCreateOrEditModal(
    create: boolean,
    dhcpServer: DHCPServer | null,
  ): void {
    let campusSlug = this.campusSlug;
    let fixedNetworkCampusSlug = null;
    if (!create) {
      // update, so take campus slug of the picked dhcp server
      campusSlug = dhcpServer!.campus
        ? (dhcpServer!.campus as Campus).slug
        : null;
      if (this.fixedNetworkCampus) {
        fixedNetworkCampusSlug = this.campusSlug;
      }
    }
    const promise: Promise<DHCPServer> = this.$modals.open(
      'app-dhcp-server-modal',
      {
        component: {
          props: {
            campusSlug,
            dhcpServer,
            fixedNetworkCampusSlug,
            canChangeCampus: this.canChangeCampus,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<DHCPServer>;
    promise.then((modifiedDHCPServer: DHCPServer) => {
      const modifiedDHCPServerIdx = this.dhcpServers.findIndex(
        (el) => el.id === modifiedDHCPServer.id,
      );
      if (modifiedDHCPServerIdx !== -1) {
        this.dhcpServers.splice(modifiedDHCPServerIdx, 1, modifiedDHCPServer);
      } else {
        this.dhcpServers.push(modifiedDHCPServer);
      }
      this.$emit('changed', modifiedDHCPServer);
    });
  }

  private showDeleteDHCPServerModal(dhcpServer: DHCPServer): void {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `DHCP strežnik ${this.repr.dhcpServerRepr(dhcpServer).text}`,
            item: dhcpServer,
          },
          deleteFn: this.deleteDHCPServer,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteDHCPServer(modal: any, dhcpServer: DHCPServer): void {
    repositories.lan.dhcpServer
      .deleteDHCPServer(dhcpServer.id!)
      .then((resp) => {
        this.dhcpServers = this.dhcpServers.filter(
          (el) => el.id !== dhcpServer.id,
        );
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            {
              message: `Pri izbrisu DHCP strežnika ${
                this.repr.dhcpServerRepr(dhcpServer).text
              } je prišlo do napake.`,
            },
          ).toString(),
        );
      });
  }

  private redirectToDHCPServerDetail(dhcpServer: DHCPServer) {
    const params: any = {
      dhcpServerId: dhcpServer.id + '',
      canChangeCampus: this.canChangeCampus,
      fixedNetworkCampusSlug: this.fixedNetworkCampus ? this.campusSlug : null,
    };
    let routeName = 'dhcpServer';
    if (this.campusSlug) {
      params.campusSlug = this.campusSlug;
      routeName = 'campusDhcpServer';
    }
    this.$router.push({ name: routeName, params });
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    this.debouncedSearch = this.search;
  }
}
