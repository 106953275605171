import axios, { AxiosPromise } from 'axios';
import { WlcUsage } from '@/api/interfaces';

const resources = { default: 'provision' };

interface ProvisionMiscApiInterface {
  getWlcUsage(): AxiosPromise<WlcUsage>;
  modifyDeviceInterfaces(data: any): AxiosPromise<any>;
}

const MiscApi: ProvisionMiscApiInterface = {
  getWlcUsage() {
    return axios.get(`${resources.default}/wlc/usage/`);
  },

  modifyDeviceInterfaces(data) {
    return axios.post(`${resources.default}/modify-device-interfaces/`, data);
  },
};

export default MiscApi;
export { ProvisionMiscApiInterface };
