
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  CIRCUITS_NAMESPACE_PATH,
  USER_NAMESPACE_PATH,
} from '@/store/namespaces.type';
import {
  FETCH_CIRCUITS,
  DELETE_CIRCUIT,
  FETCH_TASKS_STATE,
} from '@/store/actions.type';
import { LOADING, CIRCUITS } from '@/store/states.type';
import { IS_ADMIN } from '@/store/getters.type';
import { CircuitInfo } from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import translateWord from '@/components/shared/translations';
import _get from 'lodash.get';

const circuitsModule = namespace(CIRCUITS_NAMESPACE_PATH);
const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class CampusCircuits extends Vue {
  @Prop() private campusSlug!: string;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @circuitsModule.State(LOADING) private loadingCircuits!: boolean;
  @circuitsModule.State(CIRCUITS) private circuits!: CircuitInfo[];
  @circuitsModule.Action(FETCH_CIRCUITS) private fetchCircuitsAction!: (
    campusSlug: string,
  ) => Promise<any>;
  @circuitsModule.Action(DELETE_CIRCUIT) private deleteCircuitAction!: (
    circuit: CircuitInfo,
  ) => Promise<any>;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;

  private translateWord = translateWord;

  private headers = [
    { text: 'Tip', value: 'type', sortable: true },
    {
      text: 'Vozlišče',
      align: 'left',
      value: 'site',
      sortable: true,
    },
    {
      text: this.translateWord('upstream'),
      value: 'upstream',
      sortable: false,
    },
    { text: 'Kampus', value: 'campus', sortable: false },
    { text: 'Status', value: 'status', sortable: true },
  ];

  private created() {
    if (this.isAdmin) {
      this.headers.push({
        text: 'Kontrola',
        value: 'control',
        sortable: false,
      });
    }
    this.fetchCircuitsAction(this.campusSlug).catch((error) => {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'povezavah' },
        ).toString(),
      );
    });
  }

  private openCreateCircuitModal() {
    this.$modals.open('app-add-circuit-modal', {
      component: {
        props: {
          campusSlug: this.campusSlug,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }
  private openEditCircuitModal(circuit: CircuitInfo) {
    this.$modals.open('app-edit-circuit-modal', {
      component: {
        props: {
          campusSlug: this.campusSlug,
          circuit: {
            id: circuit.id,
            status: circuit.status,
            type: circuit.type,
            campusDeviceID: _get(circuit, 'downstream.device.id'),
            campusInterfaceID: _get(circuit, 'downstream.interface.id'),
            campusInterfaceDescription: _get(
              circuit,
              'downstream.interface.description',
            ),
            siteSlug: _get(circuit, 'upstream.site.slug'),
            nodeDeviceID: _get(circuit, 'upstream.device.id'),
            nodeInterfaceID: _get(circuit, 'upstream.interface.id'),
            nodeInterfaceDescription: _get(
              circuit,
              'upstream.interface.description',
            ),
          },
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private openDeleteCircuitModal(circuit: CircuitInfo) {
    let upstreamInterfaceRepr = '[neznan upstream]';
    if (circuit?.upstream?.interface) {
      upstreamInterfaceRepr = circuit.upstream.interface.name;
    }
    let downstreamInterfaceRepr = '[neznan downstream]';
    if (circuit?.downstream?.interface) {
      downstreamInterfaceRepr = circuit.downstream.interface.name;
    }

    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `povezavo med ${upstreamInterfaceRepr} in ${downstreamInterfaceRepr}`,
            item: circuit,
          },
          deleteFn: this.deleteCircuit,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteCircuit(modal: any, circuit: CircuitInfo) {
    this.deleteCircuitAction(circuit)
      .then(() => {
        // fetch tasks state so that it immediatelly updates the badges
        this.fetchTasksStateAction();
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { message: 'Med brisanjem povezave je prišlo do napake.' },
          ).toString(),
        );
        modal.deleting = false;
      });
  }
}
