import { PaginatedResponse, WifiNetwork } from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';
import { transformData } from '../helpers';

const resources = { default: 'lan/wifi-networks' };

interface WifiNetworkApiInterface {
  getWifiNetworksForCampus(
    campusSlug: string,
  ): AxiosPromise<PaginatedResponse<WifiNetwork>>;
  getWifiNetworksOfNetwork(
    networkId: string | number,
  ): AxiosPromise<PaginatedResponse<WifiNetwork>>;
  getWifiNetwork(wifiNetworkId: number): AxiosPromise<WifiNetwork>;
  createWifiNetwork(data: any): AxiosPromise<any>;
  deleteWifiNetwork(wifiNetworkId: number): AxiosPromise<any>;
  updateWifiNetwork(wifiNetworkId: number, data: any): AxiosPromise<any>;
}

const WifiNetworkApi: WifiNetworkApiInterface = {
  getWifiNetworksForCampus(campusSlug) {
    const query = new Query({ network_campus_slug: campusSlug });
    return axios.get(`${resources.default}/${query}`);
  },

  getWifiNetworksOfNetwork(networkId) {
    const query = new Query({ network: networkId });
    return axios.get(`${resources.default}/${query}`);
  },

  getWifiNetwork(wifiNetworkId) {
    return axios.get(`${resources.default}/${wifiNetworkId}/`);
  },

  createWifiNetwork(data) {
    let ignoreProps = ['created', 'modified', 'id'];
    if (data.type === 'eduroam') {
      ignoreProps = ignoreProps.concat(['psk_password']);
    }
    const wifi = transformData(data, ignoreProps, ['network']);
    return axios.post(`${resources.default}/`, wifi);
  },

  deleteWifiNetwork(wifiNetworkId) {
    return axios.delete(`${resources.default}/${wifiNetworkId}/`);
  },

  updateWifiNetwork(wifiNetworkId, data) {
    let ignoreProps = ['created', 'modified', 'id'];
    if (data.type === 'eduroam') {
      ignoreProps = ignoreProps.concat(['psk_password']);
    }
    const wifi = transformData(data, ignoreProps, ['network']);
    return axios.patch(`${resources.default}/${wifiNetworkId}/`, wifi);
  },
};

export default WifiNetworkApi;
export { WifiNetworkApiInterface };
