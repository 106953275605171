
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN_OR_ROID } from '@/store/getters.type';
import { Upload } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class ListAttachments extends Vue {
  @Prop() private attachments!: Upload[];
  @Prop() private editable!: boolean;
  @Prop() private objectId!: number | string;
  @Prop() private modelName!: string;
  @Prop() private uploadType!: string;
  @Prop({ default: 'Priloge' }) private title!: string;
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;

  private headers = [
    {
      text: 'Priloga',
      align: 'left',
      value: 'name',
      sortable: false,
    },
    {
      text: 'Akcije',
      align: 'center',
      value: 'actions',
      sortable: false,
      width: '120px',
    },
  ];

  private openAddAttachmentModal() {
    const promise: Promise<Upload> = this.$modals.open(
      'app-add-attachment-modal',
      {
        component: {
          props: {
            objectId: this.objectId,
            modelName: this.modelName,
            uploadType: this.uploadType,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Upload>;
    promise.then((attachment: Upload) => {
      this.$emit('addAttachment', attachment);
    });
  }

  private openDeleteAttachmentModal(attachment: Upload) {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `prilogo ${attachment.uploaded_name}`,
            item: attachment,
          },
          deleteFn: this.deleteAttachment,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteAttachment(modal: any, attachment: Upload) {
    repositories.documentation.upload
      .deleteUpload(attachment.id)
      .then(() => {
        this.$toasted.success('Priloga je bila uspešno izbrisana.');
        this.$emit('deleteAttachment', attachment);
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
        modal.deleting = false;
      });
  }

  private downloadAttachment(attachment: Upload) {
    repositories.documentation.upload
      .getUpload(attachment.id)
      .then((response) => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = attachment.uploaded_name;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
  }
}
