
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, IS_ADMIN_OR_ROID } from '@/store/getters.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import DrawDevice from '@/components/shared/DrawDevice.vue';
import DeviceData from '@/components/device/DeviceData.vue';
import DeviceSTPFacts from '@/components/device/DeviceSTPFacts.vue';
import InterfaceLegend from '@/components/device/InterfaceLegend.vue';
import DenseInterface from '@/components/device/DenseInterface.vue';
import InterfaceInfo from '@/components/interfaces/InterfaceInfo.vue';
import VlanTaggedSelectMultipleItem from '@/components/interfaces/VlanTaggedSelectMultipleItem.vue';
import VlanSelectItem from '@/components/interfaces/VlanSelectItem.vue';
import VlanTaggedSelectSelection from '@/components/interfaces/VlanTaggedSelectSelection.vue';
import VlanSelectSelection from '@/components/interfaces/VlanSelectSelection.vue';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import {
  Interface,
  DeviceInfo,
  Task,
  StatsL2Interface,
  Vlan,
  InterfaceStateFact,
  ModifyDeviceInterfacesForm,
  SpanningTree,
} from '@/api/interfaces';
import {
  getPortType,
  getPortTypeIcon,
  getColorForVlanType,
  getIconForVlanType,
  possibleUsagesForInterfaces,
  parsePortSTPState,
} from '@/components/shared/helpers';
import _get from 'lodash.get';
const userModule = namespace(USER_NAMESPACE_PATH);
import axios from 'axios';

// number of task retries when get-facts task fails
const MaxTaskRetries = 1000000000000;
// minumum delay between two creations of get-facts tasks - to avoid creating multiple tasks fast, eg:
// init creates one, then fetch gets an old one which is a success and it would create another one at
// that point unless you give it some delay.
const MinFactsTaskDelay = 5;

interface RetrieveGetFacts {
  task: {
    id: string;
    last_update: string | null;
    data: {
      success: boolean;
      error: boolean;
      msg: string;
    };
  };
}

@Component({
  components: {
    DrawDevice,
    InterfaceInfo,
    DeviceData,
    'device-stp-facts': DeviceSTPFacts,
    InterfaceLegend,
    DenseInterface,
    VlanSelectItem,
    VlanTaggedSelectMultipleItem,
    VlanSelectSelection,
    VlanTaggedSelectSelection,
  },
})
export default class EditInterface extends Vue {
  // @Prop(Number) private deviceId!: number;
  @Prop(String) private deviceName!: string;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;

  // interface description should be ascii printable characters without ?
  private fab = false;
  private fabUp = false;

  private getPortType = getPortType;
  private getPortTypeIcon = getPortTypeIcon;

  private panel: boolean[] = [];
  private items = 48;

  private expandedInterfaces: number[] = [];
  private loadExpandedParts: number[] = [];

  private updateExpanded(expand: boolean, ifaceId: number): void {
    if (expand) {
      if (!this.expandedInterfaces.includes(ifaceId)) {
        this.expandedInterfaces.push(ifaceId);
      }
      if (!this.loadExpandedParts.includes(ifaceId)) {
        this.loadExpandedParts.push(ifaceId);
      }
    } else {
      this.expandedInterfaces = this.expandedInterfaces.filter(
        (item) => item !== ifaceId,
      );
    }
  }
  // (single)
  private selectedPort: null | number = null;

  private loading = {
    initial: true,
    facts: false,
    submit: false,
  };

  private isLastTaskStatusOk = true;
  // list of failed task id's. Once this list is big enough we stop creating
  // new get-facts tasks because we assume it will keep failing.
  private taskRetries: string[] = [];
  // fetchingStoppedAt is defined when MaxTaskRetries is hit
  private fetchingStoppedAt: string | null = null;

  // Key for forcing rerendering of interfaces list (multiple)
  private deviceFactsKey = 0;
  private deviceFacts: {
    data: Task | null;
    taskId: string | null;
    updatedAt: string;
  } = { data: null, taskId: null, updatedAt: '' };
  private intervalReference: number | null = null;
  private readonly intervalDuration = 5000;
  private intervalNextUpdate: Date | null = null;
  private currentTime: Date = new Date();
  private lastSuccessUpdated: Date | null = null;
  private getFactsLocked = false;
  private latestCreatedTaskTime = 0;

  private deviceInfo: DeviceInfo | null = null;

  private multipleEditor = false;

  private formData: ModifyDeviceInterfacesForm = {
    device: null,
    change_actions: {
      usage: {
        change: false,
        value: null,
        native_vlan: null,
        tagged_vlans: [],
        access_vlan: null,
      },
      state: {
        change: false,
        value: true,
      },
      managed: {
        change: false,
        value: true,
      },
      description: {
        change: false,
        value: '',
      },
    },
    interfaces: [],
  };

  private possibleActions = possibleUsagesForInterfaces;

  private onScroll() {
    if (typeof window === 'undefined') return;
    const top = window.pageYOffset || 0;

    if (top > 350) {
      this.fabUp = true;
    } else {
      this.fabUp = false;
    }
  }

  private scrollTo() {
    if (this.fabUp) {
      this.$vuetify.goTo(0);
    } else {
      this.$vuetify.goTo('#goToBottom');
    }
  }

  private scrollToSelector(selector: string) {
    this.$vuetify.goTo(selector);
  }

  private get taskFetchingText(): string {
    if (this.isLastTaskStatusOk) {
      if (this.lastSuccessUpdated === null) {
        return 'se izvaja';
      } else {
        const momentDate = this.$moment(this.lastSuccessUpdated);
        return `uspešno ob ${momentDate.format(
          'DD. MM. YYYY',
        )} ${momentDate.format('HH:mm')}`;
      }
    }
    if (this.fetchingStoppedAt !== null) {
      return `ustavljeno ob ${this.fetchingStoppedAt}`;
    }
    return `neuspešno ob ${this.deviceFacts.updatedAt}`;
  }

  /*
   * Used to disable compponent for changing interfaces for support and ROID users on interfaces with infrastructure usage
   */
  private get isDisabled(): boolean {
    return (
      !this.isAdminOrRoid ||
      (!this.isAdmin &&
        this.formData.change_actions.usage.value === 'infrastructure_trunk')
    );
  }

  private get isTSP(): boolean {
    return (
      (this.deviceInfo &&
        this.deviceInfo.roles?.some((role) => role.slug === 'tsp')) ||
      false
    );
  }

  get remainingUntilNextUpdate(): null | number {
    if (this.intervalNextUpdate === null) {
      return null;
    }
    return this.$moment(this.intervalNextUpdate).diff(
      this.currentTime,
      'seconds',
    );
  }

  get lastUpdatedText(): string {
    const momentDate = this.$moment(this.lastSuccessUpdated);
    const dateRepr = this.lastSuccessUpdated
      ? `${momentDate.format('DD. MM. YYYY')} ${momentDate.format('HH:mm')}`
      : '/';
    return `Podatki pridobljeni iz omrežja: ${dateRepr}`;
  }

  get interfaces(): Interface[] {
    if (this.deviceInfo && this.deviceInfo.interfaces) {
      if (
        this.deviceInfo.asset &&
        this.deviceInfo.asset.product &&
        this.deviceInfo.asset.product.info &&
        this.deviceInfo.asset.product.info.port_groups
      ) {
        const orderedInterface: Interface[] = [];
        this.deviceInfo.asset.product.info.port_groups.forEach((pg) => {
          pg.interfaces.forEach((pIface) => {
            if (this.deviceInfo) {
              const matchedIface = this.deviceInfo.interfaces.find(
                (iface) => iface.name === pIface.name,
              );
              if (matchedIface && this.isActiveInterface(matchedIface)) {
                orderedInterface.push(matchedIface);
              }
            }
          });
        });
        return orderedInterface;
      } else {
        return this.deviceInfo.interfaces;
      }
    }
    return [];
  }

  private ruleCheck(item: Interface, rule: string) {
    if (rule === 'infrastructure' || rule === 'user' || rule === 'ztp') {
      return item.usage === rule;
    } else if (rule === 'enabled' || rule === 'disabled') {
      return item.enabled;
    } else if (rule === 'all' || rule === 'clear-all') {
      return true;
    } else {
      return false;
    }
  }

  private getImage(formFactor: number) {
    return require(`@/assets/ports/${this.getPortTypeIcon(formFactor)}`);
  }

  private addToSelection(usage: 'infrastructure' | 'user' | 'ztp', mode = '') {
    this.interfaces.forEach((iface) => {
      if (iface.usage === 'infrastructure' && usage === 'infrastructure') {
        // infrasturcture
        this.selectPortUpdate(true, iface.id);
      } else if (iface.usage === 'user' && usage === 'user') {
        // user
        if (iface.switchports) {
          const tagged = iface.switchports.find((sw) => {
            return sw.tagged;
          });
          if (mode === 'trunk' && tagged) {
            this.selectPortUpdate(true, iface.id);
          } else if (mode !== 'trunk' && !tagged) {
            this.selectPortUpdate(true, iface.id);
          }
        }
      } else if (iface.usage === 'ztp' && usage === 'ztp') {
        //ztp
        this.selectPortUpdate(true, iface.id);
      }
    });
  }

  private get selectedPorts(): number[] {
    if (this.multipleEditor) {
      return this.formData.interfaces;
    } else {
      return this.selectedPort ? [this.selectedPort] : [];
    }
  }

  private updateSelectedPorts(ifaces: number[]) {
    this.formData.interfaces = ifaces
      .map((intfId) => this.interfaces.filter((intf) => intf.id === intfId)[0])
      .filter((intf) => intf.circuit === null)
      .map((intf) => intf.id);
  }

  private updateSelectedPort(iface: number) {
    if (this.multipleEditor) {
      return;
    }

    if (this.selectedPort !== iface) {
      this.selectedPort = iface;
    }
    if (iface) {
      this.scrollToSelector('#iface' + iface.toString());
      this.updateExpanded(true, iface);
    }
  }

  // Some devices don't support STP, so we need to ignore it
  private get ignoreSTPFacts(): boolean {
    return (
      this.deviceInfo != null &&
      ['1941'].includes(this.deviceInfo.asset?.product?.name || '')
    );
  }

  private get interfacesStateFacts() {
    const states: {
      [key: string]: InterfaceStateFact;
    } = {};
    if (this.deviceFacts.data && this.deviceInfo) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let l2Interfaces: any = _get(this.deviceFacts.data, 'data.custom_stats');
      // Check if has property
      if (
        l2Interfaces !== undefined &&
        Object.prototype.hasOwnProperty.call(l2Interfaces, this.deviceInfo.name)
      ) {
        l2Interfaces = l2Interfaces[this.deviceInfo.name].l2_interfaces;
        if (l2Interfaces) {
          l2Interfaces.forEach((ifaceFacts: StatsL2Interface) => {
            const iface: Interface | null =
              this.interfaces.find(
                (i) =>
                  i.name === ifaceFacts.name || i.long_name === ifaceFacts.name,
              ) || null;

            states[ifaceFacts.name] = {
              link: ifaceFacts.up ? 'up' : 'down',
              stp: parsePortSTPState(
                iface,
                ifaceFacts,
                this.ignoreSTPFacts,
                this.deviceSTPFacts.protocol,
              ),
            };
          });
        }
      }
    }
    return states;
  }

  private get deviceSTPFacts(): SpanningTree {
    let spanningTree = null;
    if (this.deviceFacts.data && this.deviceInfo) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let customStats: any = _get(this.deviceFacts.data, 'data.custom_stats');
      // Check if has property
      if (
        customStats !== undefined &&
        Object.prototype.hasOwnProperty.call(customStats, this.deviceInfo.name)
      ) {
        spanningTree = customStats[this.deviceInfo.name].spanning_tree;
      }
    }
    return spanningTree;
  }

  private findInterfaceStateFact(iface: Interface): InterfaceStateFact {
    return _get(this.interfacesStateFacts, `${iface.name}`, {
      link: 'unknown',
      stp: parsePortSTPState(iface, null),
    });
  }

  private selectPortUpdate(value: boolean, selectedPortId: number) {
    if (value) {
      if (!this.formData.interfaces.includes(selectedPortId)) {
        this.formData.interfaces.push(selectedPortId);
      }
    } else {
      this.formData.interfaces = this.formData.interfaces.filter(
        (portId: number) => portId !== selectedPortId,
      );
    }
  }

  private findIfaceFactsByName(
    name: string,
    longName: string | null,
  ): StatsL2Interface | undefined {
    if (this.deviceFacts.data) {
      const cStats = _get(this.deviceFacts.data, 'data.custom_stats');
      if (this.deviceInfo && cStats && cStats[this.deviceInfo.name]) {
        return cStats[this.deviceInfo.name].l2_interfaces.find(
          (element: StatsL2Interface) =>
            element.name === name || element.name === longName,
        );
      }
    }
  }

  // Helpers for tagged vlan combo box
  private get selectedAllTaggedVlans(): boolean {
    if (this.deviceInfo) {
      return (
        this.formData.change_actions.usage.tagged_vlans.length ===
        this.availableUserVlans.length
      );
    }
    return false;
  }

  private get selectedSomeTaggedVlans(): boolean {
    if (this.deviceInfo) {
      return (
        this.formData.change_actions.usage.tagged_vlans.length > 0 &&
        !this.selectedAllTaggedVlans
      );
    }
    return false;
  }

  private get icon() {
    if (this.selectedAllTaggedVlans) {
      return 'mdi-close-box';
    }

    if (this.selectedSomeTaggedVlans) {
      return 'mdi-minus-box';
    }

    return 'mdi-checkbox-blank-outline';
  }

  private toggle() {
    this.$nextTick(() => {
      if (this.selectedAllTaggedVlans) {
        this.formData.change_actions.usage.tagged_vlans = [];
      } else {
        this.formData.change_actions.usage.tagged_vlans = this.deviceInfo
          ? this.availableUserVlans.slice().map((vlan) => {
              return vlan.id;
            })
          : [];
      }
    });
  }

  private get availableUserVlans(): Vlan[] {
    if (this.deviceInfo && this.deviceInfo.available_vlans) {
      return this.deviceInfo.available_vlans
        .filter((vlan: Vlan) => vlan.vid !== 1)
        .map((vlan: Vlan) => {
          if (vlan.network?.name) {
            vlan.repr = vlan.network.name;
            vlan.icon = getIconForVlanType(vlan.network.type);
            vlan.color = getColorForVlanType(vlan.network.type);
          } else {
            vlan.repr = vlan.name;
            vlan.icon = 'mdi-slash-forward';
            vlan.color = '';
          }
          return vlan;
        });
    }
    return [];
  }

  private get isUserAccessAction(): boolean {
    return this.formData.change_actions.usage.value === 'user_access';
  }

  private extractTaggedVlans(iface: Interface): number[] {
    if (!iface.switchports) {
      return [];
    }
    const out: number[] = [];
    iface.switchports.forEach((s) => {
      // doesn't need to be tagged because untagged can also be under tagged_vlans
      if (s.vlan) {
        out.push(s.vlan.id);
      }
    });
    return out;
  }

  private extractNativeOrAccessVlan(iface: Interface): number | null {
    if (!iface.switchports) {
      return null;
    }
    const first = iface.switchports.find((s) => !s.tagged);
    return first && first.vlan ? first.vlan.id : null;
  }

  private isActiveInterface(iface: Interface): boolean {
    if (iface.port !== null) {
      return iface.port_active;
    }
    return true;
  }

  private interfacesOnPort(iface: Interface): Interface[] {
    if (iface.port && this.deviceInfo && this.deviceInfo.interfaces) {
      const portIfaces = this.deviceInfo.interfaces.filter((el: Interface) => {
        return el.port && iface.port && el.port.name === iface.port.name;
      });
      return portIfaces;
    }
    return [iface];
  }

  private resetFormData() {
    this.formData = {
      device: null,
      change_actions: {
        usage: {
          change: false,
          value: null,
          native_vlan: null,
          tagged_vlans: [],
          access_vlan: null,
        },
        state: {
          change: false,
          value: true,
        },
        managed: {
          change: false,
          value: true,
        },
        description: {
          change: false,
          value: '',
        },
      },
      interfaces: [],
    };
    this.updateSelectedPorts([]);
  }

  private get selectedUsage() {
    return this.possibleActions.find(
      (el) => el.value === this.formData.change_actions.usage.value,
    );
  }
  private show = false;

  private showTooltip() {
    this.show = true;
  }

  private hideTooltip() {
    this.show = false;
  }

  private get possibleUsagesForInterfacesComputed(): any[] {
    return this.possibleActions.map((el: any) =>
      Object.assign(el, {
        disabled:
          (!this.isAdmin && el.permission === 'admin') ||
          (!this.isAdmin &&
            this.formData.change_actions.usage.value ===
              'infrastructure_trunk'),
      }),
    );
  }
  private setDeviceData(data: DeviceInfo): void {
    this.deviceInfo = data;

    if (this.deviceInfo.vlans) {
      this.deviceInfo.vlans = this.deviceInfo.vlans.map((vl) => {
        vl.name = `${vl.name} (${vl.vid})`;
        return vl;
      });
    }
  }

  private switchActiveInterfaceOnPort(
    oldIface: Interface,
    newIface: Interface,
  ) {
    let portId = null;
    if (newIface.port !== null && this.deviceInfo !== null) {
      portId = newIface.port.id;
      repositories.infrastructure.port
        .changeActiveInterfaceOnPort(portId, newIface.id)
        .then((res: any) => {
          this.fetchDevice(this.deviceName, false).finally(() => {
            if (this.multipleEditor) {
              this.selectPortUpdate(true, newIface.id);
            } else {
              this.selectedPort = newIface.id;
            }
          });
          this.updateExpanded(true, newIface.id);
        })
        .catch((error: any) => {
          this.$toasted.error(
            new ErrorHandler(
              { error, status: true },
              { message: 'Napaka pri spreminjanju tipa modula' },
            ).toString(),
          );
        });
    }
  }

  private async fetchDevice(deviceName: string, loading = true) {
    this.loading.initial = loading;
    try {
      const { data } = await repositories.infrastructure.device.getDeviceInfo(
        deviceName,
      );
      this.setDeviceData(data);
      this.formData.device = data.id;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'napravi' },
        ).toString(),
      );
    } finally {
      this.loading.initial = false;
    }
  }

  private async fetchFactsData() {
    this.intervalNextUpdate = new Date(
      new Date().getTime() + this.intervalDuration,
    );
    this.loading.facts = true;
    try {
      const { data } =
        await repositories.orchestrator.task.getTaskResultsDeviceGetFacts(
          this.deviceInfo!.name,
        );
      // reset failed tasks because one succeeded
      this.taskRetries = [];
      this.fetchingStoppedAt = null;
      // if there is no taskId in the cache or if taskId is old then we don't do anything
      if (data.task === null || this.deviceFacts.taskId === data.task.id) {
        this.loading.facts = false;
        return;
      }
      this.deviceFacts.taskId = data.task.id;
      this.lastSuccessUpdated = new Date(data.task.last_update!);
      if (
        Date.now() / 1000 - this.latestCreatedTaskTime / 1000 >
        MinFactsTaskDelay
      ) {
        // run another device_get_facts_fast for this device
        const resp =
          await repositories.orchestrator.task.createTaskDeviceGetFacts([
            this.deviceInfo!.name,
          ]);
        this.getFactsLocked = resp.data.locked || false;
        this.latestCreatedTaskTime = Date.now();
      }
      // Check if data is for this device
      // problem with fetchRepeatedly if changing device before promise resolve.
      if (this.deviceInfo && this.deviceInfo.name) {
        if (
          data.task.data.data &&
          data.task.data.data.custom_stats[this.deviceInfo.name]
        ) {
          this.loading.facts = false;
          this.deviceFacts.data = data.task.data;
          this.isLastTaskStatusOk = true;
          return;
        }
      }
      this.loading.facts = false;
      this.isLastTaskStatusOk = true;
    } catch (error: unknown) {
      this.isLastTaskStatusOk = false;
      // handle maintenance case
      if (error && axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.status > 400) {
            this.loading.facts = false;
            return;
          } else if (error.response.status === 400) {
            // task failed (can also happen if device doesn't exist, but that's extremely rare case)
            if (error.response.data === '') {
              // device doesn't exist anymore
              this.loading.facts = false;
              return;
            }
            const data = error.response.data as RetrieveGetFacts;
            const taskId = data.task?.id;
            if (taskId != null) {
              // got a task with status 'finished'
              if (!this.taskRetries.includes(taskId)) {
                this.taskRetries.push(taskId);
              }
              if (
                this.taskRetries.length === MaxTaskRetries &&
                this.fetchingStoppedAt === null
              ) {
                const dateNow = new Date();
                this.fetchingStoppedAt = `${dateNow.getDate()}.${dateNow.getMonth()}.${dateNow.getFullYear()} ${dateNow.getHours()}:${dateNow.getMinutes()}`;
              }
              // retry task if needed
              if (
                !data.task.data.success &&
                this.taskRetries.length < MaxTaskRetries &&
                Date.now() / 1000 - this.latestCreatedTaskTime / 1000 >
                  MinFactsTaskDelay
              ) {
                // run another device_get_facts_fast for this device
                setTimeout(async () => {
                  const resp =
                    await repositories.orchestrator.task.createTaskDeviceGetFacts(
                      [this.deviceInfo!.name],
                    );
                  this.getFactsLocked = resp.data.locked || false;
                  this.latestCreatedTaskTime = Date.now();
                }, 1200);
              }
            }
          }
          const data = error.response.data as RetrieveGetFacts;
          if (data.task) {
            this.deviceFacts.taskId = data.task.id;
            if (data.task?.last_update) {
              // update failed time
              const momentUpdatedAt = this.$moment(data.task.last_update);
              this.deviceFacts.updatedAt = `${momentUpdatedAt.format(
                'DD. MM. YYYY',
              )} ${momentUpdatedAt.format('HH:mm')}`;
            }
          }
        }
      }
      this.loading.facts = false;
    }
  }

  private async createDeviceGetFactsTask() {
    try {
      if (this.deviceInfo) {
        const resp =
          await repositories.orchestrator.task.createTaskDeviceGetFacts([
            this.deviceInfo.name,
          ]);
        this.latestCreatedTaskTime = Date.now();
        this.getFactsLocked = resp.data.locked || false;
        // has to be window.setInterval otherwise it takes nodejs's setInterval which has different types
        this.intervalReference = window.setInterval(
          this.fetchFactsData,
          this.intervalDuration,
        );
        // first fetch should happen immediatelly
        this.fetchFactsData();
      } else {
        this.$toasted.error('Ni podatkov o napravi.');
      }
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'napravi' },
        ).toString(),
      );
    }
    this.deviceFactsKey += 1;
  }

  private submit() {
    this.submitModifyDeviceInterfaces(this.formData).catch(() => {
      //
    });
  }

  private submitModifyDeviceInterfaces(formData: ModifyDeviceInterfacesForm) {
    return new Promise((resolve, reject) => {
      this.loading.submit = true;

      const clone = JSON.parse(JSON.stringify(formData));

      repositories.provision.misc
        .modifyDeviceInterfaces(clone)
        .then((res) => {
          this.setDeviceData(res.data);
          if (!this.deviceInfo!.managed) {
            this.$toasted.info(
              'Naprava ne bo konfigurirana, ker ni upravljana.',
            );
          }
          resolve(res.data);
        })
        .catch((error) => {
          this.$toasted.error(
            new ErrorHandler({ error, status: true }).toString(),
          );
          reject();
        })
        .finally(() => {
          this.loading.submit = false;
          // refresh badge state icon
          this.fetchTasksStateAction();
        });
    });
  }

  private clearFetchInterval() {
    if (this.intervalReference !== null) {
      window.clearInterval(this.intervalReference);
      this.intervalReference = null;
      this.intervalNextUpdate = null;
      this.lastSuccessUpdated = null;
    }
  }

  private destroyed() {
    this.clearFetchInterval();
  }

  private async init(deviceName: string) {
    this.clearFetchInterval();
    await this.fetchDevice(deviceName);
    await this.createDeviceGetFactsTask();
  }

  private created() {
    if (this.deviceName) {
      this.init(this.deviceName);
    }
    // need an interactive change of currentTime so that moment's diff gets refreshed
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  }

  @Watch('getFactsLocked')
  onGetFactsLockedChange() {
    if (this.getFactsLocked) {
      this.$toasted.info(
        'Opravilo za pridobivanje podatkov iz naprave je zaklenjeno.',
      );
    }
  }
}
