import axios, { AxiosPromise } from 'axios';
import { Organization, PaginatedResponse } from '@/api/interfaces';
import { Query } from '../query';

const resources = { default: 'tenants/organizations' };

interface OrganizationApiInterface {
  getOrganizations(queryParams: {
    [key: string]: string | number;
  }): AxiosPromise<PaginatedResponse<Organization>>;
  getOrganization(tenantId: number | string): AxiosPromise<Organization>;
  createOrganization(portalID: number): AxiosPromise<Organization>;
  searchOrganizations(
    search: string,
  ): AxiosPromise<PaginatedResponse<Organization>>;
  getOrganizationInfoFromPortal(
    portalID: number,
  ): AxiosPromise<{ name: string; short_name: string | null; portal_id: number }>;
}

const OrganizationApi: OrganizationApiInterface = {
  getOrganizations(queryParams) {
    const query = new Query(queryParams);
    return axios.get(`${resources.default}/${query}`);
  },

  getOrganization(tenantId) {
    return axios.get(`${resources.default}/${tenantId}/`);
  },

  createOrganization(portalID) {
    return axios.post(`${resources.default}/`, { portal_id: portalID });
  },

  searchOrganizations(search) {
    const query = new Query({ search });
    return axios.get(`${resources.default}/${query}`);
  },

  getOrganizationInfoFromPortal(portalID) {
    return axios.get(`${resources.default}/get-org-from-portal/${portalID}/`);
  },
};

export default OrganizationApi;
export { OrganizationApiInterface };
