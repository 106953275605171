
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ProductInfoManual extends Vue {
  private infoKeys = [
    {
      name: 'size',
      description:
        'Določa velikost naprave oziroma ozadja, na katerem bojo izrisani vmesniki',
      example: JSON.stringify(
        {
          x: 800,
          y: 110,
        },
        null,
        2,
      ),
    },
    {
      name: 'radius',
      description:
        'Določa zaokroženost robov naprave. Ta ključ je opcijski in ga lahko izpustimo.',
      example: `Number\nali\n${JSON.stringify(
        {
          x: 4,
          y: 6,
        },
        null,
        2,
      )}`,
    },
    {
      name: 'background_color',
      description:
        'Določa barvo ozadja naprave. Ta ključ je opcijski in ga lahko izpustimo.',
      example: `"#CBCCC7"`,
    },
    {
      name: 'name',
      description:
        'Ime naprave/modela, ki bo izpisano na vrhu naprave v primeru, da definiramo svoje "labels", ki izpisujejo le te to polje lahko pustimo prazno.',
      example: `"C4500X-32" ali ""`,
    },
    {
      name: 'labels',
      description: `Seznam oznak, ki se bodo izrisale na napravi. To polje je opcijsko in ga lahko izpustimo.
            Oznake imajo lahko razlicne pisave, barve itd.
            `,
      example: JSON.stringify(
        [
          {
            name: 'JUNIPER',
            hidden: false,
            color: 'white',
            fontSize: 10,
            fontWeight: 'normal',
            fontFamily: 'Arial',
            rotation: 0,
            position: {
              x: 10,
              y: 5,
            },
          },
        ],
        null,
        2,
      ),
    },
    {
      name: 'skeletonPorts',
      description: `Seznam vmesnikov, ki se bodo izrisali na napravi. 
          To polje je opcijsko in ga lahko izpustimo.
          Ti vmesniki so namenjeni dekoraciji, kar pomeni, da ne prožijo dogodkov in jih ni mogoče izbrati.
          Podprti vmesniki (formFactor) so: "RJ45", "SFP", "QSFP", "USB", "MINI_USB", "ALT_USB" in "POWER_INPUT"
          `,
      example: JSON.stringify(
        [
          {
            hidden: false,
            color: '#555',
            formFactor: 'MINI_USB',
            rotation: 180,
            position: {
              x: 130,
              y: 47,
            },
          },
        ],
        null,
        2,
      ),
    },
    {
      name: 'shapes',
      description: `Seznam oblik. To polje je opcijsko in ga lahko izpustimo.
          Oblike so namenjene dekoraciji, kar pomeni, da ne prožijo dogodkov in jih ni mogoče izbrati.
          Podprte oblike (shape) so: "rectangle" (properties mora vsebovati "width" in "height"), 
          "square" (properties mora vsebovati "width"), 
          "triangle" (properties mora vsebovati "width"), 
          "circle" (properties mora vsebovati "radius") in 
          "hexagon" (properties mora vsebovati "width")
          
          Rectangle in square v properties podpirata tudi "radius" za zaobljene robove.
          `,
      example: JSON.stringify(
        [
          {
            hidden: false,
            borderWidth: 1,
            borderColor: '#555',
            filled: true,
            fillColor: '#777',
            rotation: 0,
            position: {
              x: 8,
              y: 4,
            },
            properties: {
              shape: 'rectangle',
              width: 445,
              height: 58,
              radius: 4,
            },
          },
        ],
        null,
        2,
      ),
    },
    {
      name: 'port_groups',
      description: `Seznam skupin vmesnikov. 
          To polje je obvezno in urejanje tega polja lahko povzroči veliko težav v primeru, 
          da napačno nastavimo vmesnike (interfaces), saj se uporabljajo za kreiranje naprav itd. zato urejanja razen ključev "starting_point", "rotated", "margin" in "mixed_count" odsvetujemo.
          `,
      example: JSON.stringify(
        [
          {
            mixed_count: false,
            two_lines: false,
            starting_point: {
              x: 150,
              y: 15,
            },
            rotated: false,
            margin: 1,
            interfaces: [
              {
                short_name: 'ge-0/0/0',
                name: 'ge-0/0/0',
                label: '0/0',
                form_factor: 1000,
                hidden: false,
                managed: true,
                port: null,
                port_active: false,
              },
            ],
          },
        ],
        null,
        2,
      ),
    },
  ];
}
