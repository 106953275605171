
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import DevicesSummit from '@/components/device/DevicesSummit.vue';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    DevicesSummit,
  },
})
export default class AdminView extends Vue {
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  triggerException(): void {
    throw Error('Test sentry sending errors');
  }
  get showTestSentry(): boolean {
    return (
      process.env.VUE_APP_SENTRY_ENVIRONMENT !== 'production' && this.isAdmin
    );
  }
}
