import Vue from 'vue';
import { Location } from 'vue-router';
import axios from 'axios';
import url from 'url';
import router from '../../router';
import {
  AuthState,
  AuthType,
  LogoutOptions,
  LogoutFrontendOptions,
} from '../types';
import { IS_LOGGED_IN, USER } from '../getters.type';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  RESET_STATE,
} from '../mutations.type';
import {
  LOGIN,
  LOGOUT,
  LOGOUT_BACKEND,
  LOGOUT_FRONTEND,
  SET_AUTH_DATA_AND_REDIRECT,
  CLEAR_AUTH_STATE,
  FETCH_USER_INFO,
} from '../actions.type';
import { USER_NAMESPACE_PATH } from '../namespaces.type';

function getInitialState(): AuthState {
  return {
    csrfToken: null,
    status: '',
    authType: null,
    loginTime: null,
    logoutTimeoutRef: null,
  };
}

const authState: AuthState = getInitialState();

const getters = {
  [IS_LOGGED_IN](state: AuthState): boolean {
    // NOTE: when csrfToken is null the user either hasn't yet logged in or has
    // been logged out (manually or automatically when jwt expired)
    return state.csrfToken !== null;
  },
};

const mutations = {
  [LOGIN_REQUEST](state: AuthState) {
    state.status = 'logging in';
  },
  [LOGIN_SUCCESS](
    state: AuthState,
    {
      authType,
      csrf,
      loginTime,
      logoutTimeoutRef,
    }: {
      authType: AuthType;
      csrf: string;
      loginTime: Date;
      logoutTimeoutRef: number;
    },
  ) {
    state.status = 'logged in';
    state.csrfToken = csrf;
    state.authType = authType;
    state.loginTime = loginTime;
    state.logoutTimeoutRef = logoutTimeoutRef;
    axios.defaults.headers.common['X-CSRFToken'] = csrf;
    axios.defaults.withCredentials = true;
  },
  [LOGIN_ERROR](state: AuthState) {
    state.status = 'login error';
  },
  [LOGOUT_REQUEST](state: AuthState) {
    state.status = 'logging out';
  },
  [LOGOUT_SUCCESS](state: AuthState) {
    state.status = 'logged out';
  },
  [RESET_STATE](state: AuthState) {
    // reset axios
    delete axios.defaults.headers.common['X-CSRFToken'];
    axios.defaults.withCredentials = false;
    // remove setTimeout for automatic logout
    if (state.logoutTimeoutRef !== null) {
      window.clearTimeout(state.logoutTimeoutRef);
    }
    // reset state
    const initialState = getInitialState();
    for (const f of Object.keys(state)) {
      // Vue.set(state, f, initialState[f]);
      // casted to any to get rid of ts implicit any error
      (state as any)[f] = (initialState as any)[f];
    }
  },
};

const actions: any = {
  [LOGIN](
    { commit, dispatch }: any,
    data: {
      authType: AuthType;
      loginData?: Record<string, unknown>;
      redirect?: string;
    },
  ): Promise<any> {
    const { authType, loginData, redirect } = data;
    commit(LOGIN_REQUEST);
    if (authType === 'shibboleth') {
      // already logged in on the backend
      return dispatch(SET_AUTH_DATA_AND_REDIRECT, { authType, redirect });
    }
    const options = {
      withCredentials: true,
    };
    const config = this._vm.$config;
    const loginUrl = url.resolve(config.backendUrl, config.localLoginUrl);
    return axios
      .post(loginUrl, loginData, options)
      .then((resp) => {
        dispatch(SET_AUTH_DATA_AND_REDIRECT, { authType, redirect });
      })
      .catch((error) => {
        commit(LOGIN_ERROR);
        throw error;
      });
  },

  [LOGOUT](
    { commit, dispatch, state }: any,
    options?: LogoutOptions,
  ): Promise<any> {
    const defaultOptions: LogoutOptions = {
      backend: true,
      redirectTo: { name: 'home' },
    };
    const opts =
      options === undefined
        ? defaultOptions
        : { ...defaultOptions, ...options };
    commit(LOGOUT_REQUEST);
    if (!opts.backend) {
      return dispatch(LOGOUT_FRONTEND, { redirectTo: opts.redirectTo });
    }
    return new Promise((resolve, reject) => {
      dispatch(LOGOUT_BACKEND)
        .then((res: any) => {
          dispatch(LOGOUT_FRONTEND, {
            showToast: true,
            redirectTo: opts.redirectTo,
          }).then(() => {
            if (res && res.data && res.data.location !== undefined) {
              window.location.href = res.data.location;
            }
            resolve(null);
          });
        })
        .catch((error: any) => {
          // logoutBackend might throw but that means that the user is probably
          // already logged out on the backend
          dispatch(LOGOUT_FRONTEND, { redirectTo: opts.redirectTo });
          // throw error;
          reject(error);
        });
    });
  },
  [LOGOUT_BACKEND]({ commit, state }: any): Promise<any> {
    let logoutUrl = '';
    const config = this._vm.$config;
    switch (state.authType) {
      case 'local':
        logoutUrl = url.resolve(config.backendUrl, config.localLogoutUrl);
        break;
      case 'shibboleth':
        logoutUrl = `${config.shibLogoutUrl}?next=${config.frontendUrl}?aai_logout=true`;
        break;
    }
    const options = {
      withCredentials: true,
    };
    return axios.post(logoutUrl, {}, options);
  },
  [LOGOUT_FRONTEND]({ commit, state }: any, options?: LogoutFrontendOptions) {
    const defaultOptions: LogoutFrontendOptions = {
      showToast: false,
      redirectTo: { name: 'home' },
    };
    const opts =
      options === undefined
        ? defaultOptions
        : { ...defaultOptions, ...options };
    const authType: AuthType = state.authType;
    commit(RESET_STATE);
    commit(`${USER_NAMESPACE_PATH}/${RESET_STATE}`, null, { root: true });
    const showToast = opts.showToast;
    // doesn't matter if logout failed or not, csrf was deleted so that's
    // a successful logout
    if (showToast && authType === 'shibboleth') {
      // set state to 'logging out' for the logout dialog
      commit(LOGOUT_REQUEST);
    } else {
      commit(LOGOUT_SUCCESS);
      if (showToast) {
        this._vm.$toasted.show('Odjava je bila uspešna', {
          icon: 'mdi-logout',
          type: 'success',
        });
      }
      const redirectTo: Location =
        typeof opts.redirectTo === 'object'
          ? opts.redirectTo
          : { name: opts.redirectTo };
      if (
        router.currentRoute.name !== redirectTo.name &&
        JSON.stringify(router.currentRoute.query) !==
          JSON.stringify(redirectTo.query)
      ) {
        router.push(opts.redirectTo);
      }
    }
  },
  [SET_AUTH_DATA_AND_REDIRECT](
    { commit, dispatch, rootGetters }: any,
    { authType, redirect }: { authType: AuthType; redirect: string },
  ) {
    const loginTime = new Date();
    const csrf = Vue.$cookies.get('csrf');
    if (!csrf) {
      return Promise.reject('Missing csrf');
    }
    Vue.$cookies.remove('csrf');
    // set timeout for automatic logout
    const logoutTimeoutRef = window.setTimeout(() => {
      dispatch(LOGOUT, { backend: false });
      this._vm.$modals.clear();
      this._vm.$toasted.show('Čas seje je potekel', {
        icon: 'mdi-logout',
        type: 'error',
      });
    }, Number(process.env.VUE_APP_COOKIE_TIMEOUT));
    commit(LOGIN_SUCCESS, { authType, csrf, loginTime, logoutTimeoutRef });
    dispatch(`${USER_NAMESPACE_PATH}/${FETCH_USER_INFO}`, null, {
      root: true,
    }).then(() => {
      if (redirect) {
        // matcher is not part of public api, so we need to use ts-ignore here
        // @ts-ignore
        const route = router.matcher.match(redirect);
        if (route.meta.requiresAuth && route.meta.requiresGroup) {
          if (rootGetters[`user/${USER}`]) {
            router.push(redirect);
          } else {
            // delay in ms between attempts to check whether you got the user info
            const delay = 100;
            // maximum time in ms before we give up on the given redirect path and redirect to home
            const timeout = 3000;
            const maxAttempts = Math.floor(timeout / delay);
            // NOTE: we could show fetching user info message if needed
            const fn = function (
              this: any,
              attempt: number,
              maxAttempts: number,
            ) {
              // check if user is set, if it is then push, otherwise recursively call
              if (rootGetters[`user/${USER}`]) {
                router.push(redirect);
                return;
              }
              if (attempt === maxAttempts) {
                // show modal info explaining that it took too long to fetch user's data
                this._vm.$toasted.show(
                  'Pridobitev podatkov o uporabniku poteka predolgo, zato ste bili preusmerjeni na domačo stran.',
                  {
                    icon: 'mdi-information',
                    type: 'error',
                  },
                );
                router.push({ name: 'home' }).catch((err) => {
                  return null;
                });
                return;
              }
              setTimeout(fn.bind(this, attempt + 1, maxAttempts), delay);
            };
            setTimeout(fn.bind(this, 1, maxAttempts), delay);
          }
        } else {
          // no need to wait for group info
          router.push(redirect).catch(() => {
            return null;
          });
        }
      } else {
        router.push({ name: 'home' }).catch((err) => {
          return null;
        });
      }
    });
  },
  [CLEAR_AUTH_STATE]({ commit }: any) {
    commit(RESET_STATE);
  },
};

export default {
  namespaced: true,
  state: authState,
  getters,
  mutations,
  actions,
};
