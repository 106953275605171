
import { Component, Vue } from 'vue-property-decorator';
import { DevicesStatusesByFunctionSummit } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import translateWord from '@/components/shared/translations';

@Component
export default class CampusDevicesSummit extends Vue {
  private rowsPerPageItems = [4, 8, 12];
  private pagination = {
    rowsPerPage: 6,
  };
  private loading = false;
  private translateWord = translateWord;

  private items: DevicesStatusesByFunctionSummit[] = [];

  private async fetchDeviceStatuses() {
    this.loading = true;
    try {
      const { data } =
        await repositories.infrastructure.device.getDevicesStatusesByFunctionSummit();
      this.items = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'napravah' },
        ).toString(),
      );
    }
    this.loading = false;
  }

  private created() {
    this.fetchDeviceStatuses();
  }
}
