import { render, staticRenderFns } from "./Switchports.vue?vue&type=template&id=130e95aa&scoped=true&"
import script from "./Switchports.vue?vue&type=script&lang=ts&"
export * from "./Switchports.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "130e95aa",
  null
  
)

export default component.exports