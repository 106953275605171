import url from 'url';

const backendUrl = String(process.env.VUE_APP_AUTOMATOR_BACKEND_URL);
const shibAuthPath = String(process.env.VUE_APP_AUTOMATOR_SHIBBOLETH_PATH);
const frontendUrl = String(process.env.VUE_APP_FRONTEND_URL);

export default {
  backendUrl,
  frontendUrl,
  localLoginUrl: url.resolve(backendUrl, url.resolve(shibAuthPath, 'login/')),
  localLogoutUrl: url.resolve(backendUrl, url.resolve(shibAuthPath, 'logout/')),
  shibLoginUrl: url.resolve(
    backendUrl,
    url.resolve(shibAuthPath, 'SSO/login/'),
  ),
  shibLogoutUrl: url.resolve(
    backendUrl,
    url.resolve(shibAuthPath, 'SSO/logout/'),
  ),
  generateScriptingJWTUrl: url.resolve(
    backendUrl,
    url.resolve(shibAuthPath, 'obtain-scripting-jwt/'),
  ),
  automatorApiUrl: url.resolve(backendUrl, '/api/v1/'),
};
