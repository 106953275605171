
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  CeleryTask,
  CeleryTaskResult,
  CeleryTaskResultTask,
} from '../../api/orchestrator/tasks';
import ErrorHandler from '@/components/shared/errorHandler';
import {
  taskNameShort,
  prettyPrintFullDate,
  prettyPrintJson,
} from '@/utils/filters';
import { sloveneTaskNames } from '@/utils/constants';
import { repositories } from '@/api/ApiFactory';

import { Representations } from '@/components/shared/representation';

@Component({
  filters: {
    taskNameShort,
    prettyPrintFullDate,
    prettyPrintJson,
  },
})
export default class TaskDetail extends Vue {
  @Prop()
  private taskId!: string;
  private celeryTask: CeleryTask | null = null;
  private celeryTaskResult: CeleryTaskResult | null = null;
  private showRawInput = false;
  private showParameters = false;

  get campusSlugs(): string[] {
    if (this.celeryTask === null) {
      return [];
    }
    return this.celeryTask.resources
      .filter((el) => el.type === 'campus')
      .map((el) => el.label);
  }

  private getDurationRepr = new Representations().celeryTaskDurationRepr;

  get taskTypeRepr(): string {
    if (this.celeryTask === null) {
      return '';
    }
    return sloveneTaskNames[this.celeryTask.name.split('.').slice(-1)[0]];
  }

  private created(): void {
    this.fetchTaskDetails();
  }

  private async fetchTaskDetails(): Promise<void> {
    try {
      const { data } = await repositories.orchestrator.task.getTask(
        this.taskId,
      );
      this.celeryTask = data._source.celery_task;
      if (this.celeryTask.result) {
        this.celeryTaskResult = JSON.parse(this.celeryTask.result);
      }
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }

  private openErrorDetailsModal(
    celeryTaskResultTask: CeleryTaskResultTask,
    hostData: any,
    host: string,
  ): void {
    this.$modals.open('task-error-details-modal', {
      component: {
        props: {
          hostData,
          host,
          celeryTaskResultTask,
        },
      },
      dialog: {
        props: {
          scrollable: true,
        },
      },
    });
  }

  private openWikiAutomatorTroubleshooting() {
    const win = window.open(
      'https://wiki.arnes.si/display/anso/automator+troubleshooting',
      '_blank',
    );
    win?.focus();
  }
}
