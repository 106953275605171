
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Vlan } from '@/api/interfaces';
import { getColorForVlanType } from '@/components/shared/helpers';

@Component
export default class VlanSelectSelection extends Vue {
  @Prop() private item!: Vlan;
  private getColorForVlanType = getColorForVlanType;
}
