
import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuItem from './MenuItem.vue';

@Component({
  components: {
    MenuItem,
  },
})
export default class Toolbar extends Vue {
  @Prop({ required: true }) private editor!: any;
  @Prop({ default: [] }) private leftItems!: any;
  @Prop({ default: [] }) private rightItems!: any;
}
