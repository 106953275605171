
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import translateWord from '@/components/shared/translations';
import { CampusDevicesStatusesByFunctionSummit } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class CampusDevicesSummit extends Vue {
  @Prop({ type: String })
  private campusSlug!: string;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  private translateWord = translateWord;
  private pagination = {
    rowsPerPage: 6,
  };
  private loading = false;

  private items: CampusDevicesStatusesByFunctionSummit | null = null;

  private async fetchCampusDeviceStatuses(campusSlug: string) {
    this.loading = true;
    try {
      const { data } =
        await repositories.tenants.campus.getCampusDevicesStatusByFunctionSummit(
          campusSlug,
        );
      this.items = data;
    } catch (error: unknown) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'napravah in statusih' },
        ).toString(),
      );
    }
    this.loading = false;
  }

  private created() {
    this.fetchCampusDeviceStatuses(this.campusSlug);
  }
}
