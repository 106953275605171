
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RichTextDoc } from '@/api/interfaces';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN_OR_ROID, IS_ADMIN } from '@/store/getters.type';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import ListDocuments from '@/components/shared/ListDocuments.vue';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    ListDocuments,
  },
})
export default class SiteDocs extends Vue {
  @Prop({ default: null }) private siteSlug!: string | null;
  @Prop({ default: null }) private docSlug!: string | null;
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;

  private loading = {
    documents: false,
  };
  private documents: RichTextDoc[] = [];

  private created() {
    this.fetchDocuments();
  }

  private canDeleteDoc(doc: RichTextDoc): boolean {
    return this.isAdmin;
  }

  private canCreateDoc() {
    return this.isAdmin;
  }

  private async fetchDocuments() {
    this.loading.documents = true;
    try {
      const response =
        await repositories.documentation.richtextdoc.getRichTextDocs(
          'site',
          this.siteSlug as string,
        );
      this.documents = response.data.results;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.documents = false;
  }
}
