
import { Component, Vue } from 'vue-property-decorator';
import { Organization, Location, LocationType } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { Representations } from '@/components/shared/representation';
import Autocomplete from '@/components/shared/Autocomplete.vue';
import Breadcrumbs from '@/views/Breadcrumbs.vue';
import ErrorHandler from '@/components/shared/errorHandler';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    Autocomplete,
    breadcrumbs: Breadcrumbs,
  },
})
export default class CampusAdd extends Vue {
  // need to do this so that TS gets correct types
  public $refs!: {
    organization: InstanceType<typeof ValidationProvider>;
    location: InstanceType<typeof ValidationProvider>;
  };
  private organization: Organization | null = null;
  private location: Location | null = null;
  private searchOrganizationsApi =
    repositories.tenants.organization.searchOrganizations;
  private locationType: LocationType | null = null;

  private name = '';
  private slug = '';
  private assignWLC = false;
  private creating = false;

  private repr = new Representations();

  private searchLocationsApi = (search: string) =>
    repositories.tenants.location.searchLocations(search, 'building');

  private async created() {
    try {
      const resp =
        await repositories.tenants.locationType.getLocationTypeByName(
          'building',
        );
      this.locationType = resp.data;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'tipu lokacije' },
        ).toString(),
      );
    }
  }

  private createCampus() {
    this.creating = true;
    const locationId = this.location ? this.location.id : null;
    repositories.tenants.campus
      .createCampus(
        this.name,
        this.slug,
        locationId,
        this.organization!.id,
        this.assignWLC,
      )
      .then((res) => {
        this.$router.push({
          name: 'organization',
          params: { campusSlug: res.data.slug },
        });
      })
      .catch((error) => {
        this.$toasted.error(new ErrorHandler({ error }).toString());
      })
      .finally(() => {
        this.creating = false;
      });
  }

  private async openCreateOrganizationModal() {
    this.organization = await this.$modals.open('app-add-organization-modal', {
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
    // delay so that input is inserted before the validation takes place
    setTimeout(() => {
      this.$refs.organization.validate();
    }, 0);
  }

  private async openCreateLocationModal() {
    this.location = await this.$modals.open('app-add-or-edit-building-modal', {
      component: {
        props: {
          campusSlug: null,
          location: null,
          locations: [],
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
    // delay so that input is inserted before the validation takes place
    setTimeout(() => {
      this.$refs.location.validate();
    }, 0);
  }
}
