
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { Campus } from '@/api/interfaces';

const userModule = namespace(USER_NAMESPACE_PATH);

interface CampusEditForm {
  name: string;
  slug: string;
}

@Component
export default class CampusEditModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private campus!: Campus;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private loading = {
    submit: false,
  };
  private form: CampusEditForm = {
    name: '',
    slug: '',
  };

  private created() {
    // populate form with current data
    this.form.name = this.campus.name;
    this.form.slug = this.campus.slug;
  }

  private async submit() {
    this.loading.submit = true;
    try {
      const { data } = await repositories.tenants.campus.editCampus(
        this.campus.slug,
        this.form,
      );
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
      this.ok(data);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.submit = false;
  }
}
