
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { repositories } from '@/api/ApiFactory';
import { ZoneGroup } from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import { DataTableHeader } from 'vuetify';
import debounce from 'lodash.debounce';

@Component
export default class ZoneGroupList extends Vue {
  private search = '';
  private zoneGroups: ZoneGroup[] = [];
  private expanded: ZoneGroup[] = [];

  private debouncedSearch = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );

  private loading = {
    initial: true,
  };

  private headers: DataTableHeader[] = [
    {
      text: 'Ime',
      sortable: true,
      value: 'name',
      width: '30%',
    },
    {
      text: 'Oznaka',
      sortable: true,
      value: 'slug',
      width: '30%',
    },
    {
      text: 'Opis',
      sortable: true,
      value: 'slug',
      width: '40%',
    },
  ];

  private created() {
    this.fetchZoneGroups();
  }

  private async fetchZoneGroups() {
    this.loading.initial = true;
    try {
      const { data } = await repositories.zoning.zoneGroup.getZoneGroups();
      this.zoneGroups = data.results;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.initial = false;
  }

  private expandRow(el: ZoneGroup) {
    let currentlyExpandedIdx = null;
    this.expanded.forEach((value, index) => {
      if (el.id === value.id) {
        currentlyExpandedIdx = index;
      }
    });
    if (currentlyExpandedIdx !== null) {
      this.expanded.splice(currentlyExpandedIdx, 1);
    } else {
      this.expanded.push(el);
    }
  }

  /*
   * We use custom-filter on v-data-table to allow search by campus slug
   */
  private customZoneGroupTableFilter(
    value: any,
    search: string | null,
    item: any,
  ) {
    if (search == null) {
      return false;
    }
    const lowerCaseSearch = search.toLocaleLowerCase();
    return (
      value != null &&
      search != null &&
      typeof value !== 'boolean' &&
      (value.toString().toLocaleLowerCase().indexOf(lowerCaseSearch) !== -1 ||
        item.campuses
          .map((campus: { slug: string; name: string }) =>
            campus.slug.toLocaleLowerCase().includes(lowerCaseSearch),
          )
          .some((x: boolean) => x === true) ||
        item.campuses
          .map((campus: { slug: string; name: string }) =>
            campus.name.toLocaleLowerCase().includes(lowerCaseSearch),
          )
          .some((x: boolean) => x === true))
    );
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    this.debouncedSearch = this.search;
  }
}
