
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SpanningTree } from '@/api/interfaces';

@Component
export default class DeviceSTPFacts extends Vue {
  @Prop(Object) private deviceSTPFacts!: SpanningTree;
  @Prop(Boolean) private ignoreSTPFacts!: boolean;

  private headers = [
    {
      text: 'Protokol',
      align: 'start',
      sortable: false,
      value: 'protocol',
    },
    {
      text: 'Prioriteta',
      sortable: false,
      value: 'priority',
    },
    {
      text: 'Opis',
      sortable: false,
      value: 'description',
    },
  ];

  get stp() {
    let desc = '';
    let protocol = '';
    let color = '#F1EFF0';
    if (this.deviceSTPFacts.protocol == null) {
      desc =
        'Napaka pri pridobivanju podatkov o protokolu za preprečevanje zank.';
      color = '#fa0';
      protocol = 'N/A';
    } else if (this.deviceSTPFacts.protocol === 'MSTP') {
      color = '#10FC16';
      desc =
        'Na napravi je vklopljen ustrezen protokol za preprečevanje zank, Multiple Spanning Tree Protocol (MSTP).';
    } else if (
      this.deviceSTPFacts.protocol !== null &&
      ['RAPID-PVST', 'PVST', 'RSTP', 'ANY-OTHER'].includes(
        this.deviceSTPFacts.protocol + '',
      )
    ) {
      desc =
        'Pozor na napravi je vklopljen neustrezen protokol za preprečevanje zank. Željen protokol je MSTP';
      color = '#f00';
    } else if (this.deviceSTPFacts.protocol === false) {
      if (this.ignoreSTPFacts) {
        desc = 'Naprava ne podpira protokola za preprečevanje zank.';
      } else {
        desc = 'Na napravi ni vklopljen protokol za preprečevanje zank.';
        color = '#f00';
      }
      protocol = 'N/A';
    }

    return {
      color: color,
      protocol: this.deviceSTPFacts.protocol || protocol,
      priority:
        this.deviceSTPFacts.priority != null
          ? String(this.deviceSTPFacts.priority)
          : '',
      description: desc,
    };
  }
}
