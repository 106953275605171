
import { Component, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import { EditorTableConstructorData } from '@/api/interfaces';
import PersistentModal from '@/components/shared/modals/PersistentModal';

@Component
export default class PickImageModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  private formData: EditorTableConstructorData = {
    rows: 3,
    cols: 3,
    withHeaderRow: true,
  };
}
