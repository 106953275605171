import axios, { AxiosPromise } from 'axios';
import { Device } from '@/api/interfaces';

const resources = {
  default: 'provision/device',
};

interface ProvisionDeviceApiInterface {
  replaceDevice(
    deviceId: number,
    data: {
      assetStatus: string;
      inventorySysId?: number;
      mac?: string;
      serialNumber?: string;
    },
  ): AxiosPromise<Device>;
  moveDevice(
    deviceId: number,
    name: string,
    campusSlug: string,
    locationId: number,
  ): AxiosPromise<Device>;
}

const ProvisionDeviceApi: ProvisionDeviceApiInterface = {
  replaceDevice(deviceId, data) {
    return axios.patch(`${resources.default}/${deviceId}/replace/`, {
      inventory_sys_id: data.inventorySysId,
      asset_status: data.assetStatus,
      base_mac: data.mac,
      serial_number: data.serialNumber,
    });
  },
  moveDevice(deviceId, name, campusSlug, locationId) {
    return axios.post(`${resources.default}/${deviceId}/change-room/`, {
      name,
      campus_slug: campusSlug,
      location: locationId,
    });
  },
};

export default ProvisionDeviceApi;
export { ProvisionDeviceApiInterface };
