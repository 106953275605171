
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';

@Component
export default class PickImageModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop({ required: true }) private originalName!: string;

  private formData = { name: '' };

  private created() {
    this.formData.name = this.originalName;
  }
}
