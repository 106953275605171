import {
  Campus,
  CampusDevicesStatusesByFunctionSummit,
  CampusFull,
  CampusManagement,
  CampusSimple,
  DataOptions,
  PaginatedResponse,
} from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';

const resources = {
  default: 'tenants/campuses',
  campusesData: 'tenants/campuses-data',
  cds: 'tenants/campus-devices-summit',
};

interface CampusApiInterface {
  searchCampuses(
    search: string,
    searchFields?: string[] | null,
  ): AxiosPromise<PaginatedResponse<Campus>>;
  getCampuses(
    pagination?: DataOptions | null,
    search?: string | null,
    searchFields?: string[] | null,
    query?: Query | null,
  ): AxiosPromise<PaginatedResponse<Campus>>;
  getCampus(slug: string): AxiosPromise<CampusSimple>;
  getCampusFull(slug: string): AxiosPromise<CampusFull>;
  getCampusExtended(slug: string): AxiosPromise<Campus>;
  getCampusDevicesStatusByFunctionSummit(
    campusSlug: string,
  ): AxiosPromise<CampusDevicesStatusesByFunctionSummit>;
  createCampus(
    name: string | null,
    slug: string | null,
    locationId: number | null,
    organizationId: number,
    assignWLC: boolean,
  ): AxiosPromise<Campus>;
  editCampus(
    slug: string, // current slug
    data: {
      name: string; // new name
      slug: string; // new slug
    },
  ): AxiosPromise<Campus>;
  addOrganization(
    slug: string,
    organizationId: number | string,
  ): AxiosPromise<any>;
  removeOrganization(
    slug: string,
    organizationId: number | string,
  ): AxiosPromise<any>;
  assignWLC(slug: string): AxiosPromise<any>;
  assignZoneGroups(
    campusSlug: string,
    zoneGroupSlugs: string[],
    autoRenumberVlans: boolean,
  ): AxiosPromise<any>;
  assignCampusManagementNetwork(
    campusSlug: string,
    managementNetworkId: number | null,
  ): AxiosPromise<{ detail: string; campus_management: CampusManagement }>;
}

const CampusApi: CampusApiInterface = {
  searchCampuses(search, searchFields = null) {
    if (searchFields == null) {
      searchFields = ['name', 'slug'];
    }
    const query = new Query({ search, search_fields: searchFields.join(',') });
    return axios.get(`${resources.default}/${query}`);
  },

  getCampuses(
    pagination = null,
    search = null,
    searchFields = null,
    query = null,
  ) {
    if (!query) {
      query = new Query({});
    }
    if (searchFields == null) {
      searchFields = ['name', 'slug'];
    }
    if (pagination !== null) {
      query.addParam('size', pagination.itemsPerPage);
      query.addParam('page', pagination.page);
      let sortBy = pagination.sortBy[0];
      if (sortBy) {
        if (pagination.sortDesc[0]) {
          sortBy = `-${sortBy}`;
        }
        query.addParam('sort_by', sortBy);
      }
    }
    if (search !== null) {
      query.addParam('search', search);
    }
    query.addParam('search_fields', searchFields.join(','));
    return axios.get(`${resources.default}/${query}`);
  },

  getCampus(slug) {
    return axios.get(`${resources.default}/${slug}/`);
  },

  /*
   * Note that this getCampusFull is not the same as a single object of getCampusesFull,
   * this one is way more extended one, usually used by ansible
   */
  getCampusFull(slug) {
    return axios.get(`${resources.default}/${slug}/full/`);
  },

  getCampusExtended(slug) {
    return axios.get(`${resources.campusesData}/${slug}/`);
  },

  getCampusDevicesStatusByFunctionSummit(campusSlug) {
    return axios.get(`${resources.cds}/${campusSlug}/`);
  },

  createCampus(name, slug, locationId, organizationId, assignWLC) {
    // take care of empty strings
    const transformedName = name ? name : null;
    const transformedSlug = slug ? slug : null;
    return axios.post(`${resources.default}/`, {
      name: transformedName,
      slug: transformedSlug,
      organization: organizationId,
      location: locationId,
      assign_wlc: assignWLC,
    });
  },

  editCampus(slug, data) {
    return axios.patch(`${resources.default}/${slug}/`, data);
  },

  addOrganization(slug, organizationId) {
    return axios.post(`${resources.default}/${slug}/add-organization/`, {
      organization_id: organizationId,
    });
  },

  removeOrganization(slug, organizationId) {
    return axios.delete(
      `${resources.default}/${slug}/remove-organization/${organizationId}/`,
    );
  },

  assignWLC(slug) {
    return axios.post(`${resources.default}/${slug}/assign-wlc/`, {});
  },

  assignZoneGroups(campusSlug, zoneGroupSlugs, autoRenumberVlans) {
    return axios.post(`${resources.default}/${campusSlug}/assign-zonegroups/`, {
      zonegroups: zoneGroupSlugs,
      auto_renumber_vlans: autoRenumberVlans,
    });
  },

  assignCampusManagementNetwork(campusSlug, managementNetworkId) {
    return axios.post(
      `${resources.default}/${campusSlug}/assign-campus-management-network/`,
      {
        management_network_id: managementNetworkId,
      },
    );
  },
};

export default CampusApi;
export { CampusApiInterface };
