
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, IS_ROID } from '@/store/getters.type';
import { DNSResolver, Campus } from '@/api/interfaces';
import { Representations } from '@/components/shared/representation';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import debounce from 'lodash.debounce';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class DNSResolverDetail extends Vue {
  @userModule.Getter(IS_ADMIN) public isAdmin!: string;
  @userModule.Getter(IS_ROID) public isRoid!: boolean;
  @Prop({ default: null }) private fixedNetworkCampusSlug!: string | null;
  @Prop({ default: false }) private canChangeCampus!: boolean;
  @Prop() private dnsResolverId!: number;
  private headers: any[] = [
    {
      text: 'Ime',
      align: 'left',
      sortable: true,
      value: 'name',
      width: '60%',
    },
    {
      text: 'Oznaka',
      align: 'left',
      sortable: true,
      value: 'slug',
      width: '20%',
    },
    {
      text: 'Kampus',
      align: 'left',
      sortable: true,
      value: 'campus.slug',
      width: '20%',
    },
  ];
  private search = '';
  private debouncedSearch = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );

  private repr = new Representations();
  private dnsResolver: DNSResolver | null = null;

  private loading = {
    initial: true,
  };

  public async created() {
    this.loading.initial = true;
    try {
      const { data } = await repositories.lan.dnsResolver.getDNSResolver(
        this.dnsResolverId,
        this.$route.params.campusSlug || null,
      );
      this.dnsResolver = data;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'DNS strežniku' },
        ).toString(),
      );
    }
    this.loading.initial = false;
  }

  public openDNSResolverEditModal(dnsResolver: DNSResolver): void {
    const campusSlug = dnsResolver!.campus
      ? (dnsResolver!.campus as Campus).slug
      : null;
    const promise: Promise<DNSResolver> = this.$modals.open(
      'app-dns-resolver-modal',
      {
        component: {
          props: {
            campusSlug,
            dnsResolver,
            canChangeCampus: this.canChangeCampus,
            fixedNetworkCampusSlug: this.fixedNetworkCampusSlug,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<DNSResolver>;
    promise.then((modifiedDNSResolver: DNSResolver) => {
      this.dnsResolver = modifiedDNSResolver;
    });
  }

  public showDeleteDNSResolverModal(dnsResolver: DNSResolver): void {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `DNS strežnik ${this.repr.dnsResolverRepr(dnsResolver).text}`,
            item: dnsResolver,
          },
          deleteFn: this.deleteDNSResolver,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  public deleteDNSResolver(modal: any, dnsResolver: DNSResolver): void {
    if (dnsResolver.id) {
      repositories.lan.dnsResolver
        .deleteDNSResolver(dnsResolver.id)
        .then(() => {
          this.$modals.close();
          if (dnsResolver.campus !== null) {
            this.$router.push({
              name: 'campusDnsResolvers',
              params: { campusSlug: (dnsResolver.campus as Campus).slug },
            });
          } else {
            this.$router.push({ name: 'dnsResolvers' });
          }
        })
        .catch((error) => {
          const dnsRepr = this.repr.dnsResolverRepr(dnsResolver).text;
          this.$toasted.error(
            new ErrorHandler(
              { error, status: true },
              {
                message: `Pri izbrisu DNS strežnika ${dnsRepr} je prišlo do napake.`,
              },
            ).toString(),
          );
        });
    }
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    this.debouncedSearch = this.search;
  }
}
