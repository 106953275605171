import axios, { AxiosPromise } from 'axios';
import { DHCPServer, PaginatedResponse } from '@/api/interfaces';
import { Query } from '../query';

const resources = { default: 'lan/dhcp-servers' };

interface DHCPServerApiInterface {
  getDHCPServer(
    id: number | string,
    campusSlug?: string | null,
  ): AxiosPromise<DHCPServer>;
  getDHCPServers(
    campusSlug?: string | null,
    includeGlobal?: boolean,
  ): AxiosPromise<PaginatedResponse<DHCPServer>>;
  createDHCPServer(dhcpServer: {
    campusSlug: string | null;
    name: string;
    address: string;
    networks: number[];
  }): AxiosPromise<DHCPServer>;
  updateDHCPServer(
    id: number | string,
    dhcpServer: {
      campusSlug: string | null;
      name: string;
      address: string;
      networks: { add: number[]; remove: number[] };
    },
    campusSlug?: string | null,
  ): AxiosPromise<DHCPServer>;
  deleteDHCPServer(id: number): AxiosPromise<any>;
}

const DHCPServerApi: DHCPServerApiInterface = {
  getDHCPServer(id, campusSlug = null) {
    // campusSlug param is to know whether you're on the campus page calling update or on the global one
    // so that the serializer can know which networks it can send!
    const query = new Query({ campus_slug: campusSlug });
    return axios.get(`${resources.default}/${id}/${query}`);
  },

  getDHCPServers(campusSlug, includeGlobal = false) {
    const query = new Query({
      campus__slug: campusSlug,
      include_global: includeGlobal,
    });
    return axios.get(`${resources.default}/${query}`);
  },

  createDHCPServer(dhcpServer) {
    return axios.post(`${resources.default}/`, {
      campus_slug: dhcpServer.campusSlug,
      name: dhcpServer.name,
      address: dhcpServer.address,
      networks: dhcpServer.networks,
    });
  },

  updateDHCPServer(
    id,
    { campusSlug: dhcpCampusSlug, name, address, networks },
    campusSlug = null,
  ) {
    // campusSlug param is to know whether you're on the campus page calling update or on the global one
    // so that the serializer can know which networks it can send!
    const query = new Query({ campus_slug: campusSlug });
    return axios.patch(`${resources.default}/${id}/${query}`, {
      campus_slug: dhcpCampusSlug,
      name,
      address,
      networks,
    });
  },

  deleteDHCPServer(id) {
    return axios.delete(`${resources.default}/${id}/`);
  },
};

export default DHCPServerApi;

export { DHCPServerApiInterface };
