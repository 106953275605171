
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { repositories } from '@/api/ApiFactory';
import { OrchestratorCampusDetail } from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class CampusRunTaskAction extends Vue {
  @Prop() private campusSlug!: string;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private loading = { campus: true, devices: true };
  private headers = [
    {
      text: 'Naprava',
      align: 'left',
      value: 'name',
      sortable: false,
    },
    {
      text: 'Akcije',
      align: 'center',
      value: 'actions',
      sortable: false,
    },
  ];
  private campus: OrchestratorCampusDetail | null = null;
  private devices: Array<{ instance: any; actions: any }> | null = null;

  private created() {
    this.fetchCampus();
    this.fetchCampusDevicesForTasks();
  }

  private async fetchCampus(): Promise<void> {
    this.loading.campus = true;
    try {
      const response = await repositories.orchestrator.campus.getCampusDetail(
        this.campusSlug,
      );
      this.campus = response.data.campus;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
    }
    this.loading.campus = false;
  }

  private async fetchCampusDevicesForTasks(): Promise<void> {
    this.loading.devices = true;
    try {
      const response =
        await repositories.orchestrator.campus.getCampusDevicesForTasks(
          this.campusSlug,
        );
      this.devices = response.data.sort((el: any) => el.instance.function.slug);
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'napravah' },
        ).toString(),
      );
    }
    this.loading.devices = false;
  }

  private get campusReady() {
    if (this.campus == null) {
      return false;
    }
    return !!(this.campus.subnet && this.campus.gateway);
  }

  private async prepareDevice(device: any) {
    try {
      const resp = await repositories.orchestrator.campus.runTaskAction(
        this.campus!.slug,
        'prepare_devices',
        [device.name],
      );
      this.fetchTasksStateAction();
      if (resp.data.detail) {
        this.$toasted.info(resp.data.detail);
      }
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }

  private async configureDevice(device: any) {
    try {
      const resp = await repositories.orchestrator.campus.runTaskAction(
        this.campus!.slug,
        'configure_devices',
        [device.name],
      );
      this.fetchTasksStateAction();
      if (resp.data.detail) {
        this.$toasted.info(resp.data.detail);
      }
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }

  private redirectToCampusTasks(): void {
    this.$router.push({
      name: 'tasks',
      params: { campusSlug: this.campus!.slug },
    });
  }
}
