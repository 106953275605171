// auth
export const IS_LOGGED_IN = 'isLoggedIn';

// user
export const FULL_NAME = 'fullName';
export const USER_SETTINGS = 'settings';
export const USER = 'user';
export const ROLES = 'roles';
export const IS_ADMIN = 'isAdmin';
export const IS_ROID = 'isRoid';
export const IS_SUPPORT = 'isSupport';
export const IS_ADMIN_OR_ROID = 'isAdminOrRoid';
export const IS_ADMIN_OR_SUPPORT = 'isAdminOrSUPPORT';
export const LAST_CHECKED_TASKS = 'lastCheckedTasks';
export const BADGE_STATES = 'badgeStates';
export const BADGE_TIMEOUT_REF = 'badgeTimeoutRef';
export const LATEST_USER_TRIGGERED_TASK_TIME = 'latestUserTriggeredTaskTime';
export const SETTINGS_DARK_THEME = 'settingsDarkTheme';
export const BADGE_STATES_LAST_CHANGED = 'badgeStatesLastChanged';

// circuits
export const GET_SHOW_MODAL = 'getShowModal';

// appp
export const GET_NAVBAR_ITEMS = 'getNavbarItems';
export const GET_DRAWER = 'getDrawer';
export const GET_APPBAR_ITEMS = 'getAppBarItems';
