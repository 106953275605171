
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { namespace } from 'vuex-class';
import { IS_ROID } from '@/store/getters.type';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { repositories } from '@/api/ApiFactory';
import { NetboxAsset, Device } from '@/api/interfaces';
import { AVAILABLE_NETBOX_ASSET_STATUSES } from '../shared/constants';
import ErrorHandler from '@/components/shared/errorHandler';
import Autocomplete from '../shared/Autocomplete.vue';
import AssetSearch from './AssetSearch.vue';
import AssetInfoWithNetboxInfo from './AssetInfoWithNetboxInfo.vue';

const userModule = namespace(USER_NAMESPACE_PATH);

interface DeviceReplaceForm {
  base_mac: string | null;
  serial_number: string | null;
}

@Component({
  components: {
    Autocomplete,
    AssetSearch,
    AssetInfoWithNetboxInfo,
  },
})
export default class DeviceReplaceModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private device!: Device;
  @Prop() private campusSlug!: string;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  @userModule.Getter(IS_ROID) private isRoid!: boolean;
  private radioBtn = 'existing';
  private loading = {
    submit: false,
    sync: false,
  };
  private assetSelect: NetboxAsset[] = [];
  private assetStatus: string | null = null;
  private form: DeviceReplaceForm = {
    base_mac: null,
    serial_number: null,
  };
  private availableAssetStatuses = AVAILABLE_NETBOX_ASSET_STATUSES;

  private created() {
    // populate form with current data
    this.form.base_mac = this.device.asset?.base_mac || null;
    this.form.serial_number = this.device.asset?.serial_number || null;
  }

  private async submit() {
    this.loading.submit = true;
    try {
      let assetId = null;
      if (this.assetSelect.length > 0) {
        assetId = this.assetSelect[0].id;
      }
      const formData =
        this.radioBtn === 'existing'
          ? {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              inventorySysId: assetId!,
              assetStatus: this.assetStatus as string,
            }
          : {
              mac: this.form.base_mac as string,
              serialNumber: this.form.serial_number as string,
              assetStatus: this.assetStatus as string,
            };
      const { data } = await repositories.provision.device.replaceDevice(
        this.device.id,
        formData,
      );
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
      this.ok(data);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.submit = false;
  }

  private async syncWithNetbox() {
    this.loading.sync = true;
    try {
      if (!this.device.asset?.inventory_sys_id) {
        return;
      }
      await repositories.infrastructure.asset.syncNetboxAsset(
        this.device.asset.inventory_sys_id,
      );
      const { data: deviceData } =
        await repositories.infrastructure.device.getDevice(this.device.name);
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
      this.$toasted.success('Podatki so bili osveženi');
      this.ok(deviceData);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.sync = false;
  }

  private resetFields() {
    this.assetSelect = [];
  }

  private showDisconnectInvModal() {
    const promise: Promise<boolean> = this.$modals.open(
      'app-confirm-device-disconnect-inv-modal',
      {
        component: {
          props: {
            device: this.device,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<boolean>;
    promise.then((res: boolean) => {
      if (res) {
        // need to refetch device so that campus-devices has proper device data
        this.refetchDeviceAndSendData();
      }
    });
  }

  private async refetchDeviceAndSendData() {
    try {
      const { data: deviceData } =
        await repositories.infrastructure.device.getDevice(this.device.name);
      this.ok(deviceData);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }
}
