
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { repositories } from '@/api/ApiFactory';
import { IS_ADMIN, IS_ADMIN_OR_ROID, IS_ROID } from '@/store/getters.type';
import ErrorHandler from '@/components/shared/errorHandler';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { getRichTextDocPolicyRepr } from '@/components/shared/helpers';
import { SelectEl } from '@/api/interfaces';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class AddRichTextDocModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private objectId!: number | string;
  @Prop() private modelName!: string;
  @Prop() private adminOnly!: boolean;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_ROID) private isRoid!: boolean;
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;
  private name = '';
  private policy: SelectEl | null = null;
  private roidCanEdit = true;
  private availablePolicies: SelectEl[] = [];
  private loading = { initial: false, submit: false };

  private created() {
    if (this.isAdmin) {
      this.availablePolicies = [
        { text: getRichTextDocPolicyRepr('admin'), value: 'admin' },
        {
          text: getRichTextDocPolicyRepr('admin|support'),
          value: 'admin|support',
        },
      ];
      if (!this.adminOnly) {
        this.availablePolicies.push({
          text: getRichTextDocPolicyRepr('admin|support|roid'),
          value: 'admin|support|roid',
        });
      }
    } else {
      this.availablePolicies = [];
      if (!this.adminOnly) {
        this.availablePolicies = [
          {
            text: getRichTextDocPolicyRepr('admin|support|roid'),
            value: 'admin|support|roid',
          },
        ];
      }
    }
    // preselect if only 1
    if (this.availablePolicies.length === 1) {
      this.policy = this.availablePolicies[0];
    }
  }

  @Watch('policy')
  private onPolicyChange(val: SelectEl | null) {
    if (val === null || !val.value.includes('roid')) {
      this.roidCanEdit = false;
    } else {
      this.roidCanEdit = true;
    }
  }

  private async createRichTextDoc() {
    this.loading.submit = true;
    try {
      const { data } =
        await repositories.documentation.richtextdoc.createRichTextDoc(
          this.name,
          this.objectId,
          this.modelName,
          this.policy!.value,
          !this.roidCanEdit,
        );
      this.$toasted.success('Dokument je bil uspešno kreiran.');
      this.ok(data);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.submit = false;
  }
}
