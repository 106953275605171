
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { Representations } from '@/components/shared/representation';
import { Device, DeviceRole, Location } from '@/api/interfaces';
import { DeviceEditFormAdmin, DeviceEditFormRoid } from './interfaces';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class DeviceEditModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private device!: Device;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private repr = new Representations();
  private loading = {
    locations: false,
    submit: false,
  };
  private form: DeviceEditFormAdmin | DeviceEditFormRoid = {
    location: null,
    description: '',
  };
  private locations: Location[] = [];
  private allowedPossibleRoles = [
    { text: 'LAN', value: ['lan'], matchValue: 'lan' },
    { text: 'CPE', value: ['cpe'], matchValue: 'cpe' },
    { text: 'LAN in CPE', value: ['lan', 'cpe'], matchValue: 'lan,cpe' },
  ];

  private get possibleRoles() {
    return this.allowedPossibleRoles.concat([
      { text: 'Backbone', value: ['backbone'], matchValue: 'backbone' },
      { text: 'TSP', value: ['tsp'], matchValue: 'tsp' },
    ]);
  }

  private getRoomReprText(room: Location) {
    return this.repr.locationRepr(room).text;
  }

  private async created() {
    // populate form with current data
    this.form.description = this.device.description || '';
    await this.fetchLocations();
    this.form.location = null;
    if (this.device.location) {
      this.form.location = this.locations.find((location) => location.id === this.device.location!.id)!.id;
    }
    if (!this.isAdmin) {
      return;
    }
    let deviceRoles =
      this.device.roles?.map((role: DeviceRole) => role.slug) || [];
    if (deviceRoles.includes('lan') && deviceRoles.includes('cpe')) {
      deviceRoles = deviceRoles.filter(
        (role) => role !== 'cpe' && role !== 'lan',
      );
      deviceRoles.push('lan,cpe');
    }
    // use possibleRoles instead of allowedPossibleRoles because TSP's and Backbones
    // still need to have a valid form
    (this.form as DeviceEditFormAdmin).roles = this.possibleRoles.filter(
      (role) => deviceRoles.includes(role.matchValue),
    )[0].value;
    (this.form as DeviceEditFormAdmin).name = this.device.name;
    (this.form as DeviceEditFormAdmin).managed = this.device.managed;
    (this.form as DeviceEditFormAdmin).primary_address = this.device
      .primary_address
      ? this.device.primary_address.address
      : null;
  }

  private async fetchLocations() {
    this.loading.locations = true;
    try {
      const { data } =
        await repositories.tenants.location.getCampusLocationsWithRooms(
          this.device.campuses[0],
        );
      const rooms = [];
      for (const building of data.results) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        for (const room of building.children!) {
          room.parent = building;
          rooms.push(room);
        }
      }
      this.locations = rooms;
    } catch (error) {
      this.$toasted.error('Lokacij za izbran kampus ni bilo mogoče pridobiti.');
    }
    this.loading.locations = false;
  }

  private get showRoles() {
    const deviceRoles =
      this.device.roles?.map((role: DeviceRole) => role.slug) || [];
    return (
      deviceRoles.filter(
        (role) => role !== 'lan' && role !== 'cpe' && role !== 'lan,cpe',
      ).length === 0
    );
  }

  private async submit() {
    this.loading.submit = true;
    try {
      const { data } = await repositories.infrastructure.device.editDevice(
        this.device.name,
        this.form,
      );
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
      this.ok(data);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.submit = false;
  }
}
