import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import user from './modules/user';
import circuits from './modules/circuits';
import app from './modules/app';

Vue.use(Vuex);

// store Storage interface from vuex-persistedstate, unfortunatelly they don't export it
interface Storage {
  getItem: (key: string) => any;
  setItem: (key: string, value: any) => void;
  removeItem: (key: string) => void;
}

// so that it's singleton
const store = new Vuex.Store({
  modules: {
    auth,
    user,
    circuits,
    app,
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'user'],
      getState: function getState(key: string, storage: Storage) {
        // read from storage but if user refreshed the page while auth.status was 'logging out'
        // then we need to manually reset auth and user state so that the user doesn't get stuck
        // in an infinite logout toast loop
        try {
          const value = storage.getItem(key);
          let res;
          if (typeof value !== 'undefined') {
            res = JSON.parse(value);
            if (res.auth.status === 'logging out') {
              auth.mutations.resetModuleState(res.auth);
              user.mutations.resetModuleState(res.user);
            }
          }
          return res;
        } catch (err) {
          // comment for ts compiler to not report an empty block
        }

        return undefined;
      },
    }),
  ],
});

export default store;
