
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, IS_SUPPORT, IS_ADMIN_OR_ROID } from '@/store/getters.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { repositories } from '@/api/ApiFactory';
import {
  Campus,
  Location,
  LocationType,
  Organization,
  RichTextDoc,
} from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import CampusLocationMap from './CampusLocationMap.vue';
import CampusLocations from './CampusLocations.vue';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    CampusLocations,
    CampusLocationMap,
  }
})
export default class CampusInfo extends Vue {
  @Prop() private campus!: Campus;
  @Prop({ default: true }) private loading!: boolean;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_SUPPORT) private isSupport!: boolean;
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private loadingDocs = false;
  private documents: RichTextDoc[] = [];

  private get initialLoading() {
    return this.loading || this.loadingDocs;
  }

  private get sortedDocuments() {
    return [...this.documents].sort(
      (a, b) =>
        new Date(b.modified as string).getTime() -
        new Date(a.modified as string).getTime(),
    );
  }

  private created() {
    this.fetchDocuments();
  }

  private async fetchDocuments() {
    this.loadingDocs = true;
    try {
      const response =
        await repositories.documentation.richtextdoc.getRichTextDocs(
          'campus',
          this.campus.slug,
        );
      this.documents = response.data.results;
    } catch (error: unknown) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loadingDocs = false;
  }

  private showMoveCampusStuffModal() {
    this.$modals.open('app-move-campus-stuff-modal', {
      component: {
        props: {
          campus: this.campus,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  get buildings(): Location[] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.campus.locations!.filter(
      (location) => (location.type as LocationType).name === 'building',
    );
  }

  get portalUrl(): string {
    return `${process.env.VUE_APP_PORTAL_URL}portal/organization?id=`;
  }

  private redirectToCampusMoveStuff() {
    this.$router.push({
      name: 'moveStuff',
      params: { campusSlug: this.campus.slug },
    });
  }

  private onTasksClick(): void {
    this.$router.push({
      name: 'tasks',
      params: { campusSlug: this.campus.slug },
    });
  }

  private redirectToCampusRunTaskAction() {
    this.$router.push({
      name: 'runTaskAction',
      params: { campusSlug: this.campus.slug },
    });
  }

  private redirectToCampusLocations() {
    this.$router.push({
      name: 'locations',
      params: { campusSlug: this.campus.slug },
    });
  }

  private async openAddOrganizationToCampusModal() {
    const organization: Organization = await this.$modals.open(
      'app-add-organization-to-campus-modal',
      {
        component: {
          props: {
            campus: this.campus,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    );
    this.campus.organizations!.push(organization);
  }

  private openDeleteOrganizationModal(organization: Organization) {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `organizacijo ${organization.name} (${organization.portal_id}) iz kampusa ${this.campus.name}`,
            item: organization,
          },
          deleteFn: this.deleteOrganization,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteOrganization(modal: any, organization: Organization) {
    repositories.tenants.campus
      .removeOrganization(this.campus.slug, organization.id)
      .then(() => {
        this.campus.organizations = this.campus.organizations!.filter(
          (org) => org.id !== organization.id,
        );
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
        modal.deleting = false;
      });
  }

  private async runCampusPrepare() {
    try {
      const response = await repositories.orchestrator.campus.runTaskAction(
        this.campus.slug,
        'prepare_campus',
      );
      this.fetchTasksStateAction();
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }

  private canCreateDoc() {
    return this.isAdminOrRoid;
  }

  private showRichTextDocCreateModal() {
    const promise: Promise<RichTextDoc> = this.$modals.open(
      'app-add-richtextdoc-modal',
      {
        component: {
          props: {
            objectId: this.campus.slug,
            modelName: 'campus',
            adminOnly: false,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<RichTextDoc>;
    promise.then((doc: RichTextDoc) => {
      this.$router.push({ name: 'docs', params: { docSlug: doc.slug } });
    });
  }

  private showDocumentationHelpModal() {
    this.$modals.open('app-documentation-help-modal', {
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private onBuildingAdd(building: Location) {
    this.campus!.locations!.push(building);
  }

  private onBuildingChanged(building: Location) {
    const buildingIdx = this.campus!.locations!.findIndex((el) => el.id === building.id);
    this.campus!.locations!.splice(buildingIdx, 1, building);
  }
}
