import axios, { AxiosPromise } from 'axios';
import { LocationType, PaginatedResponse } from '@/api/interfaces';
import { Query } from '../query';

const resources = {
  default: 'tenants/location-types',
};

interface LocationTypeApiInterface {
  getLocationType(id: string | number): AxiosPromise<LocationType>;
  getLocationTypes(): AxiosPromise<PaginatedResponse<LocationType>>;
  getLocationTypeByName(name: string): AxiosPromise<LocationType>;
}

const LocationTypeApi: LocationTypeApiInterface = {
  getLocationType(id) {
    return axios.get(`${resources.default}/${id}/`);
  },

  async getLocationTypeByName(name) {
    const query = new Query({ name });
    const res = await axios.get(`${resources.default}/${query}`);
    // used any here because otherwise it complains that res.data doesn't have array functions
    // anymore. I could create a new response here but i think that's an even weirder solution
    res.data = res.data.results[0];
    return res;
  },

  getLocationTypes() {
    return axios.get(`${resources.default}/`);
  },
};

export default LocationTypeApi;
export { LocationTypeApiInterface };
