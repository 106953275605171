import { render, staticRenderFns } from "./PickImageModal.vue?vue&type=template&id=0dd71d60&scoped=true&"
import script from "./PickImageModal.vue?vue&type=script&lang=ts&"
export * from "./PickImageModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dd71d60",
  null
  
)

export default component.exports