
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Network } from '@/api/interfaces';
import { DataTableHeader } from 'vuetify';
import _get from 'lodash.get';
import translateWord from '@/components/shared/translations';

@Component
export default class ManagementNetworkGateways extends Vue {
  @Prop(Boolean) private loading!: boolean;
  @Prop() private network!: Network;
  private translateWord = translateWord;

  private skeletonAttrs = {
    class: 'mb-6',
    boilerplate: true,
    elevation: 2,
  };
  private headersDevices: DataTableHeader[] = [
    {
      text: 'Ime',
      align: 'start',
      sortable: true,
      value: 'device.name',
    },
    {
      text: 'IP',
      align: 'start',
      sortable: true,
      value: 'device.primary_address',
    },
    {
      text: 'Serijska',
      align: 'start',
      sortable: true,
      value: 'device.serial_number',
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'device.status',
    },
    {
      text: 'Omogočen',
      align: 'start',
      sortable: true,
      value: 'enabled',
    },
    {
      text: 'Upravljana',
      align: 'start',
      sortable: true,
      value: 'device.managed',
    },
    {
      text: 'Funkcija',
      align: 'start',
      sortable: true,
      value: 'device.function',
    },
    {
      text: 'Vloge',
      align: 'start',
      sortable: true,
      value: 'device.roles',
    },
    {
      text: 'Model',
      align: 'start',
      sortable: true,
      value: 'device.model',
    },
    {
      text: 'MAC',
      align: 'start',
      sortable: true,
      value: 'device.mac_address',
    },
    {
      text: 'Kampusi',
      align: 'start',
      sortable: true,
      value: 'device.campuses',
    },
    {
      text: 'Vozlišča',
      align: 'start',
      sortable: true,
      value: 'device.sites',
    },
  ];
}
