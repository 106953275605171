
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, IS_ADMIN_OR_ROID } from '@/store/getters.type';
import {
  Interface,
  StatsL2Interface,
  ModifyDeviceInterfacesForm,
  Vlan,
  InterfaceStateFact,
  Switchport,
} from '@/api/interfaces';
import Switchports from './Switchports.vue';
import InterfaceLLDPFacts from './InterfaceLLDPFacts.vue';
import DenseInterfaceToolbarElementInterfaceFactsLights from './DenseInterfaceToolbarElementInterfaceFactsLights.vue';
import InterfaceSTP from './InterfaceSTP.vue';
import SwitchActiveInterface from './SwitchActiveInterface.vue';
import VlanTaggedSelectMultipleItem from '@/components/interfaces/VlanTaggedSelectMultipleItem.vue';
import VlanSelectItem from '@/components/interfaces/VlanSelectItem.vue';
import VlanSelectSelection from '@/components/interfaces/VlanSelectSelection.vue';
import VlanTaggedSelectSelection from '@/components/interfaces/VlanTaggedSelectSelection.vue';
import _get from 'lodash.get';
import {
  getColorForVlanType,
  getIconForVlanType,
  getIconForInterfaceUsage,
  possibleUsagesForInterfaces,
} from '@/components/shared/helpers';
import { getFontColorBasedOnBackground } from '@/helpers';
import isEqual from 'lodash.isequal';

const userModule = namespace(USER_NAMESPACE_PATH);

import { getPortTypeIcon } from '@/components/shared/helpers';

@Component({
  components: {
    Switchports,
    'interface-stp': InterfaceSTP,
    'interface-lldp-facts': InterfaceLLDPFacts,
    'interface-facts-lights': DenseInterfaceToolbarElementInterfaceFactsLights,
    SwitchActiveInterface,
    VlanSelectItem,
    VlanTaggedSelectMultipleItem,
    VlanSelectSelection,
    VlanTaggedSelectSelection,
  },
})
export default class DenseInterface extends Vue {
  @Prop(Boolean) private selected!: boolean;
  @Prop(Boolean) private expanded!: boolean;
  // loadExpandedPart tells whether to load the extended part of the interface
  // in the DOM (note that expanded might be false but the content might still
  // be loaded in the dom)
  @Prop(Boolean) private loadExpandedPart!: boolean;
  @Prop(Boolean) private multi!: boolean;
  @Prop() private operationalState!: InterfaceStateFact;
  @Prop({ default: false }) private ignoreSTPFacts!: boolean;
  @Prop() private submitFn!: (
    formDataForSubmition: ModifyDeviceInterfacesForm,
  ) => Promise<unknown>;
  @Prop() private deviceId!: number;
  @Prop() private deviceManaged!: boolean;
  @Prop(Boolean) private isDeviceTSP!: boolean;
  @Prop() private iface!: Interface;
  @Prop() private ifaceFacts!: StatsL2Interface;
  @Prop() private availableUserVlans!: Vlan[];
  @Prop({ default: [] }) private interfacesOnPort!: Interface[];
  @Prop({ default: false }) private hideName!: boolean;
  @Prop({ default: false }) private dense!: boolean;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;

  private possibleUsagesForInterfaces = possibleUsagesForInterfaces;
  private getFontColor = getFontColorBasedOnBackground;
  private gett = _get;
  private warningString = '';
  private loading = false;

  // Expandable only if not in multi edit mode
  private expand = false;

  private get isDisabled(): boolean {
    return (
      !this.isAdminOrRoid ||
      (!this.isAdmin &&
        this.formData.change_actions.usage.value === 'infrastructure_trunk') ||
      (!this.isAdmin && (!this.iface.managed || !this.deviceManaged))
    );
  }

  private get possibleUsagesForInterfacesComputed(): {
    name: string;
    value: string;
    icon: string;
    description: string;
    permission: string;
  }[] {
    return possibleUsagesForInterfaces.map((el) =>
      Object.assign(el, {
        disabled:
          (!this.isAdmin && el.permission === 'admin') ||
          (!this.isAdmin &&
            this.formData.change_actions.usage.value ===
              'infrastructure_trunk'),
      }),
    );
  }
  private show = false;

  private showTooltip() {
    this.show = true;
  }

  private hideTooltip() {
    this.show = false;
  }

  private submitFormData(fData: any) {
    this.loading = true;
    this.submitFn(fData)
      .catch(() => {
        //
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private get selectedUsage() {
    return this.possibleUsagesForInterfaces.find(
      (el) => el.value === this.formData.change_actions.usage.value,
    );
  }
  private valueChangedExpanded(value: boolean) {
    this.$emit('expanded', value);
  }

  private get isExpanded(): boolean {
    return this.expanded;
  }

  private formData: ModifyDeviceInterfacesForm = {
    device: null,
    change_actions: {
      usage: {
        change: false,
        value: null,
        native_vlan: null,
        tagged_vlans: [],
        access_vlan: null,
      },
      state: {
        change: false,
        value: true,
      },
      managed: {
        change: false,
        value: true,
      },
      description: {
        change: false,
        value: '',
      },
    },
    interfaces: [],
  };

  private originalStateOfFormData: ModifyDeviceInterfacesForm = {
    device: null,
    change_actions: {
      usage: {
        change: false,
        value: null,
        native_vlan: null,
        tagged_vlans: [],
        access_vlan: null,
      },
      state: {
        change: false,
        value: true,
      },
      managed: {
        change: false,
        value: true,
      },
      description: {
        change: false,
        value: '',
      },
    },
    interfaces: [],
  };

  /*
   * Getter for replication of the left light on port which is displayed in toolbar
   */
  private get portLinkState() {
    let color;
    let text = 'Aktiven';
    if (this.operationalState.link === 'up' && this.iface.enabled) {
      color = '#10FC16';
    } else if (!this.iface.enabled) {
      color = '#FF9901';
      text = 'Onemogočen';
    } else {
      color = '#F1EFF0';
      text = 'Neaktiven';
    }
    return { color, text };
  }

  private get portSTPState() {
    if (!this.operationalState) {
      return {
        text: 'Neznano',
        color: '#F1EFF0',
      };
    }

    return this.operationalState.stp;
  }

  private get neighbor(): { name: string; managed: boolean } {
    const res = {
      name: '',
      managed: false,
    };
    if (
      this.ifaceFacts &&
      this.ifaceFacts.neighbors &&
      this.ifaceFacts.neighbors.length
    ) {
      const neighbor = this.ifaceFacts.neighbors[0];
      if (Array.isArray(neighbor.devices) && neighbor.devices.length) {
        res.managed = neighbor.devices[0].managed;
        res.name = neighbor.devices[0].name;
      } else {
        res.name = neighbor.remote_system_name ?? '/';
      }
    }
    return res;
  }

  private getPortTypeIcon = getPortTypeIcon;

  private get isSelected(): boolean {
    return this.selected;
  }

  private valueChangedSelect(value: boolean) {
    this.$emit('selected', value);
  }

  private switchActiveInterface(value: any) {
    this.$emit('switchActiveInterface', value);
  }

  // private valueChangedDescription(value: any) {
  //   this.$emit('description', value);
  // }

  private get mdAndUpBreakpoint(): boolean {
    return this.$vuetify.breakpoint.mdAndUp;
  }

  private get smAndUpBreakpoint(): boolean {
    return this.$vuetify.breakpoint.smAndUp;
  }

  private getImage(formFactor: number) {
    return require(`@/assets/ports/${this.getPortTypeIcon(formFactor)}`);
  }

  /*
   * Usages:
   *  - ztp, doesn't have switch port mode
   *  - infrastructure, has always switchport mode of trunk
   *  - user, can have trunk or access
   *  Usage can never be empty
   */
  private get usageAndSwitchportMode(): {
    usage: string;
    vlanIcon: string;
    usageIcon: string;
    color: string;
    textColor: string;
    unmanagedColor: string;
  } {
    const mode = this.iface.switchport_mode;
    const res = {
      usage: this.iface.usage,
      vlanIcon: '',
      usageIcon: '',
      color: '',
      textColor: 'black',
      unmanagedColor: '',
    };

    if (res.usage === 'infrastructure') {
      if (mode === 'trunk') {
        res.usage = 'Infrastruktura';
      } else {
        this.warningString = `Dostopne nastavitve "${res.usage}${
          mode ? '-' + res.usage : ''
        }" niso pravilno nastavljene.`;
      }
    } else if (res.usage === 'user') {
      if (mode !== null) {
        const networks = this.iface.switchports
          ? this.iface.switchports
              .sort((a, b) => (a.tagged > b.tagged ? 1 : -1))
              .map((el) => _get(el, 'vlan.network.name'))
          : [];
        if (mode === 'trunk') {
          res.usage = `Trunk (${networks.join()})`;
        } else {
          res.usage = `Dostop (${networks.join()})`;
        }
      } else {
        this.warningString = `Dostopne nastavitve "${res.usage}" niso pravilno nastavljene.`;
      }
    } else if (res.usage === 'ztp') {
      res.usage = 'Ni v uporabi';
    }

    const { color, unmanagedColor, icon, untagged, textColor } =
      this.getColorAndIconForInterfaceTaggedVlan(this.iface);

    res.color = color;
    res.textColor = textColor;
    res.unmanagedColor = unmanagedColor;
    res.vlanIcon = icon;

    if (this.iface.circuit) {
      res.usage = 'Povezava v Arnes';
      res.usageIcon = icon;
    } else {
      res.usageIcon = getIconForInterfaceUsage(
        mode ? `${this.iface.usage}_${mode}` : this.iface.usage,
      );
    }
    return res;
  }

  /*
   * Returns color and icon based on network type
   */
  private getColorAndIconForInterfaceTaggedVlan(iface: Interface): {
    color: string;
    unmanagedColor: string;
    icon: string;
    untagged: Switchport | null;
    textColor: string;
  } {
    const alpha = 0.65;
    let icon = '';
    let untagged = null;
    let color = 'rgb(66, 20, 123)';
    let textColor = 'black';
    let unmanagedColor = `rgba(66, 20, 123, ${alpha})`;
    if (iface.usage === 'ztp' || iface.usage === 'infrastructure') {
      const usage = iface.circuit ? 'wan' : iface.usage;
      color = getColorForVlanType(usage);
      unmanagedColor = getColorForVlanType(usage, true, alpha);
      textColor = getColorForVlanType(usage, false, 1, true);
      icon = getIconForVlanType(usage);
    } else {
      if (iface.switchports) {
        const untaggedSwitchport = iface.switchports.find(
          (sw) => sw.tagged === false,
        );
        if (
          untaggedSwitchport &&
          untaggedSwitchport.vlan &&
          untaggedSwitchport.vlan.network
        ) {
          color = getColorForVlanType(untaggedSwitchport.vlan.network.type);
          textColor = getColorForVlanType(
            untaggedSwitchport.vlan.network.type,
            false,
            1,
            true,
          );
          unmanagedColor = getColorForVlanType(
            untaggedSwitchport.vlan.network.type,
            true,
            alpha,
          );
          icon = getIconForVlanType(untaggedSwitchport.vlan.network.type);
          untagged = untaggedSwitchport;
        } else {
          color = getColorForVlanType('tagged');
          unmanagedColor = getColorForVlanType('tagged', true, alpha);
          textColor = getColorForVlanType('tagged', false, 1, true);
          icon = getIconForVlanType('tagged');
        }
      }
    }
    return { color, unmanagedColor, icon, untagged, textColor };
  }

  private get isUserAccessAction(): boolean {
    return this.formData.change_actions.usage.value === 'user_access';
  }

  /*
   * Helper for multi select, this selects all vlans or
   * removes all vlans in case all are selected
   */
  private toggle() {
    this.$nextTick(() => {
      if (this.selectedAllTaggedVlans) {
        this.formData.change_actions.usage.tagged_vlans = [];
      } else {
        this.formData.change_actions.usage.tagged_vlans = this.iface
          ? this.availableUserVlans.slice().map((vlan) => {
              return vlan.id;
            })
          : [];
      }
    });
  }

  // Helper for tagged vlan multi select
  private get selectedAllTaggedVlans(): boolean {
    if (this.iface) {
      return (
        this.formData.change_actions.usage.tagged_vlans.length ===
        this.availableUserVlans.length
      );
    }
    return false;
  }
  // Helper for icon function
  private get selectedSomeTaggedVlans(): boolean {
    if (this.iface) {
      return (
        this.formData.change_actions.usage.tagged_vlans.length > 0 &&
        !this.selectedAllTaggedVlans
      );
    }
    return false;
  }

  /*
   * Getter for multi select on allowed vlans, returns correct icon based on state of it.
   */
  private get icon() {
    if (this.selectedAllTaggedVlans) {
      return 'mdi-close-box';
    }

    if (this.selectedSomeTaggedVlans) {
      return 'mdi-minus-box';
    }

    return 'mdi-checkbox-blank-outline';
  }

  /*
   * getter which checks if user has changed form data from original one
   */
  get showSaveButton(): boolean {
    return this.formHasChanges;
  }

  get formHasChanges(): boolean {
    if (this.multi) {
      return !isEqual(this.originalStateOfFormData, this.formData);
    }
    return (
      this.formData.change_actions.usage.change ||
      this.formData.change_actions.state.change ||
      this.formData.change_actions.managed.change ||
      this.formData.change_actions.description.change
    );
  }

  /*
   * Refresh form if the new data is diferent.
   * This resets form, if user changed the form from multiple editor or
   * if some other user changed data on same device.
   */
  @Watch('iface', { deep: true })
  private refreshForm(newVal: any, oldVal: any): void {
    if (!isEqual(oldVal, newVal)) {
      this.setFormData(this.iface);
    }
  }

  /*
   * Update 'changed' values for all change actions in the form if needed.
   */
  @Watch('formData.change_actions', { deep: true })
  private updateActionChangedValues(newVal: any): void {
    const oldVal = this.originalStateOfFormData.change_actions;
    if (
      !isEqual(oldVal.usage.value, newVal.usage.value) ||
      !isEqual(oldVal.usage.native_vlan, newVal.usage.native_vlan) ||
      !isEqual(oldVal.usage.tagged_vlans, newVal.usage.tagged_vlans) ||
      !isEqual(oldVal.usage.access_vlan, newVal.usage.access_vlan)
    ) {
      this.formData.change_actions.usage.change = true;
    } else {
      this.formData.change_actions.usage.change = false;
    }
    if (!isEqual(oldVal.state.value, newVal.state.value)) {
      this.formData.change_actions.state.change = true;
    } else {
      this.formData.change_actions.state.change = false;
    }
    if (!isEqual(oldVal.managed.value, newVal.managed.value)) {
      this.formData.change_actions.managed.change = true;
    } else {
      this.formData.change_actions.managed.change = false;
    }
    // handle the case where desc value is null, then someone types 's' in it to change it to 's',
    // then you remove 's' and desc value becomes ''. Both are empty strings basically but have
    // different values
    const descEqualValues = new Set(['', null]);
    if (
      !isEqual(
        new Set([oldVal.description.value, newVal.description.value]),
        descEqualValues,
      ) &&
      !isEqual(oldVal.description.value, newVal.description.value)
    ) {
      this.formData.change_actions.description.change = true;
    } else {
      this.formData.change_actions.description.change = false;
    }
  }

  /*
   * If interface's description is currently null and the user enters something
   * in its input and then deletes everything you're left with value "". This
   * means that showSaveButton will not work properly unless we set it manually
   * to null to make sure the equality holds.
   * /
  @Watch('formData.change_actions.description.value')
  private makeDescriptionNullIfEmpty(val: any): void {
    if (val === "") {
      this.formData.change_actions.description.value = null;
    }
  }

  /*
   * Helper function for setFormData, extracts first untagged vlan.
   */
  private extractNativeOrAccessVlan(
    iface: Interface,
    returnObj = false,
  ): Switchport | number | null {
    if (!iface.switchports) {
      return null;
    }
    const first = iface.switchports.find((s) => !s.tagged);
    if (returnObj && first) return first;
    return first && first.vlan ? first.vlan.id : null;
  }

  /*
   * Helper function for setFormData, extracts all tagged vlans.
   */
  private extractTaggedVlans(
    iface: Interface,
    returnObj = false,
  ): Switchport[] | number[] {
    if (!iface.switchports) {
      return [];
    }

    if (returnObj) {
      return iface.switchports;
    }
    return iface.switchports.map((s) => s.vlan.id);
  }

  /*
   * Set the form data and set the same data to the originalFormData
   * which is later used to check if user has changed anything so we can
   * show him the save button.
   */
  private setFormData(iface: Interface) {
    this.formData.change_actions.usage = {
      change: false,
      value: null,
      native_vlan: null,
      tagged_vlans: [],
      access_vlan: null,
    };
    let usage: string | null = null;
    this.formData.device = this.deviceId;
    if (iface.usage && iface.usage === 'user') {
      if (iface.switchport_mode && iface.switchport_mode === 'access') {
        usage = 'user_access';
        this.formData.change_actions.usage.access_vlan =
          this.extractNativeOrAccessVlan(iface) as number;
      } else {
        usage = 'user_trunk';
        this.formData.change_actions.usage.native_vlan =
          this.extractNativeOrAccessVlan(iface) as number;
        this.formData.change_actions.usage.tagged_vlans =
          this.extractTaggedVlans(iface) as number[];
      }
    } else if (iface.usage && iface.usage === 'infrastructure') {
      usage = 'infrastructure_trunk';
    } else if (iface.usage && iface.usage === 'ztp') {
      usage = 'ztp';
    }
    this.formData.change_actions.usage.value = usage;
    this.formData.change_actions.description = {
      change: false,
      value: iface.description,
    };
    this.formData.interfaces = [iface.id];
    this.formData.change_actions.state = {
      change: false,
      value: iface.enabled,
    };
    this.formData.change_actions.managed = {
      change: false,
      value: iface.managed,
    };
    this.originalStateOfFormData = JSON.parse(JSON.stringify(this.formData));
  }

  private handleNativeVlanChange() {
    const nativeVlanId = this.formData.change_actions.usage.native_vlan!;
    const taggedVlans = this.formData.change_actions.usage.tagged_vlans.slice();
    // NOTE: nativeVlanId becomes null if you clear its field
    if (nativeVlanId !== null && !taggedVlans.includes(nativeVlanId)) {
      taggedVlans.push(nativeVlanId);
      this.formData.change_actions.usage.tagged_vlans = taggedVlans.sort(
        (pk1, pk2) => {
          const vid1 = this.availableUserVlans!.find(
            (vlan: Vlan) => vlan.id == pk1,
          )!.vid;
          const vid2 = this.availableUserVlans!.find(
            (vlan: Vlan) => vlan.id == pk2,
          )!.vid;
          return vid1 - vid2;
        },
      );
    }
  }

  private handleTaggedVlansChange() {
    const taggedVlans = this.formData.change_actions.usage.tagged_vlans;
    taggedVlans.sort((pk1, pk2) => {
      const vid1 = this.availableUserVlans!.find(
        (vlan: Vlan) => vlan.id == pk1,
      )!.vid;
      const vid2 = this.availableUserVlans!.find(
        (vlan: Vlan) => vlan.id == pk2,
      )!.vid;
      return vid1 - vid2;
    });
    const nativeVlanId = this.formData.change_actions.usage.native_vlan;
    if (nativeVlanId !== null && !taggedVlans.includes(nativeVlanId)) {
      this.formData.change_actions.usage.native_vlan = null;
    }
  }

  private get isConnectedToCircuit(): boolean {
    return this.iface.circuit !== null;
  }

  /*
   * Fill the form data so the user can see what is on this interface
   */
  created(): void {
    if (this.iface) {
      this.setFormData(this.iface);
    }
  }
}
