import {
  ADD_CIRCUIT,
  REMOVE_CIRCUIT,
  RESET_STATE,
  SET_CIRCUITS,
  SET_LOADING,
  SET_MODAL,
} from '../mutations.type';
import {
  CLEAR_CIRCUITS_STATE,
  CLOSE_MODAL,
  CREATE_CIRCUIT,
  DELETE_CIRCUIT,
  FETCH_CIRCUITS,
  OPEN_MODAL,
  UPDATE_CIRCUIT,
} from '../actions.type';

import { CircuitInfo } from '@/api/interfaces';
import { CircuitsState } from '../types';
import { GET_SHOW_MODAL } from '../getters.type';
import { repositories } from '@/api/ApiFactory';

const circuitsState: CircuitsState = {
  circuits: [],
  showModal: false,
  loading: false,
};

const getters = {
  /**
   * Returns state.showModal. Useful because, unlike with state, this is a
   * computed property.
   *
   * @param {CircuitsState} state
   * @returns
   */
  [GET_SHOW_MODAL](state: CircuitsState) {
    return state.showModal;
  },
};

const mutations = {
  /**
   * Stores circuits in the state.
   *
   * @param {CircuitsState} state
   * @param {CircuitInfo[]} circuits
   */
  [SET_CIRCUITS](state: CircuitsState, circuits: CircuitInfo[]) {
    state.circuits = circuits;
  },

  /**
   * Adds a circuit in the state.
   *
   * @param {CircuitsState} state
   * @param {CircuitInfo} circuit
   */
  [ADD_CIRCUIT](state: CircuitsState, circuit: CircuitInfo) {
    state.circuits.push(circuit);
  },

  /**
   * Removes a circuit from the state.
   *
   * @param {CircuitsState} state
   * @param {CircuitInfo} circuit
   */
  [REMOVE_CIRCUIT](state: CircuitsState, circuit: CircuitInfo) {
    state.circuits = state.circuits.filter((el) => el.id !== circuit.id);
  },

  /**
   * Resets circuits the state.
   *
   * @param {CircuitsState} state
   */
  [RESET_STATE](state: CircuitsState) {
    state.circuits = [];
    state.showModal = false;
    state.loading = false;
  },

  /**
   * Sets showModal to a given value.
   *
   * @param {CircuitsState} state
   * @param {boolean} showModal
   */
  [SET_MODAL](state: CircuitsState, showModal: boolean) {
    state.showModal = showModal;
  },

  /**
   * Sets loading to a given value.
   *
   * @param {CircuitsState} state
   * @param {boolean} value
   */
  [SET_LOADING](state: CircuitsState, value: boolean) {
    state.loading = value;
  },
};

const actions: any = {
  /**
   * Fetches circuits and stores them in the state.
   * It also controls state.loading during the fetch.
   * Throws an error if api call fails.
   *
   * @param {*} { commit }
   * @param {string} campusSlug
   */
  async [FETCH_CIRCUITS]({ commit }: any, campusSlug: string) {
    commit(SET_LOADING, true);
    try {
      const { data } = await repositories.connectivity.circuit.getCircuitsInfo(
        campusSlug,
      );
      commit(SET_CIRCUITS, data.results);
      commit(SET_LOADING, false);
    } catch (error) {
      commit(SET_LOADING, false);
      throw error;
    }
  },

  /**
   * Creates a circuit from given data and stores it in the state.
   * Throws error if api call fails.
   *
   * @param {*} { commit }
   * @param {{
   *       campusSlug: string;
   *       campusInterfaceID: number;
   *       nodeInterfaceID: number;
   *     }} {
   *       campusSlug,
   *       campusInterfaceID,
   *       nodeInterfaceID,
   *     }
   * @returns
   */
  async [CREATE_CIRCUIT](
    { commit }: any,
    {
      campusSlug,
      status,
      type,
      campusInterfaceID,
      campusInterfaceDescription,
      nodeInterfaceID,
      nodeInterfaceDescription,
    }: {
      campusSlug: string;
      status: string;
      type: string;
      campusInterfaceID: number;
      campusInterfaceDescription: string | null;
      nodeInterfaceID: number | null;
      nodeInterfaceDescription: string | null;
    },
  ) {
    const response = await repositories.connectivity.circuit.createCircuit(
      campusSlug,
      status,
      type,
      campusInterfaceID,
      campusInterfaceDescription,
      nodeInterfaceID,
      nodeInterfaceDescription,
    );
    const circuit = response.data;
    commit(ADD_CIRCUIT, circuit);
    return circuit;
  },

  /**
   * Updates a circuit and stores it in the state.
   * Throws error if api call fails.
   *
   * @param {*} { commit }
   * @param {{
   *       id: number;
   *       status: string;
   *       campusInterfaceID: number;
   *       nodeInterfaceID: number;
   *     }} {
   *       id,
   *       status,
   *       campusInterfaceID,
   *       nodeInterfaceID,
   *     }
   * @returns
   */
  async [UPDATE_CIRCUIT](
    { commit }: any,
    {
      id,
      status,
      type,
      campusInterfaceID,
      campusInterfaceDescription,
      nodeInterfaceID,
      nodeInterfaceDescription,
    }: {
      id: number;
      status?: string;
      type?: string;
      campusInterfaceID?: number | null;
      campusInterfaceDescription?: string | null;
      nodeInterfaceID?: number | null;
      nodeInterfaceDescription?: string | null;
    },
  ) {
    const response = await repositories.connectivity.circuit.updateCircuit(id, {
      status,
      type,
      campusInterfaceID,
      campusInterfaceDescription,
      nodeInterfaceID,
      nodeInterfaceDescription,
    });
    const circuit = response.data;
    // Remove old circuit from state
    commit(REMOVE_CIRCUIT, circuit);
    // Add updated circuit to state
    commit(ADD_CIRCUIT, circuit);
    return circuit;
  },

  /**
   * Deletes a circuit and removes it from the state.
   * Throws an error if api call fails.
   *
   * @param {*} { commit }
   * @param {CircuitInfo} circuit
   */
  async [DELETE_CIRCUIT]({ commit }: any, circuit: CircuitInfo) {
    await repositories.connectivity.circuit.deleteCircuit(circuit.id);
    commit(REMOVE_CIRCUIT, circuit);
  },

  /**
   * Resets circuits state.
   *
   * @param {*} { commit }
   */
  [CLEAR_CIRCUITS_STATE]({ commit }: any) {
    commit(RESET_STATE);
  },

  /**
   * Sets showModal to true.
   *
   * @param {*} { commit }
   */
  [OPEN_MODAL]({ commit }: any) {
    commit(SET_MODAL, true);
  },

  /**
   * Sets showModal to false.
   *
   * @param {*} { commit }
   */
  async [CLOSE_MODAL]({ commit }: any) {
    commit(SET_MODAL, false);
  },
};

export default {
  namespaced: true,
  state: circuitsState,
  getters,
  mutations,
  actions,
};
