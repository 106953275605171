import {
  Address,
  Campus,
  CampusWithUniqueLocations,
  Device,
  Interface,
  Location,
  NetboxAsset,
} from '@/api/interfaces';

function deepClone(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

function isRouter(device: Device): boolean {
  return ['router', 'l3_switch'].includes(device.function.slug);
}

function isBackbone(device: Device): boolean {
  return device!.roles!.map((role) => role.slug).includes('backbone');
}

function isTSP(device: Device): boolean {
  const roleTSP =
    device.roles?.map((role) => role.slug).includes('tsp') || false;
  return (
    roleTSP &&
    ['router', 'l3_switch', 'l2_switch'].includes(device.function.slug)
  );
}

function isCPE(device: Device): boolean {
  const roleCPE =
    device.roles?.map((role) => role.slug).includes('cpe') || false;
  return roleCPE && isRouter(device);
}

function isGatewayCapable(device: Device): boolean {
  return isTSP(device) || isCPE(device);
}

function deviceIsRoutingCapable(device: Device): boolean {
  return isRouter(device) && isGatewayCapable(device);
}

function getCampusesWithUniqueLocations(campuses: Campus[]): {
  campuses: CampusWithUniqueLocations[];
  errors: string[];
} {
  const campusesWithUniqueLocation: CampusWithUniqueLocations[] = [];
  const errors: string[] = [];
  for (const campus of campuses) {
    campusesWithUniqueLocation.push({
      ...campus,
      ...{
        uniqueLocations: campus.locations?.reduce(
          (uniqueLocations: Location[], location: Location) => {
            return uniqueLocations.some(
              (loc: Location) =>
                loc.coordinates!.coordinates[0] === location.coordinates!.coordinates[0] &&
                loc.coordinates!.coordinates[1] === location.coordinates!.coordinates[1],
            )
              ? uniqueLocations
              : [...uniqueLocations, location];
          },
          [],
        ),
      },
    } as CampusWithUniqueLocations);
  }

  return { campuses: campusesWithUniqueLocation, errors };
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function interfaceSort(interfaces: Interface[]) {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  return interfaces.sort((a: Interface, b: Interface) =>
    collator.compare(a.name, b.name),
  );
}

function hexIsLightColor(color: string) {
  const hex = color.replace('#', '');
  const cR = parseInt(hex.substr(0, 2), 16);
  const cG = parseInt(hex.substr(2, 2), 16);
  const cB = parseInt(hex.substr(4, 2), 16);
  const brightness = (cR * 299 + cG * 587 + cB * 114) / 1000;
  return brightness > 155;
}

function getFontColorBasedOnBackground(backgroundColor: string) {
  return hexIsLightColor(backgroundColor) || backgroundColor === 'default'
    ? 'black'
    : 'white';
}

/** assumes array elements are primitive types
 * check whether 2 arrays are equal sets.
 * @param  {} a1 is an array
 * @param  {} a2 is an array
 */
function areArraysEqualSets(a1: any, a2: any) {
  const superSet: any = {};
  for (const i of a1) {
    const e = i + typeof i;
    superSet[e] = 1;
  }

  for (const i of a2) {
    const e = i + typeof i;
    if (!superSet[e]) {
      return false;
    }
    superSet[e] = 2;
  }

  for (const e in superSet) {
    if (superSet[e] === 1) {
      return false;
    }
  }

  return true;
}

function openInNewTab(url: string) {
  const win = window.open(url, '_blank');
  win?.focus();
}

function isString(a: any): boolean {
  return Object.prototype.toString.call(a) === '[object String]';
}

function isObject(obj: any) {
  return (
    obj === Object(obj) &&
    Object.prototype.toString.call(obj) !== '[object Array]'
  );
}

function htmlEscape(str: string) {
  return str
    .replace(/&/g, '&')
    .replace(/'/g, "'")
    .replace(/"/g, '"')
    .replace(/>/g, '>')
    .replace(/</g, '<');
}

function toNetmask(subnet: string, version: number): string {
  if (version === 4) {
    const splited = subnet.split('/');
    if (splited.length === 2) {
      return CIDR2netmask(Number(splited[1]));
    }
  }
  return '';
}

function CIDR2netmask(bitCount: number): string {
  const mask = [];
  for (let i = 0; i < 4; i++) {
    const n = Math.min(bitCount, 8);
    mask.push(256 - Math.pow(2, 8 - n));
    bitCount -= n;
  }
  return mask.join('.');
}

function ipAddressPre(a: string): string {
  let m;

  if (!a) {
    return '0';
  }

  a = a.replace(/<[\s\S]*?>/g, '');
  //IPv4:Port
  const t = a.split(':');
  if (t.length == 2) {
    m = t[0].split('.');
  } else {
    m = a.split('.');
  }
  let n = a.split(':');
  let x = '';
  let xa = '';

  if (m.length == 4) {
    // IPV4
    for (let i = 0; i < m.length; i++) {
      const item = m[i];

      if (item.length == 1) {
        x += '00' + item;
      } else if (item.length == 2) {
        x += '0' + item;
      } else {
        x += item;
      }
    }
  } else if (n.length > 0) {
    // IPV6
    let count = 0;
    for (let i = 0; i < n.length; i++) {
      const item = n[i];

      if (i > 0) {
        xa += ':';
      }

      if (item.length === 0) {
        count += 0;
      } else if (item.length == 1) {
        xa += '000' + item;
        count += 4;
      } else if (item.length == 2) {
        xa += '00' + item;
        count += 4;
      } else if (item.length == 3) {
        xa += '0' + item;
        count += 4;
      } else {
        xa += item;
        count += 4;
      }
    }

    // Padding the ::
    n = xa.split(':');
    let paddDone = 0;

    for (let i = 0; i < n.length; i++) {
      const item = n[i];

      if (item.length === 0 && paddDone === 0) {
        for (let padding = 0; padding < 32 - count; padding++) {
          x += '0';
          paddDone = 1;
        }
      } else {
        x += item;
      }
    }
  }

  return x;
}

function isAssetEnabledByStatus(item: NetboxAsset, statuses = ['used', 'retired']) {
  return !statuses.includes(item.status);
}

function isAssetSupportedDeviceModel(
  item: NetboxAsset,
  supportedModels: string[],
) {
  return (
    item.device_type != null && supportedModels.includes(item.device_type.model)
  );
}

export {
  deepClone,
  isRouter,
  isBackbone,
  isTSP,
  isCPE,
  isGatewayCapable,
  deviceIsRoutingCapable,
  getCampusesWithUniqueLocations,
  sleep,
  interfaceSort,
  hexIsLightColor,
  getFontColorBasedOnBackground,
  areArraysEqualSets,
  openInNewTab,
  isString,
  isObject,
  htmlEscape,
  CIDR2netmask,
  toNetmask,
  ipAddressPre,
  isAssetEnabledByStatus,
  isAssetSupportedDeviceModel,
};
