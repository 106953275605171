
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Campus, DataOptions, Location, Address } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { Representations } from '@/components/shared/representation';
import ErrorHandler from '@/components/shared/errorHandler';
import { Query } from '@/api/query';
import debounce from 'lodash.debounce';

interface CampusWithUniqueLocations extends Campus {
  uniqueLocations: Location[];
}

@Component
export default class ManagedCampuses extends Vue {
  @Prop(String) private managementNetworkId!: string;

  private repr = new Representations();
  private rowsPerPageItems = [30, 50, 70, 100];
  private skeletonAttrs = {
    class: 'mb-6',
    boilerplate: true,
    elevation: 2,
  };
  private headers = [
    {
      text: 'Oznaka',
      align: 'left',
      sortable: true,
      value: 'slug',
      width: '15%',
    },
    { text: 'Ime', value: 'name', width: '25%', sortable: true },
    {
      text: 'Organizacije',
      value: 'organizations',
      width: '30%',
      sortable: false,
    },
    { text: 'Lokacije', value: 'locations', width: '30%', sortable: false },
  ];

  private campuses: CampusWithUniqueLocations[] = [];
  private totalCampuses = 0;
  private loading = { initial: true, secondary: true };
  private options: DataOptions | null = null;
  private search = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    400,
  );

  @Watch('options', { deep: true })
  private handleOptions() {
    this.fetchCampuses(this.options);
  }

  private async fetchCampuses(pagination: DataOptions | null) {
    this.loading.secondary = true;
    try {
      const { data } = await repositories.tenants.campus.getCampuses(
        pagination,
        this.search,
        null,
        new Query({
          campus_management__subnet__subnet_in__id: this.managementNetworkId,
        }),
      );
      const campuses: CampusWithUniqueLocations[] = [];
      for (const campus of data.results) {
        campuses.push({
          ...campus,
          ...{
            uniqueLocations:
              campus.locations?.reduce(
                (uniqueLocations: Location[], location: Location) => {
                  return uniqueLocations.some(
                    (loc: Location) =>
                      loc.coordinates!.coordinates[0] === location.coordinates!.coordinates[0] &&
                      loc.coordinates!.coordinates[1] === location.coordinates!.coordinates[1],
                  )
                    ? uniqueLocations
                    : [...uniqueLocations, location];
                },
                [],
              ) || [],
          },
        });
      }
      this.campuses = campuses;
      this.totalCampuses = data.count;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.initial = false;
    this.loading.secondary = false;
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    // after a new search you want to be looking at the first page
    // manually change pagination object so that @update.pagination on data table gets triggered,
    // otherwise it doesn't update number of pages/items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.options!.page = 1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.fetchCampuses(this.options!);
  }
}
