
import { Component, Prop, Vue } from 'vue-property-decorator';
import CampusInfo from '@/components/campus/CampusInfo.vue';
import ErrorHandler from '@/components/shared/errorHandler';

import { Campus } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';

@Component({
  components: {
    'app-campus-info': CampusInfo,
  },
})
export default class Organization extends Vue {
  @Prop() private campusSlug!: string;

  private campus: Campus | null = null;
  private loading = true;

  private async fetchCampusInfo(): Promise<void> {
    this.loading = true;
    try {
      const response = await repositories.tenants.campus.getCampusExtended(
        this.campusSlug,
      );
      this.campus = response.data;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
    }
    this.loading = false;
  }

  private created() {
    this.fetchCampusInfo();
  }
}
