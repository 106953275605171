
import { Component } from 'vue-property-decorator';
import PersistentModal from '@/components/shared/modals/PersistentModal';

@Component
export default class HelpModal extends PersistentModal {
  private images = {
    access_point: {
      image: require(`@/assets/networkTopologyIcons/access-point.svg`),
      description: 'Dostopovna točka',
    },
    l3_switch: {
      image: require(`@/assets/networkTopologyIcons/l3-switch.svg`),
      description: 'L3 stikalo',
    },
    l2_switch: {
      image: require(`@/assets/networkTopologyIcons/l2-switch-alt.svg`),
      description: 'L2 stikalo',
    },
    router: {
      image: require(`@/assets/networkTopologyIcons/router.svg`),
      description: 'Usmerjevalnik',
    },
    other: {
      image: require(`@/assets/networkTopologyIcons/other.svg`),
      description:
        'Nepoznane naprave. V to skupino spadajo naprave, katerih automator ne prepozna.',
    },
  };

  private links = [
    {
      color: '#E6832A',
      description: 'Povezava med upravljanima napravama',
    },
    {
      color: '#D32F2F',
      description: 'Druga povezava med istima napravama',
    },
    {
      color: '#E53935',
      description: 'Tretja povezava med istima napravama',
    },
    {
      color: '#F44336',
      description: 'Četrta povezava med istima napravama',
    },
    {
      color: '#EF5350',
      description: 'Peta povezava med istima napravama',
    },
    {
      color: '#E57373',
      description: 'Šesta povezava med istima napravama',
    },
    {
      color: '#EF9A9A',
      description: 'Sedma in vse naslednje povezave med istima napravama',
    },
    {
      color: '#000',
      description: 'Povezava v omrežje Arnes (uplink)',
    },
    {
      color: '#969696',
      description: 'Povezava do naprave, ki ni upravljana',
    },
  ];
}
