import Vue from 'vue';
// register the plugin on vue
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
  iconPack: 'mdi', // Icon pack type to be used
  duration: 8000, // Display time of the toast in millisecond
  className: 'title', // Font
  keepOnHover: true,
});
