
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, IS_ROID } from '@/store/getters.type';
import { DHCPServer, Campus } from '@/api/interfaces';
import { Representations } from '@/components/shared/representation';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import debounce from 'lodash.debounce';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class DHCPServerDetail extends Vue {
  @userModule.Getter(IS_ADMIN) public isAdmin!: string;
  @userModule.Getter(IS_ROID) public isRoid!: boolean;
  @Prop({ default: null }) private fixedNetworkCampusSlug!: string | null;
  @Prop({ default: false }) private canChangeCampus!: boolean;
  @Prop() private dhcpServerId!: number;
  private headers: any[] = [
    {
      text: 'Ime',
      align: 'left',
      sortable: true,
      value: 'name',
      width: '60%',
    },
    {
      text: 'Oznaka',
      align: 'left',
      sortable: true,
      value: 'slug',
      width: '20%',
    },
    {
      text: 'Kampus',
      align: 'left',
      sortable: true,
      value: 'campus.slug',
      width: '20%',
    },
  ];
  private search = '';
  private debouncedSearch = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );

  private repr = new Representations();
  private dhcpServer: DHCPServer | null = null;

  private loading = {
    initial: true,
  };

  public async created(): Promise<void> {
    this.loading.initial = true;
    try {
      const { data } = await repositories.lan.dhcpServer.getDHCPServer(
        this.dhcpServerId,
        this.$route.params.campusSlug || null,
      );
      this.dhcpServer = data;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'dhcp strežniku' },
        ).toString(),
      );
    }
    this.loading.initial = false;
  }

  public openDHCPServerEditModal(dhcpServer: DHCPServer): void {
    const campusSlug = dhcpServer.campus
      ? (dhcpServer.campus as Campus).slug
      : null;
    const promise: Promise<DHCPServer> = this.$modals.open(
      'app-dhcp-server-modal',
      {
        component: {
          props: {
            campusSlug,
            dhcpServer,
            canChangeCampus: this.canChangeCampus,
            fixedNetworkCampusSlug: this.fixedNetworkCampusSlug,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<DHCPServer>;
    promise.then((modifiedDHCPServer: DHCPServer) => {
      this.dhcpServer = modifiedDHCPServer;
    });
  }

  public showDeleteDHCPServerModal(dhcpServer: DHCPServer): void {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `DHCP strežnik ${this.repr.dhcpServerRepr(dhcpServer).text}`,
            item: dhcpServer,
          },
          deleteFn: this.deleteDHCPServer,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  public deleteDHCPServer(modal: any, dhcpServer: DHCPServer): void {
    if (dhcpServer.id) {
      repositories.lan.dhcpServer
        .deleteDHCPServer(dhcpServer.id)
        .then((resp) => {
          this.$modals.close();
          if (dhcpServer.campus !== null) {
            this.$router.push({
              name: 'campusDhcpServers',
              params: { campusSlug: (dhcpServer.campus as Campus).slug },
            });
          } else {
            this.$router.push({ name: 'dhcpServers' });
          }
        })
        .catch((error) => {
          this.$toasted.error(
            new ErrorHandler(
              { error, status: true },
              {
                message: `Pri izbrisu DHCP strežnika ${
                  this.repr.dhcpServerRepr(dhcpServer).text
                } je prišlo do napake.`,
              },
            ).toString(),
          );
        });
    }
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    this.debouncedSearch = this.search;
  }
}
