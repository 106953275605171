
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Location, Address } from '@/api/interfaces';

import L, { latLng } from 'leaflet';
import { LMap } from 'vue2-leaflet';

interface NamedMarker {
  addressRepr: string;
  buildingNames: string[];
  coordinates: L.LatLng;
  draggable: boolean;
}

@Component
export default class CampusLocationMap extends Vue {
  @Prop() private locations!: Location[] | null;
  @Prop({ default: null }) private editableLocationId!: number | null;
  @Prop({ default: null }) private draggableData!: {
    addressRepr: string | null;
    buildingNames: string[];
    coordinates: L.LatLng | null;
  };
  @Prop({ default: true }) private allowMarkerControl!: boolean;

  private url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  private markers: NamedMarker[] = [];
  private newMarkerDefaultName = 'brez naslova';

  private draggableCoordinates: L.LatLng | null = null;
  // we cache icons otherwise while you're dragging it keeps recalculating them
  // due to draggableCoordinates changes and for some reason it drops the marker
  // after a very short time.
  private cachedIcons: any = {}

  private mounted() {
    const coordinates: L.LatLng[] = this.markers.map(
      (el: NamedMarker) => el.coordinates,
    );
    if (coordinates.length === 0) {
      // show slovenia
      (this.$refs.locationMap as LMap).mapObject.setView([46.034314824338395, 14.873657003045084], 8);
      return
    }
    const bounds = L.latLngBounds(coordinates);
    const mapObject = (this.$refs.locationMap as LMap).mapObject;
    // add some padding so that points are not too near the map's edges
    mapObject.fitBounds(bounds, { padding: [50, 50] });

  }

  private created() {
    this.calculateMarkers()
  }

  private calculateMarkers() {
    const markers: NamedMarker[] = [];
    this.draggableCoordinates = this.draggableData ? this.draggableData.coordinates : null;
    // set existing markers
    let draggableSkipped = false;
    if (this.locations) {
      this.locations.forEach((location: Location) => {
        let coordinates = location.coordinates!.coordinates;
        let addressRepr = this.newMarkerDefaultName;
        if (
          this.editableLocationId === location.id && (
          this.draggableCoordinates && (
            this.allowMarkerControl ||
            this.draggableCoordinates.lat !== location.coordinates?.coordinates[0] ||
            this.draggableCoordinates.lng !== location.coordinates?.coordinates[1])
          )
        ) {
          // we don't add it here, we add it bellow
          draggableSkipped = true;
          return;
        }
        if (location.address !== null) {
          const addr = location.address as Address;
          // no need to override coordinates since they should be the same
          addressRepr = `${addr.street_name} ${addr.house_number}${addr.house_number_addon} - ${addr.postal_code} ${addr.post_name}`;
        }
        const matchingMarker = markers.find(
          (el) => el.coordinates.lat === coordinates[0] && el.coordinates.lng === coordinates[1])
        if (matchingMarker) {
          matchingMarker.buildingNames.push(location.name)
          if (this.editableLocationId === location.id && this.allowMarkerControl) {
            matchingMarker.draggable = true;
          }
        } else {
          markers.push({
            addressRepr,
            buildingNames: [location.name],
            coordinates: latLng(
              coordinates[0],
              coordinates[1],
            ),
            draggable: this.editableLocationId === location.id,
          });
        }
      });
    }
    // when adding and draggableData is set add new draggable marker - only if
    // we allow control of the marker. When control isn't allowed we add that
    // marker in the location for-loop above since there's the logic for
    // same-address building location handling.
    if (this.draggableCoordinates && (!this.editableLocationId || draggableSkipped)) {
      markers.push({
        addressRepr: this.draggableData.addressRepr || this.newMarkerDefaultName,
        buildingNames: this.draggableData.buildingNames,
        coordinates: this.draggableCoordinates,
        draggable: this.allowMarkerControl,
      })
    }
    this.markers = markers;
  }

  private onMapRightClick(event: any) {
    if (!this.allowMarkerControl) {
      return
    }
    if (this.draggableCoordinates) {
      // already have draggable marker, noop
      return
    }
    this.addMarker(event)
  }

  private addMarker(event: any) {
    this.draggableCoordinates = event.latlng;
    this.markers.push({
      addressRepr: this.newMarkerDefaultName,
      buildingNames: this.draggableData ? this.draggableData.buildingNames : [],
      coordinates: this.draggableCoordinates!,
      draggable: true,
    })
    this.$emit('markerChangedLatLng', this.draggableCoordinates)
  }


  private getIcon(item: any) {
    const cacheRepr = `${item.addressRepr}-${item.buildingNames.join(',')}-(${item.coordinates?.lat},${item.coordinates?.lng})`
    if (this.cachedIcons[cacheRepr]) {
      return this.cachedIcons[cacheRepr];
    }
    let color = 'rgb(56 139 204)';
    let strokeColor = 'rgb(56 139 204)';
    let circleColor = 'white';
    // when you move the marker it looses the equality
    if (item.draggable || item.coordinates === this.draggableCoordinates) {
      color = 'rgb(204 56 56)';
      strokeColor = 'rgb(204 56 56)';
    }
    const icon = L.divIcon({
      className: "my-custom-pin",
      // margin-top has to be the same as svg's height so that the bottom point of the marker marks the position and
      // not the center of the white circle
      html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892337" height="40" width="25" style="margin-top: -40px">
    <g transform="translate(-814.59595,-274.38623)">
      <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
        <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="fill:${color};stroke:${strokeColor};"/>
        <circle r="3.0355" cy="288.25278" cx="823.03064" id="path3049" style="display:inline;fill:${circleColor};"/>
      </g>
    </g>
  </svg>`
    });
    this.cachedIcons[cacheRepr] = icon;
    return icon;
  }

  @Watch('draggableData')
  private updateName(newData: any, oldData: any) {
    this.calculateMarkers()
  }

  @Watch('locations')
  private updateMap(newData: any, oldData: any) {
    this.calculateMarkers()
  }

  private updateLatLng(coords: L.LatLng) {
    this.draggableCoordinates = coords;
  }

  private onMarkerMouseUp(event: any) {
    // when you're dragging the marker the update:latLng event is called every
    // 50ms (with debounce). If we were to send the event while still holding
    // the mouse button down the updates would cause the marker to be released.
    // we wait some time so that the update:latLng triggers before we send new
    // coordinates.
    // Also note that this event is on Map and not on Marker, that's because if
    // you don't see the whole map and drag a marker it slightly moves the map
    // and doesn't recognize that mouse btn presses happened on marker itself
    if (event.originalEvent.button !== 0) {
      // not left button mouse-up, ignore
      return;
    }
    if (this.draggableCoordinates == null) {
      // the user has moved the map (not moved the marker), noop
      return;
    }
    setTimeout(() => this.$emit('markerChangedLatLng', this.draggableCoordinates), 100)
  }
}
