
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Vlan } from '@/api/interfaces';
import { getColorForVlanType } from '@/components/shared/helpers';

@Component
export default class VlanSelectItem extends Vue {
  @Prop() private item!: Vlan;
  @Prop() private on!: Record<string, unknown>;
  @Prop() private attrs!: Record<string, unknown>;

  private getColorForVlanType = getColorForVlanType;
}
