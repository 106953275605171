
import { Component, Vue } from 'vue-property-decorator';
import General from '../components/settings/General.vue';
import Scripts from '../components/settings/Scripts.vue';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { namespace } from 'vuex-class';
import { IS_ADMIN } from '@/store/getters.type';

const userModule = namespace(USER_NAMESPACE_PATH);

interface Tab {
  name: string;
  repr: string;
  component: any;
}

@Component
export default class Settings extends Vue {
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;

  public tabs: Tab[] = [
    { name: 'general', repr: 'Splošno', component: General },
  ];

  created() {
    if (this.isAdmin) {
      this.tabs.push({
        name: 'scripting',
        repr: 'Skripte',
        component: Scripts,
      });
    }
  }

  public selectedTab: string = this.tabs[0].component;
}
