
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import PersistentModal from '@/components/shared/modals/PersistentModal';

@Component
export default class PickImageModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private objectId!: number | string;
  @Prop() private modelName!: string;
  @Prop() private uploadType!: string;
  private uploading = false;
  private src: string | null = null;
  private alt: string | null = null;
  private file: any = null;

  private async addImage() {
    // validate that form is ok
    const upload = await this.uploadImage();
    if (!upload) {
      return;
    }
    this.src = upload.stored_name || null;
    this.alt = `[Slika: ${upload.uploaded_name}]`;
    this.ok({ src: this.src as string, alt: this.alt as string });
  }

  private async uploadImage() {
    this.uploading = true;
    try {
      const { data } = await repositories.documentation.upload.createUpload(
        this.objectId,
        this.modelName,
        this.uploadType,
        this.file,
      );
      return data;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.uploading = false;
  }
}
