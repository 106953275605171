import {
  DataOptions,
  Device,
  Network,
  Organization,
  PaginatedResponse,
  Zone,
} from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';

const resources = { default: 'lan/networks' };

interface RoutingDeviceData {
  device: Device;
  // l3_enabled --> whether L3 interface on the device is enabled
  l3_enabled: boolean;
}

interface NetworkApiInterface {
  getNetwork(networkId: number | string): AxiosPromise<Network>;
  getNetworks(
    query?: Query | null,
    options?: DataOptions | null,
    search?: string | null,
    searchFields?: string[] | null,
  ): AxiosPromise<PaginatedResponse<Network>>;
  createNetwork(
    campusSlug: string,
    network: Network,
    zones: Zone[],
    dhcpServers: number[],
    dnsResolvers: number[],
    routingDevices: RoutingDeviceData[],
    organization: Organization | null,
  ): AxiosPromise<Network>;
  updateNetwork(
    campusSlug: string,
    network: Network,
    zones: Zone[],
    dhcpServers: number[],
    dnsResolvers: number[],
    routingDevices: RoutingDeviceData[],
    organization: Organization | null,
  ): AxiosPromise<Network>;
  updateNetworkRoid(
    campusSlug: string,
    networkId: number | string,
    dhcpServers: number[] | null,
    dnsResolvers: number[],
  ): AxiosPromise<Network>;
  deleteNetwork(networkId: number): AxiosPromise<any>;
  editGateways(
    networks: {
      id: number;
      routing_devices: { device_id: number; l3_enabled: boolean }[];
    }[],
  ): AxiosPromise<any>;
  createManagementNetwork(
    routingDevices: RoutingDeviceData[],
  ): AxiosPromise<Network>;
  updateManagementNetwork(
    vlanId: number,
    routingDevices: RoutingDeviceData[],
  ): AxiosPromise<Network>;
  getAvailableRoutingDevices(
    networkId: number | null,
  ): AxiosPromise<RoutingDeviceData[]>;
}

const NetworkApi: NetworkApiInterface = {
  getNetwork(networkId) {
    return axios.get(`${resources.default}/${networkId}/`);
  },

  getNetworks(
    query = null,
    options = null,
    search = null,
    searchFields = null,
  ) {
    if (!query) {
      query = new Query({});
    }
    if (searchFields === null) {
      searchFields = [
        'ip',
        'device_management',
        'device_gateway',
        'campus',
        'name',
        'slug',
      ];
    }

    if (options) {
      if (options.itemsPerPage > 0) {
        query.addParam('size', options.itemsPerPage);
      }
      query.addParam('page', options.page);
      let sortBy = options.sortBy[0];
      if (sortBy) {
        if (options.sortDesc[0]) {
          sortBy = `-${sortBy}`;
        }
        query.addParam('sort_by', sortBy);
      }
    }
    if (search !== null) {
      query.addParam('search', search);
    }

    query.addParam('search_fields', searchFields.join(','));

    return axios.get(`${resources.default}/${query}`);
  },

  createNetwork(
    campusSlug,
    network,
    zones,
    dhcpServers,
    dnsResolvers,
    routingDevices,
    organization,
  ) {
    return axios.post(`${resources.default}/`, {
      campusSlug,
      network,
      zones: zones.map((zone) => zone.id),
      dhcp_servers: dhcpServers,
      dns_resolvers: dnsResolvers,
      routing_devices: routingDevices.map((o) => ({
        device_id: o.device.id,
        l3_enabled: o.l3_enabled,
      })),
      organization: organization?.id || null,
    });
  },

  updateNetwork(
    campusSlug,
    network,
    zones,
    dhcpServers,
    dnsResolvers,
    routingDevices,
    organization,
  ) {
    return axios.patch(`${resources.default}/${network.id}/`, {
      campusSlug,
      network,
      zones: zones.map((zone) => zone.id),
      dhcp_servers: dhcpServers,
      dns_resolvers: dnsResolvers,
      routing_devices: routingDevices.map((o) => ({
        device_id: o.device.id,
        l3_enabled: o.l3_enabled,
      })),
      organization: organization?.id || null,
    });
  },

  updateNetworkRoid(campusSlug, networkId, dhcpServers, dnsResolvers) {
    const data: {
      campusSlug: string;
      dhcp_servers?: number[];
      dns_resolvers: number[];
    } = {
      campusSlug,
      dns_resolvers: dnsResolvers,
    };
    if (dhcpServers !== null) {
      data.dhcp_servers = dhcpServers;
    }
    return axios.patch(`${resources.default}/${networkId}/`, data);
  },

  deleteNetwork(networkId) {
    return axios.delete(`${resources.default}/${networkId}/`);
  },

  editGateways(data) {
    return axios.post(`${resources.default}/edit-multiple/`, {
      networks: data,
    });
  },

  createManagementNetwork(routingDevices) {
    return axios.post(`${resources.default}/management/`, {
      routing_devices: routingDevices.map((o) => ({
        device_name: o.device.name,
        l3_enabled: o.l3_enabled,
      })),
    });
  },

  updateManagementNetwork(networkId, routingDevices) {
    return axios.patch(`${resources.default}/${networkId}/management/`, {
      routing_devices: routingDevices.map((o) => ({
        device_name: o.device.name,
        l3_enabled: o.l3_enabled,
      })),
    });
  },

  getAvailableRoutingDevices(networkId) {
    const query = new Query({ network: networkId });
    return axios.get(
      `${resources.default}/get-available-routing-devices/${query}`,
    );
  },
};

export default NetworkApi;

export { NetworkApiInterface };
