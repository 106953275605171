
import { Component, Prop, Vue } from 'vue-property-decorator';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class GeneralInterfacesEditorRedirector extends Vue {
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @Prop() private deviceName!: string;

  private loading = {
    device: false,
  };
  private attrs = {
    class: 'mb-6',
    boilerplate: false,
    elevation: 2,
  };

  private async loadDevice(deviceName: string) {
    this.loading.device = true;
    try {
      const { data } = await repositories.infrastructure.device.getDeviceInfo(
        deviceName,
      );
      let routerArgs: any = {
        name: 'pageNotFound',
      };
      if (
        data.managed &&
        ['l2_switch', 'l3_switch', 'router'].includes(data.function.slug) &&
        (!data.roles?.some((role) => role.slug === 'tsp') || this.isAdmin) &&
        !data.roles?.some((role) => role.slug === 'backbone')
      ) {
        if (data.campus && data.campus.slug) {
          routerArgs = {
            name: 'interfacesOnDevice',
            params: {
              campusSlug: data.campus.slug,
              deviceName: data.name,
            },
          };
        } else if (data?.site && data.site.slug) {
          routerArgs = {
            name: 'interfacesOnSiteDevice',
            params: {
              siteSlug: data.site.slug,
              deviceName: data.name,
            },
          };
        } else {
          this.$toasted.error(
            'Urejevalnika za izbrano napravo ni mogoče odpreti.',
          );
        }
      }
      if (routerArgs['name'] === 'pageNotFound') {
        this.$toasted.error(
          'Urejevalnika za izbrano napravo ni mogoče odpreti.',
        );
      }
      this.$router.replace(routerArgs);
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'napravi' },
        ).toString(),
      );
    }
    this.loading.device = false;
  }

  private created() {
    if (this.deviceName) {
      this.loadDevice(this.deviceName);
    } else {
      this.$router.push({ name: 'pageNotFound' });
    }
  }
}
