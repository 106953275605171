
import { AxiosResponse } from 'axios';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { Representations, ReprValue } from '@/components/shared/representation';
import { repositories } from '@/api/ApiFactory';
import { Location } from '@/api/interfaces';

@Component
export default class RoomEditModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  public $refs!: {
    name: HTMLFormElement;
  };
  @Prop() private location!: Location;
  private repr = new Representations();
  private name: string | null = null;
  private loading = { submit: false };

  private created() {
    this.name = this.location.name;
  }

  private mounted() {
    setTimeout(() => this.$refs.name.$refs.input.focus(), 0);
  }

  private editRoom() {
    this.loading.submit = true;
    repositories.tenants.location
      .editRoom(
        this.location.id,
        this.name as string,
      )
      .then((res) => {
        this.ok(res.data);
      })
      .catch((err) => {
        this.$toasted.error(
          `Napaka pri urejanju lokacije: ${err.response.data.detail}`,
        );
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }
}
