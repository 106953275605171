
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { DataOptions, Device } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import _get from 'lodash.get';
import translateWord from '@/components/shared/translations';
import debounce from 'lodash.debounce';

@Component
export default class ManagedDevices extends Vue {
  @Prop(String) private managementNetworkId!: string;
  private skeletonAttrs = {
    class: 'mb-6',
    boilerplate: true,
    elevation: 2,
  };
  private rowsPerPageItems = [50, 200, 500];
  private headers = [
    { text: 'Ime', value: 'name', align: 'left' },
    { text: 'IP naslov', value: 'primary_address', align: 'left' },
    { text: 'Serijska', value: 'serial_number', align: 'left' },
    { text: 'Status', value: 'status', align: 'left' },
    { text: 'Upravljana', value: 'managed', align: 'left' },
    { text: 'Funkcija', value: 'function', align: 'left' },
    { text: 'Vloge', value: 'roles', align: 'left', sortable: false },
    {
      text: 'Model',
      value: 'product',
      align: 'left',
    },
    { text: 'MAC', value: 'base_mac', align: 'left' },
  ];
  private search = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    400,
  );

  private devices: Device[] = [];
  private totalDevices = 0;
  private loading = { initial: true, secondary: false };
  private options: DataOptions | null = null;
  private get parsedDevices(): {
    id: number;
    name: string;
    managed: string;
    status: string;
    primary_address: string;
    function: string;
    functionSlug: string;
    roles: string;
    base_mac: string | null;
    product: string;
    serial_number: string;
  }[] {
    const parsedDev = [];

    for (const device of this.devices) {
      parsedDev.push({
        id: device.id,
        name: device.name,
        managed: device.managed ? 'Da' : 'Ne',
        status: translateWord(device.status),
        primary_address: _get(device, 'primary_address.address', '/'),
        function: translateWord(_get(device, 'function.name', '/')),
        functionSlug: translateWord(_get(device, 'function.slug', '/')),
        roles: device.roles?.map((role) => role.name).join(', ') || '/',
        base_mac: _get(device, 'asset.base_mac', '/'),
        product: _get(device, 'asset.product.name', '/'),
        serial_number: _get(device, 'asset.serial_number', '/'),
      });
    }
    return parsedDev;
  }

  @Watch('options', { deep: true })
  private handleOptions() {
    this.fetchManagedDevices(this.options);
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    // after a new search you want to be looking at the first page
    // manually change pagination object so that @update.pagination on data table gets triggered,
    // otherwise it doesn't update number of pages/items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.options!.page = 1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.fetchManagedDevices(this.options!);
  }

  private async fetchManagedDevices(pagination?: DataOptions | null) {
    this.loading.secondary = true;
    try {
      const { data } = await repositories.infrastructure.device.getDevices(
        {
          pagination: pagination,
          managedByNetworkId: Number(this.managementNetworkId),
        },
        this.search,
      );
      this.devices = data.results;
      this.totalDevices = data.count;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.secondary = false;
    this.loading.initial = false;
  }
}
