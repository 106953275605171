
import { Component, Prop } from 'vue-property-decorator';
import { AVAILABLE_NETBOX_ASSET_STATUSES } from '../shared/constants';
import PersistentModal from '@/components/shared/modals/PersistentModal';

@Component
export default class ConfirmDeviceDeleteModal extends PersistentModal {
  @Prop() private data!: any;
  @Prop() private deleteFn!: (modal: any, item: any) => void;
  private deleting = false;
  private availableAssetStatuses = AVAILABLE_NETBOX_ASSET_STATUSES;
  private form: { assetStatus: string | null } = {
    assetStatus: null,
  };
  private confirmText = '';
  private requiredConfirmText = '';

  private created() {
    this.requiredConfirmText = this.data.item.name.split('.')[0];
  }

  private deleteItem() {
    this.deleting = true;
    this.data.form = this.form;
    this.deleteFn(this, this.data);
  }
}
