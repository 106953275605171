import axios, { AxiosPromise } from 'axios';

import { TaskDeviceActions } from '../interfaces';

const resources = {
  default: 'orchestrator/site',
};

interface OrchestratorSiteApiInterface {
  runTaskAction(
    siteSlug: string,
    action: string,
    devices?: string[] | null,
  ): AxiosPromise<any>;
  getSiteDevicesForTasks(siteSlug: string): AxiosPromise<TaskDeviceActions[]>;
}

const OrchestratorSiteApi: OrchestratorSiteApiInterface = {
  runTaskAction(siteSlug, action, devices = null) {
    const data: any = {
      site_slug: siteSlug,
      action,
    };
    if (devices !== null) {
      data.devices = devices;
    }
    return axios.post(
      `${resources.default}/${siteSlug}/run-task-action/`,
      data,
    );
  },

  getSiteDevicesForTasks(siteSlug) {
    return axios.get(`${resources.default}/${siteSlug}/task-devices/`);
  },
};

export default OrchestratorSiteApi;
export { OrchestratorSiteApiInterface };
