
import { Component, Prop, Vue } from 'vue-property-decorator';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';

@Component
export default class CampusParams extends Vue {
  @Prop() private campusSlug!: string;
  private loading = true;
  private data = '';
  private copied = false;

  copyTestingCode() {
    const testingCodeToCopy = document.querySelector(
      '#campusParamsData',
    ) as HTMLInputElement;
    if (testingCodeToCopy) {
      testingCodeToCopy.select();

      try {
        const successful = document.execCommand('copy');
        this.copied = true;
        setTimeout(() => (this.copied = false), 2000);
      } catch (err) {
        this.$toasted.error(
          'Kopiranje podatkov ni bilo uspešno. Poizkusite kopirati ročno.',
        );
      }

      /* unselect the range */
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
    }
  }

  private async fetchParams(campusSlug: string) {
    this.loading = true;
    try {
      const { data } = await repositories.provision.campus.getCampusParams(
        campusSlug,
      );
      this.data = data;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading = false;
  }

  created() {
    if (this.campusSlug) {
      this.fetchParams(this.campusSlug);
    }
  }
}
