import axios, { AxiosPromise } from 'axios';
import { Address } from '@/api/interfaces';

const resources = { default: 'tenants/addresses' };

interface AddressApiInterface {
  getAddress(mid: number): AxiosPromise<Address>;
}

const AddressApi: AddressApiInterface = {
  getAddress(mid) {
    return axios.get(`${resources.default}/${mid}/`);
  },
};

export default AddressApi;
export { AddressApiInterface };
