
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RichTextDoc } from '@/api/interfaces';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN_OR_ROID, IS_ADMIN } from '@/store/getters.type';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import ListDocuments from '@/components/shared/ListDocuments.vue';
import DocEditor from '@/components/shared/editor/DocEditor.vue';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    ListDocuments,
  },
})
export default class CampusDocs extends Vue {
  @Prop({ default: null }) private campusSlug!: string | null;
  @Prop({ default: null }) private docSlug!: string | null;
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;

  private loading = {
    documents: false,
  };
  private documents: RichTextDoc[] = [];

  private created() {
    this.fetchDocuments();
  }

  private canDeleteDoc(doc: RichTextDoc): boolean {
    if (this.isAdmin) {
      return true;
    }
    // only admins and roids can delete documents
    if (!this.isAdminOrRoid) {
      return false;
    }
    // only admin can delete readonly documents or documents with other policies
    if (doc.readonly || doc.policy !== 'admin|support|roid') {
      return false;
    }
    return true;
  }

  private canCreateDoc() {
    return this.isAdminOrRoid;
  }

  private async fetchDocuments() {
    this.loading.documents = true;
    try {
      const response =
        await repositories.documentation.richtextdoc.getRichTextDocs(
          'campus',
          this.campusSlug as string,
        );
      this.documents = response.data.results;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.documents = false;
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    // NOTE: beforeRouteLeave must be on the View itself, not on the nested components
    // check for unsaved changes in the documents

    // We set the default value to empty array, because when there are no
    // existing documents it returns undefined which causes page to crash
    const docs =
      ((this.$refs?.docList as ListDocuments)?.$refs[
        'docEditor'
      ] as DocEditor[]) || [];

    const unsavedDocs = docs
      .filter((docEditor) => docEditor.docHasChanges())
      .map((docEditor) => docEditor.document.name);

    if (unsavedDocs.length === 0) {
      next();
    } else {
      const answer = window.confirm(
        `Spodnji dokumenti imajo neshranjene spremembe:\n${unsavedDocs
          .map((el) => '-  ' + el)
          .join('\n')}\nSte prepričani, da želite zapustiti stran?`,
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    }
  }
}
