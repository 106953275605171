
import { Prop, Component, Vue } from 'vue-property-decorator';

const STORAGE_TYPES = {
  local: 'localStorage',
  cookies: 'cookies',
};

@Component
export default class CookiesConsent extends Vue {
  @Prop({ default: 'Razumem' }) private buttonText!: string;
  @Prop() private buttonLink!: string;
  @Prop() private buttonLinkText!: string;
  @Prop({ default: false, type: Boolean }) private buttonLinkNewTab!: boolean;
  @Prop({
    default:
      'Ta stran uporablja piškotke. Z nadaljevanjem uporabe te strani soglašate z uporabo piškotkov.',
  })
  private message!: string;
  @Prop({ default: 'bottom' }) private position!: 'bottom' | 'top';
  @Prop({ default: 'cookies_accepted' }) private storageName!: string;
  @Prop({ default: STORAGE_TYPES.local }) private storageType!: string;

  private supportsLocalStorage = true;
  private isOpen = false;

  get containerPosition(): string {
    return `Cookie--${this.position}`;
  }

  get externalButtonLink(): boolean {
    return typeof this.buttonLink === 'string' && this.buttonLink.length > 0;
  }
  get internalButtonLink(): boolean {
    return (
      typeof this.buttonLink === 'object' &&
      this.buttonLink != null &&
      Object.keys(this.buttonLink).length > 0
    );
  }

  get target(): string {
    return this.buttonLinkNewTab ? '_blank' : '_self';
  }

  get canUseLocalStorage(): boolean {
    return (
      this.storageType === STORAGE_TYPES.local && this.supportsLocalStorage
    );
  }

  private created() {
    if (this.storageType === STORAGE_TYPES.local) {
      // Check for availability of localStorage
      try {
        const test = '__vue-check-localStorage';
        if (typeof window !== 'undefined') {
          window.localStorage.setItem(test, test);
          window.localStorage.removeItem(test);
        }
      } catch (e) {
        //  Local storage is not supported, falling back to cookie use
        this.supportsLocalStorage = false;
      }
    }

    if (!this.getVisited()) {
      this.isOpen = true;
    }
  }

  private mounted() {
    if (this.isAccepted()) {
      this.$emit('accept');
    }
  }

  setVisited(): void {
    if (this.canUseLocalStorage) {
      localStorage.setItem(this.storageName, 'true');
    } else {
      this.$cookies.set(this.storageName, true, '1Y');
    }
  }

  setAccepted(): void {
    if (this.canUseLocalStorage) {
      localStorage.setItem(this.storageName, 'true');
    } else {
      this.$cookies.set(this.storageName, true, '1Y');
    }
  }
  setDeclined(): void {
    if (this.canUseLocalStorage) {
      localStorage.setItem(this.storageName, 'false');
    } else {
      this.$cookies.set(this.storageName, 'false', '1Y');
    }
  }
  getVisited(): boolean {
    let visited: boolean | string | null = false;
    if (this.canUseLocalStorage) {
      visited = localStorage.getItem(this.storageName);
    } else {
      visited = this.$cookies.get(this.storageName);
    }

    if (typeof visited === 'string') {
      visited = JSON.parse(visited);
    }
    return !(visited === null || visited === undefined);
  }

  isAccepted(): boolean {
    let accepted: boolean | string | null = 'false';
    if (this.canUseLocalStorage) {
      accepted = localStorage.getItem(this.storageName);
    } else {
      accepted = this.$cookies.get(this.storageName);
    }

    if (typeof accepted === 'string') {
      accepted = JSON.parse(accepted);
    }

    return !!accepted;
  }

  accept(): void {
    this.setVisited();
    this.setAccepted();
    this.isOpen = false;
    this.$emit('accept');
  }
  close(): void {
    this.isOpen = false;
    this.$emit('close');
  }
  decline(): void {
    this.setVisited();
    this.setDeclined();
    this.isOpen = false;
    this.$emit('decline');
  }
  revoke(): void {
    if (this.canUseLocalStorage) {
      localStorage.removeItem(this.storageName);
    } else {
      this.$cookies.remove(this.storageName);
    }
    this.isOpen = true;
    this.$emit('revoke');
  }
  open(): void {
    if (!this.getVisited()) {
      this.isOpen = true;
    }
  }
}
