
import { Component, Prop } from 'vue-property-decorator';

import { Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import { Representations } from '../shared/representation';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import {
  Network,
  CampusSimple,
  Vlan,
  Zone,
  ZoneGroup,
  Device,
  Subnet,
  DHCPServer,
  DNSResolver,
  Organization,
} from '@/api/interfaces';
import { deepClone, deviceIsRoutingCapable } from '@/helpers';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { parse } from 'ipaddr.js';

const userModule = namespace(USER_NAMESPACE_PATH);

function getInitialNetwork(): Network {
  return {
    id: null,
    vlan: null,
    name: '',
    slug: '',
    dhcp_servers: [],
    dns_resolvers: [],
    type: '',
    description: '',
    subnets: [],
    organization: null,
  };
}

@Component
export default class NetworkModal extends Mixins<PromiserType, PersistentModal>(
  PromiserMixin,
  PersistentModal,
) {
  @Prop({ default: null }) private network!: Network | null;
  @Prop() private campusSlug!: string;
  // we need networks to find out vlan.vids of this campus, because zones give
  // you all occupied vids (so including vids that are occupied in related zonegroups)
  @Prop() private networks!: Network[];
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;

  private repr = new Representations();
  private networkClone: Network = getInitialNetwork();
  private campus: CampusSimple | null = null;
  private campusZones: Zone[] | null = null;
  private filteredRoutingDevices: Device[] = [];
  private routingDevices: Device[] = [];
  private enabledRoutingDevices: string[] = [];
  private zones: Zone[] = [];
  private dhcpServers: DHCPServer[] = [];
  private dnsResolvers: DNSResolver[] = [];
  private availableDHCPServers: DHCPServer[] | null = null;
  private availableDNSResolvers: DNSResolver[] | null = null;
  // campusVids is an array of taken vlan.vids (doesn't include vlan.vid of the network that you're editing)
  private campusVids: number[] = [];
  // zoneGroupsVids is an array of taken vlan.vids from all of campus's zonegroups
  // (doesn't include vlan.vid of the network that you're editing)
  private zoneGroupsVids: number[] = [];
  private organization: Organization | null = null;
  private organizations: Organization[] = [];

  private loading = {
    initial: false,
    submit: false,
  };

  private networkTypes = [
    { text: 'Privzeto', value: 'user-default' },
    { text: 'Pedagoško', value: 'user-pedag' },
    { text: 'Pedagoško2', value: 'user-pedag2' },
    { text: 'Administrativno', value: 'user-admin' },
    { text: 'Administrativno2', value: 'user-admin2' },
    { text: 'IT', value: 'user-it' },
    { text: 'eduroam', value: 'user-eduroam' },
    { text: 'VoIP', value: 'user-voip' },
    { text: 'Razno1', value: 'user-other1' },
    { text: 'Razno2', value: 'user-other2' },
    { text: 'Razno3', value: 'user-other3' },
  ];

  private get vlanValidationRules() {
    return {
      numeric: true,
      min_value: 1,
      max_value: 4094,
      oneOf: this.availableVids,
    };
  }

  private get availableVids() {
    const allVids = [...Array(4094).keys()].map((v) => 1 + v);
    if (this.campusZones === null) {
      return allVids;
    }
    // available vlan ID's depend on the zones that you select for the network,
    // if you select only lanonly zones then vids which are occupied through
    // zonegroups can still be picked for this network
    let usedVids = this.campusVids;
    if (this.zones.filter((zone) => !zone.lanonly).length > 0) {
      // user picked at least one global zone for this network, which means used
      // vlan vids must include vids from campus's zonegroups
      usedVids = this.campusVids.concat(this.zoneGroupsVids);
    }
    return allVids.filter((vid) => !usedVids.includes(vid));
  }

  private submit(): void {
    // add prefixlen to gateways, but clone subnets because otherwise
    // you change the actual subnets which are bounded by inputs through v-model
    // and you then change what's shown in the form
    const clonedSubnets = deepClone(this.networkClone.subnets);
    for (const subnet of clonedSubnets) {
      if (subnet.gateway) {
        if (parse(subnet.gateway).kind() === 'ipv4') {
          subnet.gateway = `${subnet.gateway}/32`;
        } else {
          subnet.gateway = `${subnet.gateway}/128`;
        }
      }
    }
    this.loading.submit = true;
    const create = this.networkClone.id ? false : true;
    let api;
    let networkCloneData: any = {
      ...this.networkClone,
    };
    delete networkCloneData['dhcp_servers'];
    delete networkCloneData['dns_resolvers'];
    const routingDevicesData = this.routingDevices.map((d) => ({
      device: d,
      l3_enabled: this.enabledRoutingDevices.includes(d.name),
    }));
    if (create) {
      delete networkCloneData['id'];
      api = repositories.lan.network.createNetwork(
        this.campusSlug,
        { ...networkCloneData, subnets: clonedSubnets },
        this.zones,
        this.dhcpServers.map((dhcpServer: DHCPServer) => dhcpServer.id!),
        this.dnsResolvers.map((dnsResolver: DNSResolver) => dnsResolver.id!),
        routingDevicesData,
        this.organization,
      );
    } else {
      api = repositories.lan.network.updateNetwork(
        this.campusSlug,
        { ...networkCloneData, subnets: clonedSubnets },
        this.zones,
        this.dhcpServers.map((dhcpServer: DHCPServer) => dhcpServer.id!),
        this.dnsResolvers.map((dnsResolver: DNSResolver) => dnsResolver.id!),
        routingDevicesData,
        this.organization,
      );
    }

    api
      .then((res: any) => {
        // fetch tasks state so that it immediatelly updates the badges
        this.fetchTasksStateAction();
        this.ok(res.data);
      })
      .catch((error: any) => {
        let message;
        try {
          const detail = error.response.data.detail;
          if (detail) {
            message = detail;
          }
        } catch {
          const jobtext = create ? 'kreiranju' : 'posodabljanju';
          message = `Neznana napaka pri ${jobtext} omrežja`;
        }
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
        this.loading.submit = false;
      });
  }

  private async created() {
    this.loading.initial = true;
    try {
      await Promise.all([
        this.fetchCampus(),
        this.fetchZones(),
        this.fetchAvailableDHCPServers(),
        this.fetchAvailableDNSResolvers(),
      ]);
      if (this.network != null) {
        // transfer network's data on networkClone
        this.networkClone = {
          ...this.networkClone,
          id: this.network.id,
          vlan: (this.network.vlan as Vlan).vid,
          name: this.network.name,
          slug: this.network.slug,
          type: this.network.type,
          description: this.network.description,
          organization: this.network.organization,
        };
        // put the right subnet info
        for (const subnet of this.network.subnets) {
          const cloneSubnet: Subnet = {
            cidr: subnet.cidr,
            gateway: null,
            ensure_gateway: false,
          };
          if (subnet.gateway !== null) {
            cloneSubnet.ensure_gateway = true;
            cloneSubnet.gateway = subnet.gateway;
          }
          this.networkClone.subnets.push(cloneSubnet);
        }
        const vlanZonesIDs = (this.network.vlan as Vlan).zones!.map(
          (zone) => zone.id,
        );
        this.zones = this.campusZones!.filter((zone) =>
          vlanZonesIDs.includes(zone.id),
        );
        this.dhcpServers = deepClone(this.network.dhcp_servers as DHCPServer[]);
        this.dnsResolvers = deepClone(
          this.network.dns_resolvers as DNSResolver[],
        );
        // @change on zones select does not get triggered, so we need to call this manually
        this.filterRoutingDevices();
        const l3_routing_interfaces = this.network.gateway_interfaces!.filter(
          (intf) => {
            return (
              ['l3_switch', 'router'].includes(intf.device.function) &&
              intf.device.roles.some((role) => ['tsp', 'cpe'].includes(role))
            );
          },
        );
        this.routingDevices = l3_routing_interfaces.map(
          (intf: any) => intf.device,
        );
        this.enabledRoutingDevices = l3_routing_interfaces
          .filter((intf: any) => intf.enabled)
          .map((intf: any) => intf.device.name);
        this.organization = this.network.organization;
      }

      // set campusVids and zoneGroupsVids
      const create = this.networkClone.id ? false : true;
      this.zoneGroupsVids = this.campusZones!.flatMap((zone: Zone) =>
        (zone.zone_groups as ZoneGroup[]).flatMap(
          (zg: ZoneGroup) => zg.occupied_vlan_vids as number[],
        ),
      );
      this.campusVids = this.networks.map(
        (network) => (network.vlan as Vlan).vid,
      );
      // add management network vid to the list of taken campus vids
      if (this.campus!.management_network != null) {
        this.campusVids = this.campusVids.concat([
          this.campus!.management_network!.vlan.vid,
        ]);
      }
      if (!create) {
        const originalVid = (this.network!.vlan as Vlan).vid;
        const originalZonesIncludedGlobalZone = this.zones.some(
          (zone) => !zone.lanonly,
        );
        // remove originalVid from campusVids since it's coming from the edited network.vlan
        this.campusVids = this.campusVids.filter((vid) => vid != originalVid);
        if (originalZonesIncludedGlobalZone) {
          // exclude originalVid from zoneGroupsVids since it's coming from the edited network.vlan
          this.zoneGroupsVids = this.zoneGroupsVids.filter(
            (vid) => vid != originalVid,
          );
        }
      }
      this.loading.initial = false;
    } catch (error) {
      this.loading.initial = false;
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'omrežju' },
        ).toString(),
      );
    }
  }

  private async fetchCampus() {
    try {
      const { data } = await repositories.tenants.campus.getCampus(
        this.campusSlug,
      );
      this.campus = data;
      const organizations = [...(data.organizations as Organization[])];
      for (const org of data.organizations!) {
        if (org.parent !== null) {
          organizations.push(org.parent as Organization);
        }
      }
      this.organizations = organizations;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
      throw error;
    }
  }

  private async fetchAvailableDHCPServers() {
    try {
      const { data } = await repositories.lan.dhcpServer.getDHCPServers(
        this.campusSlug,
        true,
      );
      this.availableDHCPServers = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'DHCP strežnikih' },
        ).toString(),
      );
      throw error;
    }
  }

  private async fetchAvailableDNSResolvers() {
    try {
      const { data } = await repositories.lan.dnsResolver.getDNSResolvers(
        this.campusSlug,
        true,
      );
      this.availableDNSResolvers = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'DNS strežnikih' },
        ).toString(),
      );
      throw error;
    }
  }

  private filterRoutingDevices() {
    if (this.campusZones === null) {
      return [];
    }
    const routingDevices: Device[] = [];
    const zones = this.zones;
    for (const zone of zones) {
      for (const device of zone.devices!) {
        if (!routingDevices.some((d) => d.id === device.id)) {
          routingDevices.push(device);
        }
      }
    }
    // convert a set to a list so that you can use filter
    const routingDevicesInAllZones = [...routingDevices].filter((device) =>
      zones.every((zone) => zone.devices!.some((d) => d.id === device.id)),
    );
    // filter by routing capability
    this.filteredRoutingDevices = routingDevicesInAllZones.filter(
      deviceIsRoutingCapable,
    );
    // remove routingDevice if it's not in all of the new zones because otherwise it won't show it,
    // but if you reselect the same it will already be picked
    this.routingDevices = this.routingDevices.filter((d) =>
      this.filteredRoutingDevices.some((device) => device.id === d.id),
    );
  }

  private updateEnabledRoutingDevices() {
    const routingDeviceNames = this.routingDevices.map((d) => d.name);
    this.enabledRoutingDevices = this.enabledRoutingDevices.filter(
      (deviceName) => routingDeviceNames.includes(deviceName),
    );
  }

  private async fetchZones() {
    try {
      const { data } = await repositories.zoning.zone.getZones(this.campusSlug);
      this.campusZones = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'zonah' },
        ).toString(),
      );
      throw error;
    }
  }

  private addSubnet() {
    this.networkClone.subnets.push({
      cidr: '',
      ensure_gateway: null,
      gateway: '',
    });
  }

  private getDHCPRepr(dhcpServer: DHCPServer): string {
    let dhcpRepr = this.repr.dhcpServerRepr(dhcpServer).text;
    dhcpRepr += dhcpServer.campus ? ' [lokalni]' : ' [globalni]';
    return dhcpRepr;
  }

  private getDNSRepr(dnsResolver: DNSResolver): string {
    let dnsRepr = this.repr.dnsResolverRepr(dnsResolver).text;
    dnsRepr += dnsResolver.campus ? ' [lokalni]' : ' [globalni]';
    return dnsRepr;
  }
}
