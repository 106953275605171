
import { Prop, Component, Vue } from 'vue-property-decorator';
import { Interface } from '@/api/interfaces';

@Component
export default class SwitchActiveInterface extends Vue {
  @Prop({ default: [] }) private interfacesOnPort!: Interface[];
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;

  private selectedInterface: Interface | undefined = undefined;
  private show = false;

  private showTooltip() {
    this.show = true;
  }

  private hideTooltip() {
    this.show = false;
  }

  private switchActiveInterface() {
    this.$emit('switchActiveInterface', this.selectedInterface);
  }

  private created() {
    if (this.interfacesOnPort) {
      this.selectedInterface = this.interfacesOnPort.find(
        (iface) => iface.port_active,
      );
    }
  }
}
