
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { APP_NAMESPACE_PATH } from '@/store/namespaces.type';
import { GET_APPBAR_ITEMS } from '@/store/getters.type';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { Device, RouteItem } from '@/api/interfaces';

const appModule = namespace(APP_NAMESPACE_PATH);

@Component
export default class Dashboard extends Vue {
  @appModule.Getter(GET_APPBAR_ITEMS) public appBarItems!: RouteItem[];
  private version = process.env.VUE_APP_VERSION;
  private loading = false;
  private maintainedDevices: Device[] = [];

  public created() {
    this.fetchDevicesInMaintenance();
  }

  private async fetchDevicesInMaintenance() {
    this.loading = true;
    try {
      const { data } = await repositories.infrastructure.device.getDevices({
        _status: 'maintenance',
      });
      this.maintainedDevices = data.results;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading = false;
  }

  public performRouting(item: RouteItem) {
    if (item.routeName) {
      // catch error so that if you click on the route which is he same as the
      // current one you don't get unhandled promise exception error
      this.$router
        .push({ name: item.routeName, params: item.params })
        .catch((err) => undefined);
    } else {
      const win = window.open(item.externalUrl, '_blank');
      win?.focus();
    }
  }
}
