import { render, staticRenderFns } from "./VlanSelectItem.vue?vue&type=template&id=7090590c&scoped=true&"
import script from "./VlanSelectItem.vue?vue&type=script&lang=ts&"
export * from "./VlanSelectItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7090590c",
  null
  
)

export default component.exports