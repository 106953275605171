
import { Network, Subnet } from '@/api/interfaces';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { toNetmask, ipAddressPre } from '@/helpers';

interface SubnetWithSort extends Subnet {
  paddedCIDR: string | number;
  mask: string;
}

@Component
export default class ManagementSubnets extends Vue {
  @Prop() private network!: Network;
  @Prop({ default: true }) private loading!: boolean;
  private sortBy = 'paddedCIDR';
  private sortDesc = false;
  private headers: DataTableHeader[] = [
    {
      text: 'IP naslov podomrežja',
      align: 'start',
      sortable: true,
      sort: (a: string, b: string) => {
        return a < b ? -1 : a > b ? 1 : 0;
      },
      value: 'paddedCIDR',
    },
    { text: 'Maska', value: 'mask', sortable: true },
    { text: 'Prehod', value: 'gateway' },
  ];
  private skeletonAttrs = {
    class: 'mb-6',
    boilerplate: true,
    elevation: 2,
  };

  private get subnets(): SubnetWithSort[] {
    if (this.network) {
      return this.network.subnets.map((subnet: Subnet) =>
        Object.assign(subnet, {
          paddedCIDR: ipAddressPre(subnet.cidr),
          mask: toNetmask(subnet.cidr, subnet.version ? subnet.version : 6),
        }),
      );
    } else {
      return [];
    }
  }
}
