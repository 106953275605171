
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  DeviceSvg,
  PortGroupSvg,
  PortGroupDrawConf,
  PortDrawConf,
  PortGroupDto,
  PortDto,
  LabelDrawConf,
  SkeletonPortDrawConf,
  ShapeDrawConf,
} from 'draw-network-devices';
import {
  Interface,
  DeviceInfo,
  PortGroup,
  InterfaceStateFact,
  PGInterface,
} from '@/api/interfaces';
import { getPortType, getColorForVlanType } from './helpers';
import { deepClone } from '@/helpers';
import _get from 'lodash.get';
import isEqual from 'lodash.isequal';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';

const userModule = namespace(USER_NAMESPACE_PATH);

type PortClickedEvent = Event & {
  detail: { portId: string; selected: boolean };
};

type SelectedPortsChanged = Event & {
  detail: { selectedPorts: string[] };
};

@Component
export default class DrawDevice extends Vue {
  @Prop(Object) private deviceInfo!: DeviceInfo;
  @Prop(Object) private interfacesStateFacts!: {
    [key: string]: InterfaceStateFact;
  };
  @Prop({ default: () => [] }) private selectedPorts!: number[];
  @Prop(Boolean) private multiple!: boolean;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;

  private drawing: DeviceSvg | null = null;
  private offsetTop = 0;
  private selectedPortsOnDrawing: number[] = [];

  public redraw(): void {
    this.clearImage();
    this.initDrawing();
    this.updateSelectedPorts();
  }

  @Watch('deviceInfo', { deep: true })
  private redrawOnDeviceInfoChange(oldVal: any, newVal: any): void {
    if (!isEqual(oldVal, newVal)) {
      this.redraw();
    }
  }
  @Watch('multiple')
  private redrawOnEdit(oldVal: any, newVal: any): void {
    if (!isEqual(oldVal, newVal)) {
      this.redraw();
    }
  }
  @Watch('interfacesStateFacts', { deep: true })
  private redrawOnFactsChange(oldVal: any, newVal: any): void {
    if (!isEqual(oldVal, newVal)) {
      this.redraw();
    }
  }
  private emitInputEvent(event: any): void {
    this.$emit('input', event.detail.portId);
  }

  private emitSelectedPortEvent(selectedPort: number): void {
    this.$emit('selectedPort', selectedPort);
  }

  private emitSelectedPortsEvent(selectedPorts: number[]): void {
    this.$emit('selectedPorts', selectedPorts);
  }

  @Watch('selectedPorts')
  private updateSelectedPorts(): void {
    if (!isEqual(this.selectedPortsOnDrawing, this.selectedPorts)) {
      if (this.drawing) {
        this.drawing.selectPorts(this.selectedPorts.map(String));
      }
    }
  }

  private initDrawing(): void {
    if (this.deviceInfo) {
      this.drawing = new DeviceSvg({
        name: _get(this.deviceInfo, 'asset.product.info.name', ''),
        selector: '#drawing',
        size: _get(this.deviceInfo, 'asset.product.info.size', {
          x: 800,
          y: 110,
        }),
        radius: _get(this.deviceInfo, 'asset.product.info.radius'),
        scale: 1.7,
        backgroundColor: _get(
          this.deviceInfo,
          'asset.product.info.background_color',
          '#aaa',
        ),
        portGroups: [],
        onlyOneSelected: !this.multiple,
      });
      this.draw(this.drawing);
    }
  }

  private getInterfaceForName(name: string, interfaces: Interface[]) {
    return interfaces.find((x: Interface) => x.name === name);
  }

  private disabledForRoid(iface: Interface) {
    return iface.usage === 'infrastructure' && !this.isAdmin;
  }

  private getColorForInterface(iface: Interface): {
    primary: string;
    trim?: string;
  } {
    const color: { primary: string; trim?: string } = {
      primary: '#fff',
    };

    if (iface.circuit) {
      // If interface has circuit it means this port is used as WAN
      color.primary = getColorForVlanType('wan', true);
    } else if (iface.usage === 'ztp' || iface.usage === 'infrastructure') {
      color.primary = getColorForVlanType(iface.usage, true);
    } else {
      if (iface.switchports) {
        const trunkColor = getColorForVlanType('tagged', true);
        const untaggedSwitchport = iface.switchports.find(
          (sw) => sw.tagged === false,
        );
        if (
          untaggedSwitchport &&
          untaggedSwitchport.vlan &&
          untaggedSwitchport.vlan.network
        ) {
          const vlanColor = getColorForVlanType(
            untaggedSwitchport.vlan.network.type,
            true,
          );
          if (iface.switchport_mode === 'trunk') {
            color.trim = vlanColor;
            color.primary = trunkColor;
          } else {
            color.primary = vlanColor;
          }
        } else {
          color.primary = trunkColor;
        }
      }
    }
    return color;
  }

  private checkIfSelected(id: number): boolean {
    return this.multiple ? this.selectedPorts.includes(id) : false;
  }

  private addPortGroup(id: number, ports: Interface[], pg: PortGroup): void {
    if (!this.drawing) {
      throw Error('No drawing created');
    }

    const portGroupDto: PortGroupDto = {
      numberOfPorts: pg.interfaces.length,
      data: {},
    };

    const portGroupDrawConf: PortGroupDrawConf = {
      rotated: pg.rotated,
      twoLines: pg.two_lines,
      mixedCount: pg.mixed_count,
      margin: pg.margin,
      startingPoint: { x: pg.starting_point.x, y: pg.starting_point.y },
      interfaces: [],
      paper: this.drawing.getPaperGroup(),
    };

    const portGroup = this.drawing.createAddPortGroup(
      id,
      portGroupDto,
      portGroupDrawConf,
    );

    // Add ports to port group
    pg.interfaces.forEach((portIface) => {
      const portData = this.getPortDtoInfo(portIface);
      if (portData) {
        const portDrawConf = this.getPortDrawInfo(
          portData.id,
          portGroup,
          portIface,
        );

        if (
          (portData.port === null || (portData.port && portData.portActive)) &&
          !portDrawConf.hidden // TODO: remove when draw-network-devices will support.
        ) {
          portGroup.addPort(portData, portDrawConf, this.multiple);
        }
      }
    });
  }

  private getPortDtoInfo(port: PGInterface): PortDto | null {
    const iface = this.getInterfaceForName(
      port.name,
      this.deviceInfo.interfaces,
    );
    if (iface) {
      const { primary, trim } = this.getColorForInterface(iface);

      const oprState = _get(this.interfacesStateFacts, `${iface.name}`);

      let statusColor;
      if (oprState != null && oprState.stp) {
        statusColor = oprState.stp.color;
      }

      const portData: PortDto = {
        id: _get(iface, 'id', 9999999),
        vid: 1, // TODO: use vid
        formFactor: getPortType(_get(iface, 'form_factor', 1000)) as
          | 'RJ45'
          | 'SFP'
          | 'QSFP'
          | 'MISC',
        usage: _get(iface, 'usage', ''),
        enabled: _get(iface, 'enabled', false),
        state: oprState && oprState.link ? oprState.link : 'unknown',
        statusColor: statusColor,
        poe: false,
        switchPortMode: _get(iface, 'switchport_mode', 'access'),
        managed: _get(iface, 'managed', true),
        port: _get(iface, 'port.id', null),
        portActive: _get(iface, 'port_active', false),
        trustPolicy: _get(iface, 'trust_policy', ''),
        backgroundColor: primary,
      };
      if (trim) {
        portData['trimColor'] = trim;
      }

      return portData;
    }
    return null;
  }

  private getPortDrawInfo(
    portId: number,
    portGroup: PortGroupSvg,
    port: PGInterface,
    startingPaper = true,
  ) {
    const iface = this.getInterfaceForName(
      port.name,
      this.deviceInfo.interfaces,
    );
    const portDrawConf: PortDrawConf = {
      paper: startingPaper ? portGroup.getPaperGroup() : null,
      shortName: port.short_name,
      name: port.name,
      label: port.label,
      rotated: false, // is set in library
      hidden: port.hidden,
      selected: this.checkIfSelected(portId),
      selectedColor: '#50FF00',
      position: { x: 0, y: 0 },
      disabled: iface ? this.disabledForRoid(iface) : false,
    } as PortDrawConf;

    return portDrawConf;
  }

  private clearImage() {
    const draw = document.querySelector('#drawing');
    const svgimg = document.querySelector('#drawing > svg');
    if (draw && svgimg) {
      draw.removeChild(svgimg);
    }
  }

  private addLabels(): void {
    const labels: LabelDrawConf[] = _get(
      this.deviceInfo,
      'asset.product.info.labels',
      [],
    );
    if (labels) {
      labels.forEach((label: LabelDrawConf) => {
        if (this.drawing) {
          this.drawing.createLabel({
            paper: this.drawing.getPaperGroup(),
            name: label.name,
            hidden: false,
            color: label.color,
            fontSize: label.fontSize,
            fontWeight: label.fontWeight,
            fontFamily: label.fontFamily,
            rotation: label.rotation, // rotation in degrees
            position: label.position,
          });
        }
      });
    }
  }

  private addSkeletonPorts(): void {
    const sPorts: SkeletonPortDrawConf[] = _get(
      this.deviceInfo,
      'asset.product.info.skeletonPorts',
      [],
    );
    if (sPorts) {
      sPorts.forEach((port: SkeletonPortDrawConf) => {
        if (this.drawing) {
          this.drawing.createSkeletonPort({
            paper: this.drawing.getPaperGroup(),
            hidden: port.hidden,
            color: port.color,
            formFactor: port.formFactor,
            rotation: port.rotation, // rotation in degrees
            position: port.position,
          });
        }
      });
    }
  }

  private addShapes(): void {
    const shapes: ShapeDrawConf[] = _get(
      this.deviceInfo,
      'asset.product.info.shapes',
      [],
    );
    if (shapes) {
      shapes.forEach((shape: ShapeDrawConf) => {
        if (this.drawing) {
          this.drawing.createShape(
            // vue has RangeError _traverse problem so object needs to be recreated here
            {
              paper: this.drawing.getPaperGroup(),
              hidden: shape.hidden,
              borderWidth: shape.borderWidth,
              borderColor: shape.borderColor,
              filled: shape.filled,
              fillColor: shape.fillColor,
              rotation: shape.rotation,
              position: shape.position,
              properties: shape.properties,
            },
          );
        }
      });
    }
  }

  private draw(parent: DeviceSvg): void {
    if (!this.drawing) {
      throw Error('Trying to draw before initial');
    }
    this.addShapes();
    this.addSkeletonPorts();
    this.addLabels();
    const portGroups: PortGroup[] = _get(
      this.deviceInfo,
      'asset.product.info.port_groups',
      [],
    );
    if (portGroups) {
      portGroups.forEach((pg: PortGroup, index) => {
        this.addPortGroup(index, this.deviceInfo.interfaces, pg);
      });
    }
    parent.draw();
  }

  private mounted() {
    const draw = document.querySelector('#drawing');
    if (draw) {
      draw.addEventListener('portClickEvent', ((e: PortClickedEvent) => {
        this.emitInputEvent(e);
        this.emitSelectedPortEvent(parseInt(e.detail.portId, 10));
      }) as (e: Event) => void);

      draw.addEventListener('selectedPortsChanged', ((
        e: SelectedPortsChanged,
      ) => {
        this.selectedPortsOnDrawing = e.detail.selectedPorts.map(Number);
        this.emitInputEvent(e);
        this.emitSelectedPortsEvent(deepClone(this.selectedPortsOnDrawing));
      }) as (e: Event) => void);
    }
    this.initDrawing();
  }

  private destroyed() {
    const draw = document.querySelector('#drawing');
    if (draw) {
      draw.removeEventListener('portClickEvent', (e) => {
        return;
      });
      draw.removeEventListener('selectedPortsChanged', (e) => {
        return;
      });
    }
  }
}
