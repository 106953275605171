
import { Component, Prop } from 'vue-property-decorator';
import PersistentModal from '@/components/shared/modals/PersistentModal';

@Component
export default class ConfirmDeleteModal extends PersistentModal {
  @Prop() private data!: any;
  @Prop() private deleteFn!: (modal: any, item: any) => void;
  @Prop({ default: '' }) private warningMessage!: string;
  private deleting = false;

  private deleteItem() {
    this.deleting = true;
    this.deleteFn(this, this.data.item);
  }
}
