
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import { EditorLinkConstructorData } from '@/api/interfaces';
import PersistentModal from '@/components/shared/modals/PersistentModal';

@Component
export default class PickImageModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop({ required: true }) private requireText!: boolean;
  @Prop({ default: '' }) private previousUrl!: string;

  private formData: EditorLinkConstructorData = { url: '' };

  private created() {
    if (this.requireText) {
      this.formData = { url: '', text: '' };
    }
    this.formData.url = this.previousUrl;
  }
}
