import { render, staticRenderFns } from "./DHCPServerDetail.vue?vue&type=template&id=c783e7f4&scoped=true&"
import script from "./DHCPServerDetail.vue?vue&type=script&lang=ts&"
export * from "./DHCPServerDetail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c783e7f4",
  null
  
)

export default component.exports