
import { Component, Prop } from 'vue-property-decorator';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import CircuitForm from './CircuitForm.vue';

@Component({
  components: {
    'app-circuit-form': CircuitForm,
  },
})
export default class EditCircuitModal extends PersistentModal {
  @Prop() private campusSlug!: string;
  @Prop() private circuit!: {
    id: number;
    status: string;
    type: string | null;
    campusDeviceID: number | null;
    campusInterfaceID: number | null;
    campusInterfaceDescription: string | null;
    siteSlug: string | null;
    nodeDeviceID: number | null;
    nodeInterfaceID: number | null;
    nodeInterfaceDescription: string | null;
  };
}
