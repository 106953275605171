
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { Product, DataOptions } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { Representations } from '@/components/shared/representation';
import ErrorHandler from '@/components/shared/errorHandler';
import debounce from 'lodash.debounce';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class ProductList extends Vue {
  @userModule.Getter(IS_ADMIN) public isAdmin!: boolean;
  private repr = new Representations();
  private indeterminate = true;
  private rowsPerPageItems = [50, 200, 500];
  private headers = [
    { text: 'Ime', value: 'name' },
    {
      text: 'Proizvajalec',
      align: 'left',
      value: 'manufacturer',
    },
    { text: 'Tip', value: 'type' },
    { text: 'Upravljan', value: 'managed' },
    { text: 'Zahteva MAC', value: 'requires_mac' },
    { text: 'Platforma', value: 'platform' },
    { text: 'Oznaka proizvajalca', value: 'part_number' },
    { text: 'Opis', value: 'description' },
    { text: 'Komentar', value: 'comment' },
  ];
  private search = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );
  private products: Product[] = [];
  private totalProducts = 0;

  private loading = true;

  private paginationOptions: DataOptions | null = null;

  private routeToProduct(item: Product) {
    this.$router.push({
      name: 'productDetail',
      params: { product: String(item.id) },
    });
  }
  private routeToProductAdd() {
    this.$router.push({ name: 'productAdd' });
  }

  @Watch('paginationOptions', { deep: true })
  private handleOptions() {
    this.fetchProducts(this.paginationOptions);
  }

  private async fetchProducts(pagination: DataOptions | null) {
    this.loading = true;
    try {
      const { data } = await repositories.infrastructure.product.getProducts(
        pagination,
        this.search,
      );
      this.products = data.results;
      this.totalProducts = data.count;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading = false;
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    // after a new search you want to be looking at the first page
    // manually change pagination object so that @update.pagination on data table gets triggered,
    // otherwise it doesn't update number of pages/items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.paginationOptions!.page = 1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.fetchProducts(this.paginationOptions!);
  }
}
