import axios, { AxiosPromise } from 'axios';
import { Zone, PaginatedResponse } from '@/api/interfaces';
import { Query } from '../query';

const resources = { default: 'zoning/zones' };

interface ZoneApiInterface {
  getZones(campusSlug: string): AxiosPromise<PaginatedResponse<Zone>>;
  getZone(id: number): AxiosPromise<Zone>;
  updateZone(id: number, data: { devices: string[] }): AxiosPromise<Zone>;
}

const ZoneApi: ZoneApiInterface = {
  getZones(campusSlug) {
    const query = new Query({ campus__slug: campusSlug });
    return axios.get(`${resources.default}/${query}`);
  },
  getZone(id) {
    return axios.get(`${resources.default}/${id}`);
  },
  updateZone(id, data) {
    return axios.patch(`${resources.default}/${id}/`, data);
  },
};

export default ZoneApi;
export { ZoneApiInterface };
