import { NetboxAsset, PaginatedResponse } from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';

const resources = {
  default: 'infrastructure/assets',
};

interface AssetApiInterface {
  getNetboxAsset(inventory_sys_id: string | number): AxiosPromise<NetboxAsset>;
  syncNetboxAsset(inventory_sys_id: string | number): AxiosPromise<void>;
  searchNetboxAssets({
    search,
    model,
    sn,
    mac,
    kind,
    page,
    itemsPerPage,
    deliveryStatus,
    tenants,
    username,
  }: {
    search?: string;
    model?: string | null;
    sn?: string | null;
    mac?: string | null;
    kind?: string | null;
    page?: number | null;
    itemsPerPage?: number | null;
    deliveryStatus?: string;
    tenants?: string;
    username?: string;
  }): AxiosPromise<PaginatedResponse<NetboxAsset>>;
  setAssetCampus(data: { [key: number]: string }): AxiosPromise<void>;
  disconnectInv(
    assetId: number,
    newInventoryAssetStatus: string,
  ): AxiosPromise<void>;
}

const AssetApi: AssetApiInterface = {
  getNetboxAsset(inventory_sys_id) {
    return axios.get(`${resources.default}/inv/${inventory_sys_id}`);
  },
  syncNetboxAsset(inventory_sys_id) {
    return axios.post(`${resources.default}/refetch/`, {
      inventory_sys_id: inventory_sys_id,
    });
  },
  searchNetboxAssets(options) {
    const query = new Query({});

    if (options.search) {
      query.addParam('search', options.search);
    }
    if (Object.prototype.hasOwnProperty.call(options, 'tenants') && options.tenants) {
      query.addParam('tenants', options.tenants);
    }
    if (
      Object.prototype.hasOwnProperty.call(options, 'model') &&
      options.model
    ) {
      query.addParam('model', options.model);
    }
    if (Object.prototype.hasOwnProperty.call(options, 'sn') && options.sn) {
      query.addParam('sn', options.sn);
    }
    if (Object.prototype.hasOwnProperty.call(options, 'mac') && options.mac) {
      query.addParam('mac', options.mac);
    }
    if (Object.prototype.hasOwnProperty.call(options, 'kind') && options.kind) {
      query.addParam('kind', options.kind);
    }
    if (Object.prototype.hasOwnProperty.call(options, 'deliveryStatus') && options.deliveryStatus) {
      query.addParam('delivery_status', options.deliveryStatus);
    }
    if (Object.prototype.hasOwnProperty.call(options, 'username') && options.username) {
      query.addParam('username', options.username);
    }
    if (
      Object.prototype.hasOwnProperty.call(options, 'itemsPerPage') &&
      options.itemsPerPage
    ) {
      query.addParam('limit', options.itemsPerPage);
      let page = 1;
      if (
        Object.prototype.hasOwnProperty.call(options, 'page') &&
        options.page
      ) {
        page = options.page;
      }
      query.addParam('offset', (page - 1) * options.itemsPerPage);
    }
    return axios.get(`${resources.default}/inv/${query}`);
  },
  setAssetCampus(data) {
    return axios.post(`${resources.default}/set-campus/`, data);
  },
  disconnectInv(assetId, new_inventory_asset_status) {
    return axios.post(`${resources.default}/${assetId}/disconnect-inv/`, {
      new_inventory_asset_status,
    });
  },
};

export default AssetApi;

export { AssetApiInterface };
