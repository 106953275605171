
import { Component, Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { repositories } from '@/api/ApiFactory';
import { ZoneGroup } from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import Autocomplete from '../Autocomplete.vue';
import { Representations } from '../representation';
import { copyTextToClipboard } from '../../../utils/other';
import { ToastOptions } from 'vue-toasted/types';

@Component({
  components: {
    Autocomplete,
  },
})
export default class AssignZoneGroupsModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private campusSlug!: string;
  @Prop() private currentZoneGroupSlugs!: string[];

  private repr = new Representations();

  private loading = {
    initial: true,
    submit: false,
  };

  private zoneGroups: ZoneGroup[] = [];
  private pickedZoneGroups: ZoneGroup[] = [];
  private autoRenumberVlans = false;

  private created() {
    this.fetchZoneGroups();
  }

  private async fetchZoneGroups() {
    this.loading.initial = true;
    try {
      const { data } = await repositories.zoning.zoneGroup.getZoneGroups();
      this.zoneGroups = data.results;
      this.pickedZoneGroups = this.zoneGroups.filter((zoneGroup) =>
        this.currentZoneGroupSlugs.includes(zoneGroup.slug as string),
      );
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.initial = false;
  }

  private async submit() {
    this.loading.submit = true;
    try {
      const { data } = await repositories.tenants.campus.assignZoneGroups(
        this.campusSlug,
        // NOTE: when you clear the autocomplete field, it gets null as value, not []
        this.pickedZoneGroups === null
          ? []
          : this.pickedZoneGroups.map((zoneGroup) => zoneGroup.slug as string),
        this.autoRenumberVlans,
      );
      let toastOptions: ToastOptions = { icon: 'mdi-info' };
      let msg = 'Skupine con so bile uspešno nastavljene.';
      // declare variable here because we use it also after the IF statement
      const renumbers: string[] = [];
      if (data.renumbered_vlans) {
        // when we renumber vlans we want the user to see the renumbering, so we
        // set toast's duration to infinite time - user must swipe it away to close it
        toastOptions.duration = undefined;
        msg +=
          '<br/><br/>Preštevilčeni vlani (format je star --> nov):<br/><br/>';
        for (const [key, value] of Object.entries(data.renumbered_vlans)) {
          renumbers.push(`-  ${key} --> ${value}`);
        }
        msg += renumbers.join('<br/>');
        // add space for toast action button
        msg += '<br/><br/><br/>';
      }
      const toast = this.$toasted.info(msg, toastOptions);
      // create a close button
      let copyBtn = document.createElement('button');
      copyBtn.innerHTML = 'Kopiraj';
      // we set button's style here because i don't like using non-scoped classes
      // and it also doesn't work if you put it in a 'style' object
      copyBtn.style.position = 'absolute';
      copyBtn.style.bottom = '10px';
      copyBtn.style.right = '20px';
      copyBtn.style.color = 'white';
      // add eventListener to copyBtn
      copyBtn.addEventListener('click', () => {
        if (copyTextToClipboard(renumbers.join('\n'))) {
          this.$toasted.success('Preštevilčevanje kopirano.');
        } else {
          this.$toasted.error('Kopiranje preštevilčevanja ni uspelo.');
        }
      });
      // append the copy button to the toast
      if (data.renumbered_vlans) {
        toast.el.appendChild(copyBtn);
      }
      this.ok(this.pickedZoneGroups);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.submit = false;
  }
}
