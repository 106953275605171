
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { Representations } from '@/components/shared/representation';
import { repositories } from '@/api/ApiFactory';
import { Device, CampusSimple, Location } from '@/api/interfaces';
import { deepClone } from '@/helpers';
import Autocomplete from '@/components/shared/Autocomplete.vue';
import ErrorHandler from '@/components/shared/errorHandler';

const userModule = namespace(USER_NAMESPACE_PATH);

interface DeviceMoveForm {
  name: string | null;
  campusSlug: string | null;
  location: number | null;
}

@Component({
  components: {
    Autocomplete,
  },
})
export default class DeviceMoveModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private device!: Device;
  @Prop() private campusSlug!: string;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private repr = new Representations();
  private searchCampusesApi = repositories.tenants.campus.searchCampuses;

  private campus: CampusSimple | null = null;
  private deviceLocation: Location | null = null;

  private locations: Location[] = [];
  private loading = {
    locations: false,
    submit: false,
  };
  private form: DeviceMoveForm = {
    name: null,
    campusSlug: null,
    location: null,
  };

  private getRoomReprText(room: Location) {
    return this.repr.locationRepr(room).text;
  }
  private async created() {
    // populate form with current data
    this.form = {
      name: this.device.name,
      campusSlug: null,
      location: null,
    };
    // need to fetch rooms so that we can properly represent current device's location
    const { data } =
      await repositories.tenants.location.getCampusLocationsWithRooms(
        this.campusSlug,
      );
    const rooms = [];
    for (const building of data.results) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      for (const room of building.children!) {
        room.parent = building;
        rooms.push(room);
      }
    }
    // set device's parent so that we can repr the room
    this.deviceLocation = {
      ...deepClone(this.device.location),
      parent: data.results.find((el) => el.id === this.device.location?.parent),
    };
  }

  @Watch('campus')
  private async onCampusChange() {
    if (this.campus == null) {
      this.locations = [];
      this.form.campusSlug = null;
      return;
    }
    this.form.campusSlug = this.campus.slug;
    this.loading.locations = true;
    try {
      const { data } =
        await repositories.tenants.location.getCampusLocationsWithRooms(
          this.campus.slug,
        );
      const rooms = [];
      for (const building of data.results) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        for (const room of building.children!) {
          room.parent = building;
          rooms.push(room);
        }
      }
      this.locations = rooms;
      if (this.deviceLocation == null) {
        // set device's parent so that we can repr the room
        this.deviceLocation = {
          ...deepClone(this.device.location),
          parent: data.results.find(
            (el) => el.id === this.device.location?.parent,
          ),
        };
      }
    } catch (error) {
      this.$toasted.error('Lokacij za izbran kampus ni bilo mogoče pridobiti.');
    }
    this.loading.locations = false;
  }

  private async submit() {
    this.loading.submit = true;
    try {
      const { data } = await repositories.provision.device.moveDevice(
        this.device.id,
        this.form.name as string,
        this.form.campusSlug as string,
        this.form.location as number,
      );
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
      this.ok({
        device: data,
        changedCampus: this.campusSlug !== this.form.campusSlug,
      });
    } catch (error: unknown) {
      const message = new ErrorHandler({ error, status: true }).toString();
      this.$toasted.error(message);
    }
    this.loading.submit = false;
  }
}
