
import { Component, Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { repositories } from '@/api/ApiFactory';
import { CampusManagement, Network } from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import Autocomplete from '../Autocomplete.vue';
import { Representations } from '../representation';
import { Query } from '@/api/query';

@Component({
  components: {
    Autocomplete,
  },
})
export default class AssignCampusManagementNetworkModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private campusSlug!: string;
  @Prop() private campusManagement!: CampusManagement | null;

  private repr = new Representations();

  private loading = {
    initial: false,
    submit: false,
  };

  private managementNetwork: Network | null = null;

  private managementNetworksSearch = async (search: string) => {
    return await repositories.lan.network.getNetworks(
      new Query({ type: 'infrastructure' }),
      null,
      search,
      ['device_gateway', 'name', 'slug'],
    );
  };

  private showTypeConfirmModal(): Promise<boolean> {
    return this.$modals.open('app-type-confirm-modal', {
      component: {
        props: {
          title: 'Potrdi odstranitev management omrežja iz kampus managementa',
          // vuex-modals currently doesn't support slots, so we pass html here
          text: `Izbrana akcija bo sprožila odstranitev management VLANA in izbris L3 vmesnikov iz kampus naprav. 
            <br> Iz management omrežja bo odstranjeno kampus management podomrežje in prehod.`,
          requiredConfirmText: 'potrjujem',
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    }) as Promise<boolean>;
  }

  private async submit() {
    this.loading.submit = true;
    const managementNetworkId = this.managementNetwork?.id || null;

    if (managementNetworkId === null) {
      const proceed = await this.showTypeConfirmModal();
      if (!proceed) {
        this.loading.submit = false;
        return;
      }
    }

    try {
      const { data } =
        await repositories.tenants.campus.assignCampusManagementNetwork(
          this.campusSlug,
          // NOTE: when you clear the autocomplete field, it gets null as value, not []
          managementNetworkId,
        );
      if (data.detail !== '') {
        // If devices need to bo manually configured we need to show this message
        this.$toasted.info(data.detail, {
          duration: 30000,
        });
      }
      this.ok(this.managementNetwork);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.submit = false;
  }

  private async fetchNetwork() {
    if (this.campusManagement && this.campusManagement.subnet.network) {
      this.loading.initial = true;
      let { data } = await repositories.lan.network
        .getNetwork(this.campusManagement.subnet.network.id)
        .finally(() => (this.loading.initial = false));
      if (data) {
        this.managementNetwork = data;
      }
    }
  }

  created(): void {
    this.fetchNetwork();
  }
}
