
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, IS_ROID } from '@/store/getters.type';

import { Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import Autocomplete from '../shared/Autocomplete.vue';
import { Representations } from '../shared/representation';
import {
  Network,
  NetworkSimple,
  CampusSimple,
  DNSResolver,
} from '@/api/interfaces';
import { deepClone } from '@/helpers';
import { repositories } from '@/api/ApiFactory';
import { Query } from '@/api/query';
import ErrorHandler from '@/components/shared/errorHandler';

const userModule = namespace(USER_NAMESPACE_PATH);

type NetworkShrinked = Pick<Network, 'id' | 'name' | 'slug'>;

interface NetworkChanges {
  add: NetworkShrinked[];
  remove: NetworkShrinked[];
}

function getInitialDNSResolver(): DNSResolver {
  return {
    id: null,
    name: '',
    address: '',
    campus: null,
    networks: [],
  };
}

@Component({
  components: {
    Autocomplete,
  },
})
export default class DNSResolverModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_ROID) private isRoid!: boolean;
  @Prop({ default: null }) private campusSlug!: string | null;
  @Prop({ default: null }) private dnsResolver!: DNSResolver | null;
  @Prop({ default: null }) private fixedNetworkCampusSlug!: string | null;
  @Prop({ default: false }) private canChangeCampus!: boolean;

  private repr = new Representations();
  private dnsResolverClone: DNSResolver = getInitialDNSResolver();

  private loading = {
    initial: false,
    submit: false,
    networks: false,
  };

  private campus: CampusSimple | null = null;
  private networkCampus: CampusSimple | null = null;
  private checkedNetworks: NetworkShrinked[] = [];
  private campusNetworks: NetworkShrinked[] = [];
  private campusSearchApi = repositories.tenants.campus.searchCampuses;
  private networksChanges: { [key: string]: NetworkChanges } = {};
  private sumOfChanges: NetworkChanges = { add: [], remove: [] };

  private onCampusSelected() {
    this.networkCampus = this.campus;
    // reset networksChanges otherwise state of changes can be weird
    this.networksChanges = {};
    this.updateSumOfChanges();
    // manually fetch networks for the new campus because @input on our autocomplete doesn't get triggered
    this.getNetworksForCampus();
  }

  private get originallyCheckedNetworks(): NetworkShrinked[] {
    return (this.dnsResolverClone.networks as NetworkSimple[])
      .filter(
        (network: NetworkSimple) =>
          network.campus.id === this.networkCampus!.id,
      )
      .map((network: NetworkSimple) => {
        const clone: any = deepClone(network);
        delete clone.campus;
        return clone as NetworkShrinked;
      });
  }

  private getNetworksChangesByCampus(campusSlug: string) {
    if (
      Object.prototype.hasOwnProperty.call(this.networksChanges, campusSlug)
    ) {
      return this.networksChanges[campusSlug];
    }
    return {
      add: [] as NetworkShrinked[],
      remove: [] as NetworkShrinked[],
    };
  }

  private updateSumOfChanges() {
    const changes: NetworkChanges[] = Object.values(this.networksChanges);
    this.sumOfChanges = {
      add: changes.flatMap(
        (networkChange: NetworkChanges) => networkChange.add,
      ),
      remove: changes.flatMap(
        (networkChange: NetworkChanges) => networkChange.remove,
      ),
    };
  }

  private updateCheckedNetworks() {
    if (this.networkCampus === null) {
      this.checkedNetworks = [];
      return;
    }
    const networksChanges = this.getNetworksChangesByCampus(
      this.networkCampus!.slug,
    );
    this.checkedNetworks = [
      ...this.originallyCheckedNetworks.filter(
        (network: NetworkShrinked) =>
          !networksChanges.remove.some(
            (innerNetwork: NetworkShrinked) => innerNetwork.id === network.id,
          ),
      ),
      ...networksChanges.add,
    ];
  }

  private applyNetworkChanges() {
    const addToAdd = this.checkedNetworks.filter(
      (network: NetworkShrinked) =>
        !this.originallyCheckedNetworks.some(
          (innerNetwork: NetworkShrinked) => innerNetwork.id === network.id,
        ),
    );
    const removeFromAdd = this.campusNetworks.filter(
      (network: NetworkShrinked) =>
        !this.checkedNetworks.some(
          (innerNetwork: NetworkShrinked) => innerNetwork.id === network.id,
        ),
    );
    const addToRemove = this.originallyCheckedNetworks.filter(
      (network: NetworkShrinked) =>
        !this.checkedNetworks.some(
          (innerNetwork: NetworkShrinked) => innerNetwork.id === network.id,
        ),
    );
    const removeFromRemove = this.checkedNetworks;

    const campusNetworksChanges = this.getNetworksChangesByCampus(
      this.networkCampus!.slug,
    );
    const newAdd = Array.from(
      new Set([...campusNetworksChanges.add, ...addToAdd]),
    );
    campusNetworksChanges.add = newAdd.filter(
      (network: NetworkShrinked) =>
        !removeFromAdd.some(
          (innerNetwork: NetworkShrinked) => innerNetwork.id === network.id,
        ),
    );
    const newRemove = Array.from(
      new Set([...campusNetworksChanges.remove, ...addToRemove]),
    );
    campusNetworksChanges.remove = newRemove.filter(
      (network: NetworkShrinked) =>
        !removeFromRemove.some(
          (innerNetwork: NetworkShrinked) => innerNetwork.id === network.id,
        ),
    );
    // remove campus slug key from network changes if it was reverted to no changes
    if (
      campusNetworksChanges.add.length === 0 &&
      campusNetworksChanges.remove.length === 0
    ) {
      delete this.networksChanges[this.networkCampus!.slug];
      return;
    }
    this.networksChanges[this.networkCampus!.slug] = campusNetworksChanges;
    this.updateSumOfChanges();
  }

  private async getNetworksForCampus() {
    if (this.networkCampus === null) {
      this.campusNetworks = [];
      return;
    }
    this.loading.networks = true;
    const { data } = await repositories.lan.network.getNetworks(
      new Query({ campus_slug: this.networkCampus.slug }),
    );
    // convert from Network to NetworkShrinked
    const shrinkedNetworks = [];
    for (const network of data.results) {
      shrinkedNetworks.push({
        id: network.id,
        name: network.name,
        slug: network.slug,
      });
    }
    this.campusNetworks = shrinkedNetworks;
    this.updateCheckedNetworks();
    this.loading.networks = false;
  }

  private submit(): void {
    this.loading.submit = true;
    const networks = {
      add: this.sumOfChanges.add.map((network: NetworkShrinked) => network.id!),
      remove: this.sumOfChanges.remove.map(
        (network: NetworkShrinked) => network.id!,
      ),
    };
    const create = this.dnsResolverClone.id ? false : true;
    let api;
    if (create) {
      api = repositories.lan.dnsResolver.createDNSResolver({
        campusSlug: this.campus ? this.campus.slug : null,
        name: this.dnsResolverClone.name,
        address: this.dnsResolverClone.address,
        networks: networks.add,
      });
    } else {
      let data: any = {
        campusSlug: this.campus ? this.campus.slug : null,
        name: this.dnsResolverClone.name,
        address: this.dnsResolverClone.address,
        networks,
      };
      if (!this.isAdmin) {
        data = { networks };
      }
      api = repositories.lan.dnsResolver.updateDNSResolver(
        this.dnsResolverClone.id as number,
        data,
        this.$route.params.campusSlug || null,
      );
    }
    api
      .then((res: any) => {
        this.ok(res.data);
      })
      .catch((error: any) => {
        const jobtext = create ? 'kreiranju' : 'posodabljanju';
        this.$toasted.error(
          new ErrorHandler({ error, status: true }, {}).toString(),
        );
        this.loading.submit = false;
      });
  }

  private async created() {
    this.loading.initial = true;
    if (this.dnsResolver != null) {
      // fetch dns resolver to get all of its networks
      try {
        const { data } = await repositories.lan.dnsResolver.getDNSResolver(
          this.dnsResolver.id!,
        );
        // remove management networks because we can't match with campuses + we don't need to fix their dns relations
        data.networks = (data.networks as NetworkSimple[]).filter(
          (network: NetworkSimple) => network.campus !== null,
        );
        this.dnsResolverClone = data;
      } catch (error) {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { itemMessageText: 'dns strežniku' },
          ).toString(),
        );
        this.loading.initial = false;
        return;
      }
    }
    try {
      if (this.campusSlug) {
        await this.fetchCampus(this.campusSlug);
      } else if (this.fixedNetworkCampusSlug) {
        await this.fetchNetworkCampus(this.fixedNetworkCampusSlug);
      }
      this.loading.initial = false;
    } catch (error) {
      this.$toasted.error('Neznana napaka');
      this.loading.initial = false;
    }
  }

  private async fetchCampus(campusSlug: string) {
    try {
      const { data } = await repositories.tenants.campus.getCampus(campusSlug);
      if (data) {
        this.campus = data;
        this.onCampusSelected();
      }
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
    }
  }

  private async fetchNetworkCampus(campusSlug: string) {
    try {
      const { data } = await repositories.tenants.campus.getCampus(campusSlug);
      this.networkCampus = data;
      this.getNetworksForCampus();
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
    }
  }
}
