
import axios from 'axios';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AuthType, BadgeStates } from '@/store/types';
import {
  AUTH_NAMESPACE_PATH,
  USER_NAMESPACE_PATH,
  APP_NAMESPACE_PATH,
} from '@/store/namespaces.type';
import { STATUS, AUTH_TYPE, CSRF_TOKEN } from '@/store/states.type';
import {
  IS_LOGGED_IN,
  FULL_NAME,
  USER_SETTINGS,
  USER,
  ROLES,
  BADGE_STATES,
  GET_NAVBAR_ITEMS,
  GET_APPBAR_ITEMS,
  GET_DRAWER,
  BADGE_TIMEOUT_REF,
  LATEST_USER_TRIGGERED_TASK_TIME,
  SETTINGS_DARK_THEME,
  BADGE_STATES_LAST_CHANGED,
} from '@/store/getters.type';
import { RouteItem, User } from '@/api/interfaces';
import {
  LOGOUT,
  CLEAR_AUTH_STATE,
  CLEAR_APP_STATE,
  SET_DRAWER,
  FETCH_TASKS_STATE,
  FETCH_USER_INFO,
} from '@/store/actions.type';
import { BadgeStatesComponent } from '@/components/shared/BadgeStates';
import { isString } from '@/helpers';
import Login from '@/views/Login.vue';
import CookiesConsent from '@/components/cookies/CookiesConsent.vue';
import * as Sentry from '@sentry/vue';

import { LogoutOptions } from '@/store/types';
import './main.css';

const authModule = namespace(AUTH_NAMESPACE_PATH);
const userModule = namespace(USER_NAMESPACE_PATH);
const appModule = namespace(APP_NAMESPACE_PATH);

@Component({
  components: { Login, 'badge-states': BadgeStatesComponent, CookiesConsent },
})
export default class App extends Vue {
  @authModule.State(STATUS) public authStatus!: string;
  @authModule.State(AUTH_TYPE) public authType!: AuthType;
  @authModule.State(CSRF_TOKEN) public csrf!: string;
  @authModule.Getter(IS_LOGGED_IN) public isLoggedIn!: boolean;
  @userModule.Getter(USER_SETTINGS) public userSettings!: string;
  @userModule.Getter(ROLES) public roles!: string[];
  @userModule.Getter(FULL_NAME) public fullName!: string;
  @userModule.Getter(USER) public user!: User;
  @userModule.Getter(BADGE_STATES) public badgeStates!: BadgeStates;
  @userModule.Getter(LATEST_USER_TRIGGERED_TASK_TIME)
  public latestUserTriggeredTaskTime!: string | null;
  @userModule.Getter(BADGE_STATES_LAST_CHANGED) public badgeStatesLastChanged!:
    | number
    | null;
  @userModule.Getter(BADGE_TIMEOUT_REF) public badgeTimeoutRef!: number | null;
  @userModule.Getter(SETTINGS_DARK_THEME) public isDark!: boolean;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  @userModule.Action(FETCH_USER_INFO) public fetchUserInfo!: () => Promise<any>;
  @authModule.Action(LOGOUT) public logoutAction!: (
    options?: LogoutOptions,
  ) => Promise<any>;
  @appModule.Getter(GET_NAVBAR_ITEMS) public navBarItems!: RouteItem[];
  @appModule.Getter(GET_APPBAR_ITEMS) public appBarItems!: RouteItem[];
  @appModule.Getter(GET_DRAWER) public drawer!: boolean;
  @appModule.Action(SET_DRAWER) public setDrawer!: (
    value: boolean,
  ) => Promise<any>;
  @authModule.Action(CLEAR_AUTH_STATE)
  public clearAuthStateAction!: () => Promise<any>;
  @appModule.Action(CLEAR_APP_STATE)
  public clearAppStateAction!: () => Promise<any>;
  public originalModalsOpen:
    | ((name: string, data: any) => void | Promise<any>)
    | null = null;
  public logoText = require('./assets/automator_text.svg');
  public logoTextWhite = require('./assets/automator_text_white.svg');
  public logo = require('./assets/automator_logo_small.png');
  public clipped = false;
  public menuItems = [
    {
      title: 'Nastavitve',
      action: () => this.goTo('settings'),
      dividerAfter: true,
      icon: 'mdi-cog-outline',
    },
    {
      title: 'Odjava',
      action: this.logout,
      icon: 'mdi-logout',
    },
  ];

  public helpItems = [
    {
      title: 'Kontakti',
      to: { name: 'contacts' },
      dividerAfter: true,
      icon: 'mdi-contacts',
    },
    {
      title: 'O automatorju',
      to: { name: 'about' },
      dividerAfter: true,
      icon: 'mdi-information',
    },
    {
      title: 'Verzija',
      icon: 'mdi-xml',
      text: process.env.VUE_APP_VERSION,
      dividerAfter: true,
    },
  ];

  public miniVariant = false;
  public right = true;
  public rightDrawer = false;
  public title = 'Automator';

  public expandAppBarItems = false;

  private itemNavBar = null;
  private itemAppBar = null;

  private flipBackground(el: HTMLElement, reverse = false) {
    if (!reverse) {
      el.style.backgroundColor = '#e6832a94';
    } else {
      el.style.backgroundColor = 'transparent';
    }
  }

  private animateBadge() {
    const el = document.getElementById('badge-btn');
    // if the element doesn't exist, don't animate
    if (el == null) {
      return;
    }
    // when you change animationDuration you also need to update the css
    // animation time - #badge-btn transition
    const animationDuration = 400;
    const animation = () => {
      this.flipBackground(el);
      setTimeout(() => this.flipBackground(el, true), animationDuration);
    };
    const repeatedAnimation = (repeat: number) => {
      if (repeat === 0) {
        return;
      }
      animation();
      setTimeout(
        repeatedAnimation.bind(this, repeat - 1),
        animationDuration * 2,
      );
    };
    setTimeout(repeatedAnimation.bind(this, 2), 0);
  }

  @Watch('latestUserTriggeredTaskTime')
  private onValueChange(value: string | null) {
    // don't show the toast when the api returned null (change from date to
    // null can happen when there are no tasks in the queried interval)
    if (value === null) {
      return;
    }
    this.$toasted.info(
      '<p>Pravkar izvedena sprememba bo v nekaj minutah uveljavljena na omrežju. S klikom na' +
        '<span class="mdi mdi-robot-industrial" style="margin: 0px 8px"></span>lahko spremljate izvajanje sprememb.</p>',
      { icon: 'mdi-info' },
    );
  }

  @Watch('badgeStatesLastChanged')
  private onBadgeStateChange(value: number | null) {
    if (value === null) {
      return;
    }
    this.animateBadge();
  }

  public performRouting(item: RouteItem) {
    if (item.routeName) {
      // catch error so that if you click on the route which is he same as the
      // current one you don't get unhandled promise exception error
      this.$router
        .push({ name: item.routeName, params: item.params })
        .catch((err) => undefined);
    } else {
      const win = window.open(item.externalUrl, '_blank');
      win?.focus();
    }
  }

  get showNavBar() {
    return this.navBarItems && this.navBarItems.length !== 0;
  }

  get showLogoutDialog() {
    return this.authStatus === 'logging out';
  }

  @Watch('isDark')
  private changeTheme() {
    this.$vuetify.theme.dark = this.isDark;
  }

  public async created() {
    // Enable modal closing with 'esc' key
    window.document.addEventListener('keyup', (e) => {
      if (e.keyCode == 27) {
        if (this.$modals._store.state.modals.stack.length > 0) {
          this.$modals.close();
        }
      }
    });
    this.$vuetify.theme.dark = this.isDark;
    // wrap $modals.open so that modals don't open when in maintenance mode
    this.originalModalsOpen = this.$modals.open;
    this.$modals.open = function (this: App, name: string, data: any) {
      if (
        (this.$toasted as any).toasts.some((toast: any) =>
          toast.el.innerText.includes('vzdrževalna dela'),
        )
      ) {
        // don't open the modal, reload the page to show the maintenance page
        location.reload();
        return;
      }
      return (
        this.originalModalsOpen as (
          name: string,
          data: any,
        ) => void | Promise<any>
      ).bind(this.$modals)(name, data);
    }.bind(this);

    // Set user for sentry on page reload
    if (this.user) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          username: this.user.username,
          email: this.user.email,
        });
      });
    } else {
      Sentry.configureScope((scope) => {
        scope.setUser({});
      });
    }

    // handle shibboleth logout - user already logged out on backend and
    // frontend and here you just tell him that it was successful
    if (this.$route.query.aai_logout) {
      this.clearAuthStateAction().then(() => {
        this.$toasted.show('Odjava je bila uspešna', {
          icon: 'mdi-logout',
          type: 'success',
        });
        // you can't redirect to the same url
        if (this.$router.currentRoute.name !== 'home') {
          this.$router.push({ name: 'home' });
        }
      });
    }
    // handle the case when user is logged in, refreshes the page, csrf is persisted
    // in vuex, however axios doesn't have it set
    if (this.csrf) {
      axios.defaults.headers.common['X-CSRFToken'] = this.csrf;
      axios.defaults.withCredentials = true;
    }
    // if the user refreshed the page, it might not have timeout set for badge fetching
    // or it might have logged out of his AAI session in another AAI app
    if (this.isLoggedIn) {
      // theoretically we could move fetchTasksStateAction call after the definition of
      // axios error interceptors but then you would get 'Prijava je potekla' message
      // which isn't really what happened since the user manually logged out from AAI
      // in another AAI app
      try {
        await this.fetchUserInfo();
      } catch (error: any) {
        if (error.response?.status === 401) {
          const redirectTo = {
            name: 'login',
            // if redirect is already in the url's query params, keep the first one which is the correct one
            query: {
              redirect: this.$route.query.redirect
                ? this.$route.query.redirect
                : this.$route.fullPath,
            },
          };
          this.logoutAction({ backend: false, redirectTo }).finally(() => {
            this.$modals.clear();
          });
        }
      }
      // set task state fetching
      this.fetchTasksStateAction();
    }
    // handle the case when backend says you're not logged in
    axios.interceptors.response.use(undefined, (error) => {
      // Do something with response error
      const resp = error.response;
      if (
        resp.status === 401 ||
        resp.data.detail === 'Avtentikacijski podatki niso bili podani.' ||
        (resp.status === 403 && resp.data.detail === 'Invalid JWT')
      ) {
        // skip logout actions if the user is already logging out
        // Note that you get 403 & Avtentikacijski podatki niso bili podani if you request
        // a resource and authentication doesn't pass
        if (this.authStatus === 'logging out') {
          return;
        }
        const redirectTo = {
          name: 'login',
          // if redirect is already in the url's query params, keep the first one which is the correct one
          query: {
            redirect: this.$route.query.redirect
              ? this.$route.query.redirect
              : this.$route.fullPath,
          },
        };
        const currentRoute = this.$router.currentRoute;
        if (this.isLoggedIn) {
          this.logoutAction({ backend: false, redirectTo }).finally(() => {
            this.$modals.clear();
            if (!(resp.status === 403 && resp.data.detail === 'Invalid JWT')) {
              this.$toasted.show('Prijava je potekla', {
                icon: 'mdi-alert-circle-outline',
                type: 'error',
              });
            }
          });
        } else {
          // don't navigate to the same page because you get an error, vue doesn't let you
          if (
            this.$router.currentRoute.name !== redirectTo.name &&
            JSON.stringify(currentRoute.query) !==
              JSON.stringify(redirectTo.query)
          ) {
            this.$router.push(redirectTo);
          }
        }
      }
      throw error;
    });
    // handle the case when user goes to page that doesn't exist
    axios.interceptors.response.use(undefined, (error) => {
      // Do something with response error
      const resp = error.response;
      if (resp.status === 404) {
        const redirectTo = {
          name: 'pageNotFound',
          // if redirect is already in the url's query params, keep the first one which is the correct one
          query: {
            redirect: this.$route.query.redirect
              ? this.$route.query.redirect
              : this.$route.fullPath,
          },
        };
        const currentRoute = this.$router.currentRoute;
        if (
          this.$router.currentRoute.name !== redirectTo.name &&
          JSON.stringify(currentRoute.query) !==
            JSON.stringify(redirectTo.query)
        ) {
          this.$router.push(redirectTo);
        }
      }
      return Promise.reject(error);
    });
    // handle maintenance case
    axios.interceptors.response.use((resp) => {
      if (isString(resp.data) && resp.data.includes('Vzdrževalna dela')) {
        // page is under maintenance, response's status is 200 but we should
        // throw here since api's response isn't the expected json
        if ((this.$toasted as any).toasts.length === 0) {
          // there is no maintenance toast, show it
          this.$toasted.error('Trenutno se izvajajo vzdrževalna dela', {
            duration: 60000,
          });
        }
        // NOTE: we convert response to 503 and add detail to it since now it's 200 and its
        // data is maintenance.html content. That's needed for ErrorHandler to work properly
        const error: any = Error('Trenutno se izvajajo vzdrževalna dela');
        resp.status = 503;
        resp.data = { detail: 'Trenutno se izvajajo vzdrževalna dela' };
        error.response = resp;
        return Promise.reject(error);
      }
      return Promise.resolve(resp);
    }, undefined);
  }

  public goTo(routeName: string): void {
    if (this.$route.name !== routeName) {
      this.$router.push({ name: routeName });
    }
  }

  public logout() {
    if (!this.isLoggedIn) {
      this.$router.push({ name: 'login' });
      return;
    }
    if (this.$route.name == 'home') {
      this.logoutAction();
      return;
    }
    // NOTE: if we have an unsaved item, then we probably handle this in the beforeRouteLeave,
    // which means that we first need to trigger some route change, otherwise it would first
    // logout the user and the reject the route change. So we push 'home' route here and only
    // if its promise is resolved we actually logout the user
    this.$router
      .push({ name: 'home' })
      .then(() => this.logoutAction())
      .catch(() => null);
  }
}
