// auth
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const LOGOUT_BACKEND = 'logoutBackend';
export const LOGOUT_FRONTEND = 'logoutFrontend';
export const SET_AUTH_DATA_AND_REDIRECT = 'setAuthDataAndRedirect';
export const CLEAR_AUTH_STATE = 'clearAuthState';

// user
export const FETCH_USER_INFO = 'fetchUserInfo';
export const CLEAR_USER_STATE = 'clearUserState';
export const CHANGE_THEME = 'changeTheme';
export const UPDATE_LAST_CHANGED_TASKS = 'updateLastChangedTasks';
export const FETCH_TASKS_STATE = 'fetchTasksState';

// global
export const OPEN_MODAL = 'openModal';
export const CLOSE_MODAL = 'closeModal';

// circuits
export const FETCH_CIRCUITS = 'fetchCircuits';
export const CREATE_CIRCUIT = 'createCircuit';
export const UPDATE_CIRCUIT = 'updateCircuit';
export const DELETE_CIRCUIT = 'deleteCircuit';
export const CLEAR_CIRCUITS_STATE = 'clearCircuitsState';

// app
export const CLEAR_APP_STATE = 'clearAppState';
export const SET_CAMPUS_NAVBAR = 'setCampusNavbar';
export const SET_CAMPUS_DATA = 'setCampusData';
export const SET_DRAWER = 'setDrawer';
