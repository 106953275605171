import axios, { AxiosPromise } from 'axios';

import { Organization } from '@/api/interfaces';

const resources = {
  default: 'provision/campus',
};

interface ProvisionCampusApiInterface {
  moveCampusStuff(
    fromCampusSlug: string,
    toCampusSlug: string,
    buildings: number[],
    networks: Array<{ network: number; zones: number[] }>,
  ): AxiosPromise<any>;
  getCampusParams(campusSlug: string): AxiosPromise<string>;
}

const ProvisionCampusApi: ProvisionCampusApiInterface = {
  moveCampusStuff(fromCampusSlug, toCampusSlug, buildings, networks) {
    return axios.post(`${resources.default}/move-stuff/`, {
      from_campus: fromCampusSlug,
      to_campus: toCampusSlug,
      buildings,
      networks,
    });
  },

  getCampusParams(campusSlug) {
    return axios.get(`${resources.default}/${campusSlug}/params/`);
  },
};

export default ProvisionCampusApi;
export { ProvisionCampusApiInterface };
