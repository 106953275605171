
import { repositories } from '@/api/ApiFactory';
import { Campus, DNSResolver } from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import { Representations } from '@/components/shared/representation';
import { deepClone } from '@/helpers';
import { IS_ADMIN, IS_ADMIN_OR_ROID, IS_ROID } from '@/store/getters.type';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import debounce from 'lodash.debounce';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { namespace } from 'vuex-class';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class DNSResolverList extends Vue {
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_ROID) private isRoid!: boolean;
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;
  @Prop({ default: null }) private campusSlug!: string | null;
  @Prop({ default: null }) private fixedDNSResolvers!: DNSResolver[] | null;
  @Prop({ default: false }) private fixedNetworkCampus!: boolean;
  @Prop({ default: false }) private canChangeCampus!: boolean;
  @Prop({ default: true }) private showSearch!: boolean;
  @Prop({ default: true }) private showAdd!: boolean;
  @Prop({ default: true }) private showPagination!: boolean;
  @Prop({ default: true }) private itemExpand!: boolean;
  @Prop({ default: () => ['info', 'edit', 'delete'] })
  private showControls!: string[];
  @Prop({ default: null }) private title!: string | null;
  private repr = new Representations();
  private indeterminate = true;
  private headers: DataTableHeader[] = [
    {
      text: 'Ime',
      sortable: true,
      value: 'name',
      width: '50%',
    },
    {
      text: 'IP naslov',
      sortable: true,
      value: 'address',
      width: '40%',
    },
  ];
  private search = '';
  private debouncedSearch = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );
  private dnsResolvers: DNSResolver[] = [];
  private loading = true;

  private expanded: DNSResolver[] = [];

  private get showAddBtn() {
    // TODO: enable add button also for ROID when we implement DNS hosting
    return this.showAdd && this.isAdmin;
  }

  private get possibleActions() {
    return this.isAdminOrRoid ? this.showControls : ['info'];
  }

  private expandRow(el: DNSResolver) {
    let remove = null;
    this.expanded.forEach((value, index) => {
      if (el.id === value.id) {
        remove = index;
      }
    });
    if (remove !== null) {
      this.expanded.splice(remove, 1);
    } else {
      this.expanded.push(el);
    }
  }

  private async fetchDNSResolvers() {
    if (this.fixedDNSResolvers !== null) {
      this.dnsResolvers = deepClone(this.fixedDNSResolvers);
      this.loading = false;
      return;
    }
    this.loading = true;
    try {
      const { data } = await repositories.lan.dnsResolver.getDNSResolvers(
        this.campusSlug,
        true,
      );
      this.dnsResolvers = data.results;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading = false;
  }

  private created() {
    if (!this.fixedNetworkCampus) {
      this.headers[0].width = '40%';
      this.headers[1].width = '20%';
      this.headers.push({
        text: 'Kampus',
        sortable: true,
        value: 'campus.slug',
        width: '20%',
      });
    }
    if (this.possibleActions.length > 0) {
      if (this.fixedNetworkCampus) {
        this.headers[0].width = '45%';
        this.headers[1].width = '35%';
      }
      this.headers.push({
        text: 'Akcije',
        value: 'actions',
        width: '10%',
        sortable: false,
      });
    }
    this.fetchDNSResolvers();
    if (this.itemExpand) {
      this.expanded = deepClone(this.dnsResolvers);
    }
  }

  private openDNSResolverCreateOrEditModal(
    create: boolean,
    dnsResolver: DNSResolver | null,
  ): void {
    let campusSlug = this.campusSlug;
    let fixedNetworkCampusSlug = null;
    if (!create) {
      // update, so take campus slug of the picked dns resolver
      campusSlug = dnsResolver!.campus
        ? (dnsResolver!.campus as Campus).slug
        : null;
      if (this.fixedNetworkCampus) {
        fixedNetworkCampusSlug = this.campusSlug;
      }
    }
    const promise: Promise<DNSResolver> = this.$modals.open(
      'app-dns-resolver-modal',
      {
        component: {
          props: {
            campusSlug,
            dnsResolver,
            fixedNetworkCampusSlug,
            canChangeCampus: this.canChangeCampus,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<DNSResolver>;
    promise.then((modifiedDNSResolver: DNSResolver) => {
      const modifiedDNSResolverIdx = this.dnsResolvers.findIndex(
        (el) => el.id === modifiedDNSResolver.id,
      );
      if (modifiedDNSResolverIdx !== -1) {
        this.dnsResolvers.splice(
          modifiedDNSResolverIdx,
          1,
          modifiedDNSResolver,
        );
      } else {
        this.dnsResolvers.push(modifiedDNSResolver);
      }
      this.$emit('changed', modifiedDNSResolver);
    });
  }

  private showDeleteDNSResolverModal(dnsResolver: DNSResolver): void {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `DNS strežnik ${this.repr.dnsResolverRepr(dnsResolver).text}`,
            item: dnsResolver,
          },
          deleteFn: this.deleteDNSResolver,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteDNSResolver(modal: any, dnsResolver: DNSResolver): void {
    repositories.lan.dnsResolver
      .deleteDNSResolver(dnsResolver.id!)
      .then((resp) => {
        this.dnsResolvers = this.dnsResolvers.filter(
          (el) => el.id !== dnsResolver.id,
        );
        this.$modals.close();
      })
      .catch((error) => {
        const dnsRepr = this.repr.dnsResolverRepr(dnsResolver).text;
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            {
              message: `Pri izbrisu DNS strežnika ${dnsRepr} je prišlo do napake.`,
            },
          ).toString(),
        );
      });
  }

  private redirectToDNSResolverDetail(dnsResolver: DNSResolver) {
    const params: any = {
      dnsResolverId: dnsResolver.id + '',
      canChangeCampus: this.canChangeCampus,
      fixedNetworkCampusSlug: this.fixedNetworkCampus ? this.campusSlug : null,
    };
    let routeName = 'dnsResolver';
    if (this.campusSlug) {
      params.campusSlug = this.campusSlug;
      routeName = 'campusDnsResolver';
    }
    this.$router.push({ name: routeName, params });
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    this.debouncedSearch = this.search;
  }
}
