type ParamVal = string | number | boolean | null | undefined;

/**
 * Generates query for REST api from parameters.
 *
 * @class Query
 */
export class Query {
  private params: { [key: string]: ParamVal[] } = {};

  /**
   * Creates an instance of Query.
   * @param {({ [key: string]: ParamVal | ParamVal[] })} [params={}]
   * @memberof Query
   */
  constructor(params: { [key: string]: ParamVal | ParamVal[] } = {}) {
    for (const param in params) {
      if (!Array.isArray(params[param])) {
        this.addParam(param, params[param] as ParamVal);
      } else {
        (params[param] as ParamVal[]).forEach((paramVal) => {
          this.addParam(param, paramVal);
        });
      }
    }
  }

  /**
   * Returns current non-filtered query params. Useful for debugging.
   *
   * @returns {string}
   * @memberof Query
   */
  public getParams(): string {
    return JSON.parse(JSON.stringify(this.params));
  }

  /**
   * Adds a query parameter. It can take a single value or a list of values.
   * If the value exists it ignores it.
   *
   * @param {string} param
   * @param {(ParamVal | ParamVal[])} val
   * @memberof Query
   */
  public addParam(param: string, val: ParamVal | ParamVal[]) {
    if (Array.isArray(val)) {
      val.forEach((el) => {
        this._addParam(param, el);
      });
    } else {
      this._addParam(param, val);
    }
  }

  /**
   * Removes a query parameter or removes a query parameter value if given.
   *
   * @param {string} param
   * @param {(ParamVal)} [value]
   * @memberof Query
   */
  public removeParam(param: string, value?: ParamVal) {
    if (value === undefined) {
      delete this.params[param];
    } else {
      this.params[param] = this.params[param].filter((el) => el !== value);
    }
  }

  /**
   * Returns a query string. It ignores null, undefined and '' values.
   *
   * @returns {string}
   * @memberof Query
   */
  public toString(): string {
    let query = '';
    for (const prop in this.params) {
      if (!Object.prototype.hasOwnProperty.call(this.params, prop)) {
        continue;
      }
      this.params[prop]
        .filter((el) => el != null && el !== '')
        .forEach((paramVal) => {
          let op = '&';
          if (query === '') {
            op = '?';
          }
          query += `${op}${prop}=${paramVal}`;
        });
    }
    return encodeURI(query);
  }

  /**
   * Helper function which handles a single query parameter value addition.
   *
   * @private
   * @param {string} param
   * @param {(ParamVal)} val
   * @returns
   * @memberof Query
   */
  private _addParam(param: string, val: ParamVal) {
    if (this.params[param] != null && this.params[param].includes(val)) {
      return;
    }
    if (this.params[param]) {
      this.params[param].push(val);
    } else {
      this.params[param] = [val];
    }
  }
}
