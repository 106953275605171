
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { CampusWithUniqueLocations, DataOptions } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { Representations } from '@/components/shared/representation';
import ErrorHandler from '@/components/shared/errorHandler';
import debounce from 'lodash.debounce';
import Breadcrumbs from '@/views/Breadcrumbs.vue';
import { getCampusesWithUniqueLocations } from '@/helpers';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    breadcrumbs: Breadcrumbs,
  },
})
export default class CampusList extends Vue {
  @userModule.Getter(IS_ADMIN) public isAdmin!: boolean;
  private repr = new Representations();
  private indeterminate = true;
  private rowsPerPageItems = [50, 200, 500, 2000];
  private headers = [
    {
      text: 'Oznaka',
      align: 'left',
      sortable: true,
      value: 'slug',
      width: '15%',
    },
    { text: 'Ime', value: 'name', width: '25%', sortable: true },
    {
      text: 'Organizacije',
      value: 'organizations',
      width: '30%',
      sortable: false,
    },
    { text: 'Lokacije', value: 'locations', width: '30%', sortable: false },
  ];
  private search = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );
  private campuses: CampusWithUniqueLocations[] = [];
  private totalCampuses = 0;
  private loading = true;
  private options: DataOptions | null = null;

  private routeToCampus(item: CampusWithUniqueLocations) {
    this.$router.push({
      name: 'organization',
      params: { campusSlug: item.slug },
    });
  }
  private routeToCampusAdd() {
    this.$router.push({ name: 'campusesAdd' });
  }

  @Watch('options', { deep: true })
  private handleOptions() {
    this.fetchCampuses(this.options);
  }

  private async fetchCampuses(pagination: DataOptions | null) {
    this.loading = true;
    try {
      const { data } = await repositories.tenants.campus.getCampuses(
        pagination,
        this.search,
        [],
      );
      const { campuses, errors } = getCampusesWithUniqueLocations(data.results);
      for (const error of errors) {
        this.$toasted.error(error);
      }
      this.campuses = campuses;
      this.totalCampuses = data.count;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading = false;
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    // after a new search you want to be looking at the first page
    // manually change pagination object so that @update.pagination on data table gets triggered,
    // otherwise it doesn't update number of pages/items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.options!.page = 1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.fetchCampuses(this.options!);
  }
}
