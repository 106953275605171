import axios, { AxiosPromise } from 'axios';
import { RichTextDoc, PaginatedResponse } from '@/api/interfaces';
import { Query } from '../query';

const resources = { default: 'documentation/documents' };

interface RichTextDocApiInterface {
  createRichTextDoc(
    name: string,
    objectId: number | string,
    model: string,
    policy: string,
    _readony: boolean,
  ): AxiosPromise<RichTextDoc>;
  updateRichTextDoc(
    id: number,
    name: string,
    content?: string | null,
    policy?: string | null,
    _readonly?: boolean | null,
  ): AxiosPromise<RichTextDoc>;
  deleteRichTextDoc(id: number): AxiosPromise<any>;
  getRichTextDoc(id: number): AxiosPromise<RichTextDoc>;
  getRichTextDocs(
    type: string,
    object: string,
  ): AxiosPromise<PaginatedResponse<RichTextDoc>>;
}

const RichTextDocApi: RichTextDocApiInterface = {
  createRichTextDoc(name, objectId, model, policy, _readonly) {
    return axios.post(`${resources.default}/`, {
      name,
      object_id: objectId,
      model,
      policy,
      readonly: _readonly,
    });
  },
  updateRichTextDoc(id, name, content = null, policy = null, _readonly = null) {
    const data: any = { name };
    if (content) {
      data['content'] = content;
    }
    if (policy) {
      data['policy'] = policy;
    }
    if (_readonly !== null) {
      data['readonly'] = _readonly;
    }
    return axios.patch(`${resources.default}/${id}/`, data);
  },
  getRichTextDoc(id) {
    return axios.get(`${resources.default}/${id}/`);
  },
  getRichTextDocs(type, object) {
    const query = new Query({ type, object });
    return axios.get(`${resources.default}/${query}`);
  },
  deleteRichTextDoc(id) {
    return axios.delete(`${resources.default}/${id}/`);
  },
};

export default RichTextDocApi;
export { RichTextDocApiInterface, resources };
