import axios, { AxiosPromise } from 'axios';
import { CampusManagement, PaginatedResponse } from '@/api/interfaces';
import { Query } from '../query';

const resources = { default: 'provision/campus-managements' };

interface CampusManagementApiInterface {
  getCampusManagementForCampus(
    campusSlug: string,
  ): AxiosPromise<PaginatedResponse<CampusManagement>>;
}

const CampusManagementApi: CampusManagementApiInterface = {
  getCampusManagementForCampus(campusSlug) {
    const query = new Query({ campus__slug: campusSlug });
    return axios.get(`${resources.default}/${query}`);
  },
};

export default CampusManagementApi;
export { CampusManagementApiInterface };
