
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { DataOptions, Device } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { sloveneStatusNames } from '@/utils/constants';
import { ModifyInterfacesComponentMode } from '@/components/shared/helpers';
import { deepClone, isTSP, isBackbone } from '@/helpers';
import ErrorHandler from '@/components/shared/errorHandler';
import CampusHeader from '@/components/campus/CampusHeader.vue';
import debounce from 'lodash.debounce';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class LatestDevices extends Vue {
  public search = '';
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;

  private sloveneStatusNamesRef = sloveneStatusNames;
  private ModifyInterfacesComponentMode = ModifyInterfacesComponentMode;
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );
  private supportedMIFunctions = ['l2_switch', 'l3_switch', 'router'];
  private itemsPerPageOptions = [50, 200, 500];
  private options: DataOptions = {
    groupBy: [],
    groupDesc: [],
    sortBy: ['created'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 50,
    multiSort: false,
    mustSort: false,
  };
  private isTSP = isTSP;
  private isBackbone = isBackbone;

  private devices: Device[] = [];
  private totalDevices = 0;
  private loading = {
    initial: true,
  };

  private headers = [
    { text: 'Ime', value: 'name', align: 'left', sortable: false },
    { text: 'Del skupine', value: 'group', align: 'left', sortable: false },
    { text: 'IP naslov', value: 'primary_address.address', align: 'left', sortable: false },
    { text: 'Serijska', value: 'asset.serial_number', align: 'left', sortable: false },
    { text: 'Status', value: 'status', align: 'left', sortable: false },
    { text: 'Upravljana', value: 'managed', align: 'left', sortable: false },
    { text: 'Funkcija', value: 'function.name', align: 'left', sortable: false },
    { text: 'Vloge', value: 'roles', align: 'left', sortable: false },
    {
      text: 'Model',
      value: 'asset.product.name',
      align: 'left',
      sortable: false,
    },
    { text: 'MAC', value: 'asset.base_mac', align: 'left', sortable: false },
    { text: 'Ustvarjeno', value: 'created', align: 'left', sortable: false },
  ];

  private created() {
    this.fetchLatestDevices();
  }

  private async fetchLatestDevices(): Promise<void> {
    this.loading.initial = true;
    try {
      const { data } = await repositories.infrastructure.device.getDevices(
        { pagination: this.options },
        this.search,
      );
      this.devices = data.results;
      this.totalDevices = data.count;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'napravah' },
        ).toString(),
      );
    }
    this.loading.initial = false;
  }

  @Watch('options', { deep: true })
  private handleOptions() {
    this.fetchLatestDevices();
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    // after a new search you want to be looking at the first page
    // manually change pagination object so that @update.pagination on data table gets triggered,
    // otherwise it doesn't update number of pages/items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.options!.page = 1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.fetchLatestDevices();
  }

  /*
   * We use custom-filter on v-data-table because filter on specific header doesn't
   * work - https://github.com/vuetifyjs/vuetify/issues/11600
   */
  private customDeviceTableFilter(
    value: any,
    search: string | null,
    item: any,
  ) {
    let normalizedSearch = search;
    if (search != null) {
      normalizedSearch = search
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll(':', '')
        .toUpperCase();
    }
    return (
      value != null &&
      search != null &&
      typeof value !== 'boolean' &&
      (value
        .toString()
        .toLocaleLowerCase()
        .indexOf(search.toLocaleLowerCase()) !== -1 ||
        (item.base_mac && item.base_mac.indexOf(normalizedSearch) !== -1))
    );
  }
}
