import { INTERFACE_FORM_FACTORS, PORT_ICON, PORT_TYPE } from './constants';
import { Interface, Neighbor, StatsL2Interface } from '@/api/interfaces';
function getPortType(formFactor: number): PORT_TYPE {
  if (
    Object.prototype.hasOwnProperty.call(INTERFACE_FORM_FACTORS, formFactor)
  ) {
    return INTERFACE_FORM_FACTORS[formFactor].type;
  } else {
    return PORT_TYPE.MISC;
  }
}

function getPortTypeIcon(formFactor: number): PORT_ICON {
  if (
    Object.prototype.hasOwnProperty.call(INTERFACE_FORM_FACTORS, formFactor)
  ) {
    return INTERFACE_FORM_FACTORS[formFactor].icon;
  } else {
    return PORT_ICON.DEFAULT;
  }
}

// TODO this is temporary solution until we don't have color options saved in backend
function getColorForVlanType(
  vlanType: string,
  rgbaFormat = false,
  alpha = 1,
  returnTextColor = false,
): string {
  const colors: { [key: string]: { rgb: string; hex: string; text: string } } =
    {
      'wan': {
        rgb: `rgba(0, 0, 0, ${alpha})`,
        hex: '#000000',
        text: 'white',
      },
      'ztp': {
        rgb: `rgba(181, 176, 184, ${alpha})`,
        hex: '#B5B0B8',
        text: 'black',
      },
      'infrastructure': {
        rgb: `rgba(244, 126, 24,${alpha})`,
        hex: '#F47E18',

        text: 'white',
      },
      'user-default': {
        rgb: `rgba(189, 150, 238, ${alpha})`,
        hex: '#BD96EE',
        text: 'black',
      },
      'user-pedag': {
        rgb: `rgba(127, 208, 157, ${alpha})`,
        hex: '#7FD09D',
        text: 'black',
      },
      'user-pedag2': {
        rgb: `rgba(101, 166, 125, ${alpha})`,
        hex: '#65A67D',
        text: 'white',
      },
      'user-admin': {
        rgb: `rgba(249, 228, 64, ${alpha})`,
        hex: '#F9E440',
        text: 'black',
      },
      'user-admin2': {
        rgb: `rgba(203, 182, 51, ${alpha})`,
        hex: '#CBB633',
        text: 'white',
      },
      'user-it': {
        rgb: `rgba(76, 187, 249, ${alpha})`,
        hex: '#4CBBF9',
        text: 'black',
      },
      'user-eduroam': {
        rgb: `rgba(244, 163, 77, ${alpha})`,
        hex: '#F4A34D',
        text: 'black',
      },
      'user-voip': {
        rgb: `rgba(82, 77, 244, ${alpha})`,
        hex: '#524DF4',
        text: 'white',
      },
      'user-other1': {
        rgb: `rgba(255, 211, 176, ${alpha})`,
        hex: '#ffd3b0',
        text: 'black',
      },
      'user-other2': {
        rgb: `rgba(255, 152, 107, ${alpha})`,
        hex: '#ff986b',
        text: 'black',
      },
      'user-other3': {
        rgb: `rgba(219, 70, 42, ${alpha})`,
        hex: '#db462a',
        text: 'white',
      },
      'tagged': {
        rgb: `rgba(66, 20, 123, ${alpha})`,
        hex: '#42147B',
        text: 'white',
      },
    };

  if (colors[vlanType]) {
    if (returnTextColor) {
      return colors[vlanType].text;
    } else if (rgbaFormat) {
      return colors[vlanType].rgb;
    } else {
      return colors[vlanType].hex;
    }
  } else {
    return '';
  }
}

function getNetboxColorAndTranslationForStatus(status: string): {
  color: string;
  text: string;
} {
  const mappings: { [key: string]: { color: string; text: string } } = {
    // Device statuses
    'used': { color: 'info', text: 'V uporabi' },
    'stored': { color: 'success', text: 'Na razpolago' },
    'reserved': { color: 'deep-purple', text: 'Rezervirano' },
    'donation': { color: '#16db93', text: 'Za donacijo' },
    'repair': { color: 'error', text: 'Na popravilu' },
    'retired': { color: 'default', text: 'Za odpis' },
    // Delivery statuses
    'Poslano': { color: 'info', text: 'Poslano' },
    'Prevzeto': { color: 'success', text: 'Prevzeto' },
    'V vračanju': { color: 'deep-purple', text: 'V vračanju' },
  };
  if (Object.prototype.hasOwnProperty.call(mappings, status)) {
    return mappings[status];
  } else {
    return { text: status, color: '#000000' };
  }
}

function getIconForVlanType(vlanType: string): string {
  const icons: { [key: string]: string } = {
    'infrastructure': 'mdi-server',
    'ztp': 'mdi-auto-fix',
    'wan': 'mdi-wan',
    'user-default': 'mdi-account-multiple',
    'user-pedag': 'mdi-school',
    'user-pedag2': 'mdi-school',
    'user-admin': 'mdi-briefcase-account',
    'user-admin2': 'mdi-briefcase-account',
    'user-it': 'mdi-desktop-classic',
    'user-eduroam': 'mdi-access-point',
    'user-voip': 'mdi-phone',
    'user-other1': 'mdi-alpha-a-box',
    'user-other2': 'mdi-alpha-b-box',
    'user-other3': 'mdi-alpha-c-box',
    'tagged': 'mdi-account-plus',
  };

  return icons[vlanType] ? icons[vlanType] : '';
}

function getSloveneNetworkType(networkType: string): string {
  const networkTypes: { [key: string]: string } = {
    'ztp': 'Ni v uporabi',
    'wan': 'WAN',
    'infrastructure': 'Infrastruktura',
    'tagged': 'Tagirano',
    'user-default': 'Privzeto',
    'user-pedag': 'Pedagoško',
    'user-pedag2': 'Pedagoško2',
    'user-admin': 'Administrativno',
    'user-admin2': 'Administrativno2',
    'user-it': 'IT',
    'user-eduroam': 'eduroam',
    'user-voip': 'VoIP',
    'user-other1': 'Razno1',
    'user-other2': 'Razno2',
    'user-other3': 'Razno3',
  };

  return networkTypes[networkType] ? networkTypes[networkType] : '';
}

const supportedModifyInterfacesDeviceFunctions = [
  'l2_switch',
  'l3_switch',
  'router',
];

enum ModifyInterfacesComponentMode {
  CAMPUS = 'campus',
  SITE = 'site',
}

// Possible actions for modifying interfaces
const possibleUsagesForInterfaces: {
  name: string;
  value: string;
  icon: string;
  description: string;
  permission: string;
}[] = [
  {
    name: 'Ni v uporabi (ZTP)',
    value: 'ztp',
    permission: 'user',
    icon: 'mdi-auto-fix',
    description:
      'Na vmesniku ni posebnih nastavitev, omogča avtomatično nastavljanje priključenih naprav, katere prevzame v upravljanje Arnes. To nastavitev izberite, kadar vmesnika ne potrebujete za priklop lastne opreme.',
  },
  {
    name: 'Infrastruktura',
    value: 'infrastructure_trunk',
    permission: 'admin',
    icon: 'mdi-server',
    description:
      'Vmesnik je namenjen priklopu opreme, katero upravlja Arnes. Spremebe lahko izvaja le osebje Arnesa. Na tak vmesnik ne priklapljajte lastne opreme. Pred posegi na takih vmesnikih se posvetujte z Arnesom. Dovoljena so vsa omrežja, Arnes management omrežje je neoznačeno.',
  },
  {
    name: 'Uporabniški trunk',
    value: 'user_trunk',
    permission: 'user',
    icon: 'mdi-account-plus',
    description:
      'Vmesnik je namenjen priklopu lastnih naprav, ki imajo dostop do več omrežji. Na primer lastna stikala za delitev omrežja, IP telefon z računalnikom in podobno.',
  },
  {
    name: 'Uporabniški dostop',
    value: 'user_access',
    permission: 'user',
    icon: 'mdi-account',
    description:
      'Vmesnik je namenjen priklopu lastnih naprav, ki potrebujejo dostop do enega omrežja. To so končni računalniki in druga oprema ter stikala brez delitve omrežja.',
  },
];

function getIconForInterfaceUsage(usage: string): string {
  const iUsage = possibleUsagesForInterfaces.find((el) => el.value === usage);
  return iUsage ? iUsage.icon : 'mdi-alert-box';
}

function getRichTextDocPolicyRepr(value: string): string {
  const repr_mapper: { [key: string]: string } = {
    'admin': 'Arnes skrbnik',
    'admin|support': 'Arnes skrbnik in Arnes podpora',
    'admin|support|roid': 'Arnes skrbnik, Arnes podpora in Lokalni skrbnik',
  };
  return repr_mapper[value];
}

function getNeighborDeviceName(neighbor: Neighbor): string | null {
  let res = neighbor.remote_system_name;
  if (Array.isArray(neighbor.devices) && neighbor.devices.length) {
    const device = neighbor.devices[0];
    if (device.managed) {
      res = device.name;
    }
  }
  return res;
}

function parsePortSTPState(
  iface: Interface | null,
  ifaceFacts: StatsL2Interface | null,
  allowDisabledSTP = false,
  STPProtocol: string | boolean | null = null,
) {
  let color = '#F1EFF0';
  let text = 'Neznano';
  let enabled = true;

  if (iface != null && (!iface.enabled || !ifaceFacts?.up)) {
    text = 'Neznano stanje vmesnik je neaktiven.';
  } else if (iface && ifaceFacts && ifaceFacts.up && iface.enabled) {
    color = '#10FC16';
    text = 'STP je vklopljen';

    const stpRole = ifaceFacts?.stp?.role;
    const stpType = ifaceFacts?.stp?.type;
    const stpState = ifaceFacts?.stp?.state;
    const hasBBNeighbor =
      ifaceFacts.neighbors
        ?.map(getNeighborDeviceName)
        .some(
          (device_name: string | null) =>
            device_name != null && device_name.endsWith('bb.arnes.si'),
        ) ?? false;

    if (ifaceFacts.stp == null) {
      if (allowDisabledSTP) {
        color = '#F1EFF0';
        text = 'STP ni podprt';
      } else if (STPProtocol !== false && STPProtocol !== 'MSTP') {
        color = '#F1EFF0';
        text = 'STP je smatran kot izklopljen zaradi napačnega protokola.';
      } else if (iface.circuit != null || hasBBNeighbor) {
        color = 'yellow';
        text = 'STP je izklopljen. Dovoljeno za WAN vmesnik.';
        if (hasBBNeighbor) {
          text =
            'STP je izklopljen. Dovoljeno za vmesnike, ki se povezujejo na backbone naprave.';
        }
      } else {
        color = '#f00';
        text = 'STP je neupravičeno izklopljen.';
      }
      enabled = false;
    } else if (
      (stpRole === 'ALTN' || stpRole === 'BACK') &&
      stpState === 'BLK'
    ) {
      // loop
      color = '#f00';
      text = 'Zaznana zanka v omrežju.';
    } else if (stpState === 'BKN') {
      text = 'Pokvarjen';
      if (stpType) {
        text = `Pokvarjen ${stpType}`;
      }
      color = '#fa0';
    } else if (stpState === 'FWD' && stpType && stpType.includes('PVST')) {
      color = '#fa0';
      // PVST BPDU frames incoming
      text = 'Na vmesniku so prisotni PVST BPDU okvirji.';
    }
  }
  return { color, text, enabled };
}

export {
  getPortType,
  getPortTypeIcon,
  getColorForVlanType,
  getIconForVlanType,
  getIconForInterfaceUsage,
  getNetboxColorAndTranslationForStatus,
  possibleUsagesForInterfaces,
  getSloveneNetworkType,
  getRichTextDocPolicyRepr,
  supportedModifyInterfacesDeviceFunctions,
  ModifyInterfacesComponentMode,
  parsePortSTPState,
};
