
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN_OR_SUPPORT } from '@/store/getters.type';
import { Representations } from '../shared/representation';
import Autocomplete from '../shared/Autocomplete.vue';
import { NetboxAsset, DataOptions, User } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import Breadcrumbs from '@/views/Breadcrumbs.vue';
import NetboxStatusChip from '../device/NetboxStatusChip.vue';
import _get from 'lodash.get';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    breadcrumbs: Breadcrumbs,
    NetboxStatusChip,
    Autocomplete,
  },
})
export default class InventoryList extends Vue {
  @userModule.Getter(IS_ADMIN_OR_SUPPORT) public isAdminOrSupport!: boolean;

  private repr = new Representations();
  private searchUsersApi = repositories.people.user.searchUsers;
  private getLodash = _get;
  private changeKey = 0;
  private changeKeyFetch: { [key: number]: boolean } = {};
  private netboxAssets: NetboxAsset[] = [];
  private totalNetboxAssets = 0;
  private rowsPerPageItems = [10, 50, 200, 500, 1000];
  private paginationOptions: DataOptions = {
    groupBy: [],
    groupDesc: [],
    sortBy: [],
    sortDesc: [false],
    page: 1,
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false,
  };
  private loading = { table: true };
  private filterUser: User | null = null;
  private users: User[] = [];
  private expanded: NetboxAsset[] = [];
  private headers = [
    {
      text: 'Proizvajalec',
      sortable: false,
      value: 'manufacturer',
    },
    {
      text: 'Model',
      sortable: false,
      value: 'model',
    },
    {
      text: 'Serijska številka',
      sortable: false,
      value: 'serial',
    },
    {
      text: 'MAC naslov',
      sortable: false,
      value: 'custom_fields.asset_mac',
    },
    {
      text: 'Status',
      sortable: false,
      value: 'status',
    },
    {
      text: 'Dostava',
      sortable: false,
      value: 'custom_fields.delivery_status',
    },
    {
      text: 'Dodeljeno',
      sortable: false,
      value: 'tenant.name',
      inExpansion: true,
    },
    {
      text: 'Lastnik',
      sortable: false,
      value: 'owner.name',
      inExpansion: true,
    },
    {
      text: 'Inventarna številka',
      sortable: false,
      value: 'asset_tag',
      inExpansion: true,
    },
    {
      text: 'Datum nakupa',
      sortable: false,
      value: 'purchase.date',
      inExpansion: true,
    },
    {
      text: 'Potek garancije',
      sortable: false,
      value: 'warranty_end',
      inExpansion: true,
    },
    {
      text: 'Sprememba dostave',
      sortable: false,
      value: 'custom_fields.delivery_status_date',
      inExpansion: true,
    },
    {
      text: 'Kraj hrambe',
      sortable: false,
      value: 'storage_location.display',
      inExpansion: true,
    },
    {
      text: '',
      value: 'data-table-expand',
    },
  ];

  private created() {
    this.fetchNetboxAssets();
  }

  private get headersForTable() {
    return {
      headers: this.headers.filter((header) => {
        if (this.$vuetify.breakpoint.mdAndDown) {
          return !header.inExpansion;
        }
        return true;
      }),
      expansion: this.headers.filter((header) => {
        if (this.$vuetify.breakpoint.mdAndDown) {
          return header.inExpansion;
        } else {
          return false;
        }
      }),
    };
  }

  private async fetchNetboxAssets() {
    if (this.isAdminOrSupport && !this.filterUser) {
      this.netboxAssets = []
      this.totalNetboxAssets = 0
      this.loading.table = false
      return;
    }
    this.loading.table = true;
    try {
      const params: { page: number, itemsPerPage: number, username?: string } = {
        page: this.paginationOptions!.page,
        itemsPerPage: this.paginationOptions!.itemsPerPage,
      }
      if (this.filterUser) {
        params['username'] = this.filterUser.username;
      }
      const { data } = await repositories.infrastructure.asset.searchNetboxAssets(params);
      this.netboxAssets = data.results;
      this.totalNetboxAssets = data.count;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.table = false;
  }

  private expandRow(el: NetboxAsset) {
    let remove = null;
    this.expanded.forEach((value, index) => {
      if (el.id === value.id) {
        remove = index;
      }
    });
    if (remove !== null) {
      this.expanded.splice(remove, 1);
    } else {
      this.expanded.push(el);
    }
  }

  private valueChanged(eventName: string, value: unknown): void {
    this.$emit(eventName, value);
  }

  @Watch('paginationOptions', { deep: true })
  private handleOptions(a: any, b: any) {
    // NOTE: when data-table element gets created this watcher triggers, but with
    // no difference between 'a' and 'b'. We ignore zero-difference triggers because
    // if you're on page 3 and then a select difference user it triggers this
    // watcher twice with zero-difference (don't know why). To avoid calling the
    // api twice we manually trigger 'fetchNetboxAssets' on autocomplete change
    // and ignore zero-diff. Because we ignore zero-diff we must call 'fetchNetboxAssets'
    // in created method.
    const getDifference = (x: any, y: any) => 
      Object.fromEntries(Object.entries(y).filter(([key, val]) => key in x && x[key] !== val));
    if (JSON.stringify(getDifference(a, b)) !== '{}') {
      this.fetchNetboxAssets();
    }
  }

  private onAutocompleteChange() {
    this.paginationOptions.page = 1;
    this.fetchNetboxAssets();
  }
}
