import AddressApi, { AddressApiInterface } from './tenants/address';
import AssetApi, { AssetApiInterface } from './infrastructure/asset';
import CampusApi, { CampusApiInterface } from './tenants/campus';
import CampusManagementApi, {
  CampusManagementApiInterface,
} from './provision/campus-management';
import CampusWlcApi, { CampusWlcApiInterface } from './provision/campus-wlc';
import CircuitApi, { CircuitApiInterface } from './connectivity/circuit';
import DHCPServerApi, { DHCPServerApiInterface } from './lan/dhcp-server';
import DNSResolverApi, { DNSResolverApiInterface } from './lan/dns-resolver';
import DeviceApi, { DeviceApiInterface } from './infrastructure/device';
import EhisApi, { EhisApiInterface } from './tenants/ehis';
import InterfaceApi, {
  InterfaceApiInterface,
} from './infrastructure/interface';
import LocationApi, { LocationApiInterface } from './tenants/location';
import LocationTypeApi, {
  LocationTypeApiInterface,
} from './tenants/locationType';
import ManufacturerApi, {
  ManufacturerApiInterface,
} from './infrastructure/manufacturer';
import NetworkApi, { NetworkApiInterface } from './lan/network';
import OrchestratorCampusApi, {
  OrchestratorCampusApiInterface,
} from './orchestrator/campus';
import OrchestratorSiteApi, {
  OrchestratorSiteApiInterface,
} from './orchestrator/site';
import OrganizationApi, {
  OrganizationApiInterface,
} from './tenants/organization';
import PortApi, { PortApiInterface } from './infrastructure/port';
import ProductApi, { ProductApiInterface } from './infrastructure/product';
import ProductTypeApi, {
  ProductTypeApiInterface,
} from './infrastructure/productType';
import ProvisionCampusApi, {
  ProvisionCampusApiInterface,
} from './provision/campus';
import ProvisionDeviceApi, {
  ProvisionDeviceApiInterface,
} from './provision/device';
import ProvisionMiscApi, { ProvisionMiscApiInterface } from './provision/misc';
import RichTextDocApi, {
  RichTextDocApiInterface,
} from './documentation/richtextdoc';
import SiteApi, { SiteApiInterface } from './connectivity/site';
import { TasksInterface, tasksApi } from './orchestrator/tasks';
import UploadApi, { UploadApiInterface } from './documentation/upload';
import UserApi, { UserApiInterface } from './people/user';
import VlanApi, { VlanApiInterface } from './ipam/vlan';
import WifiNetworkApi, { WifiNetworkApiInterface } from './lan/wifi-network';
import ZoneApi, { ZoneApiInterface } from './zoning/zones';
import ZoneGroupApi, { ZoneGroupApiInterface } from './zoning/zoneGroup';

interface Documentation {
  upload: UploadApiInterface;
  richtextdoc: RichTextDocApiInterface;
}

interface Connectivity {
  circuit: CircuitApiInterface;
  site: SiteApiInterface;
}

interface Infrastructure {
  asset: AssetApiInterface;
  device: DeviceApiInterface;
  interface: InterfaceApiInterface;
  manufacturer: ManufacturerApiInterface;
  port: PortApiInterface;
  product: ProductApiInterface;
  productType: ProductTypeApiInterface;
}

interface Ipam {
  vlan: VlanApiInterface;
}

interface Lan {
  wifiNetwork: WifiNetworkApiInterface;
  network: NetworkApiInterface;
  dhcpServer: DHCPServerApiInterface;
  dnsResolver: DNSResolverApiInterface;
}

interface Orchestrator {
  campus: OrchestratorCampusApiInterface;
  site: OrchestratorSiteApiInterface;
  task: TasksInterface;
}

interface People {
  user: UserApiInterface;
}

interface Provision {
  campus: ProvisionCampusApiInterface;
  campusManagement: CampusManagementApiInterface;
  campusWlc: CampusWlcApiInterface;
  device: ProvisionDeviceApiInterface;
  misc: ProvisionMiscApiInterface;
}

interface Tenants {
  address: AddressApiInterface;
  campus: CampusApiInterface;
  location: LocationApiInterface;
  locationType: LocationTypeApiInterface;
  organization: OrganizationApiInterface;
  ehis: EhisApiInterface;
}

interface Zoning {
  zone: ZoneApiInterface;
  zoneGroup: ZoneGroupApiInterface;
}

interface Repositories {
  documentation: Documentation;
  connectivity: Connectivity;
  infrastructure: Infrastructure;
  ipam: Ipam;
  lan: Lan;
  orchestrator: Orchestrator;
  people: People;
  provision: Provision;
  tenants: Tenants;
  zoning: Zoning;
}

export const repositories: Repositories = {
  documentation: {
    upload: UploadApi,
    richtextdoc: RichTextDocApi,
  },
  connectivity: {
    circuit: CircuitApi,
    site: SiteApi,
  },
  infrastructure: {
    asset: AssetApi,
    device: DeviceApi,
    interface: InterfaceApi,
    manufacturer: ManufacturerApi,
    port: PortApi,
    product: ProductApi,
    productType: ProductTypeApi,
  },
  ipam: {
    vlan: VlanApi,
  },
  lan: {
    wifiNetwork: WifiNetworkApi,
    network: NetworkApi,
    dhcpServer: DHCPServerApi,
    dnsResolver: DNSResolverApi,
  },
  orchestrator: {
    campus: OrchestratorCampusApi,
    site: OrchestratorSiteApi,
    task: tasksApi,
  },
  people: {
    user: UserApi,
  },
  provision: {
    campus: ProvisionCampusApi,
    campusManagement: CampusManagementApi,
    campusWlc: CampusWlcApi,
    device: ProvisionDeviceApi,
    misc: ProvisionMiscApi,
  },
  tenants: {
    address: AddressApi,
    campus: CampusApi,
    location: LocationApi,
    locationType: LocationTypeApi,
    organization: OrganizationApi,
    ehis: EhisApi,
  },
  zoning: {
    zone: ZoneApi,
    zoneGroup: ZoneGroupApi,
  },
};
