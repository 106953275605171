import { render, staticRenderFns } from "./AddAttachmentModal.vue?vue&type=template&id=ddaa3074&scoped=true&"
import script from "./AddAttachmentModal.vue?vue&type=script&lang=ts&"
export * from "./AddAttachmentModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddaa3074",
  null
  
)

export default component.exports