
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { Representations } from '@/components/shared/representation';
import { repositories } from '@/api/ApiFactory';
import { Campus, Organization } from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import Autocomplete from '@/components/shared/Autocomplete.vue';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    Autocomplete,
  },
})
export default class AddOrganizationToCampusModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  // need to do this so that TS gets correct types
  public $refs!: {
    organization: InstanceType<typeof ValidationProvider>;
  };
  @Prop({ default: null }) private campus!: Campus;
  private searchOrganizationsApi =
    repositories.tenants.organization.searchOrganizations;
  private repr = new Representations();
  private organization: Organization | null = null;
  private loading = {
    create: false,
  };
  private async openCreateOrganizationModal() {
    this.organization = await this.$modals.open('app-add-organization-modal', {
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
    // delay so that input is inserted before the validation takes place
    setTimeout(() => {
      this.$refs.organization.validate();
    }, 0);
  }

  private addOrganizationToCampus() {
    this.loading.create = true;
    repositories.tenants.campus
      .addOrganization(this.campus.slug, this.organization!.id)
      .then(() => {
        this.ok(this.organization);
      })
      .catch((error: any) => {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
      })
      .finally(() => {
        this.loading.create = false;
      });
  }
}
