
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { PaginatedResponse } from '@/api/interfaces';
import debounce from 'lodash.debounce';
import ErrorHandler from '@/components/shared/errorHandler';

@Component
export default class Autocomplete extends Vue {
  @Prop({ default: 'mdi-magnify' }) private prependIcon!: string;
  @Prop({ default: '' }) private appendIcon!: string;
  // Labels
  @Prop(String) private label!: string;
  @Prop(String) private hint!: string;
  @Prop(String) private placeholder!: string;
  @Prop(String) private suffix!: string;
  // Errors from vee-validate
  @Prop() private errorMessages!: string[] | string;
  // Disable component
  @Prop(Boolean) private disabled!: boolean;
  // Readonly component
  @Prop(Boolean) private readonly!: boolean;
  // Clearable component
  @Prop({ default: true }) private clearable!: boolean;
  // vue autocomplete's hide-select
  @Prop({ default: false }) private hideSelected!: boolean;
  // Smaller size of items
  @Prop(Boolean) private dense!: boolean;
  // Custom item style with two lines
  @Prop(Boolean) private extraField!: boolean;
  // Api call function and repr for data returned from api
  @Prop({
    default: (search: string) => {
      return;
    },
  })
  private apiCall!: (
    search?: string,
  ) =>
    | Promise<Record<string, unknown>[]>
    | Promise<PaginatedResponse<Record<string, unknown>[]>>;
  @Prop({
    default: (data: any) => data,
  })
  private apiCallFilterFn!: ((
    data: Record<string, unknown>[],
  ) => Record<string, unknown>[] | PromiseLike<Record<string, unknown>[]>) &
    ((
      data: PaginatedResponse<Record<string, unknown>[]>,
    ) => Record<string, unknown>[] | PromiseLike<Record<string, unknown>[]>);
  @Prop({ default: 1 }) private minSearchLength!: number;
  @Prop(Function) private repr!: (element: Record<string, unknown>) => {
    text: string;
    extra?: string;
    value: Record<string, unknown>;
  };
  // show persistent hint
  @Prop(Boolean) private persistentHint!: boolean;
  // use when filtering on server side
  @Prop(Boolean) private noFilter!: boolean;
  // We should be using this with server side filtering so that all items are
  // stored in cache here, but they don't have implemented unique check for
  // objects in cachedItems. This causes multiple instances of same item in cache.
  // Until this is not fixed we have manual check with function `checkIfSelectedItemInItems`
  @Prop(Boolean) private cacheItems!: boolean;

  // Pass data from parent when no need for api call in this component
  @Prop() private itemsPredefined!: Record<string, unknown>[];
  @Prop({ default: false, type: Boolean }) private onlyLocal!: boolean;

  // Display selected data in lis
  @Prop(Boolean) private expandableDetails!: boolean;
  // Return whole selected item object
  @Prop(Boolean) private returnObject!: boolean;
  @Prop(Boolean) private multiple!: boolean;
  // Disable item function
  @Prop() private itemDisabled!: any;

  @Prop() private value!:
    | Record<string, unknown>[]
    | Record<string, unknown>
    | null;
  @Prop({ default: false }) private loading!: boolean;

  // hintLinkTo can be an object which is passed as "to" to router-link component
  // you need to pass "hint" aswell which is used as link's text
  @Prop({ default: null }) private hintLinkTo!: Record<string, unknown> | null;

  private search = '';
  private entries: Record<string, unknown>[] = [];
  private isLoading = false;

  private debouncedGetResults = debounce(this.getResults, 300);

  private showDetails = false;

  /**
   * Populates items of autocomplete if the chosen value is not in it (when pre-selected).
   */
  @Watch('value')
  private onValueChange(val: any) {
    // you need to populate items of autocomplete if you want autocomplete to display your element as selected
    if (val == null) {
      this.entries = [];
    } else if (Array.isArray(val)) {
      this.entries = val;
    } else {
      this.entries = [val] as Record<string, unknown>[];
    }
  }

  @Watch('loading')
  private onLoadingChange(val: boolean) {
    this.isLoading = val;
  }

  private valueChanged(eventName: string, value: any) {
    this.$emit(eventName, value);
  }
  /**
   * @description Returnes data for expandable details
   * @returns {any}
   */

  private get fieldsData() {
    if (!this.value) {
      return [];
    }
    return Object.keys(this.value).map((key: any) => {
      return {
        key,
        value: (this.value as any)[key] || 'n/a',
      };
    });
  }

  /**
   * @description items transformed for displaying autocomplete component
   * @returns {object[{text, value, ?extra}]}
   */
  private get items(): Record<string, unknown>[] {
    if (this.itemsPredefined) {
      this.entries = this.itemsPredefined;
    }
    if (this.value && this.entries.length === 0) {
      this.entries = [this.value] as Record<string, unknown>[];
    }
    return this.entries.map((entry: any) => {
      return this.repr(entry);
    });
  }

  private checkIfSelectedItemInItems(): void {
    if (Array.isArray(this.value)) {
      this.value.forEach((selectedItem: Record<string, unknown>) => {
        if (!this.entries.includes(selectedItem))
          this.entries.push(selectedItem);
      });
    } else if (this.value) {
      if (!this.entries.includes(this.value)) this.entries.push(this.value);
    }
  }

  @Watch('search')
  /**
   * @description watches for search changes and refreshes data if filtering is
   * serverside, if data is not predefined it calls onece api for data
   */
  private onSearchChange(val: string) {
    if (this.search === null) {
      return;
    }

    if (
      this.value != null &&
      !Array.isArray(this.value) &&
      this.repr(this.value as Record<string, unknown>).text === this.search
    ) {
      // if the new search value is exact to the select repr of an element, don't search again
      // this fixes a problem where it would display a new dropdown after you have selected an element
      return;
    }

    if (this.disabled || this.readonly) {
      return;
    } else if (!this.noFilter) {
      // Items have already been loaded
      if (this.items.length > 0) {
        return;
      }
      // Items have already been requested
      if (this.isLoading) {
        return;
      }
      // Items are given from parent
      if (this.onlyLocal) {
        return;
      }
      this.getResults();
    } else {
      this.debouncedGetResults();
    }
  }

  /**
   * @description whatches for model changes and refreshes data if filtering is
   * serverside, if data is not predefined it calls onece api for data
   */
  private onApiCallChange() {
    this.entries = [];
    this.clearModel();
  }

  /**
   * @description Calls api for data
   */
  private getResults(): void {
    this.isLoading = true;
    // reset entries because otherwise the dropdown box's position is buggy
    this.entries = [];
    // Lazily load input items
    let promise;
    // this.search is null at the start or if you press backspace when input is
    // empty (switches from '' to null and also sets value to null at that point)
    const search = this.search || '';
    if (this.noFilter) {
      if (search.length < this.minSearchLength) {
        this.isLoading = false;
        return;
      }
      promise = this.apiCall(search).then(this.apiCallFilterFn);
    } else {
      promise = this.apiCall().then(this.apiCallFilterFn);
    }

    (promise as Promise<any>)
      .then((res: any) => {
        if (Array.isArray(res.data)) {
          this.entries = res.data;
        } else if (Object.prototype.hasOwnProperty.call(res.data, 'results')) {
          // handle paginated results
          this.entries = res.data.results;
        } else {
          throw new Error('Unknown api output format for autocomplete');
        }
      })
      .catch((error: any) => {
        // comment for compiler
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
      })
      .finally(() => (this.isLoading = false));
  }

  /**
   * @description clears selected item
   */
  private clearModel(): void {
    this.entries = [];
    if (this.multiple) {
      this.$emit('input', []);
      this.$emit('change', []);
    } else {
      this.$emit('input', null);
      this.$emit('change', null);
    }
  }

  private created(): void {
    this.isLoading = this.loading;
  }
}
