
import { AxiosResponse } from 'axios';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { Representations, ReprValue } from '@/components/shared/representation';
import { repositories } from '@/api/ApiFactory';
import {
  Location,
  Street,
  House,
  PaginatedResponse,
} from '@/api/interfaces';
import Autocomplete from '@/components/shared/Autocomplete.vue';

@Component({
  components: {
    Autocomplete,
  },
})
export default class AddRoomModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  public $refs!: {
    name: HTMLFormElement;
  };
  @Prop() private parent!: Location;
  @Prop({ default: null }) private campusSlug!: string | null;
  private repr = new Representations();
  private name: string | null = null;
  private loading = {
    create: false,
  };

  private mounted() {
    setTimeout(() => this.$refs.name.$refs.input.focus(), 0);
  }

  private createLocation() {
    this.loading.create = true;
    repositories.tenants.location
      .createLocation(
        'room',
        this.name as string,
        this.parent.id,
        null,
        this.campusSlug,
      )
      .then((res) => {
        this.ok(res.data);
      })
      .catch((err) => {
        this.$toasted.error(
          `Napaka pri dodajanju prostora: ${err.response.data.detail}`,
        );
      })
      .finally(() => {
        this.loading.create = false;
      });
  }
}
