
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import ErrorHandler from '@/components/shared/errorHandler';

const userModule = namespace(USER_NAMESPACE_PATH);

interface Dict {
  [key: string]: string;
}

export interface Button {
  name: string;
  action: () => void;
  icon: string;
  fab?: boolean;
  rounded?: boolean;
  onlyIcon?: boolean;
  asyncAction?: boolean;
  small?: boolean;
  xsmall?: boolean;
}

@Component
export default class Lists extends Vue {
  @Prop() private list!: Dict[];
  @Prop({ type: Boolean, default: false }) private chip!: boolean;
  @Prop({ type: String, default: 'Title' }) private title!: string;
  @Prop({ type: Boolean, default: false }) private threeLine!: boolean;
  @Prop({ type: String, default: '' }) private icon!: string;
  @Prop({ type: String, default: 'title' }) private itemTitle!: string;
  @Prop({ type: String, default: 'href' }) private itemHref!: string;
  @Prop({ type: Boolean, default: false }) private itemHrefExternal!: boolean;
  @Prop({ type: String, default: 'content' }) private itemContent!: string;
  @Prop({ type: String, default: 'extraContent' })
  private itemExtraContent!: string;
  @Prop({ type: String, default: 'chip' }) private itemChip!: string;
  @Prop({ type: Boolean, default: false }) private loading!: boolean;
  @Prop({ type: Boolean, default: false }) private noContent!: boolean;
  @Prop({ type: String, default: 'Ni podatkov' })
  private emptyListText!: string;
  @Prop({ type: Array, default: () => [] }) private buttons!: Array<Button>;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;

  private buttonLoading: { [key: string]: boolean } = {};
  private attrs = {
    class: 'mb-11',
    boilerplate: false,
  };
  /*
   * This watcher is needed because when component instance is created buttons
   * might not have been passed yet (eg. if buttons is a getter and it's waiting
   * for some api call)
   */
  @Watch('buttons')
  private resetButtonLoading() {
    for (const button of this.buttons) {
      Vue.set(this.buttonLoading, button.name, false);
    }
  }

  private async callButtonAction(button: Button) {
    if (button.asyncAction) {
      this.buttonLoading[button.name] = true;
      try {
        await button.action();
        setTimeout(() => this.$emit('requiredRefresh'), 100);
      } catch (error) {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
      }
      this.buttonLoading[button.name] = false;
    } else {
      button.action();
    }
  }

  private getValue(item: Dict, val: string): string {
    if (Object.prototype.hasOwnProperty.call(item, val)) {
      return item[val];
    }
    return '';
  }

  private getExtraSubtitles(item: {
    [key: string]: string[] | Record<string, unknown>[] | string;
  }): string[] | Record<string, unknown>[] {
    if (Object.prototype.hasOwnProperty.call(item, this.itemExtraContent)) {
      if (!Array.isArray(item[this.itemExtraContent])) {
        return new Array(item[this.itemExtraContent]) as
          | string[]
          | Record<string, unknown>[];
      } else {
        return item[this.itemExtraContent] as
          | string[]
          | Record<string, unknown>[];
      }
    }
    return [];
  }

  private openHref(item: Dict): void {
    if (this.itemHrefExternal) {
      const url = this.getValue(item, this.itemHref);
      if (url) {
        window.open(url);
      }
    }
  }

  private get modifiedList() {
    if (this.list.length > 0) {
      return this.list;
    }
    const obj: any = {};
    obj[this.itemTitle] = this.emptyListText;
    return [obj];
  }
}
