
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AuthType, ShibAuth, LocalAuth } from '../store/types';
import {
  AUTH_NAMESPACE_PATH,
  USER_NAMESPACE_PATH,
} from '../store/namespaces.type';
import { STATUS } from '../store/states.type';
import { IS_LOGGED_IN, USER } from '../store/getters.type';
import { LOGIN, SET_AUTH_DATA_AND_REDIRECT } from '../store/actions.type';
import url from 'url';

const authModule = namespace(AUTH_NAMESPACE_PATH);
const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class Login extends Vue {
  @Prop({ default: false, type: Boolean }) private onlyAAI!: boolean;
  @Prop({ default: false, type: Boolean }) private noWarning!: boolean;
  @authModule.State(STATUS) public authStatus!: string;
  @authModule.Getter(IS_LOGGED_IN) public isLoggedIn!: boolean;
  @userModule.Getter(USER) public user!: boolean;
  @authModule.Action(LOGIN) public loginAction!: (data: {
    loginData: { username: string; password: string };
    redirect?: string;
  }) => Promise<any>;
  @authModule.Action(SET_AUTH_DATA_AND_REDIRECT)
  public setAuthDataAndRedirect!: (data: {
    authType: AuthType;
    redirect?: string;
  }) => Promise<any>;
  public $refs!: {
    usernameInput: HTMLInputElement;
  };
  public username = '';
  public password = '';
  public passwordShown = false;
  public redirect: string | null = null;
  private dialog = false;

  private AAIImage = require('@/assets/aai-prijava.png');

  get loginUrls() {
    this.setRedirectFromQuery();
    const config = this.$config;
    let next: string = config.frontendUrl;
    const frontendLogin: string = url.resolve(
      config.frontendUrl,
      this.$router.currentRoute.path,
    );
    next = `${frontendLogin}?aai_login=true`;
    if (this.redirect) {
      next += `&redirect=${this.redirect}`;
    }

    const shibLogin = `${config.shibLoginUrl}?next=${btoa(next)}`;

    return {
      shibLogin,
      shibLogout: `${config.shibLogoutUrl}?next=${next}`,
      localLogin: config.localLoginUrl,
      localLogout: config.localLogoutUrl,
    };
  }

  @Watch('dialog')
  setFocus() {
    if (!this.dialog) return;
    requestAnimationFrame(() => {
      this.$refs.usernameInput.focus();
    });
  }

  public setRedirectFromQuery() {
    let redirectQuery: any = this.$route.query.redirect || null;
    if (Array.isArray(redirectQuery)) {
      redirectQuery = redirectQuery.length > 0 ? redirectQuery[0] : null;
    }
    this.redirect = redirectQuery;
  }

  public created() {
    if (this.isLoggedIn) {
      this.$router.push({ name: 'home' });
      return;
    }
    this.setRedirectFromQuery();
    const errorMsg = this.$route.query.err_msg;
    if (errorMsg) {
      if (Array.isArray(errorMsg)) {
        for (const error of errorMsg) {
          const err = error ? error : 'Napaka pri preusmeritvi';
          this.$toasted.show(err, {
            type: 'error',
            icon: 'mdi-alert-circle-outline',
          });
        }
      } else {
        this.$toasted.show(errorMsg, {
          type: 'error',
          icon: 'mdi-alert-circle-outline',
        });
      }
      const rr = this.$route;
      const newQueryParams = Object.assign({}, this.$route.query);
      delete newQueryParams.err_msg;
      this.$router.push({ query: { ...newQueryParams } });
    } else if (this.$route.query.aai_login) {
      const data: { authType: ShibAuth; redirect?: string } = {
        authType: 'shibboleth',
      };
      if (this.redirect) {
        data.redirect = this.redirect;
      }
      this.setAuthDataAndRedirect(data).catch((error) => {
        this.$toasted.show(error, {
          type: 'error',
          icon: 'mdi-alert-circle-outline',
        });
      });
    }
  }

  public login() {
    const next = this.$config.frontendUrl;
    const data: {
      loginData: { username: string; password: string };
      authType: LocalAuth;
      redirect?: string;
    } = {
      loginData: { username: this.username, password: this.password },
      authType: 'local',
    };
    if (this.redirect) {
      data.redirect = this.redirect;
    }
    this.loginAction(data)
      .then((resp) => {
        this.$toasted.show('Dobrodošli', {
          type: 'success',
          icon: 'mdi-human-handsup',
        });
      })
      .catch((error) => {
        const notActive = 'No active account found with the given credentials';
        let errors: string[] | null = null;
        try {
          errors = error.response.data.non_field_errors;
          // tslint:disable-next-line:no-empty
        } catch (err) {
          // Non empty
        }
        if (errors && error.response.data.non_field_errors[0] === notActive) {
          this.$toasted.show(
            'Vneseni podatki so napačni ali pa je račun blokiran',
            { type: 'error', icon: 'mdi-alert-circle-outline' },
          );
        } else if (error.response.data.detail) {
          this.$toasted.show(error.response.data.detail, {
            type: 'error',
            icon: 'mdi-alert-circle-outline',
          });
        } else {
          this.$toasted.show('Prijava ni bila uspešna', {
            type: 'error',
            icon: 'mdi-alert-circle-outline',
          });
        }
      });
  }
}
