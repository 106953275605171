
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MenuItem extends Vue {
  @Prop({ required: true }) private icon!: string;
  @Prop({ required: true }) private title!: string;
  @Prop({ required: true }) private action!: () => any;
  @Prop({ default: null }) private isActive!: () => boolean;
  @Prop({ default: () => false }) private disabled!: () => boolean;
  @Prop({ default: true }) private canActivate!: boolean;
  @Prop({ default: false }) private titleAsIconPrefix!: boolean;
}
