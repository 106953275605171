
import { Component, Prop } from 'vue-property-decorator';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import CircuitForm from './CircuitForm.vue';

@Component({
  components: {
    'app-new-circuit-form': CircuitForm,
  },
})
export default class AddCircuitModal extends PersistentModal {
  @Prop() private campusSlug!: string;
}
