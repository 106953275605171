
import { Component, Vue } from 'vue-property-decorator';
import Breadcrumbs from '@/views/Breadcrumbs.vue';

@Component({
  components: {
    breadcrumbs: Breadcrumbs,
  },
})
export default class RouterViewWithBreadcrumbsHolder extends Vue {}
