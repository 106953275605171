var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-server")]),_c('v-toolbar-title',{staticClass:"font-weight-bold white--text"},[_vm._v(" Pregled DNS strežnika ")]),_c('v-spacer'),(_vm.isAdmin)?[(
            !_vm.loading.initial &&
            (_vm.isAdmin || (_vm.isRoid && _vm.dnsResolver.campus !== null))
          )?_c('v-btn',{attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openDNSResolverEditModal(_vm.dnsResolver)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(
            !_vm.loading.initial &&
            (_vm.isAdmin || (_vm.isRoid && _vm.dnsResolver.campus !== null))
          )?_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.showDeleteDNSResolverModal(_vm.dnsResolver)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]:_vm._e()],2),_c('v-card-title',[_c('v-row',{attrs:{"align":"center","justify":"space-between"}})],1),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.loading.initial)?[_c('v-progress-linear',{attrs:{"indeterminate":""}})]:_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"mb-1 text-right d-inline-block",staticStyle:{"max-width":"100px"}},[_c('strong',[_vm._v("Kampus:  ")])]),_c('v-col',{staticClass:"d-inline-block"},[(_vm.dnsResolver.campus !== null)?_c('router-link',{attrs:{"to":{
                  name: 'campus',
                  params: { campusSlug: _vm.dnsResolver.campus.slug },
                }}},[_vm._v(_vm._s(_vm.dnsResolver.campus.name))]):_c('span',[_vm._v(" [globalen] ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-1 text-right d-inline-block",staticStyle:{"max-width":"100px"}},[_c('strong',[_vm._v("Ime:  ")])]),_c('v-col',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.dnsResolver.name)+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"mb-1 text-right d-inline-block",staticStyle:{"max-width":"100px"}},[_c('strong',[_vm._v("IP:  ")])]),_c('v-col',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.dnsResolver.address)+" ")])],1),_c('v-container',{staticClass:"pa-0 mt-5"},[_c('v-card',{attrs:{"rounded":"","color":"subsecondary"}},[_c('v-card-title',{attrs:{"color":"primary"}},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-network")]),_vm._v("  "),_c('span',{staticClass:"subheading"},[_vm._v("Omrežja")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"solo":"","rounded":"","clearable":"","prepend-inner-icon":"mdi-magnify","label":"Išči","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',{staticClass:"px-0 pb-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.dnsResolver.networks,"search":_vm.debouncedSearch,"no-data-text":"Ni omrežij"},scopedSlots:_vm._u([{key:`item.campus.slug`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.campus ? item.campus.slug : '[globalen]')+" ")]}}],null,true)})],1)],1)],1)],1)],1)],1)],2)],1),_c('v-card-actions')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }