
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CeleryTaskResultTask } from '@/api/orchestrator/tasks';
import { prettyPrintJson } from '@/utils/filters';

@Component({
  filters: {
    prettyPrintJson,
  },
})
export default class TaskErrorDetailsModal extends Vue {
  @Prop()
  private celeryTaskResultTask!: CeleryTaskResultTask;
  @Prop()
  private host!: string;
  @Prop()
  private hostData!: any;

  get stdoutDatas() {
    return this.hostData.module_stdout
      .split('\n')
      .filter((el: Array<string>) => el.length > 0)
      .filter(
        (el: string, index: number, self: Array<string>) =>
          self.indexOf(el) === index,
      )
      .map((el: string) => JSON.parse(el));
  }

  private openWikiAutomatorTroubleshooting() {
    const win = window.open(
      'https://wiki.arnes.si/display/anso/automator+troubleshooting',
      '_blank',
    );
    win!.focus();
  }
}
