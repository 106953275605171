/**
 * Repeatedly tries to execute a given function with a given delay and returns
 * a promise.
 *
 * @param {Function} fn      - function which returns a promise and is repeatedly called.
 * @param {Function} delayFn - function which accepts current number of attempts
 * and returns delay in ms or null if you want to stop retrying. Default is a function which delays every
 * subsequent call by 1 second.
 * @param {Function} validateResponseFn - function which accepts response data and checks if the data is OK.
 * This function should return true when response is valid. It is used in cases like fetching device data
 * when you wait for successful response.
 *
 * @return {Promise} promise which retries the fn function.
 *
 * good promise example:
 * https://dev.to/ycmjason/javascript-fetch-retry-upon-failure-3p6g
 */
function fetchRepeatedly(
  fn: () => Promise<any>,
  delayFn: (attempt: number) => number | null = (retries) => 1000 * retries,
  validateResponseFn: (data: Record<string, unknown>) => boolean = () => true,
): Promise<any> {
  function helper(attempt = 1) {
    return fn()
      .then((res) => {
        if (validateResponseFn(res)) {
          return Promise.resolve(res);
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        const delay = delayFn(attempt);
        if (
          delay === null ||
          (error.response.status >= 400 && error.response.status < 500)
        ) {
          throw error;
        }
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            helper(attempt + 1)
              .then(resolve)
              .catch(reject);
          }, delay);
        });
      });
  }
  return helper();
}

/**
 * Copies content of an input element into clipboard. We might want to use some
 * library if it turns out that this solution doesn't work on all modern browsers.
 * Currently it doesn't support IE 8.
 */
function copyToClipboard(inputEl: HTMLInputElement): void {
  inputEl.select();
  document.execCommand('copy');
  inputEl.blur();
  if (window.getSelection) {
    // All browsers, except IE <=8
    (window.getSelection() as Selection).removeAllRanges();
  } /* else if (document.selection) { // IE <=8
      document.selection.empty();
    }*/
}

/*
 * Copies the given text to clipboard, returns true if copy was successful.
 */
function copyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
  } catch (err) {
    return false;
  }
  document.body.removeChild(textArea);
  return true;
}

export { fetchRepeatedly, copyToClipboard, copyTextToClipboard };
