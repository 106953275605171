
import { Component, Vue } from 'vue-property-decorator';
import InventoryList from '../../components/inventory/InventoryList.vue';
import InventoryDeliveryChange from '../../components/inventory/InventoryDeliveryChange.vue';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { namespace } from 'vuex-class';
import { IS_ADMIN } from '@/store/getters.type';

const userModule = namespace(USER_NAMESPACE_PATH);

interface Tab {
  name: string;
  repr: string;
  component: any;
}

@Component
export default class Inventory extends Vue {
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;

  public tabs: Tab[] = [
    { name: 'inventory-delivery-change', repr: 'Prevzem opreme', component: InventoryDeliveryChange },
    { name: 'inventory-list', repr: 'Vsa oprema', component: InventoryList },
  ];

  public selectedTab: string = this.tabs[0].component;
}
