import axios, { AxiosPromise } from 'axios';
import { DNSResolver, PaginatedResponse } from '@/api/interfaces';
import { deepClone } from '@/helpers';
import { Query } from '../query';

const resources = { default: 'lan/dns-resolvers' };

interface DNSResolverApiInterface {
  getDNSResolver(
    id: number | string,
    campusSlug?: string | null,
  ): AxiosPromise<DNSResolver>;
  getDNSResolvers(
    campusSlug?: string | null,
    includeGlobal?: boolean,
  ): AxiosPromise<PaginatedResponse<DNSResolver>>;
  createDNSResolver(dnsResolver: {
    campusSlug: string | null;
    name: string;
    address: string;
    networks: number[];
  }): AxiosPromise<DNSResolver>;
  updateDNSResolver(
    id: number | string,
    dnsResolver: {
      campusSlug?: string | null;
      name?: string;
      address?: string;
      networks?: { add: number[]; remove: number[] };
    },
    campusSlug?: string | null,
  ): AxiosPromise<DNSResolver>;
  deleteDNSResolver(id: number): AxiosPromise<any>;
}

const DNSResolverApi: DNSResolverApiInterface = {
  getDNSResolver(id, campusSlug = null) {
    // campusSlug param is to know whether you're on the campus page calling update or on the global one
    // so that the serializer can know which networks it can send!
    const query = new Query({ campus_slug: campusSlug });
    return axios.get(`${resources.default}/${id}/${query}`);
  },

  getDNSResolvers(campusSlug, includeGlobal = false) {
    const query = new Query({
      campus__slug: campusSlug,
      include_global: includeGlobal,
    });
    return axios.get(`${resources.default}/${query}`);
  },

  createDNSResolver(dnsResolver) {
    return axios.post(`${resources.default}/`, {
      campus_slug: dnsResolver.campusSlug,
      name: dnsResolver.name,
      address: dnsResolver.address,
      networks: dnsResolver.networks,
    });
  },

  updateDNSResolver(id, dnsResolver, campusSlug = null) {
    // campusSlug param is to know whether you're on the campus page calling update or on the global one
    // so that the serializer can know which networks it can send!
    const query = new Query({ campus_slug: campusSlug });
    const data: any = deepClone(dnsResolver);
    if (Object.prototype.hasOwnProperty.call(data, 'campusSlug')) {
      data.campus_slug = data.campusSlug;
      delete data.campusSlug;
    }
    return axios.patch(`${resources.default}/${id}/${query}`, data);
  },

  deleteDNSResolver(id) {
    return axios.delete(`${resources.default}/${id}/`);
  },
};

export default DNSResolverApi;

export { DNSResolverApiInterface };
