
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  Image as ImageI,
  RichTextDoc,
  EditorTableConstructorData,
  EditorLinkConstructorData,
} from '@/api/interfaces';
import MenuItem from './MenuItem.vue';

interface DocEditorLinkModalResponse {
  formData?: EditorLinkConstructorData;
  deleted?: boolean;
}

@Component({
  components: {
    MenuItem,
  },
})
export default class MenuBar extends Vue {
  @Prop({ required: true }) private editor!: any;
  @Prop({ required: true }) public document!: RichTextDoc;
  @Prop({ required: true }) private editable!: boolean;
  @Prop({ required: true }) private canEdit!: boolean;
  @Prop({ default: false }) private saving!: boolean;
  @Prop({ default: false }) private renaming!: boolean;
  @Prop({ default: false }) private showDeleteIcon!: boolean;

  private items: any = [];
  private rightItems: any = [];

  private created() {
    this.updateItems();
  }

  @Watch('editable')
  private onEditableChange() {
    this.updateItems();
  }

  @Watch('saving')
  private onSavingChange() {
    this.updateItems();
  }

  @Watch('renaming')
  private onNameChange() {
    this.updateItems();
  }

  private updateItems() {
    this.rightItems = []
    if (!this.editable) {
      // icon for document print
      this.rightItems.push(
        {
          icon: 'printer-line',
          title: 'Natisni',
          action: () => this.$emit('print'),
          titleAsIconPrefix: !this.editable,
        },
      );
      if (!this.canEdit) {
        return;
      }
      this.rightItems.push({
        type: 'divider',
        title: 'print-divider',
      });
    }
    // icon for document rename
    if (this.renaming) {
      this.rightItems.push({ type: 'loading', title: 'loading-divider' });
    } else {
      this.rightItems.push(
        {
          icon: 'folder-settings-line',
          title: 'Preimenuj',
          action: () => this.openRenameRichTextDocModal(),
          titleAsIconPrefix: !this.editable,
        },
      );
    }
    // divider after that icon because there's always a bin icon
    this.rightItems.push({
      type: 'divider',
      title: 'bin-divider',
    });
    if (this.showDeleteIcon) {
      this.rightItems.push({
        icon: 'delete-bin-line',
        title: 'Izbriši',
        action: () => this.$emit('delete'),
        titleAsIconPrefix: !this.editable,
      });
    }
    if (!this.editable) {
      this.items = [
        {
          icon: 'pencil-line',
          title: 'Uredi',
          action: () => this.$emit('enableEdit'),
          titleAsIconPrefix: true,
        },
      ];
    } else {
      this.items = [
        {
          icon: 'save-3-fill',
          title: 'Shrani',
          action: () => this.$emit('save'),
          titleAsIconPrefix: true,
        },
        {
          type: 'divider',
        },
        {
          icon: 'bold',
          title: 'Krepko',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'italic',
          title: 'Poševno',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          icon: 'strikethrough',
          title: 'Prečrtano',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike'),
        },
        {
          icon: 'code-view',
          title: 'Koda',
          action: () => this.editor.chain().focus().toggleCode().run(),
          isActive: () => this.editor.isActive('code'),
        },
        {
          icon: 'mark-pen-line',
          title: 'Izpostavljeno',
          action: () => this.editor.chain().focus().toggleHighlight().run(),
          isActive: () => this.editor.isActive('highlight'),
        },
        {
          type: 'divider',
        },
        {
          icon: 'image-fill',
          title: 'Slika',
          action: () => this.openPickImageModal(),
        },
        {
          icon: 'table-2',
          title: 'Tabela',
          action: () => this.openCreateTableModal(),
        },
        {
          icon: 'link',
          title: 'Povezava',
          action: () => this.openDocEditorLinkModal(),
          isActive: () => this.editor.isActive('link'),
        },
        {
          type: 'divider',
        },
        {
          type: 'menu',
          children: [
            {
              icon: 'paragraph',
              title: 'Navaden',
              action: () => this.editor.chain().focus().setParagraph().run(),
              isActive: () => this.editor.isActive('paragraph'),
              titleAsIconPrefix: true,
            },
            {
              icon: 'h-1',
              title: 'Naslov 1',
              action: () =>
                this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
              isActive: () => this.editor.isActive('heading', { level: 1 }),
              titleAsIconPrefix: true,
            },
            {
              icon: 'h-2',
              title: 'Naslov 2',
              action: () =>
                this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
              isActive: () => this.editor.isActive('heading', { level: 2 }),
              titleAsIconPrefix: true,
            },
            {
              icon: 'code-box-line',
              title: 'Blok kode',
              action: () => this.editor.chain().focus().toggleCodeBlock().run(),
              isActive: () => this.editor.isActive('codeBlock'),
              titleAsIconPrefix: true,
              hint: 'Zaključi s Shift+Enter',
            },
          ],
        },
        {
          icon: 'align-left',
          title: 'Leva poravnava',
          action: () => this.editor.chain().focus().setTextAlign('left').run(),
          isActive: () => this.editor.isActive({ textAlign: 'left' }),
        },
        {
          icon: 'align-center',
          title: 'Sredinska poravnava',
          action: () =>
            this.editor.chain().focus().setTextAlign('center').run(),
          isActive: () => this.editor.isActive({ textAlign: 'center' }),
        },
        {
          icon: 'align-right',
          title: 'Desna poravnava',
          action: () => this.editor.chain().focus().setTextAlign('right').run(),
          isActive: () => this.editor.isActive({ textAlign: 'right' }),
        },
        {
          type: 'divider',
        },
        {
          icon: 'list-unordered',
          title: 'Seznam alinej',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'list-ordered',
          title: 'Urejen seznam',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
        },
        {
          icon: 'list-check-2',
          title: 'Seznam opravil',
          action: () => this.editor.chain().focus().toggleTaskList().run(),
          isActive: () => this.editor.isActive('taskList'),
        },
        {
          type: 'divider',
        },
        {
          icon: 'double-quotes-l',
          title: 'Citat',
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
          isActive: () => this.editor.isActive('blockquote'),
        },
        {
          icon: 'separator',
          title: 'Horizontalna črta',
          action: () => this.editor.chain().focus().setHorizontalRule().run(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'text-wrap',
          title: 'Prelom',
          action: () => this.editor.chain().focus().setHardBreak().run(),
        },
        {
          icon: 'format-clear',
          title: 'Počisti obliko',
          action: () =>
            this.editor.chain().focus().clearNodes().unsetAllMarks().run(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'arrow-go-back-line',
          title: 'Razveljavi',
          action: () => this.editor.chain().focus().undo().run(),
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Obnovi',
          action: () => this.editor.chain().focus().redo().run(),
        },
      ];
      if (this.editable && this.saving) {
        this.items[0] = { type: 'loading' };
      }
      if (this.rightItems.length > 0) {
        this.rightItems.push({
          type: 'divider',
          title: 'close-divider',
        });
      }
      this.rightItems.push({
        icon: 'close-line',
        title: 'Zapri',
        action: () => this.$emit('close'),
      });
    }
  }

  private openPickImageModal() {
    const promise: Promise<ImageI> = this.$modals.open('app-pick-image-modal', {
      component: {
        props: {
          objectId: this.document.id,
          modelName: 'richtextdoc',
          uploadType: 'image',
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    }) as Promise<ImageI>;
    promise.then((image: ImageI) => {
      return this.editor
        .chain()
        .focus()
        .setImage({ src: image.src, alt: image.alt })
        .run();
    });
  }

  private openCreateTableModal() {
    const promise: Promise<EditorTableConstructorData> = this.$modals.open(
      'app-create-table-modal',
      {
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<EditorTableConstructorData>;
    promise.then((data: EditorTableConstructorData) => {
      return this.editor
        .chain()
        .focus()
        .insertTable({ ...data })
        .run();
    });
  }

  private openDocEditorLinkModal() {
    const previousUrl = this.editor.getAttributes('link').href || '';
    const requireText = this.editor.state.selection.empty && !previousUrl;
    const promise: Promise<DocEditorLinkModalResponse> = this.$modals.open(
      'app-doceditor-link-modal',
      {
        component: {
          props: {
            requireText,
            previousUrl,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<DocEditorLinkModalResponse>;
    promise.then((data: DocEditorLinkModalResponse) => {
      if (data.deleted) {
        // user wants to delete this link
        this.editor.chain().focus().unsetLink().run();
        return;
      }
      let url = data.formData!.url;
      const pattern = /^((http|https|ftp):\/\/)/;
      // default to https
      if (!pattern.test(url)) {
        url = 'https://' + url;
      }
      if (previousUrl) {
        // user wants to edit the link, we need to extend the mark first
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .run();
        return;
      }
      if (requireText) {
        this.editor.commands.insertContent(data.formData!.text);
        this.editor.commands.setTextSelection({
          from:
            this.editor.state.selection.$from.pos - data.formData!.text!.length,
          to: this.editor.state.selection.$to.pos,
        });
      }
      return this.editor.chain().focus().setLink({ href: url }).run();
    });
  }

  private openRenameRichTextDocModal() {
    const promise: Promise<string> = this.$modals.open(
      'app-rename-richtextdoc-modal',
      {
        component: {
          props: {
            originalName: this.document.name,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<string>;
    promise.then((name: string) => {
      this.$emit('rename', name);
    });
  }
}
