import axios, { AxiosPromise } from 'axios';
import { Manufacturer, PaginatedResponse } from '@/api/interfaces';

const resources = {
  default: 'infrastructure/manufacturers',
};

interface ManufacturerApiInterface {
  getManufacturers(): AxiosPromise<PaginatedResponse<Manufacturer>>;
  getManufacturer(manufacturerId: string | number): AxiosPromise<Manufacturer>;
}

const manufacturerApi: ManufacturerApiInterface = {
  getManufacturers() {
    return axios.get(`${resources.default}/`);
  },

  getManufacturer(manufacturerId) {
    return axios.get(`${resources.default}/${manufacturerId}/`);
  },
};

export default manufacturerApi;
export { ManufacturerApiInterface };
