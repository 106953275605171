import axios, { AxiosPromise } from 'axios';
import { CampusWlc, PaginatedResponse } from '@/api/interfaces';
import { Query } from '../query';

const resources = { default: 'provision/campus-wlcs' };

interface CampusWlcApiInterface {
  getCampusWlcs(campusSlug: string): AxiosPromise<PaginatedResponse<CampusWlc>>;
}

const CampusWlcApi: CampusWlcApiInterface = {
  getCampusWlcs(campusSlug) {
    const query = new Query({ campus__slug: campusSlug });
    return axios.get(`${resources.default}/${query}`);
  },
};

export default CampusWlcApi;
export { CampusWlcApiInterface };
