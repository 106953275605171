import * as Sentry from '@sentry/vue';

import Vue from 'vue';

if (process.env.VUE_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    Vue: Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    autoSessionTracking: true,
    release: 'automator@' + process.env.VUE_APP_VERSION,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,

    logErrors: true,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate:
      process.env.VUE_APP_SENTRY_ENVIRONMENT === 'production' ? 0.2 : 1.0,
  });
}
