const translations: {
  [key: string]: {
    singular: string;
    plural?: string;
  };
} = {
  'L2 Switch': { singular: 'L2 stikalo', plural: 'L2 stikala' },
  'L3 Switch': { singular: 'L3 stikalo', plural: 'L3 stikala' },
  'Access Point': { singular: 'Dostopovna točka', plural: 'Dostopne točke' },
  'Router': { singular: 'Usmerjevalnik', plural: 'Usmerjevalniki' },
  'Unknown': { singular: 'Neznano', plural: 'Neznane' },
  'Other': { singular: 'Drugo', plural: 'Drugo' },
  'active': { singular: 'Aktivna', plural: 'Aktivne' },
  'discovered': { singular: 'Zaznana', plural: 'Zaznane' },
  'planned': { singular: 'Planirana', plural: 'Planirane' },
  'retired': { singular: 'Upokojena', plural: 'Upokojene' },
  'upstream': { singular: 'Navzgornji prenos podatkov' },
  'type': { singular: 'tip' },
  'description': { singular: 'opis' },
  'function': { singular: 'funkcija' },
  'role': { singular: 'vloga' },
  'roles': { singular: 'vloge' },
  'label': { singular: 'oznaka' },
  'managed': { singular: 'upravljano' },
  'is_tsp': { singular: 'TSP' },
  'true': { singular: 'da' },
  'false': { singular: 'ne' },
  'l2_switch': { singular: 'l2 stikalo' },
  'l3_switch': { singular: 'l3 stikalo' },
  'router': { singular: 'usmerjevalnik' },
  'other': { singular: 'drugo' },
  'access_point': { singular: 'dostopovna točka' },
  'device': { singular: 'naprava' },
  'interface': { singular: 'vmesnik' },
  'enabled': { singular: 'omogočeno' },
  'up': { singular: 'aktiven' },
  'source': { singular: 'izvor' },
  'target': { singular: 'ponor' },
  'name': { singular: 'ime' },
  'is_circuit': { singular: 'je povezava' },
  'open': { singular: 'Odprto' },
  'eduroam': { singular: 'eduroam' },
  'wpa2-enterprise': { singular: 'WPA2 Enterprise' },
  'psk': { singular: 'PSK' },
  'l2': { singular: 'L2' },
  'l3': { singular: 'L3' },
  'tunnel': { singular: 'Tunel' },
};

/**
 * Converts the first character of a string to capital (uppercase) letter.
 * If the string has its first character as capital, then it returns the original string.
 *
 * @param {string} s
 * @param {boolean} [uncapitalize=false] if true then it converts the first character of a string to lowercase letter.
 * @return {*}  {string}
 */
function capitalize(s: string, uncapitalize = false): string {
  if (uncapitalize) return s.charAt(0).toLowerCase() + s.slice(1);
  return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * Translates given word from english terminology to slovene.
 * If the word doesn't exist in dictionary it returns untranslated word.
 *
 * @param s word to translate
 * @param [plural=false] If true the translated word will be in plural from.
 * @param [cap=true] If true the translated word will be capitalized.
 * @param [defaultCap=false] If true the translated word will be returned in capitalization as defined in dictionary.
 * @return {*}  {string}
 */
function translateWord(
  s: string,
  plural = false,
  cap = true,
  defaultCap = false,
): string {
  const form = plural ? 'plural' : 'singular';
  let out = s;
  if (translations[s] && translations[s][form]) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    out = translations[s][form]!;
  }
  if (defaultCap) {
    return out;
  } else {
    return cap ? capitalize(out) : capitalize(out, true);
  }
}

export default translateWord;
