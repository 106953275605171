import {
  CLEAR_APP_STATE,
  SET_CAMPUS_DATA,
  SET_CAMPUS_NAVBAR,
  SET_DRAWER,
} from '../actions.type';
import { Campus, RouteItem } from '@/api/interfaces';
import {
  GET_APPBAR_ITEMS,
  GET_DRAWER,
  GET_NAVBAR_ITEMS,
  ROLES,
} from '../getters.type';
import {
  RESET_STATE,
  SET_DRAWER_STATE,
  SET_NAVBAR_ITEMS,
  SET_SELECTED_CAMPUS,
  SET_SELECTED_CAMPUS_SLUG,
} from '../mutations.type';

import { AppState } from '../types';
import { deepClone } from '@/helpers';

const defaultNavbar: RouteItem[] = [
  // {
  //   icon: 'mdi-home',
  //   title: 'Domov',
  //   routeName: 'home',
  // },
];

const authAppBar: RouteItem[] = [
  {
    icon: 'mdi-domain',
    title: 'Kampusi',
    routeName: 'campuses',
    visibleTo: ['admin', 'support', 'roid'],
    description: 'Kampusi so lokalna omrežja članic, katera upravlja Arnes.',
  },
  {
    icon: 'mdi-sitemap',
    title: 'Vozlišča',
    routeName: 'sites',
    visibleTo: ['admin', 'support'],
    description: 'Pregled in urejanje vozlišč.',
  },
  {
    icon: 'mdi-tool',
    title: 'Orodja',
    routeName: 'tools',
    visibleTo: ['admin', 'support', 'roid'],
    children: [
      {
        icon: 'mdi-server',
        title: 'DHCP Strežniki',
        routeName: 'dhcpServers',
        visibleTo: ['admin', 'support'],
        description: 'Prikaz in urejanje DHCP strežnikov.',
      },
      {
        icon: 'mdi-server',
        title: 'DNS Strežniki',
        routeName: 'dnsResolvers',
        visibleTo: ['admin', 'support'],
        description: 'Prikaz in urejanje DNS strežnikov.',
      },
      {
        icon: 'mdi-console-network',
        title: 'Management omrežja',
        routeName: 'managementNetworks',
        visibleTo: ['admin', 'support'],
        description: 'Prikaz management omrežij.',
      },
      {
        icon: 'mdi-flower-tulip',
        title: 'Celery Flower',
        visibleTo: ['admin'],
        externalUrl: '/i/flower/',
        description: 'Povezava do Celery Flower strani.',
      },
      {
        icon: 'mdi-account-supervisor',
        title: 'Admin',
        routeName: 'adminPage',
        visibleTo: ['admin', 'support'],
        description: 'Pregledna plošča za administratorje.',
      },
      {
        icon: 'mdi-table-network',
        title: 'Skupine con',
        routeName: 'zone-groups',
        visibleTo: ['admin', 'support'],
        description: 'Prikaz in urejanje skupin con.',
      },
      {
        icon: 'mdi-router-wireless-settings',
        title: 'Strojna oprema',
        routeName: 'inventory',
        visibleTo: ['admin', 'support', 'roid'],
        description: 'Pregled dodeljene strojne opreme in opreme za prevzem.',
      },
      {
        icon: 'mdi-chip',
        title: 'Nova oprema',
        routeName: 'latest-devices',
        visibleTo: ['admin', 'support'],
        description: 'Pregled nove omrežne opreme.',
      },
    ],
  },
];
const standardAppBarItems: RouteItem[] = [];

const appState: AppState = {
  selectedCampusSlug: null, // need this first for fast rendering of navbar
  selectedCampus: null,
  navBarItems: defaultNavbar,
  authAppBarItems: authAppBar,
  standardAppBarItems: standardAppBarItems,
  drawer: true,
  loading: false,
};

function filterAllowedRoutes(
  routeItems: RouteItem[],
  rootGetters: any,
): RouteItem[] {
  const visibleAuthItems = [];
  for (const authItem of routeItems) {
    if (
      authItem.visibleTo!.filter((x) =>
        rootGetters[`user/${ROLES}`].includes(x),
      ).length > 0
    ) {
      let item = authItem;
      if (authItem.children) {
        item = deepClone(authItem);
        item.children = item.children!.filter((child) => {
          return (
            child.visibleTo!.filter((group) =>
              rootGetters[`user/${ROLES}`].includes(group),
            ).length > 0
          );
        });
      }
      visibleAuthItems.push(item);
    }
  }
  return visibleAuthItems;
}

const getters = {
  /**
   * Returns navbar items when campus is selected
   */
  [GET_NAVBAR_ITEMS](
    state: AppState,
    getters: any,
    rootState: any,
    rootGetters: any,
  ) {
    if (state.selectedCampusSlug) {
      return filterAllowedRoutes(state.navBarItems, rootGetters);
    } else {
      return [];
    }
  },
  [GET_APPBAR_ITEMS](
    state: AppState,
    getters: any,
    rootState: any,
    rootGetters: any,
  ) {
    const standardItems: RouteItem[] = [];
    const visibleAuthItems = filterAllowedRoutes(
      state.authAppBarItems,
      rootGetters,
    );
    return state.standardAppBarItems.concat(visibleAuthItems);
  },
  [GET_DRAWER](state: AppState) {
    return state.drawer;
  },
};

const mutations = {
  [RESET_STATE](state: AppState) {
    state.selectedCampus = null;
    state.selectedCampusSlug = null;
    state.navBarItems = defaultNavbar;
    state.drawer = true;
  },
  [SET_SELECTED_CAMPUS](state: AppState, campus: Campus) {
    state.selectedCampus = campus;
  },
  [SET_SELECTED_CAMPUS_SLUG](state: AppState, campusSlug: string) {
    state.selectedCampusSlug = campusSlug;
  },
  [SET_DRAWER_STATE](state: AppState, value: boolean) {
    state.drawer = value;
  },
  [SET_NAVBAR_ITEMS](state: AppState) {
    if (state.selectedCampusSlug) {
      state.navBarItems = defaultNavbar.concat([
        {
          icon: 'mdi-office-building',
          title: 'Splošno',
          routeName: 'campusGeneral',
          visibleTo: ['admin', 'support', 'roid'],
          params: { campusSlug: state.selectedCampusSlug },
        },
        {
          icon: 'mdi-wan',
          title: 'Povezave',
          routeName: 'circuits',
          visibleTo: ['admin', 'support', 'roid'],
          params: { campusSlug: state.selectedCampusSlug },
        },
        {
          icon: 'mdi-lan',
          title: 'Omrežja',
          routeName: 'networks',
          visibleTo: ['admin', 'support', 'roid'],
          params: { campusSlug: state.selectedCampusSlug },
        },
        {
          icon: 'mdi-router-wireless',
          title: 'Omrežna oprema',
          routeName: 'devices',
          visibleTo: ['admin', 'support', 'roid'],
          params: { campusSlug: state.selectedCampusSlug },
        },
        {
          icon: 'mdi-graph',
          title: 'Topologija omrežja',
          routeName: 'visualizeNetworkTopology',
          visibleTo: ['admin', 'support', 'roid'],
          params: { campusSlug: state.selectedCampusSlug },
        },
      ]);
    }
  },
};

const actions: any = {
  [SET_CAMPUS_NAVBAR]({ commit }: any, campusSlug: string) {
    commit(SET_SELECTED_CAMPUS_SLUG, campusSlug);
    commit(SET_NAVBAR_ITEMS);
  },
  [SET_CAMPUS_DATA]({ commit }: any, campus: Campus) {
    commit(SET_SELECTED_CAMPUS, campus);
  },
  [SET_DRAWER]({ commit }: any, value: boolean) {
    commit(SET_DRAWER_STATE, value);
  },
  [CLEAR_APP_STATE]({ commit }: any) {
    commit(RESET_STATE);
  },
};

export default {
  namespaced: true,
  state: appState,
  getters,
  mutations,
  actions,
};
