import axios, { AxiosPromise } from 'axios';
import { ProductType, PaginatedResponse } from '@/api/interfaces';

const resources = {
  default: 'infrastructure/product-types',
};

interface ProductTypeApiInterface {
  getProductTypes(): AxiosPromise<PaginatedResponse<ProductType>>;
  getProductType(productTypeId: number | string): AxiosPromise<ProductType>;
}

const ProductTypeApi: ProductTypeApiInterface = {
  getProductTypes() {
    return axios.get(`${resources.default}/`);
  },
  getProductType(productTypeId) {
    return axios.get(`${resources.default}/${productTypeId}/`);
  },
};

export default ProductTypeApi;
export { ProductTypeApiInterface };
