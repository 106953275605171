import axios, { AxiosPromise } from 'axios';
import { User, CampusSimple } from '@/api/interfaces';
import { Query } from '../query';

const resources = { default: 'people' };


interface UserApiInterface {
  getUserInfo(): AxiosPromise<User>;
  getUserCampuses(username?: string | null): AxiosPromise<CampusSimple[]>;
  searchUsers(search: string): AxiosPromise<User[]>;
  changeSigningKey(): AxiosPromise<any>;
}

const UserApi: UserApiInterface = {
  /*
   * Returns info about the logged in user
   */
  getUserInfo() {
    return axios.get(`${resources.default}/get-user-info/`);
  },
  getUserCampuses(username) {
    const query = new Query({});
    if (username) {
      query.addParam('username', username);
    }
    return axios.get(`${resources.default}/users/campuses/${query}`);
  },
  searchUsers(search='') {
    const query = new Query({});
    if (search) {
      query.addParam('search', search);
    }
    return axios.get(`${resources.default}/users/${query}`);
  },
  changeSigningKey() {
    return axios.post(`${resources.default}/change-signing-key/`, {});
  },
};

export default UserApi;
export { UserApiInterface };
