import axios, { AxiosPromise } from 'axios';
import { Interface, PaginatedResponse } from '@/api/interfaces';
import { Query } from '../query';

const base = 'infrastructure';
const resources = {
  default: `${base}/interfaces`,
  iws: `${base}/interfaces-with-switchports`,
};

interface InterfaceApiInterface {
  getInterfacesByDeviceId(
    deviceId: number,
    options: { physicalOnly?: boolean | null },
  ): AxiosPromise<PaginatedResponse<Interface>>;
  getWithSwitchports(
    deviceId: number,
  ): AxiosPromise<PaginatedResponse<Interface>>;
}

const interfaceApi: InterfaceApiInterface = {
  getInterfacesByDeviceId(deviceId, { physicalOnly = null }) {
    const query = new Query({
      device: deviceId,
      physical_only: physicalOnly,
    });
    return axios.get(`${resources.default}/${query}`);
  },

  getWithSwitchports(deviceId) {
    const query = new Query({
      device: deviceId,
    });
    return axios.get(`${resources.iws}/${query}`);
  },
};

export default interfaceApi;
export { InterfaceApiInterface };
