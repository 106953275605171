
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import { Device } from '@/api/interfaces';
import { AVAILABLE_NETBOX_ASSET_STATUSES } from '../shared/constants';
import { repositories } from '@/api/ApiFactory';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import ErrorHandler from '@/components/shared/errorHandler';

@Component
export default class ConfirmDeviceDisconnectInvModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private device!: Device;
  private submiting = false;
  private availableAssetStatuses = AVAILABLE_NETBOX_ASSET_STATUSES;
  private form: { assetStatus: string | null } = {
    assetStatus: null,
  };
  private confirmText = '';
  private requiredConfirmText = '';

  private created() {
    this.requiredConfirmText = this.device.name.split('.')[0];
  }

  private async submit() {
    this.submiting = true;
    try {
      await repositories.infrastructure.asset.disconnectInv(
        this.device.asset!.id as number,
        this.form.assetStatus as string,
      );
      this.$toasted.success('Povezava z inventarjem je bila prekinjena');
      this.ok(true);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    } finally {
      this.submiting = false;
    }
  }
}
