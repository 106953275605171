
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { Network } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import _get from 'lodash.get';
import ManagedCampuses from '@/components/managementNetwork/ManagedCampuses.vue';
import ManagedDevices from '@/components/managementNetwork/ManagedDevices.vue';
import ManagementSubnets from '@/components/managementNetwork/ManagementSubnets.vue';
import ManagementNetworkGateways from '@/components/managementNetwork/ManagementNetworkGateways.vue';
import { prettyPrintFullDate } from '@/utils/filters';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    ManagedCampuses,
    ManagedDevices,
    ManagementNetworkGateways,
    ManagementSubnets,
  },
})
export default class ManagementNetworkDetail extends Vue {
  @Prop(String) private managementNetworkId!: string;
  @userModule.Getter(IS_ADMIN) public isAdmin!: boolean;

  private skeletonAttrs = {
    class: 'pt-2',
    boilerplate: true,
    elevation: 0,
  };

  private managementNetwork: Network | null = null;

  private loading = {
    initial: true,
  };

  private get basicInfo(): {
    divider: boolean;
    subheader: string | null;
    title: string;
    icon: string;
    text: string;
  }[] {
    return [
      {
        divider: false,
        subheader: 'Omrežje',
        title: 'Ime',
        icon: 'mdi-text',
        text: _get(this.managementNetwork, 'name', ''),
      },
      {
        divider: false,
        subheader: null,
        title: 'Oznaka',
        icon: 'mdi-label',
        text: _get(this.managementNetwork, 'slug', ''),
      },
      {
        divider: false,
        subheader: null,
        title: 'Opis',
        icon: 'mdi-text-long',
        text: _get(this.managementNetwork, 'description', '') || '',
      },
      {
        divider: false,
        subheader: null,
        title: 'Ustvarjeno',
        icon: 'mdi-calendar',
        text:
          this.managementNetwork && this.managementNetwork.created
            ? prettyPrintFullDate(this.managementNetwork.created)
            : '/',
      },
    ];
  }

  private get vlanInfo() {
    return [
      {
        divider: false,
        subheader: 'Vlan',
        title: 'Ime',
        icon: 'mdi-text',
        text: _get(this.managementNetwork, 'vlan.name', ''),
      },
      {
        divider: false,
        subheader: null,
        title: 'Vlan ID',
        icon: 'mdi-numeric',
        text: _get(this.managementNetwork, 'vlan.vid', ''),
      },
      {
        divider: false,
        subheader: null,
        title: 'Opis',
        icon: 'mdi-text-long',
        text: _get(this.managementNetwork, 'vlan.description', ''),
      },
    ];
  }

  public async fetchManagementNetwork() {
    this.loading.initial = true;
    try {
      const { data } = await repositories.lan.network.getNetwork(
        this.managementNetworkId,
      );
      this.managementNetwork = data;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'management omrežju' },
        ).toString(),
      );
    }
    this.loading.initial = false;
  }

  public async created(): Promise<void> {
    this.fetchManagementNetwork();
  }

  private showManagementNetworkModal() {
    const promise: Promise<Network> = this.$modals.open(
      'app-management-network-modal',
      {
        component: {
          props: {
            managementNetwork: this.managementNetwork,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Network>;
    // fetch networks again to update network info
    promise.then((modifiedOrCreatedNetwork: Network) => {
      this.fetchManagementNetwork();
    });
  }

  private openDeleteManagementNetworkModal() {
    if (!this.managementNetwork) {
      return;
    }
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `management omrežje ${this.managementNetwork.name}`,
            item: this.managementNetwork,
          },
          deleteFn: this.deleteManagementNetwork,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteManagementNetwork(modal: any, network: Network) {
    repositories.lan.network
      .deleteNetwork(network.id as number)
      .then(() => {
        this.$toasted.success('Omrežje je bilo uspešno izbrisano.');
        this.$modals.close();
        this.$router.push({ name: 'managementNetworks' });
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
        modal.deleting = false;
      });
  }
}
