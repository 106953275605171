
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { deepClone } from '@/helpers';
import { Product, ProductType, Manufacturer } from '@/api/interfaces';
import DrawProduct from '@/components/shared/DrawProduct.vue';
import ProductInfoManual from './ProductInfoManual.vue';

@Component({
  components: {
    DrawProduct,
    ProductInfoManual,
  },
})
export default class ProductAdd extends Vue {
  private loading = {
    manufacturers: false,
    productTypes: false,
    submit: false,
  };

  private form: {
    info: string;
    name: string;
    manufacturer: number | null;
    productType: number | null;
    platform: string | null;
    part_number: string | null;
    description: string | null;
    comment: string | null;
    managed: boolean;
    requires_mac: boolean;
  } = {
    info: '{ "port_groups": [] }',
    name: '',
    manufacturer: null,
    productType: null,
    platform: null,
    part_number: null,
    description: null,
    comment: null,
    managed: false,
    requires_mac: true,
  };

  private manufacturers: Manufacturer[] = [];
  private productTypes: ProductType[] = [];

  private get items() {
    return [
      {
        title: 'Ime',
        info: {
          type: 'text',
          attr: 'name',
          formName: 'name',
          rules: 'required|max:255',
          counter: 255,
        },
      },
      {
        title: 'Proizvajalec',
        align: 'left',
        info: {
          'type': 'select',
          'attr': 'manufacturer.name',
          'formName': 'manufacturer',
          'rules': 'required',
          'items': this.manufacturers,
          'item-text': 'name',
          'item-value': 'id',
        },
      },
      {
        title: 'Tip',
        align: 'left',
        info: {
          'type': 'select',
          'attr': 'type.name',
          'formName': 'productType',
          'rules': 'required',
          'items': this.productTypes,
          'item-text': 'name',
          'item-value': 'id',
        },
      },
      {
        title: 'Platforma',
        info: {
          type: 'text',
          attr: 'platform',
          formName: 'platform',
          rules: 'max:255',
          counter: 255,
        },
      },
      {
        title: 'Oznaka proizvajalca',
        info: {
          type: 'text',
          attr: 'part_number',
          formName: 'part_number',
          rules: 'max:255',
          counter: 255,
        },
        description: 'Part number',
      },
      {
        title: 'Opis',
        info: {
          type: 'text',
          attr: 'description',
          formName: 'description',
          rules: 'max:255',
          counter: 255,
        },
      },
      {
        title: 'Komentar',
        info: {
          type: 'text',
          attr: 'comment',
          formName: 'comment',
        },
      },
      {
        title: 'Upravljan',
        info: {
          type: 'checkbox',
          attr: 'managed',
          formName: 'managed',
        },
      },
      {
        title: 'Zahteva MAC',
        info: {
          type: 'checkbox',
          attr: 'requires_mac',
          formName: 'requires_mac',
        },
      },
    ];
  }

  private get isValid(): boolean {
    return Boolean(this.tryParseJSON(this.form.info));
  }

  private get loadingInitial(): boolean {
    return this.loading.manufacturers || this.loading.productTypes;
  }

  private get getFormData(): Product | null {
    const newJson = this.tryParseJSON(this.form.info);
    if (!newJson) {
      return null;
    }
    const data = deepClone(this.form);
    data['info'] = newJson;
    return data;
  }

  private async fetchManufacturers() {
    this.loading.manufacturers = true;
    try {
      const { data } =
        await repositories.infrastructure.manufacturer.getManufacturers();
      this.manufacturers = data.results;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.manufacturers = false;
  }

  private async fetchProductTypes() {
    this.loading.productTypes = true;
    try {
      const { data } =
        await repositories.infrastructure.productType.getProductTypes();
      this.productTypes = data.results;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.productTypes = false;
  }

  private created() {
    this.fetchManufacturers();
    this.fetchProductTypes();
  }

  private tryParseJSON(jsonString: string) {
    try {
      const o = JSON.parse(jsonString);
      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
      //
    }
    return false;
  }

  private submit() {
    if (this.isValid && this.form.info) {
      this.loading.submit = true;
      repositories.infrastructure.product
        .createProduct(
          this.form.name,
          this.tryParseJSON(this.form.info),
          this.form.manufacturer,
          this.form.productType,
          this.form.platform,
          this.form.part_number,
          this.form.description,
          this.form.comment,
          this.form.managed,
          this.form.requires_mac,
        )
        .then((res) => {
          this.$toasted.success('Product created');
          this.$router.push({ name: 'products' });
        })
        .catch((error) => {
          this.$toasted.error(
            new ErrorHandler({ error, status: true }).toString(),
          );
        })
        .finally(() => {
          this.loading.submit = false;
        });
    }
  }
}
