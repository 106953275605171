
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { Device } from '@/api/interfaces';
import { sloveneStatusNames } from '@/utils/constants';
import { AVAILABLE_NETBOX_ASSET_STATUSES } from '../shared/constants';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class DeviceChangeStatusModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private devices!: Device[];
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private immediateValidation = false;
  private loading = {
    submit: false,
  };
  private status: string | null = null;
  private netboxNewAssetStatus: string | null = null;
  private availableAssetStatuses = AVAILABLE_NETBOX_ASSET_STATUSES;
  private availableStatuses = [
    { text: sloveneStatusNames['planned'], value: 'planned' },
    { text: sloveneStatusNames['active'], value: 'active' },
    {
      text: sloveneStatusNames['discovered'],
      value: 'discovered',
      disabled: true,
    },
    { text: sloveneStatusNames['maintenance'], value: 'maintenance' },
    { text: sloveneStatusNames['retired'], value: 'retired' },
  ];
  private restrictedStatusValues = '';

  get showNetboxAssetSelect() {
    return this.status === 'retired' && this.devices.filter((d) => d.inventory_sys_id != null).length > 0
  }

  private created() {
    if (this.devices.length === 0) {
      throw Error('Missing devices');
    }
    this.restrictedStatusValues = this.availableStatuses
      .filter((el) => el.disabled === true)
      .map((el) => el.value)
      .join(',');
    // if all devices have the same status then make this one selected
    if (
      [...new Set(this.devices.map((device) => device.status))].length === 1
    ) {
      this.status = this.devices[0].status;
      this.immediateValidation = true;
    }
  }

  private async submit() {
    if (this.status === 'retired') {
      return this.showTypeConfirmModal(this.devices);
    }
    this.changeDevicesStatus();
  }

  private showTypeConfirmModal(devices: Device[]): void {
    let requiredConfirmText = 'prosim';
    if (devices.length === 1) {
      requiredConfirmText = devices[0].name.split('.')[0];
    }
    const promise: Promise<boolean> = this.$modals.open(
      'app-type-confirm-modal',
      {
        component: {
          props: {
            title: 'Potrdi upokojitev naprav',
            // vuex-modals currently doesn't support slots, so we pass html here
            text:
              'Izbrana akcija bo sprožila <b>izbris</b> naprav: <ul>' +
              devices.map((d) => `<li>${d.name}</li>`).join('') +
              '</ul>',
            requiredConfirmText: requiredConfirmText,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<boolean>;
    promise.then((res: boolean) => {
      if (res) {
        this.changeDevicesStatus();
      }
    });
  }

  private async changeDevicesStatus() {
    this.loading.submit = true;
    try {
      await repositories.infrastructure.device.changeDevicesStatus(
        this.devices.map((device: Device) => device.name),
        this.status as string,
        this.showNetboxAssetSelect ? this.netboxNewAssetStatus : null,
      );
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
      this.ok(this.status);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.submit = false;
  }
}
