var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-toolbar',{attrs:{"color":"subsecondary","extended":"","extension-height":"65"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-container',{staticClass:"pa-0 fill-height"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"solo":"","rounded":"","clearable":"","prepend-inner-icon":"mdi-magnify","label":"Išči","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true}])},[_c('v-toolbar-title',[_c('v-icon',[_vm._v("mdi-switch")]),_vm._v(" Naprave ")],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.devices,"search":_vm.search,"loading":_vm.loading.initial,"server-items-length":_vm.totalDevices,"footer-props":{ 'items-per-page-options': _vm.itemsPerPageOptions },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.status`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.sloveneStatusNamesRef[item.status])+" ")]}},{key:`item.roles`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.roles.map((el) => el.name).join(', '))+" ")]}},{key:`item.managed`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.managed ? 'Da' : 'Ne')+" ")]}},{key:`item.group`,fn:function({ item }){return [[(item.sites.length > 0 && (_vm.isTSP(item) || _vm.isBackbone(item)))?_c('router-link',{attrs:{"to":{
                  name: 'site',
                  params: {
                    siteSlug: item.sites[0],
                  },
                }}},[_c('b',[_vm._v("Vozlišče "+_vm._s(item.sites[0]))])]):(item.campuses.length > 0)?_c('router-link',{attrs:{"to":{
                  name: 'campusGeneral',
                  params: {
                    campusSlug: item.campuses[0],
                  },
                }}},[_c('b',[_vm._v("Kampus "+_vm._s(item.campuses[0]))])]):_vm._e()]]}},{key:`item.name`,fn:function({ item }){return [(_vm.supportedMIFunctions.includes(item.function.slug) && !_vm.isBackbone(item))?[(item.sites.length > 0 && _vm.isTSP(item))?_c('router-link',{attrs:{"to":{
                  name: 'interfacesOnSiteDevice',
                  params: {
                    deviceName: item.name,
                    siteSlug: item.sites[0],
                    mode: _vm.ModifyInterfacesComponentMode.SITE,
                    multiple: '1',
                  },
                },"disabled":item.status === 'retired'}},[_c('b',[_vm._v(_vm._s(item.name))])]):(item.campuses.length > 0)?_c('router-link',{attrs:{"to":{
                  name: 'interfacesOnDevice',
                  params: {
                    deviceName: item.name,
                    campusSlug: item.campuses[0],
                    multiple: '1',
                  },
                },"disabled":item.status === 'retired'}},[_c('b',[_vm._v(_vm._s(item.name))])]):_vm._e()]:_c('span',[_vm._v(_vm._s(item.name))])]}},{key:`item.created`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.created ? new Date(item.created).toLocaleString('sl-SI') : '')+" ")]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }