import axios, { AxiosPromise } from 'axios';

const resources = {
  default: 'infrastructure/ports',
};

interface PortApiInterface {
  changeActiveInterfaceOnPort(
    portId: string | number,
    newActiveInterfaceId: string | number,
  ): AxiosPromise<any>;
}

const PortApi: PortApiInterface = {
  changeActiveInterfaceOnPort(portId, newActiveInterfaceId) {
    return axios.patch(`${resources.default}/${portId}/set-active-interface/`, {
      interface: newActiveInterfaceId,
    });
  },
};

export default PortApi;

export { PortApiInterface };
