import { DataOptions, PaginatedResponse, Product } from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';

const resources = {
  default: 'infrastructure/products',
};

interface ProductApiInterface {
  getProduct(id: string | number): AxiosPromise<Product>;
  getProducts(
    pagination?: DataOptions | null,
    search?: string,
    query?: Query,
  ): AxiosPromise<PaginatedResponse<Product>>;
  updateProduct(
    productId: number,
    name: string,
    info: unknown,
    manufacturer: number | null,
    productType: number | null,
    platform: string | null,
    part_number: string | null,
    description: string | null,
    comment: string | null,
    managed: boolean,
    requires_mac: boolean,
  ): AxiosPromise<Product>;
  createProduct(
    name: string,
    info: unknown,
    manufacturer: number | null,
    productType: number | null,
    platform: string | null,
    part_number: string | null,
    description: string | null,
    comment: string | null,
    managed: boolean,
    requires_mac: boolean,
  ): AxiosPromise<Product>;
}

const ProductApi: ProductApiInterface = {
  getProduct(id) {
    return axios.get(`${resources.default}/${id}/`);
  },
  getProducts(pagination = null, search = '', query = undefined) {
    if (!query) {
      query = new Query({});
    }

    if (pagination !== null) {
      query.addParam('size', pagination.itemsPerPage);
      query.addParam('page', pagination.page);
      let sortBy = pagination.sortBy[0];
      if (sortBy) {
        if (pagination.sortDesc[0]) {
          sortBy = `-${sortBy}`;
        }
        query.addParam('sort_by', sortBy);
      }
    }
    if (search !== null) {
      query.addParam('search', search);
    }

    return axios.get(`${resources.default}/${query}`);
  },
  updateProduct(
    productId,
    name,
    info,
    manufacturer,
    productType,
    platform,
    part_number,
    description,
    comment,
    managed,
    requires_mac,
  ) {
    return axios.patch(`${resources.default}/${productId}/`, {
      name,
      info,
      manufacturer,
      type: productType,
      platform,
      part_number,
      description,
      comment,
      managed,
      requires_mac,
    });
  },
  createProduct(
    name,
    info,
    manufacturer,
    productType,
    platform,
    part_number,
    description,
    comment,
    managed,
    requires_mac,
  ) {
    return axios.post(`${resources.default}/`, {
      name,
      info,
      manufacturer,
      type: productType,
      platform,
      part_number,
      description,
      comment,
      managed,
      requires_mac,
    });
  },
};

export default ProductApi;
export { ProductApiInterface };
