
import axios, { AxiosError } from 'axios';
import { repositories } from '@/api/ApiFactory';
import { Graph, Node } from '@/api/interfaces';
import NetworkTopology from '@/components/campus/networkTopology/NetworkTopology.vue';
import ErrorHandler from '@/components/shared/errorHandler';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import _get from 'lodash.get';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({ components: { NetworkTopology } })
export default class VisualizeNetwork extends Vue {
  @Prop() private campusSlug!: string;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;

  private loading = {
    initial: true,
    facts: false,
    updatingGraph: false,
  };

  private isLastTaskStatusOk = true;
  private deviceFactsKey = 0;
  private deviceFacts: {
    data: Graph | null;
    graphId: string | null;
  } = { data: null, graphId: null };
  private intervalReference: number | null = null;
  private readonly intervalDuration = 15000;
  private intervalNextUpdate: Date | null = null;
  private currentTime: Date = new Date();
  private lastUpdated: Date | null = null;

  get remainingUntilNextUpdate(): number | null {
    if (this.intervalNextUpdate === null) {
      return null;
    }
    return this.$moment(this.intervalNextUpdate).diff(
      this.currentTime,
      'seconds',
    );
  }

  get graph(): {
    nodes: Record<string, unknown>[];
    edges: Record<string, unknown>[];
  } {
    const obj: {
      nodes: Record<string, unknown>[];
      edges: Record<string, unknown>[];
    } = { nodes: [], edges: [] };
    function getGroup(nodeFunction: string) {
      let group = 0;
      switch (nodeFunction) {
        case 'router':
          group = 1;
          break;
        case 'l3_switch':
          group = 2;
          break;
        case 'l2_switch':
          group = 3;
          break;
        case 'access_point':
          group = 4;
          break;
        default:
          group = 5;
      }

      return group;
    }

    if (this.deviceFacts.data) {
      const nodes = this.deviceFacts?.data?.data?.nodes;
      if (nodes) {
        obj.nodes = Object.values(nodes).map((node: Node) => {
          return {
            ...node,
            id: node.label,
            group: getGroup(node.function),
          };
        });
      }

      obj.edges = this.deviceFacts?.data?.data?.edges.map((edge: any) => {
        return {
          source: edge.source.name,
          target: edge.target.name,
          data: edge,
          value: 10,
        };
      });
    }
    return obj;
  }

  get lastUpdatedText(): string {
    if (this.lastUpdated === null) {
      return '';
    }
    const momentDate = this.$moment(this.lastUpdated);
    return `Podatki pridobljeni iz omrežja ${momentDate.format(
      'DD. MM. YYYY',
    )} ${momentDate.format('HH:mm')}`;
  }
  private showNetworkTopologyHelpModal() {
    this.$modals.open('app-network-topology-help-modal', {
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }
  private async fetchFactsData(graphId?: string) {
    this.intervalNextUpdate = new Date(
      new Date().getTime() + this.intervalDuration,
    );
    if (graphId) {
      this.loading.updatingGraph = true;
    } else {
      this.loading.facts = true;
    }
    try {
      const res =
        await repositories.orchestrator.task.getTaskResultsCampusGraphGetFacts(
          this.campusSlug,
          graphId,
        );

      const { data } = res;

      if (
        data.graph === null ||
        (graphId && graphId !== _get(data, 'graph.id'))
      ) {
        if (data.graph === null) {
          if (graphId) return;
          else
            this.$toasted.info(
              'Za ta kampus še ne obstaja graf topologije omrežja. \n  Ustvarite ga s klikom na gumb s napisom "POSODOBI".',
            );
        }
        this.loading.facts = false;
        return;
      }

      if (!_get(data, 'graph.data.success', false)) {
        if (_get(data, 'graph.data.error', false)) {
          this.$toasted.error(
            _get(
              data,
              'graph.data.msg',
              'Pridobivanje grafa iz omrežja je bilo neuspešno.',
            ),
          ),
            (this.isLastTaskStatusOk = false);
          this.clearFetchInterval();
          this.loading.facts = false;
          return;
        }
        return;
      }

      this.deviceFacts.graphId = data.graph.id;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.lastUpdated = new Date(data.graph.last_update!);

      if (data.graph.data.data) {
        // let the cycle spin for at least 1 second, better than super fast show/hide
        setTimeout(
          ((innerData: any) => {
            this.loading.facts = false;
            if (graphId) {
              this.loading.updatingGraph = false;
              this.clearFetchInterval();
            }
            this.deviceFacts.data = innerData.graph.data;
          }).bind(this, data),
          1000,
        );
        return;
      }
      // let the cycle spin for at least 1 second, better than super fast show/hide
      setTimeout(() => {
        this.loading.facts = false;
        this.isLastTaskStatusOk = true;
      }, 1000);
    } catch (error: unknown) {
      setTimeout(() => {
        this.isLastTaskStatusOk = false;
        if (error && error instanceof AxiosError) {
          this.$toasted.error(
            new ErrorHandler(
              { error, status: true },
              {
                message: `Napaka pri posodabljanju grafa: ${error?.response?.data?.detail}`,
              },
            ).toString(),
          );
        }
        this.clearFetchInterval();
        this.loading.updatingGraph = false;
        this.loading.facts = false;
      }, 1000);
    }
  }

  private async createGraphTask() {
    try {
      if (this.campusSlug) {
        this.loading.updatingGraph = true;
        const { data } =
          await repositories.orchestrator.task.createTaskCampusGraphGetFacts(
            this.campusSlug,
          );
        if (data.locked) {
          // when locked then there should always exist "detail" key
          this.$toasted.info(data.detail as string);
          this.loading.updatingGraph = false;
        } else {
          const graphId = data['graph_id'];
          // has to be window.setInterval otherwise it takes nodejs's setInterval which has different types
          this.intervalReference = window.setInterval(() => {
            this.fetchFactsData(graphId);
          }, this.intervalDuration);
          // first fetch should happen immediatelly
          this.fetchFactsData(graphId);
        }
      } else {
        this.$toasted.error('Ni podatkov o kampus omrezju in grafu.');
      }
    } catch (error) {
      this.loading.updatingGraph = false;
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'grafa' },
        ).toString(),
      );
    }
    this.deviceFactsKey += 1;
  }

  private clearFetchInterval() {
    if (this.intervalReference !== null) {
      window.clearInterval(this.intervalReference);
      this.intervalReference = null;
      this.intervalNextUpdate = null;
    }
  }

  private destroyed() {
    this.clearFetchInterval();
  }

  private async init() {
    this.clearFetchInterval();
    await this.fetchFactsData();
  }

  private created() {
    if (this.campusSlug) {
      this.init();
    }
    // need an interactive change of currentTime so that moment's diff gets refreshed
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  }
}
