
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';

@Component
export default class TypeConfirmModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop() private title!: string;
  @Prop() private text!: string;
  @Prop({ default: '' }) private requiredConfirmText!: string;

  private confirmText = '';

  private async submit() {
    this.ok(true);
  }
}
