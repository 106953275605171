import { AxiosError } from 'axios';
import _get from 'lodash.get';

interface ErrorHandlerParams {
  error?: Error | AxiosError | unknown;
  status?: boolean;
}

interface UserDefinedErrors {
  message?: string;
  itemMessageText?: string;
}

export default class ErrorHandler {
  private errorData: ErrorHandlerParams;
  private userData: UserDefinedErrors;

  constructor(
    errorParams: ErrorHandlerParams,
    private userParams: UserDefinedErrors = {},
  ) {
    const errorParamsDefaults = {
      status: false,
    };
    this.errorData = { ...errorParamsDefaults, ...errorParams };
    this.userData = userParams;
  }

  public toString(): string {
    try {
      const detail = JSON.parse(
        (this.errorData.error! as AxiosError).request.response,
      ).detail;
      if (detail === 'Avtentikacijski podatki niso bili podani.') {
        // if we return empty string, it won't show the toast! the login expired toast is called in App.vue
        // already, so we ignore it here.
        return '';
      } else if (detail === 'Ni najdeno') {
        // if we return empty string
        return '';
      }
    } catch (error) {
      // this is meant to be empty, comment required for TS compiler to pass
    }
    let message = this.getMessage();
    if (message === null) {
      if (this.errorData.error) {
        let prepend = '';
        if (this.errorData.status) {
          const statusCode = this.getStatusCode();
          if (statusCode !== '') {
            prepend = `(${statusCode}) `;
          }
        }
        message = `${prepend}${this.getResponseDetail()}`;
      } else {
        message = 'Prišlo je do napake.';
      }
    }
    return message;
  }

  private getMessage(): string | null {
    if (this.userData.itemMessageText) {
      if (this.userData.message) {
        return `${this.userData.message} ${this.userData.itemMessageText}.`;
      }
      return `Napaka pri pridobivanju podatkov o ${this.userData.itemMessageText}.`;
    }
    const message = _get(this.userData, 'message');
    if (typeof message === 'undefined') {
      return null;
    }
    return message;
  }

  private getStatusCode(): string {
    return _get(this.errorData, 'error.response.status', '') + '';
  }

  private replaceLtGtForHtml(s: string): string {
    return s.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }

  private getResponseDetail(): string {
    let detail: string | string[] | undefined = _get(
      this.errorData,
      'error.response.data.detail',
    );
    if (typeof detail === 'string') {
      return detail;
    }
    if (Array.isArray(detail)) {
      return (detail as string[]).map(this.replaceLtGtForHtml).join('<br>');
    } else if (typeof detail === 'object' && detail !== null) {
      // it's an object aka backend gave us dictionary
      const messages = [];
      for (const field in detail as Record<string, string>) {
        if (Object.prototype.hasOwnProperty.call(detail, field)) {
          let prepend = '';
          if (field !== '__all__') {
            prepend = `${field}: `;
          }
          let error: string = detail[field];
          if (Array.isArray(error)) {
            error = error.map(this.replaceLtGtForHtml).join('<br>');
          } else {
            error = this.replaceLtGtForHtml(error);
          }
          messages.push(`${prepend}${error}`);
        }
      }
      return messages.join('<br>');
    } else if (!detail && this.getStatusCode() === '400') {
      /* ValidationError in DRF gives you 'non_field_errors' with array as value and fields with arrays, eg:
       * {
       *   "non_field_errors": ["bla", "bla2"],
       *   "name": ["wrong"],
       * }
       */
      let messages: string[] = [];
      const data = _get(this.errorData, 'error.response.data', {});
      if (Array.isArray(data)) {
        // DRF's ValidationError returns list of messages in data
        // we replace < and > because sometimes we get str(instance) in the text
        // and if we don't replace them whatever is inside < and > gets cut out
        // eg. "[<Network: 918 - admin>]" would become "[]" which we don't want
        messages = data.map((msg: string) => this.replaceLtGtForHtml(msg));
      } else {
        // DRF's other exceptions which have 'detail' return an object with
        // properties where each property has a message
        for (const [prop, value] of Object.entries(data)) {
          messages.push(`${prop}:`);
          messages = messages.concat(
            (value as string[]).map((msg: string) => {
              msg = this.replaceLtGtForHtml(msg);
              return `&nbsp;&nbsp;&nbsp;&nbsp;- ${msg}`;
            }),
          );
        }
      }
      if (messages.length === 0) {
        messages.push('Prišlo je do napake.');
      }
      return messages.join('<br>');
    } else if (this.getStatusCode() === '500') {
      detail = 'Med izvajanjem je prišlo do napake';
    } else if (this.getStatusCode() === '503') {
      detail = 'Povezava do zalednega sistema ni uspela';
    } else {
      try {
        if (detail !== undefined) {
          detail = this.replaceLtGtForHtml(detail);
        } else {
          throw new Error('Detail is undefined');
        }
      } catch (error: unknown) {
        detail = 'Neznana napaka';
        if (this.errorData.error instanceof Error) {
          const err = this.errorData.error as Error;
          detail = err.message;
        }
      }
    }
    return detail;
  }
}
