
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, IS_ROID } from '@/store/getters.type';
import Autocomplete from '../shared/Autocomplete.vue';
import { Representations } from '../shared/representation';
import AssetSearch from './AssetSearch.vue';
import { Asset, NetboxAsset } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { CDevice } from '@/views/campus/CreateDevice.vue';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    Autocomplete,
    AssetSearch,
  },
})
export default class extends Vue {
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_ROID) private isRoid!: boolean;
  @Prop() private campusSlug!: string;
  @Prop() private title!: string;
  @Prop() private form!: CDevice;
  @Prop({ default: false }) private allowAssetsInUse!: boolean;

  private repr = new Representations();

  private asset: Asset = {
    serial_number: '',
    base_mac: null,
    product: null,
    inventory_sys_id: null,
  };
  private assetSelect: NetboxAsset[] = [];

  private getProductsApi = repositories.infrastructure.product.getProducts;

  private radioBtn = 'existing';

  get macRequired() {
    return this.asset.product?.requires_mac ?? false;
  }

  // parent component can get form data through this function
  public getAssetData() {
    if (this.radioBtn === 'existing') {
      return this.assetSelect.length > 0 ? this.assetSelect[0] : null;
    }
    return this.asset;
  }
}
