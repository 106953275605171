
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { Campus } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class CampusHeader extends Vue {
  @Prop() private campus!: Campus;
  @Prop() private loading!: boolean;
  @Prop() private value!: boolean;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;

  private emitDrawer() {
    this.$emit('input', !this.value);
  }

  private get toolbarHeight(): number {
    if (
      this.$vuetify.breakpoint.xs &&
      this.campus &&
      this.campus.name.length > 30
    ) {
      return 90;
    }
    return 60;
  }

  private async openEditCampusModal() {
    const promise: Promise<Campus> = this.$modals.open(
      'app-campus-edit-modal',
      {
        component: {
          props: {
            campus: this.campus,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Campus>;
    // update campus
    promise.then((modifiedCampus: Campus) => {
      // refresh the page because we need to update top component's props and
      // that's the simplest way to achieve that
      this.$emit('campusChanged', modifiedCampus.slug);
    });
  }
}
